import { WalletContextState } from "@solana/wallet-adapter-react";
import {
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  RpcResponseAndContext,
  SimulatedTransactionResponse,
  SystemProgram,
  Transaction,
  TransactionSignature,
} from "@solana/web3.js";
import BN from "bn.js";
import {
  DexInstructions,
  Market,
  parseInstructionErrorResponse,
  TokenInstructions,
} from "@charles-seteda/sb-js";
import { createMarket } from "../be-calls/be-calls";

const DEFAULT_TIMEOUT = 15000;

export async function createOnDex(
  connection: Connection,
  wallet: WalletContextState,
  baseTokenMint: string,
  baseMintDecimals: number,
  quoteTokenMint: string,
  quoteMintDecimals: number,
  poolAddress: string
) {
  try {
    const baseMintAddress: PublicKey = new PublicKey(baseTokenMint);
    const quoteMintAddress: PublicKey = new PublicKey(quoteTokenMint);

    let lotSize = "0.001";
    let tickSize = "0.001";
    let baseLotSize: number;
    let quoteLotSize: number;

    baseLotSize = Math.round(10 ** baseMintDecimals * parseFloat(lotSize));
    quoteLotSize = Math.round(10 ** quoteMintDecimals * parseFloat(lotSize) * parseFloat(tickSize));
    //quoteLotSize = parseFloat(tickSize) * 10 ** quoteMintDecimals;
    const dexProgramAddress = "9fX7a67EvdZXhzu3Dnse62Ap2vgHjdiunEBcSwVWjh1E";
    const dexProgramId = new PublicKey(dexProgramAddress);

    const marketAddress = await listMarket({
      connection,
      wallet,
      baseMint: baseMintAddress,
      quoteMint: quoteMintAddress,
      baseLotSize,
      quoteLotSize,
      dexProgramId,
    });

    console.log("createOnDex marketAddress = ", marketAddress.toBase58());

    if (marketAddress) {
      let data = await createMarket(
        poolAddress,
        marketAddress.toBase58(),
        dexProgramAddress,
        baseTokenMint,
        quoteTokenMint
      );

      //console.log("data.status = ", data.status, "data.data.status = ", data.data.status, " data.data.message = ", data.data.message);

      if (data.status !== "RECEIVED")
        console.log("Fail to receive response to createMarket");
      else if (data.data.status !== "OK")
        console.log("Fail to save the market to DB");
      else {
        return "OK";
      }
    } else console.log("Fail to create market");
  } catch (e) {
    console.log(e);
  }

  return "ERROR";
}

export async function listMarket({
  connection,
  wallet,
  baseMint,
  quoteMint,
  baseLotSize,
  quoteLotSize,
  dexProgramId,
}: {
  connection: Connection;
  wallet: WalletContextState;
  baseMint: PublicKey;
  quoteMint: PublicKey;
  baseLotSize: number;
  quoteLotSize: number;
  dexProgramId: PublicKey;
}) {
  const market = Keypair.generate();
  const requestQueue = Keypair.generate();
  const eventQueue = Keypair.generate();
  const bids = Keypair.generate();
  const asks = Keypair.generate();
  const baseVault = Keypair.generate();
  const quoteVault = Keypair.generate();
  const feeRateBps = 0;
  const quoteDustThreshold = new BN(100);

  // console.log("wallet: ", wallet.publicKey!.toBase58());
  // console.log("baseVault: ", baseVault.publicKey.toBase58());
  // console.log("quoteVault: ", quoteVault.publicKey.toBase58());
  // console.log("market: ", market.publicKey.toBase58());
  // console.log("requestQueue: ", requestQueue.publicKey.toBase58());
  // console.log("eventQueue: ", eventQueue.publicKey.toBase58());
  // console.log("bids: ", bids.publicKey.toBase58());
  // console.log("asks: ", asks.publicKey.toBase58());

  async function getVaultOwnerAndNonce() {
    const nonce = new BN(0);
    while (true) {
      try {
        const vaultOwner = await PublicKey.createProgramAddress(
          [market.publicKey.toBuffer(), nonce.toArrayLike(Buffer, "le", 8)],
          dexProgramId
        );
        return [vaultOwner, nonce];
      } catch (e) {
        nonce.iaddn(1);
      }
    }
  }
  const [vaultOwner, vaultSignerNonce] = await getVaultOwnerAndNonce();

  const tx1 = new Transaction();
  tx1.add(
    SystemProgram.createAccount({
      fromPubkey: wallet.publicKey!,
      newAccountPubkey: baseVault.publicKey,
      lamports: await connection.getMinimumBalanceForRentExemption(165),
      space: 165,
      programId: TokenInstructions.TOKEN_PROGRAM_ID,
    }),
    SystemProgram.createAccount({
      fromPubkey: wallet.publicKey!,
      newAccountPubkey: quoteVault.publicKey,
      lamports: await connection.getMinimumBalanceForRentExemption(165),
      space: 165,
      programId: TokenInstructions.TOKEN_PROGRAM_ID,
    }),
    TokenInstructions.initializeAccount({
      account: baseVault.publicKey,
      mint: baseMint,
      owner: vaultOwner,
    }),
    TokenInstructions.initializeAccount({
      account: quoteVault.publicKey,
      mint: quoteMint,
      owner: vaultOwner,
    })
  );

  const tx2 = new Transaction();
  tx2.add(
    SystemProgram.createAccount({
      fromPubkey: wallet.publicKey!,
      newAccountPubkey: market.publicKey,
      lamports: await connection.getMinimumBalanceForRentExemption(
        Market.getLayout(dexProgramId).span
      ),
      space: Market.getLayout(dexProgramId).span,
      programId: dexProgramId,
    }),
    SystemProgram.createAccount({
      fromPubkey: wallet.publicKey!,
      newAccountPubkey: requestQueue.publicKey,
      lamports: await connection.getMinimumBalanceForRentExemption(5120 + 12),
      space: 5120 + 12,
      programId: dexProgramId,
    }),
    SystemProgram.createAccount({
      fromPubkey: wallet.publicKey!,
      newAccountPubkey: eventQueue.publicKey,
      // lamports: await connection.getMinimumBalanceForRentExemption(262144 + 12),
      lamports: await connection.getMinimumBalanceForRentExemption(131072 + 12),
      space: 131072 + 12,
      programId: dexProgramId,
    }),
    SystemProgram.createAccount({
      fromPubkey: wallet.publicKey!,
      newAccountPubkey: bids.publicKey,
      // lamports: await connection.getMinimumBalanceForRentExemption(65536 + 12),
      lamports: await connection.getMinimumBalanceForRentExemption(32768 + 12),
      space: 32768 + 12,
      programId: dexProgramId,
    }),
    SystemProgram.createAccount({
      fromPubkey: wallet.publicKey!,
      newAccountPubkey: asks.publicKey,
      // lamports: await connection.getMinimumBalanceForRentExemption(65536 + 12),
      lamports: await connection.getMinimumBalanceForRentExemption(32768 + 12),
      space: 32768 + 12,
      programId: dexProgramId,
    }),
    DexInstructions.initializeMarket({
      market: market.publicKey,
      requestQueue: requestQueue.publicKey,
      eventQueue: eventQueue.publicKey,
      bids: bids.publicKey,
      asks: asks.publicKey,
      baseVault: baseVault.publicKey,
      quoteVault: quoteVault.publicKey,
      baseMint,
      quoteMint,
      baseLotSize: new BN(baseLotSize),
      quoteLotSize: new BN(quoteLotSize),
      feeRateBps,
      vaultSignerNonce,
      quoteDustThreshold,
      programId: dexProgramId,
      authority: undefined,
    })
  );

  const signedTransactions = await signTransactions({
    transactionsAndSigners: [
      { transaction: tx1, signers: [baseVault, quoteVault] },
      {
        transaction: tx2,
        signers: [market, requestQueue, eventQueue, bids, asks],
      },
    ],
    wallet,
    connection,
  });
  for (let signedTransaction of signedTransactions) {
    const tx = await sendSignedTransaction({
      signedTransaction,
      connection,
    });
  }

  return market.publicKey;
}

export function convertLastAddress(address: string) {
  return address ? address.substring(address.length - 5) : "";
}

export const getUnixTs = () => {
  return new Date().getTime() / 1000;
};

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function signTransactions({
  transactionsAndSigners,
  wallet,
  connection,
}: {
  transactionsAndSigners: {
    transaction: Transaction;
    signers?: Array<Keypair>;
  }[];
  wallet: WalletContextState;
  connection: Connection;
}) {
  const blockhash = (await connection.getRecentBlockhash("max")).blockhash;
  transactionsAndSigners.forEach(({ transaction, signers = [] }) => {
    transaction.recentBlockhash = blockhash;
    transaction.setSigners(
      wallet.publicKey!,
      ...signers.map((s) => s.publicKey)
    );
    if (signers?.length > 0) {
      transaction.partialSign(...signers);
    }
  });
  const tx = await wallet.signAllTransactions!(
    transactionsAndSigners.map(({ transaction }) => transaction)
  );
  return tx
}

export async function sendSignedTransaction({
  signedTransaction,
  connection,
  sendingMessage = "Sending transaction...",
  sentMessage = "Transaction sent",
  successMessage = "Transaction confirmed",
  timeout = DEFAULT_TIMEOUT,
  sendNotification = false,
}: {
  signedTransaction: Transaction;
  connection: Connection;
  sendingMessage?: string;
  sentMessage?: string;
  successMessage?: string;
  timeout?: number;
  sendNotification?: boolean;
}): Promise<string> {
  const rawTransaction = signedTransaction.serialize();
  const startTime = getUnixTs();
  if (sendNotification) {
    console.log({ message: sendingMessage });
  }
  const txid: TransactionSignature = await connection.sendRawTransaction(
    rawTransaction,
    {
      skipPreflight: true,
    }
  );
  if (sendNotification) {
    console.log({ message: sentMessage, type: "success", txid });
  }

  console.log("Started awaiting confirmation for", txid);

  let done = false;
  (async () => {
    while (!done && getUnixTs() - startTime < timeout) {
      connection.sendRawTransaction(rawTransaction, {
        skipPreflight: true,
      });
      await sleep(300);
    }
  })();
  try {
    await awaitTransactionSignatureConfirmation(txid, timeout, connection);
  } catch (err) {
    console.log("sendSignedTransaction err:", err);
    /*
    if (err.timeout) {
      throw new Error('Timed out awaiting confirmation on transaction');
    }
    */
    let simulateResult: SimulatedTransactionResponse | null = null;
    try {
      simulateResult = (
        await simulateTransaction(connection, signedTransaction, "single")
      ).value;
    } catch (e) {}
    if (simulateResult && simulateResult.err) {
      if (simulateResult.logs) {
        for (let i = simulateResult.logs.length - 1; i >= 0; --i) {
          const line = simulateResult.logs[i];
          if (line.startsWith("Program log: ")) {
            throw new Error(
              "Transaction failed: " + line.slice("Program log: ".length)
            );
          }
        }
      }
      let parsedError;
      if (
        typeof simulateResult.err == "object" &&
        "InstructionError" in simulateResult.err
      ) {
        const parsedErrorInfo = parseInstructionErrorResponse(
          signedTransaction,
          (simulateResult as any).err["InstructionError"]
        );
        parsedError = parsedErrorInfo.error;
      } else {
        parsedError = JSON.stringify(simulateResult.err);
      }
      throw new Error(parsedError);
    }
    throw new Error("Transaction failed");
  } finally {
    done = true;
  }
  // if (sendNotification) 
  {
    console.log("sendSignedTransaction: ", { message: successMessage, type: "success", txid });
  }

  console.log("Latency", txid, getUnixTs() - startTime);
  return txid;
}

async function awaitTransactionSignatureConfirmation(
  txid: TransactionSignature,
  timeout: number,
  connection: Connection
) {
  let done = false;
  const result = await new Promise((resolve, reject) => {
    (async () => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        console.log("Timed out for txid", txid);
        reject({ timeout: true });
      }, timeout);
      try {
        connection.onSignature(
          txid,
          (result) => {
            console.log("WS confirmed", txid, result);
            done = true;
            if (result.err) {
              reject(result.err);
            } else {
              resolve(result);
            }
          },
          "recent"
        );
        console.log("Set up WS connection", txid);
      } catch (e) {
        done = true;
        console.log("WS error in setup", txid, e);
      }
      while (!done) {
        // eslint-disable-next-line no-loop-func
        (async () => {
          try {
            const signatureStatuses = await connection.getSignatureStatuses([
              txid,
            ]);
            const result = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!result) {
                console.log("REST null result for", txid, result);
              } else if (result.err) {
                console.log("REST error for", txid, result);
                done = true;
                reject(result.err);
              } else if (!result.confirmations) {
                console.log("REST no confirmations for", txid, result);
              } else {
                console.log("REST confirmation for", txid, result);
                done = true;
                resolve(result);
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
          }
        })();
        await sleep(300);
      }
    })();
  });
  done = true;
  return result;
}

async function simulateTransaction(
  connection: Connection,
  transaction: Transaction,
  commitment: Commitment
): Promise<RpcResponseAndContext<SimulatedTransactionResponse>> {
  // @ts-ignore
  transaction.recentBlockhash = await connection._recentBlockhash(
    // @ts-ignore
    connection._disableBlockhashCaching
  );

  const signData = transaction.serializeMessage();
  // @ts-ignore
  const wireTransaction = transaction._serialize(signData);
  const encodedTransaction = wireTransaction.toString("base64");
  const config: any = { encoding: "base64", commitment };
  const args = [encodedTransaction, config];

  // @ts-ignore
  const res = await connection._rpcRequest("simulateTransaction", args);
  if (res.error) {
    throw new Error("failed to simulate transaction: " + res.error.message);
  }
  return res.result;
}

import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiOutlineSelector } from "react-icons/hi";
import "./index.css"
import { SB_MINT_ADDRESS } from "../../utils/ids";

export const tokensData = [
    { name: "custom", address:"", symbol: "Custom Token", decimals: 0},
    { name: "RZR", address: SB_MINT_ADDRESS, symbol: "RZR", decimals: 8},
    { name: "solETH", address: "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk", symbol: "solETH", decimals: 6},
    { name: "solBTC", address: "Wbt2CgkkD3eVckD5XxWJmT8pTnFTyWrwvGM7bUMLvsM", symbol: "solBTC", decimals: 6},
    { name: "RAY", address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R", symbol: "RAY", decimals: 6},
];

export const ListTokens = (props) => {
 
  
    
  return (
      <Listbox value={tokensData[1]}  disabled={props.disabled}>
        <div className="relative list-menu">
          <Listbox.Button className=" relative w-12/12 cursor-default rounded-lg bg-[#1F2934] py-1 pl-3  text-white text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate list-header">Change approved borrower</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <HiOutlineSelector
                className="h-5 w-5 text-white"
                aria-hidden="true"
            />
          </span>
          </Listbox.Button>
          <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 cursor-pointer max-h-28 w-12/12 z-40 overflow-auto rounded-md  bg-[#1F2934] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {tokensData.map((token, tokenId) => (
                  <Listbox.Option
                      key={tokenId + 1}
                      className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-4 pr-4 ${
                              active ? "bg-amber-100 text-[#687D95]" : "text-white"
                          }`
                      }
                      value={token}
                  >
                    {({ selected }) => (
                        <>
                    <span
                        className={`block truncate list-header ${
                            selected ? "font-medium" : "font-normal"
                        }`}
                    >
                      {token?.symbol}
                    </span>
                        </>
                    )}
                  </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
  );
};

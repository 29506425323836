import styled from "styled-components";

export const TableWarpper = styled.div`
  width: 100%;
  .table-btn{
    background: rgba(1, 192, 252, 0.3);
    border: 1px solid #01C0FC;
    border-radius: 6px;
    padding:1px 7px;
    span{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
        color: #FFFFFF;
    }
  }
.disable-btn {
  background: #334150;
  border: none;
}
  .table-warpper{
    width:100%;
    display: flex;
    gap: 5px;
  }

  .table-overflow-wrapper{
   height:350px;

   &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
   }
 
   &::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,.1);
    border-radius: 3px;
 
    &:hover {
     background: rgba(255,255,255,.2);
    }
   }
   
  }

  .header-div {
    padding: 10px 3px;
  }
  .table-header-main {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #ffffff;
  }
  .table-one {
    width: 40%;
    th{
        text-align: inherit;
    }
    .table-card {
      table {
        border-collapse: separate;
        border-spacing: 0px;
        border-radius: 1.1em;
        tr:nth-child(odd) {
          background-color: #12181f;
        }
        tr:nth-child(even) {
          background-color: #161d23;
        }
      }

      background: #1a232b;
      border-radius: 8px;
      padding: 10px 10px;
      diplay: flex;
      flex-direction: column;
      .line-break {
        border-bottom: 2px solid #28333f;
      }
      .table-header {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        text-transform: uppercase;
        color: #687d95;
      }
      .table-text {
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        color: #ffffff;
      }
    }
  }
  .table-two {
    width: 59%;
    th{
        text-align: inherit;
    }
    .table-card {
      table {
        border-collapse: separate;
        border-spacing: 0px;
        border-radius: 1.1em;
        tr:nth-child(odd) {
          background-color: #12181f;
        }
        tr:nth-child(even) {
          background-color: #161d23;
        }
      }

      background: #1a232b;
      border-radius: 8px;
      padding: 10px 10px;
      diplay: flex;
      flex-direction: column;
      .line-break {
        border-bottom: 2px solid #28333f;
      }
      .table-header {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        text-transform: uppercase;
        color: #687d95;
      }
      .table-text {
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        color: #ffffff;
      }
    }
  }

  
`;

export const ButtonWithoutEffect = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;
  gap: 10px;
  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;
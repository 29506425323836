import styled from "styled-components";

 
export const Wrapper = styled.div`
.btn-text{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px; 
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  color: #FFFFFF;
}
.arrow-btn{
  transition: 0.5s;
}

  button.modal-button {
    position: relative;
    border: 2px solid transparent;
    border-radius: 6px;
    padding: 9px 15px;
    width: 263px;
    height: 52px;
    transition: 0.5s;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
        border-box;
      border: inherit;
      border-radius: inherit;
      mask: linear-gradient(rgba(1, 192, 252, 0.7), rgba(1, 192, 252, 0.7))
          padding-box,
        linear-gradient(white, white);
      -webkit-mask-composite: xor;
    }
  }

  button.modal-button:hover {
    .arrow-btn{
    margin-left:0.5rem;
    }

    position: relative;
    border: 2px solid transparent;
    border-radius: 6px;
    padding: 9px 15px;
    width: 263px;
    height: 52px;
    // border-width: 3px;
    // color: white;
    box-shadow: white;
    filter: drop-shadow(0px 3px 9px rgba(255, 255, 255, 0.46));
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
        border-box;
      border: inherit;
      border-radius: inherit;
      mask: linear-gradient(white, white) padding-box,
        linear-gradient(white, white);
      -webkit-mask-composite: xor;
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;

  .link-btn {
    width: 263px;
    height: 52px;

    background: rgba(1, 192, 252, 0.3);
    border-radius: 6px;

    text {
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 15px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-feature-settings: "ss02" on, "ss03" on, "ss04" on;

      /* White */

      color: #ffffff;
    }
  }
  .part-one {
    display: flex;
    flex-direction: column;
    background-position-y: bottom 3px;
    background: radial-gradient(
      38.91% 52.69% at 52.01% 67.76%,
      rgba(1, 252, 252, 0.36) 0%,
      rgba(31, 107, 165, 0.36) 25.89%,
      rgba(31, 41, 52, 0.36) 51.34%,
      rgba(22, 29, 35, 0.36) 80.28%,
      rgba(18, 24, 31, 0.36) 100%,
      rgba(18, 24, 31, 0.36) 100%
    );
    border-radius: 18px;
    padding-bottom: 9rem;
    margin-top: 2rem;

    .text-one {
      display: flex;
      flex-direction: column;

      .header,
      .sub-header {
        font-style: normal;
        font-weight: 600;

        text-align: center;
        letter-spacing: -1px;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .header {
        font-size: 52px;
        font-family: "Inter";
        line-height: 60px;
      }

      .sub-header {
        line-height: 48px;
        font-family: "Inter";
        font-size: 40px;
      }
    }
    .text-two {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
      color: #ffffff;
      margin-top: 2rem;
    }
  }

  .part-two {
    margin-top: -5rem;
    background: #1a232b;
    width: 1020px;
    max-width: 1020px;
    height: 596px;
    border: 2px solid #1184a6;
    box-shadow: 2px 8px 50px rgba(0, 137, 214, 0.38);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .credora-link {
    }

    .section-one {
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
      margin-left: 2rem;

      img {
        width: 174.35px;
        height: 39px;
      }

      .section-one-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        color: #8eda87;
        margin-top: 1rem;
      }
    }

    .section-two {
      display: flex;

      justify-content: space-between;
      .first-half {
        width: 55%;
        img {
          max-width: 90%;
          max-height: 100%;
        }
        .second-half {
          width: 45%;
        }
      }

      .second-half {
        display: flex;
        flex-direction: column;
        width: 45%;
        gap: 2rem;
        align-self: center;
        .header {
          font-family: "Space Grotesk";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
          color: #ffffff;
        }

        .each-part {
          display: flex;
          align-items: center;
          gap: 10px;
          .img {
            background-image: url("https://res.cloudinary.com/drr1rnoxf/image/upload/v1663075746/Group_1340_hratis.svg");
            height: 45px;
            width: 45px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            display: flex;
            justify-content: center;
            align-items: center;
            .text {
              font-family: "Space Grotesk";
              font-style: normal;
              font-weight: 500;
              font-size: 16.8449px;
              line-height: 29px;
              text-align: center;
              text-transform: uppercase;
              font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
              color: #ffffff;
            }
          }

          .desc {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
            color: #ffffff;
          }
        }
      }
    }
  }
`;
export const ModalWrapper = styled.div`
  button.modal-button {
    position: relative;
    border: 3px solid transparent;
    border-radius: 6px;
    padding: 9px 24px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
        border-box;
      border: inherit;
      border-radius: inherit;
      mask: linear-gradient(rgba(1, 192, 252, 0.7), rgba(1, 192, 252, 0.7))
          padding-box,
        linear-gradient(white, white);
      -webkit-mask-composite: xor;
    }
  }

  button.modal-button:hover {
    position: relative;
    border: 3px solid transparent;
    border-radius: 6px;
    padding: 9px 24px;
    // border-width: 3px;
    // color: white;
    box-shadow: white;
    filter: drop-shadow(0px 3px 9px rgba(255, 255, 255, 0.46));
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
        border-box;
      border: inherit;
      border-radius: inherit;
      mask: linear-gradient(white, white) padding-box,
        linear-gradient(white, white);
      -webkit-mask-composite: xor;
    }
  }
`;


export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 14px;
  gap: 10px;
  width: 263px;
  height: 52px;
  background: rgba(1, 192, 252, 0.3);
  border-radius: 6px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid rgba(0, 133, 255, 1);
  transition: all 0.3s ease-in;

  &:hover {
    background: transparent;
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }

  @media (max-width: 600px) {
    padding: 13px 26px;
  }
`;

export const FundedButton = styled(Button)`
  position: relative;
  display: block;
  transition: 0.5s;
  display: flex;

  &:hover .buttonLeft {
    padding-right: 260px;

    @media (max-width: 600px) {
      padding-right: 180px;
    }
  }

  &:hover .buttonRight {
    opacity: 1;
    padding-left: 15px;
    transform: translateY(-10px);
     
  }

  &:hover .arrow-btn{
    
      margin-left:0.5rem;
      
  }
  @media (max-width: 600px) {
    padding: 13px 17px;
  }
`;

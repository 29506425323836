import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { FC, useEffect, useState } from 'react';
import { IApprovalInfoForAdminRequest } from '../../utils/interfaces';
import { getBNfromUint8Array, shortAddress } from '../../utils/utils';
import { PublicKey } from '@solana/web3.js';
import { EMPTY_ADDRESS } from '../../utils/ids';
import {
  BN_SIZE,
  CHANGE_ADMIN_ADDRESS,
  CHANGE_ADMIN_LIMIT,
  CHANGE_APPROVED_ADDRESS,
  CHANGE_APPROVED_LIMIT,
  CHANGE_APPROVED_REQUEST_FEE,
  CHANGE_APPROVED_WITHDRAW_FEE,
  CHANGE_CLAIM_FEE,
  CHANGE_COMMIT_FEE,
  CHANGE_EXTRA_PERIOD,
  CHANGE_GAS_FEE,
  CHANGE_GOVERNOR_ADDRESS,
  CHANGE_UNAPPROVED_REQUEST_FEE,
  CHANGE_UNAPPROVED_WITHDRAW_FEE,
} from '../../utils/constants';
import { TableCell, TableRow } from '@material-ui/core';
// import { approvePendingUpdation } from '../../utils/instructions';

export const PendingUpdationForAdminInfo: FC<{
  pendingUpdation: IApprovalInfoForAdminRequest;
  adminList: string[];
  approvalLimit: number;
  approverList: string[];
  poolRequestLimit: number;
  requestFeeApproved: number;
  withdrawalFeeApproved: number;
  requestFeeUnapproved: number;
  withdrawalFeeUnapproved: number;
  commitFee: number;
  claimFee: number;
  gasFee: number;
  extraPeriod: number;
  feeDecimalValue: number;
  governor: string;
}> = ({
  pendingUpdation,
  adminList,
  approvalLimit,
  approverList,
  poolRequestLimit,
  requestFeeApproved,
  withdrawalFeeApproved,
  requestFeeUnapproved,
  withdrawalFeeUnapproved,
  commitFee,
  claimFee,
  gasFee,
  extraPeriod,
  feeDecimalValue,
  governor,
}) => {
  console.log(pendingUpdation.account.approvedFlagList)
  const { connection } = useConnection();
  const wallet = useWallet();
  const [alreadyApprovedFlag, setAlreadyApprovedFlag] = useState(false);

  const [oldValue, setOldValue] = useState(0);
  const [newValue, setNewValue] = useState(0);
  const [id, setID] = useState(0);
  const [oldAddress, setOldAddress] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [issueType, setIssueType] = useState(0);

  // const onApproveClick = async () => {
  //   const res = await approvePendingUpdation(connection, wallet, pendingUpdation.publicKey);
  //   console.log(res);
  // };

  useEffect(() => {
    getTransactionData();
  }, [wallet]);

  useEffect(() => {
    if (pendingUpdation.account.admins.findIndex((owner) => owner.toBase58() === wallet.publicKey?.toBase58()) === -1)
      setAlreadyApprovedFlag(true);
    else setAlreadyApprovedFlag(pendingUpdation.account.approvedFlagList[id]);
  }, [pendingUpdation]);

  const getTransactionData = () => {
    let idx = 0;

    let data = pendingUpdation.transaction.data.reverse();
    const value = getBNfromUint8Array(data, idx, BN_SIZE);
    idx += BN_SIZE;

    let tmp_pubkey = data.slice(idx, idx + 32);
    setNewAddress(new PublicKey(tmp_pubkey.reverse()).toBase58());
    idx += 32;
    const array_id = getBNfromUint8Array(data, idx, BN_SIZE);
    idx += BN_SIZE;
    const flag = getBNfromUint8Array(data, idx, BN_SIZE);

    setID(array_id);
    setIssueType(flag);

    switch (flag) {
      case CHANGE_ADMIN_ADDRESS:
        setOldAddress(adminList[array_id]);
        setNewValue(value);
        break;
      case CHANGE_ADMIN_LIMIT:
        setOldValue(approvalLimit);
        setNewValue(value);
        break;
      case CHANGE_APPROVED_ADDRESS:
        setOldAddress(approverList[array_id]);
        setNewValue(value);
        break;
      case CHANGE_APPROVED_LIMIT:
        setOldValue(poolRequestLimit);
        setNewValue(value);
        break;
      case CHANGE_APPROVED_REQUEST_FEE:
        setOldValue(requestFeeApproved);
        setNewValue(value / feeDecimalValue);
        break;
      case CHANGE_APPROVED_WITHDRAW_FEE:
        setOldValue(withdrawalFeeApproved);
        setNewValue(value);
        break;
      case CHANGE_UNAPPROVED_REQUEST_FEE:
        setOldValue(requestFeeUnapproved);
        setNewValue(value / feeDecimalValue);
        break;
      case CHANGE_UNAPPROVED_WITHDRAW_FEE:
        setOldValue(withdrawalFeeUnapproved);
        setNewValue(value);
        break;
      case CHANGE_COMMIT_FEE:
        setOldValue(commitFee);
        setNewValue(value);
        break;
      case CHANGE_CLAIM_FEE:
        setOldValue(claimFee);
        setNewValue(value);
        break;
      case CHANGE_GAS_FEE:
        setOldValue(gasFee);
        setNewValue(value / feeDecimalValue);
        break;
      case CHANGE_EXTRA_PERIOD:
        setOldValue(extraPeriod);
        setNewValue(value);
        break;
      case CHANGE_GOVERNOR_ADDRESS:
        setOldAddress(governor);
        setNewValue(value);
        break;
    }

    if (pendingUpdation.account.admins.findIndex((owner) => owner.toBase58() === wallet.publicKey?.toBase58()) === -1)
      setAlreadyApprovedFlag(true);
    else setAlreadyApprovedFlag(pendingUpdation.account.approvedFlagList[id]);
  };

  return (
    <TableRow key={pendingUpdation.publicKey.toBase58()}>
      <TableCell align="center" style={{ fontFamily: "Space Grotesk", color: "#ffffff" }}>
        {issueType === CHANGE_ADMIN_ADDRESS
          ? 'Admin Address'
          : issueType === CHANGE_ADMIN_LIMIT
          ? 'Admin Limit'
          : issueType === CHANGE_APPROVED_ADDRESS
          ? 'Approved Address'
          : issueType === CHANGE_APPROVED_LIMIT
          ? 'Approved Limit'
          : issueType === CHANGE_APPROVED_REQUEST_FEE
          ? 'Approved Request Fee'
          : issueType === CHANGE_APPROVED_WITHDRAW_FEE
          ? 'Approved Withdraw Fee'
          : issueType === CHANGE_UNAPPROVED_REQUEST_FEE
          ? 'Unapproved Request Fee'
          : issueType === CHANGE_UNAPPROVED_WITHDRAW_FEE
          ? 'Unapproved Withdraw Fee'
          : issueType === CHANGE_COMMIT_FEE
          ? 'Commit Fee'
          : issueType === CHANGE_CLAIM_FEE
          ? 'Claim Fee'
          : issueType === CHANGE_GAS_FEE
          ? 'Gas Fee'
          : issueType === CHANGE_EXTRA_PERIOD
          ? 'Extra Period'
          : issueType === CHANGE_GOVERNOR_ADDRESS
          ? 'Change Governor Address'
          : 'Unknown Issue'}
      </TableCell>
      <TableCell align="center" style={{ fontFamily: "Space Grotesk", color: "#ffffff" }}>{id}</TableCell>
      <div className="grow px-3" style={{ width: "80%" }}>
        {oldAddress && oldAddress !== 'NotSet' ? shortAddress(oldAddress) : 'NotSet'}
        <br />
        {shortAddress(newAddress)}
      </div>
      {/* <TableCell align="center">
        {oldAddress && oldAddress !== 'NotSet' ? shortAddress(oldAddress) : 'NotSet'}
        <br />
        {shortAddress(newAddress)}
      </TableCell> */}
      <TableCell align="center" style={{ fontFamily: "Space Grotesk", color: "#ffffff" }}>
        {oldValue}
        <br />
        {newValue}
      </TableCell>
      {pendingUpdation.account.admins
        .filter((admin) => admin.toBase58() !== EMPTY_ADDRESS)
        .map((admin, i) => (
          <TableCell align="center" style={{ whiteSpace: 'nowrap', fontFamily: "Space Grotesk", color: "#ffffff" }}>
            {/* {shortAddress(admin.toBase58())} <br /> */}
            {pendingUpdation.account.approvedFlagList[i] ? <>Approved</> : <> Not Approved </>}
          </TableCell>
        ))}
      {/* <TableCell align="center" style={{ fontFamily: "Space Grotesk", color: "#ffffff" }}>
        {pendingUpdation.account.approvalLimit} <br />
        <button disabled={alreadyApprovedFlag} onClick={onApproveClick}>
          Approve
        </button>
      </TableCell> */}
    </TableRow>
  );
};

import styled from "styled-components";

// import Inter from "../../assets/fonts/Inter-Regular.ttf";
// import SpaceGrotesk from "../../assets/fonts/SpaceGrotesk-Regular.ttf";

export const CardWrapper = styled.div`
 
button.modal-button {
  position: relative;
  border: 3px solid transparent;
  border-radius: 6px;
  padding: 9px 24px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
      border-box;
    border: inherit;
    border-radius: inherit;
    mask: linear-gradient(rgba(1, 192, 252, 0.7), rgba(1, 192, 252, 0.7))
        padding-box,
      linear-gradient(white, white);
    -webkit-mask-composite: xor;
  }
}

button.modal-button:hover {
  position: relative;
  border: 3px solid transparent;
  border-radius: 6px;
  padding: 9px 24px;
  // border-width: 3px;
  // color: white;
  box-shadow: white;
  filter: drop-shadow(0px 3px 9px rgba(255, 255, 255, 0.46));
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
      border-box;
    border: inherit;
    border-radius: inherit;
    mask: linear-gradient(white, white) padding-box,
      linear-gradient(white, white);
    -webkit-mask-composite: xor;
  } 
}

.extraClass {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #FFFFFF !important;
  pointer-events: auto !important;
   border-radius:8px  !important;
   
    
   
  &:hover {
 visibility: visible !important;
 opacity: 1 !important;
  }
 }
 .__react_component_tooltip .multi-line{
  text-align: left !important;

 }
    
 
  font-family: "Space Grotesk";
  background: #161d23;
  border-radius: 16px;
  width: 603px;
  display: flex;

  .info-dot{
    width:6px;
    height:6px;
    margin-top:7px;
    margin-left:6px;
    line-height: 20px;
    transition: all 0.3s ease-in-out 0s;
     &:hover{
      transform: scale(1.8); 
     }
  }
  
  
  .style61::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 10px;
    background-color: #f5f5f500;
  }
  .style61::-webkit-scrollbar-track {
    background: transparent;
    -webkit-box-shadow: inset 0 0 20px rgba(142, 142, 142, 0.3);
    margin: 0 580px;
    border-radius: 10px;
  }

  .style61::-webkit-scrollbar-thumb {
    background-color: #7cfa4c;
    -webkit-box-shadow: none;
    border-bottom: none;
    border-radius: 10px;
    border-top: none;
  }

  .style61::-webkit-scrollbar-track-piece:end {
    background: transparent;
  }

  .style61::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }

  .bg-gradient:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  p {
    margin-bottom: 3px;
  }
  .text-white-50 {
    color: #8f9092;
  }
  .style1 {
    max-width: 603px;
    background: linear-gradient(
        180.07deg,
        rgba(34, 151, 241, 0.3) 0.07%,
        rgba(1, 252, 252, 0.3) 8.81%
      ),
      #161d23;
    box-shadow: 0px 1px 19px 2px rgba(1, 252, 252, 0.12);
    border-radius: 12px;
    position: initial;
  }
  @media (max-width: 640px) {
    .style1 {
      height: 100vh;
    }
  }

  .style59 {
    flex-grow: 1;
    position: relative;
    background: #161d23;
    border-radius: 10px;
    margin: 0 3px 2px;
  }
  /* .style60{overflow: visible; height: 0px; width: 0px; height: 95%;} */
  .style61 {
    margin-top: 30px;
    position: relative;
    height: 100%;
    width: 603px;
    overflow: auto;
    will-change: transform;
  }
  .style62 {
    width: 100%;
  }
  /* .style113{width: 449px; height: 542px;} */

  .border-div-top {
    border-top: 1px solid #3d4f61;
  }
  .label-text {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    /* identical to box height, or 200% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;

    /* White */

    color: #ffffff;

    /* x margin */
  }
  .text-shadow {
    text-shadow: 3px 2px 10px rgba(1, 252, 252, 0.55);
  }
  .grey-text {
    color: #687d95;
  }
`;

export const DetailCard = styled.div.attrs(
  (props: { active: Boolean }) => props
)`
  width: 603px;
  margin: 6px;
  height: 100%;
  background: ${(props) =>
    props.active
      ? "linear-gradient(180.07deg, rgba(34, 151, 241, 0.3) 0.07%, rgba(1, 252, 252, 0.3) 39.82%), #161D23;"
      : "#1A232B"};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;
  gap: 10px;
  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;

export const TextGradient = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;


export const ButtonNew = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  gap: 10px;
  margin-top: 1rem;
 
  background: rgba(1, 192, 252, 0.3);
  border-radius: 6px;
 
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid rgba(0, 133, 255, 1);
  transition: all 0.3s ease-in;

  &:hover {
    background: transparent;
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }

  @media (max-width: 600px) {
    padding: 13px 26px;
  }
`;

export const FundedButton = styled(ButtonNew)`
  position: relative;
  display: block;
  transition: 0.5s;
  display: flex;

  &:hover .buttonLeft {
    padding-right: 260px;

    @media (max-width: 600px) {
      padding-right: 180px;
    }
  }

  &:hover .buttonRight {
    opacity: 1;
    padding-left: 15px;
    transform: translateY(-10px);
     
  }

  &:hover .arrow-btn{
    
      margin-left:0.5rem;
      
  }
  @media (max-width: 600px) {
    padding: 13px 17px;
  }
`;

import { CreatePool } from "../../../components";
import { Container, Wrapper, ContentWrapper, Main, NextBtn, SkipBtn, RestartText } from "./styles";
import { Toggle } from "rsuite";
import { useState } from "react";
import "./index.css";
import Tour from "reactour";
import { useUIContext } from "../../../context/AppContext";

const tourConfig: any = [
  { selector: '[data-tut="createpool__1"', content: () => (
    <>
      <div className="title-tour">Create new Pool Info </div>
      <div className="desc-tour">This is where you create your borrower pool.</div>
    </>
  ), position: 'left' },
  { selector: '[data-tut="createpool__2"', content: () => (
    <>
      <div className="title-tour">SET UP BORROWING PERIOD</div>
      <div className="desc-tour">This is where you set up the borrowing period details</div>
    </>
  ), position: 'left' },
  { selector: '[data-tut="createpool__3"', content: () => (
    <>
      <div className="title-tour">SET UP BORROWING amount </div>
      <div className="desc-tour">This is where you set up the borrowing amount details</div>
    </>
  ), position: 'left' },
  { selector: '[data-tut="createpool__4"', content: () => (
    <>
      <div className="title-tour">add collateral and create pool </div>
      <div className="desc-tour">This is where you add collateral and create your pool</div>
    </>
  ), position: 'left' },
];

const CreateNewPool = () => {
  const {showGuide, setShowGuide, setShowWalkthrough, showWalkthrough} = useUIContext();
  const [isTourOpen, setIsTourOpen] = useState(showGuide);
  const [curStep, setCurStep] = useState(0);

  const onFinishTour = () => {
    setShowWalkthrough(false)
    setCurStep(0)
  }

  const onRestartWalk = () => {
    setShowWalkthrough(true)
    setCurStep(0)
  }
  
  return (
    <Main className="relative">
      <Container>
        <Wrapper>
          <ContentWrapper>
            <CreatePool showGuide={showGuide} />
          </ContentWrapper>
        </Wrapper>
      </Container>
      {showWalkthrough && <Tour
        isOpen={showWalkthrough}
        className="helper"
        steps={tourConfig}
        closeWithMask={false}
        onRequestClose={onFinishTour}
        badgeContent={(curr: number, tot: number) => `STEP ${curr}/${tot}`}
        nextButton={<NextBtn>Next</NextBtn>}
        getCurrentStep={setCurStep}
        goToStep={curStep}
        prevButton={curStep !=3 ?<SkipBtn onClick={onFinishTour}>Skip</SkipBtn>: <div></div>}
        lastStepNextButton={<NextBtn onClick={onFinishTour}>Finish</NextBtn>} 
        showNavigation={false}
        disableInteraction={true}
      ></Tour>}
      <div className="absolute gap-2 restart-section over-mask" onClick={onRestartWalk}>RESTART WALKTROUGH</div>
      <div className="absolute flex gap-2 guide-sectiion over-mask">
        <Toggle
          checked={showGuide}
          size="sm"
          className="self-center"
          onChange={(e) => setShowGuide(e)}
        />
        <text className="guide-header">guide on</text>
      </div>
    </Main>
  );
};

export default CreateNewPool;

import styled from "styled-components";

export const Main = styled.div`
  padding-bottom: 200px;
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  .menu-color {
    color: #4D647B;
    letter-spacing: 0.3px;
text-transform: uppercase;
 a {
  cursor: pointer;
  margin: 2px 10px;
  padding-bottom: 3px;
 }
  }
  .active-item {
  }
`;
export const TopSearchBar = styled.div``;
export const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  
`;

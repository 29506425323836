import {
  Wrapper,
  MainContainer,
  Content,
  Left,
  Right,
  Title,
  Description,
} from "./styles";

import toast from "react-hot-toast";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";

type PropsDates = {
  dateContent: any;
  topContent: any;
  middleContent: any;
};

const StatusRight = ({
  dateContent,
  topContent,
  middleContent,
}: PropsDates) => {
  const CopyAction = () => {
    toast.success("Copied Successfully!");
  };

  return (
    <MainContainer
      heightTop={topContent.title}
      heightBottom={middleContent.statusContent.title}
    >
      <Wrapper>
        {dateContent.map((date: any, index: any) => {
          return (
            <Content className={index === 0 ? 'pt-3' : ''} key={index}>
              <Left>
                <Title>{date.startDateTitle}</Title>
                <div className="flex self-center">
                  <Description>
                    {date.startDate === "Not Started" ? "TBD" : date.startDate}
                  </Description>
                  {date.startDateTitle === "Pool Token Address" && (
                    <CopyToClipboard onCopy={CopyAction} text={date.startDate}>
                      <BiCopy className=" self-center ml-1 text-[#01C0FC] text-base cursor-pointer" />
                    </CopyToClipboard>
                  )}
                </div>
              </Left>
              <Right>
                <Title>{date.startTimeTitle}</Title>
                <Description>
                  {" "}
                  {date.startTime === "Not Started" ? "TBD" : date.startTime}
                </Description>
              </Right>
            </Content>
          );
        })}
      </Wrapper>
    </MainContainer>
  );
};

export default StatusRight;

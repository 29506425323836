import styled from "styled-components";
 

export const Wrapper = styled.div`
 width:100%;
 display:flex;
 flex-direction:column;
 gap:5rem;
 .input-section{
    width:100%
    dipsplay:flex;
    flex-direction:column;
    background: #1A232B;
    border-radius: 12px;
    height: 194px;
    padding: 21px 31px;
    .heading{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 30px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
        color: #FFFFFF;
    }

    .line-breaker{
        border-bottom: 2px solid  rgba(1, 192, 252, 0.3);
        margin-top:0.5rem;
    }
    .input-wrapper{
        display:flex;
        height:80%;
        align-items:center;

        .first-half{
            width:40%;
        }
         .second-half{
            width:60%;
            display:flex;
            gap:10px;

            .combined-section{
                display:flex;
                flex-direction:column;
                .label{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: #4D647B;
                }
            }
         }
    }

 }
`;
export const Input = styled.input`
  width: ${({ width }) => width || "340px"};
  height: 40px;
  background: #161d23;
  border-radius: 6px;
  border: 1px solid transparent;
  outline: none;
  padding-left: 12px;
  padding-right: ${(props) => (props.padding ? props.padding : "0px")};
  font-size: 12px;
  color: #ffffff;
  font-family: "Space Grotesk";
  font-weight: 500;
  &:focus {
    border: 1px solid rgba(1, 192, 252, 0.8);
    /* box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5); */
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #ffffff;
    }
    :-ms-input-placeholder {
      color: #ffffff;
    }
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
  :-ms-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
`;
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 14px;
  gap: 10px;
  width: 107px;
  height: 40px;
  background: rgba(1, 192, 252, 0.3);
  border-radius: 6px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid rgba(0, 133, 255, 1);
  transition: all 0.3s ease-in;

  &:hover {
    background: transparent;
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }

  @media (max-width: 600px) {
    padding: 13px 26px;
  }
`;

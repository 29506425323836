
import {
    Container,
    Wrapper,
    TopSearchBar,
    ContentWrapper,
    Main,
  } from "./style";
  import Account from "../../components/DaoAccount"
   
  const DaoAccount = () => {
      
    return (
      <>
        <Main>
          <Container>
            <Wrapper>
            
              <ContentWrapper>
              <Account />
               
              </ContentWrapper>
            </Wrapper>
          </Container>
        </Main>
        
      </>
    );
  };
  
  export default DaoAccount;
  
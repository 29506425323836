import {
  Container,
  Wrapper,
  TopSearchBar,
  ContentWrapper,
  Main,
  TitleTxt,
} from "./style";
import AuctionHistory from "../../components/Auction";
import { useEffect, useState } from "react";
import {
  getPoolsByState,
  getPoolsWithNFTCollateral,
} from "../../be-calls/be-calls";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useDAOContext } from "../DAO/data";
import { shortAddress } from "../../utils/utils";
import toast from "react-hot-toast";
import Stakers from "../../components/TopStakers/index";

const TopStaker = () => {
  const [listItem, setListItem] = useState([]);
  const wallet = useWallet();
  const { rank, yesGov, currentEpoch, period, refreshData, setRefreshData } =
    useDAOContext();
  const { connection } = useConnection();

  const confirmGov = async () => {
    if (wallet?.connected) {
      try {
        if (period === "PRE_GRACE") {
          await yesGov(connection, wallet, currentEpoch);
          setRefreshData(!refreshData);
          toast.success("Become GOVERNORS successfully!");
        } else {
          toast.error("Invalid period to become GOVERNORS!");
        }
      } catch (error) {
        toast.error("Invalid period to become GOVERNORS!");
      }
    }
  };

  const checkGob = () => {
    const check = rank?.rank.findIndex(
      (item) => item.toBase58() === wallet.publicKey.toBase58()
    );
    return period === "PRE_GRACE" && check > -1 && !rank?.becomeGovernor[check];
  };

  return (
    <>
      <Main>
        <Container >
          <Wrapper className="mt-10">  
            <div className="flex flex-col">
            
              {/* <ContentWrapper>
                <div class="text-white">
                  {rank && rank?.rank.length > 0 && (
                    <>
                      {rank?.rank.map((item, index) => (
                        <div className="rank mt-2">{`Rank ${index + 1}: ${
                          rank ? shortAddress(rank.rank[index].toBase58()) : ""
                        }`}</div>
                      ))}
                    </>
                  )}
                </div>
              </ContentWrapper> */}
              <Stakers rank={rank} confirmGov={confirmGov}  checkGob={checkGob}/>
              {/* <div>
                {checkGob() && (
                  <div className="label-text text-white">
                    WILLING BECOME GOVERNORS:{" "}
                    <button onClick={confirmGov} className="table-btn">
                      YES
                    </button>
                  </div>
                )}
              </div> */}
            </div>
          </Wrapper>
        </Container>
      </Main>
    </>
  );
};

export default TopStaker;
import LendEmptyPage from "../../components/LendEmptyPage";
import { Container, Wrapper, ContentWrapper, Main } from "./style";

const EmptyPage = ({text,url}:{text:any,url:any}) => {
  return (
    <Main>
      <Container>
        <LendEmptyPage text={text} url={url}/>
      </Container>
    </Main>
  );
};

export default EmptyPage;

import {
  useConnection,
  WalletContextState,
} from "@solana/wallet-adapter-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { FC, useEffect, useState, useRef } from "react";
import {
  AWAITING_FOR_SUBSCRIPTION,
  COUPON_RATE_SCALE,
  decimalUSDC,
  DELAY_TIME,
  EXTRA_PERIOD,
  FINISHED_POOL,
  INVALID_POOL,
  LENDING_EXPIRED,
  LENDING_PERIOD,
  SUBSCRIPTION_PERIOD,
  TIME_UNIT_HOUR, TRADE_ON_DEX_MARKET,
} from "../../utils/constants";
import { IPoolInfo, TokenAccount } from "../../utils/interfaces";
import {
  defaultDecimal,
  delay,
  formatDateTimeInDayAndHours,
  getDateFromSec,
  getDecimal,
  getPoolState,
  getPoolStateText,
  getRoundValue,
  getSolScanUrl,
  getSymbol,
  getTimeFromSec,
  setDecimal,
  shortAddress,
} from "../../utils/utils";
import { stake } from "../../utils/instructions";
import { getMintDecimalValue } from "../../utils/walletManager";
import { FundedArrowImg } from "../../assets";
import StatusRight from "./StatusRight";
import { BorrowerInfoModal } from "./BorrowerInfoModal";

import {
  Container,
  StatusLeft,
  Topbar,
  Heading,
  HeadingContent,
  VerifiedText,
  Score,
  Span,
  ContentWrapper,
  DepositedContent,
  Title,
  DepositedAmount,
  StatusContent,
  TopContent,
  SubTitle,
  MainTitle,
  BottomContent,
  TotalCommited,
  H4,
  Value,
  Borrowers,
  IssuanceContent,
  LeftDivider,
  RightDivider,
  VerticleSidebar,
  CommitContent,
  TopContentWrapper,
  H2,
  H3,
  InputWrapper,
  Button,
  Input,
  ShadowWrapper,
  FundedButton,
  ButtonWrapper,
  RightArrow,
  SpanText,
  InputGroup,
  GlobalStyle,
} from "./styles";
import { TokenInfo } from "@solana/spl-token-registry";
import { PublicKey } from "@solana/web3.js";
import toast from "react-hot-toast";
import {
  getPool,
  updateLender,
  updatePool,
  getVerifiedData,
  getMarket,
} from "../../be-calls/be-calls";
import { BsInfoCircle } from "react-icons/bs";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BN } from "@project-serum/anchor";
import dayjs from "../../utils/dayjs";
import Swal from "sweetalert2";

import { decimalSB } from "../../utils/constants";
import { SB_MINT_ADDRESS, USDC_MINT_ADDRESS } from "../../utils/ids";
import { useUIContext } from "../../context/AppContext";
import { CreditInfoModal } from "./CreditInfoModal";

let regexp = /^\d*\.?\d*$/;

type PoolProps = {
  topContent: {
    title: String;
    xMargin: String;
    scoreValue: String;
    scoreTitle: String;
  };
  middleContent: any;
  dateContent: any;
};

const StatusPool: FC<{
  poolInfo: IPoolInfo;
  feeVault: PublicKey;
  feeMint: PublicKey;
  sbFeeVault: PublicKey;
  userSbVault: PublicKey;
  extraPeriod: number;
  wallet: WalletContextState;
  userAccounts: TokenAccount[];
  tokenMap: Map<string, TokenInfo>;
  style?: Object;
  isDisableBorrowerInfo?: boolean;
  checkVerifiedPool?: boolean
}> = ({
  poolInfo,
  feeVault,
  feeMint,
  sbFeeVault,
  userSbVault,
  extraPeriod,
  wallet,
  userAccounts,
  tokenMap,
  style,
  isDisableBorrowerInfo,
  checkVerifiedPool
}) => {
  const [pInfo, setPInfo] = useState<IPoolInfo>(poolInfo);
  const originalState = poolInfo.account.state;
  const ref = useRef(null);
  const { connection } = useConnection();
  var nf = new Intl.NumberFormat();
  const [collateralDecimal, setCollateralDecimal] = useState(1);
  const [stakeAmount, setStakeAmount] = useState("0");
  const [availableCommitment, setAvailableCommitment] = useState(0);
  let [isModalOpen, setIsModalOpen] = useState(false);
  const [poolState, setPoolState] = useState(AWAITING_FOR_SUBSCRIPTION);
  const [poolStateText, setPoolStateText] = useState("Active Subscription");
  const [pool, setPool] = useState<PoolProps | undefined>(undefined);
  const [disableTradeOnDex, setDisableTradeOnDex] = useState(false);
  const [userAccount, setUserAccount] = useState<any>(undefined);

  const [verifiedData, setVerifiedData] = useState<any>(null);

  let [isFeesModalOpen, setIsFeesModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  function closeFeesModal() {
    setIsFeesModalOpen(false);
  }
  const {adminInfo} = useUIContext()

  const [SBBalance, setSBBalance] = useState(0);
  const [USDCBalance, setUSDCBalance] = useState(0);
  useEffect(() => {
    if (wallet.publicKey) {
      const sbToken = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === SB_MINT_ADDRESS
      );
      if (sbToken) {
        setSBBalance(Number(sbToken.info.amount) / decimalSB);
      }

      const usdcToken = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === USDC_MINT_ADDRESS
      );
      if (usdcToken) {
        setUSDCBalance(Number(usdcToken.info.amount) / decimalUSDC);
      }
    }
  }, [wallet.connected, userAccounts, wallet.publicKey]);


  const openFeesModal = async () => {
    const commitFee = getRoundValue(Number(stakeAmount) * (adminInfo.commitFee/10000), defaultDecimal)
   
    const message = `
    <div class="bg-gray-200 py-3 p-4 mt-3 sm:p-1 rounded-md">
      <div class="table2">
        <table class="w-full">
        ${(commitFee > 0) ? `<tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                 Commit Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${commitFee}</b> USDC</span>
              </td>
            </tr>`: ''}

            <tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                  Gas Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${Number(adminInfo.gasFeeConverted)}</b> RZR</span>
              </td>
            </tr>
        </table>
      </div>
    </div>
    `;
    let ProceedForStake = false;
    await Swal.fire({
      title: "Fees Confirmation",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#01C0FC",
    }).then((result) => {
      if (result.isConfirmed) {
        if(SBBalance < Number(adminInfo.gasFeeConverted) || USDCBalance < Number(commitFee)) {
          toast.error("Not enough balance")
          return 
        }
        stakeUserAmount();
      }
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const stakeUserAmount = async () => {
    if (Number(stakeAmount) > USDCBalance) {
      toast("Insufficient wallet balance");
      return;
    }

    if (Number(stakeAmount) !== 0) {
      // console.log("stakeUserAmount feeMint = ", feeMint.toBase58());
      let info = await stake(
        connection,
        wallet,
        pInfo.publicKey,
        decimalUSDC * Number(stakeAmount),
        userAccount,
        pInfo.account.lendingMint,
        feeVault,
        feeMint,
        sbFeeVault,
        userSbVault,
        pInfo.account.mintPoolToken
      );
      if (!info.tx) toast("Step 1: Commitment failed");
      else if (info.status === "OK") {
        await delay(DELAY_TIME);
        let updatedPoolInfo = await updatePool(pInfo.publicKey.toBase58());

        //Update lender to DB
        await updateLender(info.lenderKey!);

        if (updatedPoolInfo.status === "RECEIVED") {
          await delay(DELAY_TIME);
          //Update new pool data from DB to pInfo
          let poolData = await getPool(pInfo.publicKey.toBase58());

          if (poolData.status === "RECEIVED") {
            setPInfo(poolData.data as IPoolInfo);
            toast("Step 2: Committing funds success");
          }
        }
      }
    }
  };

  const tradeOnDex = async () => {
    /*
    console.log("Prepare to getTokenSupply");
    connection.getTokenSupply(pInfo.account.mintPoolToken).then((data) => {
      console.log("getTokenSupply mintPoolToken = ", pInfo.account.mintPoolToken.toBase58(), " amount = ", data.value.amount);
    });
    */

    let info = await getMarket(pInfo.publicKey.toBase58());
    if (info.status === "RECEIVED") {
      let marketLink = TRADE_ON_DEX_MARKET + info.data;
      window.open(marketLink);
    } else {
      toast("Cannot find market address for token pair");
    }
  };

  const fetchVerifiedData = async (pubKey: string) => {
    let user_data = await getVerifiedData(pubKey);

    if (user_data && user_data.data.message === "SUCCESS") {
      //console.log('data',user_data.data);
      setVerifiedData(user_data.data.user);
    } else
      setVerifiedData({
        name: undefined,
        about: undefined,
        email: undefined,
        addresses: [],
        telegramUrl: undefined,
        discordUrl: undefined,
        twitterUrl: undefined,
        website: undefined,
      });
  };

  useEffect(() => {
    if (poolState === SUBSCRIPTION_PERIOD)
      setAvailableCommitment(
        (pInfo.account.goal.toNumber() -
          pInfo.account.totalLockedAmount.toNumber()) /
          decimalUSDC
      );

    //pInfo.account.owner.toBase58()
    fetchVerifiedData(pInfo.account.owner.toBase58());
  }, [
    pInfo.account.goal,
    pInfo.account.owner,
    pInfo.account.totalLockedAmount,
    poolState,
  ]);

  useEffect(() => {
    const currAccount = userAccounts.find(
      (acc) => acc.info.mint.toBase58() === pInfo.account.lendingMint.toBase58()
    );
    if (currAccount !== undefined) {
      setUserAccount(currAccount.pubkey);
    }

    //const poolState = pInfo.account.state;
    //const poolStateText = getPoolStateText(poolState, pInfo);
    const { poolState, poolStateText } = getPoolState(
      pInfo,
      extraPeriod,
      decimalUSDC
    );

    setPoolState(poolState);
    setPoolStateText(poolStateText);

    let collateralDecimal = getDecimal(
      pInfo.account.collateralToken.toBase58()
    );

    if (!collateralDecimal) {
      getMintDecimalValue(pInfo.account.collateralToken).then(
        (collateralDecimal) => {
          setDecimal(
            pInfo.account.collateralToken.toBase58(),
            collateralDecimal
          );
          setCollateralDecimal(collateralDecimal);
        }
      );
    } else setCollateralDecimal(collateralDecimal);

    //console.log("Prepare to getTokenSupply::");
    connection.getTokenSupply(pInfo.account.mintPoolToken).then((data) => {
      //console.log("getTokenSupply mintPoolToken = ", pInfo.account.mintPoolToken.toBase58(), " amount = ", data.value.amount);

      if (new BN(data.value.amount).isZero()) {
        setDisableTradeOnDex(true);
        //console.log("getTokenSupply disableTradeOnDex = true");
      }
    });
  }, [
    connection,
    pInfo.account.collateralToken,
    pInfo.account.lendingMint,
    pInfo.account.mintPoolToken,
    pInfo.account.state,
    userAccounts,
  ]);

  useEffect(() => {
    if (poolState === SUBSCRIPTION_PERIOD)
      setAvailableCommitment(
        (pInfo.account.goal.toNumber() -
          pInfo.account.totalLockedAmount.toNumber()) /
          decimalUSDC
      );
  }, [pInfo.account.goal, pInfo.account.totalLockedAmount, poolState]);

  useEffect(() => {
    const collateralToken = tokenMap.get(
      pInfo.account.collateralToken.toBase58()
    );
    
    setPool({
      topContent: {
        title:
          pInfo.account.collateralAmount.toNumber() > 0
            ? "COLLATERALIZED POOL"
            : "Uncollateralized Pool",
        xMargin: pInfo?.credit?.creditScore ? "CREDORA VERIFIED" : "",
        scoreTitle: "CREDIT SCORE: ",
        scoreValue: pInfo?.credit?.creditScore,
      },
      middleContent: {
        depositedTitle: "Deposited Collateral",
        amount:
          <div>{nf.format(
            pInfo.account.collateralAmount.toNumber() / collateralDecimal
          ) +
          " "}
          {collateralDecimal == 1? <a target="_blank" href={getSolScanUrl(pInfo.account.collateralToken.toBase58())}>NFT</a> :
            <a target="_blank" href={getSolScanUrl(pInfo.account.collateralToken.toBase58())}>{getSymbol(pInfo.account.collateralToken.toBase58(), tokenMap)}</a>}</div>,
        statusContent: {
          subtitle: "Status",
          title: poolStateText,
          totalCommited: {
            name: "TOTAL COMMITED",
            amount: new Intl.NumberFormat("en", {
              maximumFractionDigits: 1,
            }).format(pInfo.account.totalLockedAmount.toNumber() / decimalUSDC),
          },
          borrowers: {
            name: "BORROWER",
            amount: shortAddress(pInfo.account.owner.toBase58()),
            value: pInfo.account.owner.toBase58()
          },
          ISSUANCE: {
            name: "Borrow YIELD",
            amount:
              new Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(
                pInfo.account.couponRate / COUPON_RATE_SCALE
              ) + "%",
          },
        },
      },
      dateContent: [
        {
          startDateTitle: "Pool Token Address",
          startDate: pInfo.account.mintPoolToken.toBase58(),
        },
        {
          startDateTitle: "Subscription Start Date",
          startDate: dayjs
            .unix(pInfo.account.subscriptionStartTime)
            .utc()
            .format("DD-MM-YYYY"),
          startTimeTitle: "Subscription Start Time",
          startTime: `${dayjs
            .unix(pInfo.account.subscriptionStartTime)
            .utc()
            .format("HH:mm:ss")} UTC`,
        },

        {
          startDateTitle: "Subscription Period",
          // startDate:nf.format(getRoundValue(pInfo.account.subscriptionPeriod / TIME_UNIT_HOUR, defaultDecimal)) + " hours",
          startDate: formatDateTimeInDayAndHours(
            pInfo.account.subscriptionPeriod
          ),
          startTimeTitle: "Borrow Duration",
          // startTime: nf.format(getRoundValue(pInfo.account.lendingPeriod / TIME_UNIT_HOUR, defaultDecimal)) + " hours",
          startTime: formatDateTimeInDayAndHours(pInfo.account.lendingPeriod),
        },
        {
          startDateTitle: "Borrow Start Date",
          startDate:
            poolState > INVALID_POOL
              ? (pInfo.account.lendingStartedTime>0?dayjs
                  .unix(pInfo.account.lendingStartedTime)
                  .utc()
                  .format("DD-MM-YYYY"): "TBD")
              : "Not Started",
          startTimeTitle: "Borrow Start Time",
          startTime:
            poolState > INVALID_POOL
              ? (pInfo.account.lendingStartedTime>0?`${dayjs
                  .unix(pInfo.account.lendingStartedTime)
                  .utc()
                  .format("HH:mm:ss")} UTC`: "TBD")
              : "Not Started",
        },
        {
          startDateTitle: "Borrow Finish Date",
          startDate:
            poolState > INVALID_POOL
              ? (pInfo.account.lendingStartedTime>0?dayjs
                  .unix(
                    pInfo.account.lendingStartedTime +
                      pInfo.account.lendingPeriod
                  )
                  .utc()
                  .format("DD-MM-YYYY"): "TBD")
              : "Not Started",
          startTimeTitle: "Borrow Finish Time",
          startTime:
            poolState > INVALID_POOL
              ? (pInfo.account.lendingStartedTime>0?`${dayjs
                  .unix(
                    pInfo.account.lendingStartedTime +
                      pInfo.account.lendingPeriod
                  )
                  .utc()
                  .format("HH:mm:ss")} UTC`: "TBD")
              : "Not Started",
        },
        {
          startDateTitle: "Max Borrow Amount",
          startDate: `${nf.format(
            pInfo.account.goal.toNumber() / decimalUSDC
          )} USDC`,
          startTimeTitle: "Min Borrow Amount",
          startTime: `${nf.format(
            pInfo.account.min.toNumber() / decimalUSDC
          )} USDC`,
        },
      ],
    });
  }, [collateralDecimal, poolState, poolStateText, pInfo, tokenMap]);
  
  const CopyAction = () => {
    toast.success("Copied Successfully!");
  };
  let i = 0;
  console.log(pInfo.account.state === originalState, pInfo.account.state, originalState);
  
  return (
    <Container>
      {/* <BorrowerInfoModal
        closeModal={closeModal}
        openModal={closeModal}
        isOpen={isModalOpen}
        verifiedData={verifiedData}
      /> */}

      {pool && pInfo.account.state === originalState && (checkVerifiedPool? !!verifiedData?.twitterUrl : true ) ? (
        <>
          <StatusLeft style={style} topBg={pool.topContent.title}>
            <Topbar>
              <Heading>{pool.topContent.title}</Heading>
              <HeadingContent>
              {pInfo?.credit?.creditScore && <> <VerifiedText><CreditInfoModal pool={pInfo} ></CreditInfoModal></VerifiedText>
                 <Score>
                  {pool.topContent?.scoreTitle}{" "}
                  <Span>{pool.topContent?.scoreValue}</Span>
                </Score></>}
              </HeadingContent>
            </Topbar>

            <ContentWrapper
              contentBg={pool.topContent.title}
              heightTop={pool.topContent.title}
              heightBottom={pool.middleContent.statusContent.title}
            >
              {pool.topContent.title === "Uncollateralized Pool" ? (
                ""
              ) : (
                <DepositedContent>
                  <Title>{pool.middleContent.depositedTitle}</Title>
                  <DepositedAmount>{pool.middleContent.amount}</DepositedAmount>
                </DepositedContent>
              )}
              <StatusContent statusBg={pool.topContent.title}>
                <VerticleSidebar
                  sidebarColor={pool.middleContent.statusContent.title}
                />
                <TopContent>
                  <SubTitle>
                    {pool.middleContent.statusContent.subtitle}
                  </SubTitle>
                  <MainTitle
                    titleColor={pool.middleContent.statusContent.title}
                  >
                    {pool.middleContent.statusContent.title}
                  </MainTitle>
                </TopContent>
                <BottomContent>
                  <Borrowers>
                    {isDisableBorrowerInfo ? null : (
                      <BorrowerInfoModal
                        pool={pool}
                        verifiedData={verifiedData}
                      />
                    )}
                    {/* <div className="flex" onClick={openModal}>
                      <H4 className="tracking-wider cursor-pointer hover:text-[#01FCFC] hover:border-b hover:border-[#01C0FC] max-h-[15px]">
                        {pool.middleContent.statusContent.borrowers.name}
                      </H4>
                      <BsInfoCircle className="ml-1 mt-0.5 cursor-pointer text-xs text-[#01C0FC]" />
                    </div> */}
                    <div className="flex">
                      <Value onClick={openModal}>
                        {pool.middleContent.statusContent.borrowers.amount}
                      </Value>
                      <CopyToClipboard
                        onCopy={CopyAction}
                        text={pool.middleContent.statusContent.borrowers.value}
                      >
                        <BiCopy className=" ml-1 text-[#01C0FC] text-base cursor-pointer" />
                      </CopyToClipboard>
                    </div>
                    <LeftDivider />
                  </Borrowers>

                  <TotalCommited>
                    <H4>
                      {pool.middleContent.statusContent.totalCommited.name}
                    </H4>
                    <Value>
                      {pool.middleContent.statusContent.totalCommited.amount}{" "}
                      USDC
                    </Value>
                    <RightDivider />
                  </TotalCommited>

                  <IssuanceContent>
                    <H4>{pool.middleContent.statusContent.ISSUANCE.name}</H4>
                    <Value>
                      {pool.middleContent.statusContent.ISSUANCE.amount}
                    </Value>
                  </IssuanceContent>
                </BottomContent>
              </StatusContent>
              {pool.middleContent.statusContent.title ===
              "Active Subscription" ? (
                <CommitContent>
                  <TopContentWrapper>
                    <H2>Commit amount</H2>
                    <H3>
                      Max Available Commit: {nf.format(availableCommitment)}{" "}
                      USDC
                    </H3>
                  </TopContentWrapper>
                  <InputWrapper>
                    <InputGroup>
                      {" "}
                      <Input
                        disabled={poolState !== SUBSCRIPTION_PERIOD}
                        type="text"
                        value={stakeAmount}
                        onChange={(e) => {
                          if (regexp.test(e.target.value)) {
                            if (Number(e.target.value) > availableCommitment) {
                              setStakeAmount(availableCommitment.toString());
                            } else {
                              setStakeAmount(e.target.value);
                            }
                          }
                        }}
                      />{" "}
                      <ShadowWrapper />
                    </InputGroup>
                    <Button
                      disabled={
                        poolState !== SUBSCRIPTION_PERIOD ||
                        Number(stakeAmount) > availableCommitment
                      }
                      onClick={openFeesModal}
                    >
                      Commit
                    </Button>
                  </InputWrapper>
                </CommitContent>
              ) : disableTradeOnDex == false &&
                (poolState === INVALID_POOL ||
                  poolState === LENDING_PERIOD ||
                  poolState === EXTRA_PERIOD ||
                  poolState === LENDING_EXPIRED ||
                  poolState === FINISHED_POOL) ? (
                <ButtonWrapper
                  paddingBottom={pool.topContent.title}
                  paddingTop={pool.middleContent.statusContent.title}
                >
                  <FundedButton
                    //disabled={disableTradeOnDex == true}
                    onClick={tradeOnDex}
                  >
                    <SpanText className="buttonLeft">Trade on dex</SpanText>
                    <RightArrow src={FundedArrowImg} className="buttonRight" />
                  </FundedButton>
                </ButtonWrapper>
              ) : (
                ""
              )}{" "}
            </ContentWrapper>
          </StatusLeft>
          <StatusRight
            topContent={pool.topContent}
            dateContent={pool.dateContent}
            middleContent={pool.middleContent}
          />
        </>
      ) : (
        <></>
      )}
      <GlobalStyle />
    </Container>
  );
};

export default StatusPool;

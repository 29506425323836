import styled from "styled-components";

export const Main = styled.div`
  padding-bottom: 20px;
  .guide-header{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color:#8EDA87;
  }

`;
export const Container = styled.div`
  width: 100%;
  max-width: 1033px;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
`;
export const TopSearchBar = styled.div``;
export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  gap: 20px;
`;

export const SkipBtn = styled.button`
font-family: 'Space Grotesk';
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 30px;
color: #ABBDD1;
letter-spacing: 0.3px;
text-transform: uppercase;
`

export const NextBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;
  color: #12181F;
  font-family: 'Space Grotesk';
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 15px;
  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;

export const RestartText = styled.div`
font-family: 'Space Grotesk';
font-style: normal;
font-weight: 700;
font-size: 11px;
line-height: 30px;
/* identical to box height, or 273% */

display: flex;
align-items: center;
letter-spacing: 0.3px;
text-transform: uppercase;
font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

/* backgrounds/G3 */

color: #687D95;
`
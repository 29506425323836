import * as anchor from "@project-serum/anchor";
import { BN } from "@project-serum/anchor";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { ButtonWithEffect, HeaderText, Input } from "../../components/BorrowersPool/BorrowersRight/styles";
import { InputTag } from "../../components/BorrowersPool/styles";
import { CHANGE_ADMIN_ADDRESS, CHANGE_ADMIN_LIMIT, CHANGE_APPROVED_ADDRESS, CHANGE_APPROVED_LIMIT, CHANGE_APPROVED_REQUEST_FEE, CHANGE_APPROVED_WITHDRAW_FEE, CHANGE_CLAIM_FEE, CHANGE_COMMIT_FEE, CHANGE_EXTRA_PERIOD, CHANGE_GAS_FEE, CHANGE_GOVERNOR_ADDRESS, CHANGE_UNAPPROVED_REQUEST_FEE, CHANGE_UNAPPROVED_WITHDRAW_FEE, GLOBAL_STATE, MAX_APPROVED_LIST_COUNT, MAX_OWNER_COUNT, USDC_VAULT } from "../../utils/constants";
import { APPROVER0_ADDRESS, EMPTY_ADDRESS, GOVERNOR_ADDRESS, SB_MINT_ADDRESS, USDC_MINT_ADDRESS } from "../../utils/ids";
import { createGlobalState, getAllPendingUpdation, getDaoProgramInstance, getGlobalState, updateGlobalStateByGovernor } from "../../utils/instructions";
import { IApprovalInfoForAdminRequest, IGlobalState } from "../../utils/interfaces";
import { shortAddress } from "../../utils/utils";
import { getMintDecimalValue } from "../../utils/walletManager";
import { CardWrapper } from "./styles";
import { PendingUpdationForAdminInfo } from "./PendingUpdationForAdminInfo";

const Admin = () => {
  const wallet = useWallet();
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const [currentAdminInfo, setCurrentAdminInfo] = useState<IGlobalState | undefined>(undefined);
  const [feeDecimalValue, setFeeDecimalValue] = useState(1);
  const [governor, setGovernor] = useState(GOVERNOR_ADDRESS);

  const [pendingUpdations, setPendingUpdations] = useState<IApprovalInfoForAdminRequest[] | undefined>(undefined);
  console.log("pendingUpdations", pendingUpdations);

  const [approvalLimit, setApprovalLimit] = useState(1);
  const [poolRequestLimit, setPoolRequestLimit] = useState(5);


  const [requestFeeApproved, setRequestFeeApproved] = useState(1000);
  const [withdrawalFeeApproved, setWithdrawalFeeApproved] = useState(50);
  const [requestFeeUnapproved, setRequestFeeUnapproved] = useState(5000);
  const [withdrawalFeeUnapproved, setWithdrawalFeeUnapproved] = useState(1000);

  const [commitFee, setCommitFee] = useState(100);
  const [claimFee, setClaimFee] = useState(100);
  const [gasFee, setGasFee] = useState(500);

  const [extraPeriod, setExtraPeriod] = useState(30);
  const [feeBurnedRate, setFeeBurnedRate] = useState(5000);

  const [isAdmin, setIsAdmin] = useState(true);
  const [isGovernor, setIsGovernor] = useState(true);

  const [adminsList, setAdminsList] = useState([
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
  ]);
  const [approversList, setApproversList] = useState([
    APPROVER0_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
    EMPTY_ADDRESS,
  ]);

  const getPendingUpdations = async () => {
    let pendingUpdationList: IApprovalInfoForAdminRequest[] | undefined = await getAllPendingUpdation(
      connection,
      wallet
    );
    setPendingUpdations(
      pendingUpdationList?.filter((updation) => !updation.account.executeState && updation.transaction.data.length > 0)
    );
  };

  const getCurrentGlobalState = async () => {
    const decValue = await getMintDecimalValue(new PublicKey(SB_MINT_ADDRESS));
    setFeeDecimalValue(decValue);

    let adminInfo: IGlobalState | undefined = await getGlobalState(connection, wallet);


    setCurrentAdminInfo(adminInfo);
    if (adminInfo) {
      let tmp_admin_lists: string[] = [];
      let tmp_approvers_list: string[] = [];

      tmp_admin_lists = adminInfo?.admins.map((admin) => {
        const adminAddress = admin.toBase58();
        if (adminAddress === EMPTY_ADDRESS) return 'NotSet';
        else return adminAddress;
      });

      tmp_approvers_list = adminInfo?.approvedList.map((approver) => {
        const approverAddress = approver.toBase58();
        if (approverAddress === EMPTY_ADDRESS) return 'NotSet';
        else return approverAddress;
      });

      let emptyCount = adminInfo?.admins.length;
      while (emptyCount < MAX_OWNER_COUNT) {
        tmp_admin_lists.push('NotSet');
        emptyCount++;
      }

      emptyCount = adminInfo?.approvedList.length;
      while (emptyCount < MAX_APPROVED_LIST_COUNT) {
        tmp_approvers_list.push('NotSet');
        emptyCount++;
      }
      setGovernor(adminInfo.governor.toBase58());
      setAdminsList(tmp_admin_lists);
      setApproversList(tmp_approvers_list);
      setApprovalLimit(adminInfo.approvalLimit);

      setRequestFeeApproved(adminInfo.requestFeeApproved.toNumber() / decValue);
      setRequestFeeUnapproved(adminInfo.requestFeeUnapproved.toNumber() / decValue);
      setWithdrawalFeeApproved(adminInfo.withdrawFeeApproved.toNumber());
      setWithdrawalFeeUnapproved(adminInfo.withdrawFeeUnapproved.toNumber());
      setPoolRequestLimit(adminInfo.limitPoolCntPerBorrower);
      setCommitFee(adminInfo.commitFee.toNumber());
      setClaimFee(adminInfo.claimFee.toNumber());
      setGasFee(adminInfo.gasFee.toNumber() / decValue);
      setExtraPeriod(adminInfo.extraPeriod);
    }
  };

  const setGlobalState = async () => {
    if (feeDecimalValue === 1) {
      const decValue = await getMintDecimalValue(new PublicKey(SB_MINT_ADDRESS));
      setFeeDecimalValue(decValue);
    }

    let adminsListKey: PublicKey[] = [];
    let approverListKey: PublicKey[] = [];

    adminsListKey = adminsList
      .filter((key) => key !== '' && key != EMPTY_ADDRESS)
      .map((key) => {
        if (key !== 'NotSet') return new PublicKey(key);
        else return new PublicKey(EMPTY_ADDRESS);
      });

    approverListKey = approversList
      .filter((key) => key !== '' && key != EMPTY_ADDRESS)
      .map((key) => {
        if (key !== 'NotSet') return new PublicKey(key);
        else return new PublicKey(EMPTY_ADDRESS);
      });

    console.log("adminsListKey = ");
    adminsListKey.map((adminKey) => {
      console.log(adminKey.toBase58());
    }); 

    console.log("approverListKey = ");
    approverListKey.map((approverKey) => {
      console.log(approverKey.toBase58());
    }); 
    //console.log(adminsListKey, approverListKey);

    if (currentAdminInfo === undefined) {
      console.log('createGlobalState called');

      const tx = await createGlobalState(
        connection,
        wallet,

        new PublicKey(governor),
        adminsListKey,
        approvalLimit,
        new PublicKey(SB_MINT_ADDRESS),
        new PublicKey(USDC_MINT_ADDRESS),

        new BN(requestFeeApproved * feeDecimalValue),
        new BN(requestFeeUnapproved * feeDecimalValue),
        new BN(withdrawalFeeApproved),
        new BN(withdrawalFeeUnapproved),

        approverListKey,
        poolRequestLimit,

        new BN(commitFee),
        new BN(claimFee),
        new BN(gasFee * feeDecimalValue),
        extraPeriod,
        feeBurnedRate
      );
      console.log("tx = ", tx);
    }
  };

  const updateGlobalState = async (flag: number, id: number, address: PublicKey) => {
    console.log(flag);
    let tx;
    switch (flag) {
      // case CHANGE_ADMIN_ADDRESS:
      //   tx = await requestGlobalStateUpdationByAdmin(connection, wallet, flag, id, address, new BN(0));
      //   break;
      // case CHANGE_ADMIN_LIMIT:
      //   if (approvalLimit === 0) {
      //     console.log('You cant set this value to zero');
      //   } else {
      //     tx = await requestGlobalStateUpdationByAdmin(
      //       connection,
      //       wallet,
      //       flag,
      //       id,
      //       new PublicKey(EMPTY_ADDRESS),
      //       new BN(approvalLimit)
      //     );
      //   }
      //   break;
      case CHANGE_APPROVED_ADDRESS:
        tx = await updateGlobalStateByGovernor(connection, wallet, flag, id, address, new BN(0));
        break;
      case CHANGE_APPROVED_LIMIT:
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          new PublicKey(EMPTY_ADDRESS),
          new BN(poolRequestLimit)
        );
        break;
      case CHANGE_APPROVED_REQUEST_FEE:
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          new PublicKey(EMPTY_ADDRESS),
          new BN(requestFeeApproved * feeDecimalValue)
        );
        break;
      case CHANGE_APPROVED_WITHDRAW_FEE:
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          new PublicKey(EMPTY_ADDRESS),
          new BN(withdrawalFeeApproved)
        );
        break;
      case CHANGE_UNAPPROVED_REQUEST_FEE:
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          new PublicKey(EMPTY_ADDRESS),
          new BN(requestFeeUnapproved * feeDecimalValue)
        );
        break;
      case CHANGE_UNAPPROVED_WITHDRAW_FEE:
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          new PublicKey(EMPTY_ADDRESS),
          new BN(withdrawalFeeUnapproved)
        );
        break;
      case CHANGE_COMMIT_FEE:
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          new PublicKey(EMPTY_ADDRESS),
          new BN(commitFee)
        );
        break;
      case CHANGE_CLAIM_FEE:
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          new PublicKey(EMPTY_ADDRESS),
          new BN(claimFee)
        );
        break;
      case CHANGE_GAS_FEE:
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          new PublicKey(EMPTY_ADDRESS),
          new BN(gasFee * feeDecimalValue)
        );
        break;
      case CHANGE_EXTRA_PERIOD:
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          new PublicKey(EMPTY_ADDRESS),
          new BN(extraPeriod)
        );
        break;
      case CHANGE_GOVERNOR_ADDRESS:
        // tx = await requestGlobalStateUpdationByAdmin(connection, wallet, flag, id, address, new BN(0));
        tx = await updateGlobalStateByGovernor(
          connection,
          wallet,
          flag,
          id,
          address,
          new BN(0)
        );
        break;
    }

    console.log(tx);
  };

  const checkAdmin = () => {
    if (!currentAdminInfo) {
      setIsAdmin(true);
      return;
    }
    const admin_idx = currentAdminInfo?.admins.findIndex((key) => {
      return key.toBase58() === wallet.publicKey?.toBase58();
    });
    if (admin_idx < 0) {
      setIsAdmin(false);
    } else setIsAdmin(true);
  };

  useEffect(() => {
    if (wallet.connected) {
      getCurrentGlobalState();
      getPendingUpdations();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    if (wallet.connected) {
      checkAdmin();
      if (currentAdminInfo) {
        if (currentAdminInfo && wallet.publicKey?.toBase58() === currentAdminInfo.governor.toBase58())
          setIsGovernor(true);
        else setIsGovernor(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminInfo]);

  return (
    <div className="flex justify-center" style={{ marginTop: 50 }}>
      <CardWrapper className="w-full">
        <div className="main-container flex flex-col relative w-full h-full overflow-hidden text-left rounded-lg style1">
          <div className="flex justify-center px-6 py-3">
            <span className="label-text text-shadow">Admin Control</span>
          </div>

          <div style={{
            display: "flex",
            flexDirection: "column"
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-start"
            }}>
              {currentAdminInfo ? (
                <TableContainer component={Paper} style={{
                  background: "#161d23",
                  borderRadius: "10px",
                  margin: "0 3px 2px",
                }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontFamily: "Space Grotesk", color: "#687d95" }}>TYPE</TableCell>
                        <TableCell style={{ fontFamily: "Space Grotesk", color: "#687d95" }}>INDEX</TableCell>
                        <TableCell style={{ fontFamily: "Space Grotesk", color: "#687d95" }}>ADDRESS</TableCell>
                        <TableCell style={{ fontFamily: "Space Grotesk", color: "#687d95" }}>VALUE</TableCell>
                        {currentAdminInfo.admins.map((address, i) => {
                          if (address.toBase58() !== EMPTY_ADDRESS)
                            return (
                              <TableCell style={{ fontFamily: "Space Grotesk", color: "#687d95" }} key={'Admin' + i} align="center">
                                ADMIN{i + 1}
                                {`(${shortAddress(address.toBase58()).toUpperCase()})`}
                              </TableCell>
                            );
                          else return <></>;
                        })}
                        <TableCell style={{ fontFamily: "Space Grotesk", color: "#687d95" }}>APPROVE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pendingUpdations ? (
                        pendingUpdations
                          .filter((updation) => !updation.account.executeState)
                          .map((updation) => (
                            <PendingUpdationForAdminInfo
                              pendingUpdation={updation}
                              adminList={adminsList}
                              approvalLimit={approvalLimit}
                              approverList={approversList}
                              poolRequestLimit={poolRequestLimit}
                              requestFeeApproved={requestFeeApproved}
                              withdrawalFeeApproved={withdrawalFeeApproved}
                              requestFeeUnapproved={requestFeeUnapproved}
                              withdrawalFeeUnapproved={withdrawalFeeUnapproved}
                              commitFee={commitFee}
                              claimFee={claimFee}
                              gasFee={gasFee}
                              extraPeriod={extraPeriod}
                              feeDecimalValue={feeDecimalValue}
                              governor={governor}
                            />
                          ))
                      ) : (
                        <TableCell align="center" colSpan={8} style={{ fontFamily: "Space Grotesk", color: "#687d95" }}>
                          No Pending Updations
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <></>
              )}
              
            </div>

            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-start"
            }}>
              {/* <div className="style59" style={{ width: "35%" }}>
                <div className="overflow-y-scroll mr-1 min-h-[12rem] px-5 style61">
                  {currentAdminInfo && Array.from(Array(11).keys()).map((item, index) => {
                    return (
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" style={{ width: "80%" }}>
                          <HeaderText className="mb-3">ADMIN {index + 1}</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={adminsList && (adminsList[index] || 'Undefined')}
                              onChange={(event: any) => {
                                let adminsListNew = adminsList;
                                adminsListNew[index] = event.target.value.toString();
                                setAdminsList(adminsListNew);
                              }}
                            />
                            <InputTag>
                              {" "}
                              <CgProfile className="text-xl" />
                            </InputTag>
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={() => updateGlobalState(CHANGE_ADMIN_ADDRESS, index, new PublicKey(adminsList[index]))}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                    )
                  })}

                </div>
              </div> */}

              <div className="style59" style={{ width: "35%" }}>
                <div className="overflow-y-scroll mr-1 min-h-[12rem] px-5 style61">
                  <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                    <div className="grow px-3" style={{ width: "80%" }}>
                      <HeaderText className="mb-3">GOVERNOR</HeaderText>
                      <div className="relative">
                        <Input
                          padding={"70px"}
                          width={"100%"}
                          type="text"
                          placeholder={governor}
                          onChange={(event: any) => {
                            setGovernor(event.target.value);
                          }}
                        />
                        <InputTag>
                          {" "}
                          <CgProfile className="text-xl" />
                        </InputTag>
                      </div>
                    </div>
                    
                    <div className="flex justify-center mt-3 mb-6">
                      <ButtonWithEffect
                        style={{ height: "45px", maxHeight: "45px" }}
                        onClick={() => updateGlobalState(CHANGE_GOVERNOR_ADDRESS, 0, new PublicKey(governor))}
                      >
                        <text className="btn-text">Update</text>
                      </ButtonWithEffect>
                    </div>

                  </div>
                  {currentAdminInfo && Array.from(Array(10).keys()).map((item, index) => {
                    return (
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" style={{ width: "80%" }}>
                          <HeaderText className="mb-3">APPROVER {index + 1}</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={approversList && (approversList[index] || 'Undefined')}
                              onChange={(event: any) => {
                                let approverListNew = approversList;
                                approverListNew[index] = event.target.value.toString();
                                setApproversList(approverListNew);
                              }}
                            />
                            <InputTag>
                              {" "}
                              <CgProfile className="text-xl" />
                            </InputTag>
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={() => updateGlobalState(CHANGE_APPROVED_ADDRESS, index, new PublicKey(approversList[index]))}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                    )
                  })}

                </div>
              </div>

              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start"
              }}>
                <div className="style59">
                  <div className="overflow-y-scroll mr-1 min-h-[12rem] px-5 style61">
                      {/* <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">Limit Approval</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={approvalLimit}
                              onChange={(event: any) => {
                                setApprovalLimit(Number(event.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={() => updateGlobalState(CHANGE_ADMIN_LIMIT, 0, new PublicKey(EMPTY_ADDRESS))}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div> */}

                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">LIMIT POOL COUNT</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={poolRequestLimit}
                              onChange={(event: any) => {
                                setPoolRequestLimit(Number(event.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={() => updateGlobalState(CHANGE_APPROVED_LIMIT, 0, new PublicKey(EMPTY_ADDRESS))}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">Approved Borrowers Request Fee</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={requestFeeApproved}
                              onChange={(e: any) => {
                                setRequestFeeApproved(Number(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={(e) => {
                              updateGlobalState(CHANGE_APPROVED_REQUEST_FEE, 0, new PublicKey(EMPTY_ADDRESS));
                            }}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">Approved Borrowers Withdraw Fee</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={withdrawalFeeApproved}
                              onChange={(e: any) => {
                                setWithdrawalFeeApproved(Number(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={(e) => {
                              updateGlobalState(CHANGE_APPROVED_WITHDRAW_FEE, 0, new PublicKey(EMPTY_ADDRESS));
                            }}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">Unapproved Borrowers Request Fee</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={requestFeeUnapproved}
                              onChange={(e: any) => {
                                setRequestFeeUnapproved(Number(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={(e) => {
                              updateGlobalState(CHANGE_UNAPPROVED_REQUEST_FEE, 0, new PublicKey(EMPTY_ADDRESS));
                            }}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">Unapproved Borrowers Withdraw Fee</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={withdrawalFeeUnapproved}
                              onChange={(e: any) => {
                                setWithdrawalFeeUnapproved(Number(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={() => {
                              updateGlobalState(CHANGE_UNAPPROVED_WITHDRAW_FEE, 0, new PublicKey(EMPTY_ADDRESS));
                            }}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">Commit Fee</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={commitFee}
                              onChange={(e: any) => {
                                setCommitFee(Number(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={() => {
                              updateGlobalState(CHANGE_COMMIT_FEE, 0, new PublicKey(EMPTY_ADDRESS));
                            }}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">Claim Fee</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={claimFee}
                              onChange={(e: any) => {
                                setClaimFee(Number(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={(e) => {
                              updateGlobalState(CHANGE_CLAIM_FEE, 0, new PublicKey(EMPTY_ADDRESS));
                            }}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">Gas Fee</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={gasFee}
                              onChange={(e: any) => {
                                setGasFee(Number(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={() => {
                              updateGlobalState(CHANGE_GAS_FEE, 0, new PublicKey(EMPTY_ADDRESS));
                            }}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                      <div className="flex" style={{ marginBottom: 10, marginTop: 10, height: 70 }}>
                        <div className="grow px-3" >
                          <HeaderText className="mb-3">Extra Period</HeaderText>
                          <div className="relative">
                            <Input
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder={extraPeriod}
                              onChange={(e: any) => {
                                setExtraPeriod(Number(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        
                        <div className="flex justify-center mt-3 mb-6">
                          <ButtonWithEffect
                            style={{ height: "45px", maxHeight: "45px" }}
                            onClick={(e) => {
                              updateGlobalState(CHANGE_EXTRA_PERIOD, 0, new PublicKey(EMPTY_ADDRESS));
                            }}
                          >
                            <text className="btn-text">Update</text>
                          </ButtonWithEffect>
                        </div>

                      </div>
                  </div>
                </div>
                <ButtonWithEffect
                  style={{ height: "45px", maxHeight: "45px" }}
                  onClick={setGlobalState}
                >
                  <text className="btn-text">Save Settings</text>
                </ButtonWithEffect>
              </div>
            </div>
          </div>
          
          
          
        </div>
      </CardWrapper>
    </div>
  );
};

export default Admin;

import styled from "styled-components";

export const TableWarpper = styled.text`

 width:100%;
 display:flex;
 flex-direction:column;
 gap:5rem;
 input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
 .input-section{
    width:100%
    dipsplay:flex;
    flex-direction:column;
    background: #1A232B;
    border-radius: 12px;
    height: 194px;
    padding: 21px 31px;
    .heading{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 30px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
        color: #FFFFFF;
    }

    .line-breaker{
        border-bottom: 2px solid  rgba(1, 192, 252, 0.3);
        margin-top:0.5rem;
    }
    .input-wrapper{
        display:flex;
        height:80%;
        align-items:center;

        .first-half{
            width:40%;
        }
         .second-half{
            width:60%;
            display:flex;
            gap:10px;
            justify-content: end;
            .combined-section{
                display:flex;
                flex-direction:column;
                .label{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: #4D647B;
                }
            }
         }
    }

 }
  .table-row {
    background: #1a232b;
  }
  .table-section {
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
    background: #161d23;
    padding: 1rem 1rem;
  }
  .table-class {
    border-collapse: separate;
    border-spacing: 0 3px;
  }
  .table-section1 {
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    position: relative;
    padding: 1rem 1rem;
    background: linear-gradient(
        180.1deg,
        rgba(34, 151, 241, 0.15) -32.56%,
        rgba(1, 252, 252, 0.15) 99.93%
      ),
      #161d23;
  }
  hr {
  }
  .list-header{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color: #687D95;
}
.list-menu{
    margin-top: 1rem;
}
`;

export const VLine = styled.hr.attrs((props: { color: any }) => props)`
  position: absolute;
  height: 30px;
  width: 4px;
  left: 0px;
  top: 17px;
  border: 0;
  background: ${({ color }) => color};
  box-shadow: 0px 1px 6px 2px ${({ color }) => color}3d;
  border-radius: 0 10px 10px 0;
`;

export const FirstTableRowHeader = styled.text.attrs(
  (props: { type: String }) => props
)`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: ${(props) =>
    props.type === "Active Subscription"
      ? "#01fcfc"
      : props.type === "Funded"
      ? "#25FF3B"
      : props.type === "Pre-Subscription"
      ? "#FFD029"
      : props.type === "Defaulted" || props.type === "Successful" || props.type === "Pool Ended"
      ? "#B3C7D9"
      :props.type === "Finished Unfunded" || props.type === "Finished Cancelled" || props.type === "Finished Successful" || props.type === "Finished Defaulted" || props.type === "Pool Finished" 
      ? "#B3C7D9"
      : "#01FCFC"};
`;

export const SectionHeader = styled.text.attrs(
  (props: { borderColor: any }) => props
)`
  font-family: "Space Grotesk";
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  margin: 0 0.9rem;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  padding-bottom: 0.8rem;
`;

export const TableText = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  letter-spacing: -0.02em;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
`;

export const TableHeaderText = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  /* justify-content: center; */
  text-transform: uppercase;
  color: #4d647b;
`;

export const EnableButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;

  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;

export const DisableButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;

  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, gray 2.67%, gray 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: gray;
    background: linear-gradient(135deg, gray 0%, gray 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
`;

export const Input = styled.input`
  width: ${({ width }) => width || "340px"};
  height: 40px;
  background: #161d23;
  border-radius: 6px;
  border: 1px solid transparent;
  outline: none;
  padding-left: 12px;
  font-size: 12px;
  color: #ffffff;
  font-family: "Space Grotesk";
  font-weight: 500;
  &:focus {
    border: 1px solid rgba(1, 192, 252, 0.8);
    /* box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5); */
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #ffffff;
    }
    :-ms-input-placeholder {
      color: #ffffff;
    }
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
  :-ms-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
`;
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 14px;
  gap: 10px;
  width: 107px;
  height: 40px;
  background: rgba(1, 192, 252, 0.3);
  border-radius: 6px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid rgba(0, 133, 255, 1);
  transition: all 0.3s ease-in;

  &:hover {
    background: transparent;
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }

  @media (max-width: 600px) {
    padding: 13px 26px;
  }
`;

export const TableDetailWarpper = styled.div`
  width: 100%;
  .table-btn{
    background: #334150;
    border-radius: 6px;
    padding:1px 7px;
    span{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
        color: #687D95;

    }
  }


  .table-warpper{
    width:100%;
    display: flex;
    gap: 5px;
  }
  .header-div {
    padding: 10px 3px;
  }
  .table-header-main {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #ffffff;
  }
   
  }
  .table-two {
    width: 100%;
    th{
        text-align: inherit;
    }
    .table-card {
      table {
        border-collapse: separate;
        border-spacing: 0px;
        border-radius: 1.1em;
        tr:nth-child(odd) {
          background-color: #12181f;
        }
        tr:nth-child(even) {
          background-color: #161d23;
        }
      }

      background: #1a232b;
      border-radius: 8px;
      padding: 10px 10px;
      diplay: flex;
      flex-direction: column;
      .line-break {
        border-bottom: 2px solid #28333f;
      }
      .table-header {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        text-transform: uppercase;
        color: #687d95;
      }
      .table-text {
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        color: #ffffff;
      }
    }
  }

  
`;

import styled from "styled-components";

// import Inter from "../../assets/fonts/Inter-Regular.ttf";
// import SpaceGrotesk from "../../assets/fonts/SpaceGrotesk-Regular.ttf";

export const Wrapper = styled.div`
 width:100%;
 display:flex;
 flex-direction:column;
 gap:5rem;
`;

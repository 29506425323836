import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  .you-get {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
    color: #1184a6;
  }

  .heading-warpper {
    display: flex;
    gap: 1rem;
    .header-text {
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .header-one {
      width: 40%;
    }
    .header-two {
      width: 60%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .input-wrapper {
    gap: 1rem;
    width: 100%;
    display: flex;
    background: linear-gradient(180.32deg, rgba(34, 151, 241, 0.2) 0.31%, rgba(1, 252, 252, 0.2) 184.05%), #161D23;
    border-radius: 12px;
    padding:10px 10px;
    .input-one {
      width: 40%;
      display: flex;
      flex-direction: column;
      .balance-section {
        display: flex;
        flex-direction: column;
      }
      .section-input{
        background: #054D63;
        border-radius: 8px;
        padding:10px 10px;
        margin-top:1rem;
        .input-box-div{
            display: flex;
            width:100%;
            gap:10px;
            
            .input-box-one{
                display: flex;
                flex-direction: column;
                width:49%;
            }
            .input-box-two{
                display: flex;
                flex-direction: column;
                width:49%;
                
            }
            .input-label{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: #FFFFFF;
                
            }
        }
     }
     
    }
    .input-two {
      width: 60%;
      display: flex;
      flex-direction: column;
      .balance-section {
        display: flex;
        flex-direction: column;
      }
      .section-input{
        background: #054D63;
        border-radius: 8px;
        padding:10px 10px;
        margin-top:1rem;
        .input-box-div{
            display: flex;
            width:100%;
            gap:10px;
            
            .input-box-one{
                display: flex;
                flex-direction: column;
                width:49%;
            }
            .input-box-two{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width:49%;
                .rank{

                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.3px;
                text-transform: uppercase;
                font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
                color: #FFFFFF;
                }
                
            }
            .input-label{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: #FFFFFF;
                
            }
        }
    }

  }
 
`;
export const BalanceHeading = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  color: #687d95;
`;
export const BalanceText = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
export const ButtonWithEffect = styled.button`
  position: relative;
  margin-top: 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
  background: transparent;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 6px;
  width: 120px;
  padding: 13px 7px;
  z-index: 1;
  transition: width 0.3s linear;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
      border-box;
    border: inherit;
    border-radius: inherit;
    mask: linear-gradient(rgba(1, 192, 252, 0.7), rgba(1, 192, 252, 0.7))
        padding-box,
      linear-gradient(white, white);
    -webkit-mask-composite: xor;
  }
  &:hover {
    position: relative;
    border: 3px solid transparent;
    border-radius: 6px;
    width: 120px;
    padding: 13px 7px;
    box-shadow: white;
    height: 45px;
    max-height: 45px;
    filter: drop-shadow(0px 3px 9px rgba(255, 255, 255, 0.46));
    &::before {
      content: "";
      position: absolute;
      text-align: center;
      top: 0;
      left: 0;
      width: 120px;
      height: 100%;

      background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
        border-box;
      border: inherit;
      border-radius: inherit;
      mask: linear-gradient(white, white) padding-box,
        linear-gradient(white, white);
      -webkit-mask-composite: xor;
    }
  }
  .btn-text {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #ffffff;
  }
`;
export const Input = styled.input`
  width: ${({ width }) => width || "340px"};
  height: 40px;
  background: #161d23;
  border-radius: 6px;
  border: 1px solid transparent;
  outline: none;
  padding-left: 12px;
  padding-right: ${(props) => (props.padding ? props.padding : "0px")};
  font-size: 12px;
  color: #ffffff;
  font-family: "Space Grotesk";
  font-weight: 500;
  &:focus {
    border: 1px solid rgba(1, 192, 252, 0.8);
    /* box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5); */
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #ffffff;
    }
    :-ms-input-placeholder {
      color: #ffffff;
    }
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
  :-ms-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
  
`;

export const ButtonEffect = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;
  gap: 10px;
  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;

export const ButtonWIthAnimate = styled.button`
    position: relative;
    margin-top: 1rem;
    align-items: center;
    display: flex;
    justify-content: center;
    background: transparent;
    text-align: center;
    border: 2px solid transparent;
    border-radius: 6px;
    width: 120px;
    padding: 13px 7px;
    z-index: 1;
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 120px;
    padding: 13px 7px;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    width: 120px;
    padding: 13px 7px;
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;


export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 7px;
  gap: 10px;
  margin-top: 1rem;
 
  background: rgba(1, 192, 252, 0.3);
  border-radius: 6px;
  width: 120px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid rgba(0, 133, 255, 1);
  transition: all 0.3s ease-in;

  &:hover {
    background: transparent;
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }

  @media (max-width: 600px) {
    padding: 13px 26px;
  }
`;

export const FundedButton = styled(Button)`
  position: relative;
  display: block;
  transition: 0.5s;
  display: flex;

  &:hover .buttonLeft {
    padding-right: 260px;

    @media (max-width: 600px) {
      padding-right: 180px;
    }
  }

  &:hover .buttonRight {
    opacity: 1;
    padding-left: 15px;
    transform: translateY(-10px);
     
  }

  &:hover .arrow-btn{
    
      margin-left:0.5rem;
      
  }
  @media (max-width: 600px) {
    padding: 13px 17px;
  }
`;

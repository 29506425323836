import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { HiOutlineSelector } from "react-icons/hi";
import {actionsData} from "./actionsData";

export const ListActions = (props: any) => {
 
  const onChangeData = (action: any) => {
    props.onChangeValue(action);
  }
    
  return (
      <Listbox value={props.selected} onChange={onChangeData} disabled={props.disabled}>
        <div className="relative list-menu">
          <Listbox.Button className="relative w-12/12 cursor-default rounded-lg bg-[#1A232B] py-2 pl-3  text-white text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate list-header">{props.selected.action}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <HiOutlineSelector
                className="h-5 w-5 text-white"
                aria-hidden="true"
            />
          </span>
          </Listbox.Button>
          <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 cursor-pointer max-h-30 w-150 z-40 overflow-auto rounded-md  bg-[#1A232B] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {actionsData.map((action, actionId) => (
                  <Listbox.Option
                      key={actionId}
                      className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-4 pr-4 ${
                              active ? "bg-amber-100 text-[#687D95]" : "text-white"
                          }`
                      }
                      value={action}
                  >
                    {({ selected }) => (
                        <>
                    <span
                        className={`block truncate list-header ${
                            selected ? "font-medium" : "font-normal"
                        }`}
                    >
                      {action.action}
                    </span>
                        </>
                    )}
                  </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
  );
};

import styled from "styled-components";
export const Main = styled.div`
  padding-bottom: 200px;
`;
export const Container = styled.div`
  width: 364px;
  margin: 0 auto;
  @media (min-width: 600px) {
    width: 1033px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;
export const TopSearchBar = styled.div``;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;

  @media (min-width: 600px) {
    gap: 20px;
  }
`;

import styled from "styled-components";

// import Inter from "../../assets/fonts/Inter-Regular.ttf";
// import SpaceGrotesk from "../../assets/fonts/SpaceGrotesk-Regular.ttf";

export const CardWrapper = styled.div`
 
  .auction-card {
    width: 300px;
    height: 543px;
    padding: 9px 4px;
    background: #1a232b;
    border-radius: 12px;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    overflow:hidden;
    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
    .card-img {
      width: 287px;
      height: 282px;
      object-fit: contain
      margin: 0 auto;
      border-radius: 8px;
      overflow: hidden;
      position: relative ;
 
      .nft-image {
        width: 100%;
        height: 100%;
        max-height: 282px;
        max-width:287px;
        border-radius: 8px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: all 0.9s ease-in-out 0s;
      }
 
   
    }
    .combined-section{
        transition: all 0.5s ease;
        position: relative;
        overflow:hidden;
        min-height: 180px;
    }
    &:hover   {
        border: 2px solid rgba(1, 192, 252, 0.3);
      .card-img {
        &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(0deg, #1F2934 12.29%, rgba(49, 49, 49, 0) 95.27%);
    transition: all 0.9s ease-in-out 0s;
  }
      }
        .nft-image{
            transform: scale(1.5); 
            filter: alpha(opacity=40);
        }
       
        .combined-section{
          margin-top:-3rem;
          z-index:10;
        }
        .place-bid{
          opacity: 1;
           visibility: visible;
            height: auto;
            }
            .third-section {
              
            }
         
          }
    .second-div {
      flex-direction: column;
      display: flex;
      padding: 8px 14px;
      .heading {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #01c0fc;
      }
      .sub-heading {
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.3px;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        color: #ffffff;
      }
      .nft-desc {
        flex-direction: column;
        display: flex;
        gap: 2px;
        .nft-desc-section {
          display: flex;
          gap: 1rem;
        }
        .left-section {
          flex: 1 1 0%;
          display: flex;
          align-items: center;
          gap: 5px;
          .left-logo {
            width: 12px;
            height: 12px;
          }
          .left-text {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            //line-height: 20px;
            text-transform: uppercase;
            color: #687d95;
          }
        }
        .right-section {
          flex: 1 1 0%;

          .right-text {
            font-family: "Space Grotesk";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            //line-height: 30px;
            letter-spacing: 0.3px;
            font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
            color: #ffffff;
          }
          .auction-name {
            font-family: "Space Grotesk";
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            //line-height: 30px;
            font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
            color: #687d95;
          }
        }
      }
    }
.third-section{
    background: linear-gradient(
        180.32deg,
        rgba(34, 151, 241, 0.2) 0.31%,
        rgba(1, 252, 252, 0.2) 184.05%
      ),
      #161d23;
    border-radius: 8px;
    display: flex;
    margin-top: 1rem;
    padding: 7px 10px;
    flex-direction: column;
  
}
    .auction-end {
        display: flex;
        justify-content: space-between;
      .left-logo {
        width: 10.5px;
        right: 10.5px;
      }
      .left-section {
        display: flex;
        gap: 5px;
        .left-text {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #01c0fc;
        }
      }
      .right-section {
        display: flex;
        gap: 5px;
        .end-date,
        .end-time {
          font-family: "Space Grotesk";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 30px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
          color: #ffffff;
        }
      }
     
    }
    .place-bid{
        justify-content: space-between;
        opacity: 0;
        height: 0;
        visibility: hidden;
        display:flex;
        margin-top:4px;
        width: 268px;
        align-items: center;
        transition: height 0ms 400ms, opacity 400ms 0ms;
        .place-bid-button{
            align-self: center;
        }
      }
  }
`;
export const GradientText = styled.div`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const Input = styled.input`
  width: ${({ width }) => width || "340px"};
  height: 40px;
  background: #161d23;
  border-radius: 6px;
  border: 1px solid transparent;
  outline: none;
  padding-left: 12px;
  padding-right: ${(props) => (props.padding ? props.padding : "0px")};
  font-size: 12px;
  color: #ffffff;
  font-family: "Space Grotesk";
  font-weight: 500;
  &:focus {
    border: 1px solid rgba(1, 192, 252, 0.8);
    /* box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5); */
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #ffffff;
    }
    :-ms-input-placeholder {
      color: #ffffff;
    }
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
  :-ms-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
`;

export const ButtonEffect = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;
  gap: 10px;
  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;

import React, { useState } from "react";
import {
  DropdownContainer,
  DropdownContent,
  DropdownItem,
  Label,
  Input,
  WrapperItem,
} from "./styles";

const FilterDropdown = (props: any) => {
  const {filterState, setFilterState} = props
  // const [state, setState] = React.useState({
  //   activePool: "",
  //   inactivePool: "",
  //   approvedPool: "",
  //   collateralosedPool: "",
  //   company1Pool: "",
  //   company2Pool: "",
  // });

  // console.log(state, "state");
  const handleChange = (event: any) => {
  
    setFilterState({...filterState, [event.target.value]: event.target.checked})
  }
  console.log(filterState)
  return (
    <DropdownContainer>
      <DropdownContent>
        <DropdownItem>
          <WrapperItem>
            <Input
              name="choose"
              type="checkbox"
              id="inactivePool"
              value="selfVerified"
              checked={filterState?.selfVerified}
              onChange={handleChange}
            />
            <Label htmlFor="inactivePool">Self Verified</Label>
          </WrapperItem>
          <WrapperItem>
            <Input
              name="choose"
              type="checkbox"
              id="approvedPool"
              value="credoraVerified"
              checked={filterState?.credoraVerified}
              onChange={handleChange}
            />
            <Label htmlFor="approvedPool">Credora Verified</Label>
          </WrapperItem>
          <WrapperItem>
            <Input
              name="choose"
              type="checkbox"
              id="collateralosedPool"
              value="collateralosedPool"
              checked={filterState?.collateralosedPool}
              onChange={handleChange}
            />
            <Label htmlFor="collateralosedPool">Collateralosed Pool</Label>
          </WrapperItem>
          {/* <WrapperItem>
            <Input
              name="choose"
              type="checkbox"
              id="collateralosedPool"
              value="collateralosedPool"
              checked={filterState?.collateralosedPool}
              onChange={handleChange}
            />
            <Label htmlFor="collateralosedPool">Clear Filter</Label>
          </WrapperItem> */}
        </DropdownItem>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default FilterDropdown;

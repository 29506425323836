import styled, { keyframes ,createGlobalStyle} from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;

  background: #161d23;
  border-radius: 16px;
  align-items: center;

  height: fit-content;
  padding: 6px;
  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
    padding: 3px 0;
  }

  
`;

export const GlobalStyle = createGlobalStyle`
font-family: Archivo;
.swal2-container.swal2-center>.swal2-popup{
  background: #1A232B !important;
 width:26rem;
  user-select: none;
}
.swal2-title{
  color: #01C0FC;
  font-size: 1.175em;
  text-transform: uppercase;
  font-family: 'Archivo', sans-serif;
}
.swal2-actions{
  width: 100%;
  padding: 0 23px;
  flex-wrap: nowrap;
  .swal2-confirm,.swal2-cancel{
    width: 50%;
    padding: 0.22em 1.1rem;
  }
  .swal2-confirm{
    background-color: #01C0FC;
    border: 3px solid #01C0FC;
    text-transform: uppercase;
    font-family: Archivo;
    font-weight: 600;
    color: #000000;
    &:focus {
      box-shadow: none;
    }
  }
  .swal2-cancel{
    background-color: transparent;
    border: 3px solid #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    font-family: Archivo;
    color: #ffffff;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      border: 3px solid #000000;
      color: #000000;
      background-color: #ffffff;
    }
  }
}
.th_span{
  font-family: Archivo;
  font-style: normal;
  font-size: 19px;
  line-height: 22px;
  text-transform: non;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  opacity: 0.75;
}
.td_span{
  font-family: Archivo;
  font-style: normal;
  font-size: 19px;
  line-height: 22px;
  text-transform: non;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.span_green{
  color: #9CF61C;
}
.small_font_td_span{
  font-size: 15px;
  color: #ffffff;
}
.table2{
  padding: 0 17px;
}
`

// status left start here
export const StatusLeft = styled.div.attrs(
  (props: {
    topBg: String;
    heightBottom: String;
    heightTop: String;
    contentBg: String;
  }) => props
)`
  /* display: grid; */
  align-self: stretch;
  width: 357px;
  margin: 0 auto;

  background: ${(props) =>
    props.topBg === "COLLATERALIZED POOL"
      ? `linear-gradient(0deg, rgba(1, 252, 252, 0.65) 73.92%, rgba(31, 107, 165, 0.65) 100%), #12181F`
      : "rgba(26, 35, 43, 1)"};
  border-radius: 12px;

  @media (max-width: 600px) {
    height: ${(props) =>
      props.heightTop === "COLLATERALIZED POOL" &&
      props.heightBottom === "Active SubscriptionL"
        ? "503px"
        : props.heightTop === "COLLATERALIZED POOL" &&
          props.heightBottom === "Funded"
        ? "448px"
        : props.heightTop === "COLLATERALIZED POOL" &&
          props.heightBottom === "Pre–Subscription pool"
        ? "386px"
        : props.heightTop === "COLLATERALIZED POOL" &&
          props.heightBottom === "expired pool"
        ? "386px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "Active Subscription"
        ? "430px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "Funded"
        ? "372px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "Pre–Subscription pool"
        ? "313px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "expired pool"
        ? "313px"
        : ""};
  }

  @media (min-width: 600px) {
    width: 438px;
  }
`;

// start topbar

export const Topbar = styled.div`
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;

  @media (min-width: 600px) {
    height: 62px;
  }
`;
export const Heading = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  text-shadow: 3px 2px 10px rgba(1, 252, 252, 0.55);
`;
export const HeadingContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const VerifiedText = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: rgba(142, 218, 135, 1);
`;
export const Score = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  text-transform: uppercase;
  color: #abbdd1;
`;
export const Span = styled.span`
  color: #ffffff;
  font-weight: 700;
`;

// contenst start here
export const ContentWrapper = styled.div.attrs(
  (props: { heightBottom: String; heightTop: String; contentBg: String }) =>
    props
)`
  position: relative;
  width: 351px;
  height: ${(props) =>
    props.heightTop === "COLLATERALIZED POOL" &&
    props.heightBottom === "Active Subscription"
      ? "435px"
      : props.heightTop === "COLLATERALIZED POOL" &&
        props.heightBottom === "Funded"
      ? "380px"
      : props.heightTop === "COLLATERALIZED POOL" &&
        props.heightBottom === "Pre–Subscription pool"
      ? "318px"
      : props.heightTop === "COLLATERALIZED POOL" &&
        props.heightBottom === "expired pool"
      ? "318px"
      : props.heightTop === "Non–Collateral Pool" &&
        props.heightBottom === "Active Subscription"
      ? "361px"
      : props.heightTop === "Non–Collateral Pool" &&
        props.heightBottom === "Funded"
      ? "261px"
      : props.heightTop === "Non–Collateral Pool" &&
        props.heightBottom === "Pre–Subscription pool"
      ? "249px"
      : props.heightTop === "Non–Collateral Pool" &&
        props.heightBottom === "expired pool"
      ? "249px"
      : ""};

  @media (min-width: 767px) {
    width: 432px;
    height: ${(props) =>
      props.heightTop === "COLLATERALIZED POOL" &&
      props.heightBottom === "Active Subscription"
        ? "351px"
        : props.heightTop === "COLLATERALIZED POOL" &&
          props.heightBottom === "Funded"
        ? "298px"
        : props.heightTop === "COLLATERALIZED POOL" &&
          props.heightBottom === "Pre–Subscription pool"
        ? "258px"
        : props.heightTop === "COLLATERALIZED POOL" &&
          props.heightBottom === "expired pool"
        ? "258px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "Active Subscription"
        ? "291px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "Funded"
        ? "261px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "Pre–Subscription pool"
        ? "261px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "expired pool"
        ? "261px"
        : ""};
  }
  margin: 3px;
  /* height: fit-content; */
  padding: 19px 18px 38px 18px;
  background: ${(props) =>
    props.contentBg === "COLLATERALIZED POOL"
      ? `linear-gradient(180.32deg, rgba(34, 151, 241, 0.2) 0.31%, rgba(1, 252, 252, 0.2) 184.05%), #161D23`
      : "rgba(26, 35, 43, 1)"};

  border-radius: 10px;
`;
export const DepositedContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 22px;
`;
export const Title = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #687d95;

  @media (min-width: 600px) {
    font-size: 11px;
  }
`;
export const DepositedAmount = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media (min-width: 600px) {
    font-size: 18px;
  }
`;

export const StatusContent = styled.div.attrs(
  (props: { statusBg: String }) => props
)`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
  gap: 16px;

  width: 100%;
  height: 201px;

  /* background: rgba(16, 67, 83, 0.65); */
  background: ${(props) =>
    props.statusBg === "COLLATERALIZED POOL"
      ? `#054D63`
      : "rgba(31, 41, 52, 1)"};
  border-radius: 8px;

  @media (min-width: 600px) {
    width: 396px;
    height: 123px;
  }
`;

export const VerticleSidebar = styled.span.attrs(
  (props: { sidebarColor: String }) => props
)`
  position: absolute;
  width: 5px;
  height: 80px;
  left: 0;
  top: 17%;

  background: ${(props) =>
    props.sidebarColor === "Active Subscription"
      ? "#01fcfc"
      : props.sidebarColor === "Funded"
      ? "#25FF3B"
      : props.sidebarColor === "Pre-Subscription"
      ? "#FFD029"
      : props.sidebarColor === "Defaulted" || props.sidebarColor === "Successful" || props.sidebarColor === "Pool Ended"
      ? "#B3C7D9"
      : "#01FCFC"};

  box-shadow: 0px 1px 6px 2px rgba(1, 252, 252, 0.23);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  @media (max-width: 600px) {
    width: 3px;
    height: 160px;
    top: 20px;
  }
`;
export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
export const SubTitle = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #687d95;

  @media (max-width: 600px) {
    font-size: 11px;
  }
`;
export const MainTitle = styled.text.attrs(
  (props: { titleColor: String }) => props
)`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;

  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: ${(props) =>
    props.titleColor === "Active Subscription"
      ? "#01FCFC"
      : props.titleColor === "Funded"
      ? "#25FF3B"
      : props.titleColor === "Pre-Subscription"
      ? "#FFD029"
      : props.titleColor === "Defaulted" || props.titleColor === "Successful" ||props.titleColor === "Pool Ended"
      ? "#B3C7D9"
      : "#01FCFC"};
  /* color: #01fcfc; */

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 20px;
  }
`;
export const BottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const TotalCommited = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const H4 = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #1184a6;

  @media (min-width: 600px) {
    font-size: 10px;
  }
`;
export const Value = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #01c0fc;
  @media (min-width: 600px) {
    font-size: 13px;
    color: #ffffff;
  }
`;
export const Borrowers = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const IssuanceContent = styled.div`
  display: flex;

  flex-direction: column;
  gap: 5px;
`;

export const RightDivider = styled.span`
  position: absolute;
  background: rgba(61, 79, 97, 1);
  height: 35px;
  top: 0;
  right: -21.8px;
  border: 2px solid #1184a6;

  opacity: 0.34;

  @media (max-width: 600px) {
    display: none;
  }
`;
export const LeftDivider = styled.span`
  position: absolute;
  background: rgba(61, 79, 97, 1);
  height: 35px;
  top: 0;
  right: -21px;
  opacity: 0.34;
  border: 2px solid #1184a6;

  @media (max-width: 600px) {
    right: -25px;
    height: 43px;
  }
`;

export const CommitContent = styled.div`
  margin-top: 24px;
  z-index: 100;
`;
export const TopContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const H2 = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;

  @media (max-width: 600px) {
    font-size: 15px;
  }
`;
export const H3 = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #687d95;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
export const InputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;
const breatheAnimation = keyframes`
 0% { transform: translateX(-20%); }
 
 100% { transform: translateX(10%); }
`;

export const ShadowWrapper = styled.div`
  position: absolute;
  bottom: -22px;
  left: 0;
  width: 100%;
  height: 12%;
  z-index: 1;

  background: radial-gradient(at 80% 0, rgba(1, 192, 252, 1), transparent 90%)
    bottom;

  background-size: 100% 15px;
  background-repeat: no-repeat;
  border-radius: 10px;
  filter: blur(10px);

  opacity: 0;
`;

export const InputGroup = styled.div`
  position: relative;
  width: 100%;
  transition: 0.5s;

  &:hover ${ShadowWrapper} {
    opacity: 1;
    animation: ${breatheAnimation} 0.5s ease-in;
  }
`;

export const Input = styled.input`
  width: 273px;
  height: 40px;
  background: #161d23;
  border-radius: 6px;
  border: 1px solid rgba(1, 192, 252, 0.1);
  outline: none;
  transition: border 0.3s ease-in;
  padding-left: 12px;
  font-size: 12px;
  color: #ffffff;
  font-family: "Space Grotesk";
  font-weight: 500;
  &:focus {
    border: 1px solid rgba(1, 192, 252, 0.8);
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }

  @media (max-width: 600px) {
    width: 200px;
  }
`;
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 14px;
  gap: 10px;
  width: 107px;
  height: 40px;
  background: rgba(1, 192, 252, 0.3);
  border-radius: 6px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid rgba(0, 133, 255, 1);
  transition: all 0.3s ease-in;

  &:hover {
    background: transparent;
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }

  @media (max-width: 600px) {
    padding: 13px 26px;
  }
`;

// status left  end here

// funded button style
export const ButtonWrapper = styled.div.attrs(
  (props: { paddingBottom: String; paddingTop: String }) => props
)`
  margin-top: 23px;
  /* padding-bottom: 20px; */

  padding-bottom: ${(props) =>
    props.paddingBottom === " Non-Collateralised Pool" &&
    props.paddingTop === "Funded"
      ? "30px"
      : ""};

  @media (min-width: 600px) {
    margin-top: 25px;
  }
`;
export const SpanText = styled.span`
  display: block;
  transition: 0.5s;
`;

export const FundedButton = styled(Button)`
  position: relative;
  width: 100%;
  display: block;
  transition: 0.5s;
  display: flex;

  &:hover .buttonLeft {
    padding-right: 260px;

    @media (max-width: 600px) {
      padding-right: 180px;
    }
  }

  &:hover .buttonRight {
    opacity: 1;
    padding-left: 15px;
    transform: translateY(-10px);
  }

  @media (max-width: 600px) {
    padding: 13px 17px;
  }
`;

export const RightArrow = styled.img`
  display: block;
  position: absolute;
  top: 22px;
  left: 355px;
  line-height: 35px;

  opacity: 0;
  transition: all 0.3s ease-in;

  @media (max-width: 600px) {
    left: 273px;
  }
`;

import {
  useConnection,
  useWallet,
  WalletContextState,
} from "@solana/wallet-adapter-react";
import { useCallback, useEffect, useState } from "react";
import {
  DisableButton,
  FirstTableRowHeader,
  TableText,
  VLine,
  EnableButton,
} from "./styles";
import toast from "react-hot-toast";
import { PublicKey } from "@solana/web3.js";
import {
  IDaoGlobalState,
  IDaoGovernorList,
  IGlobalState,
  IProposal,
} from "../../utils/interfaces";
import {
  ABLE_TO_VOTE,
  ACTIVE_PROPOSAL,
  CHANGE_EXPENSE_ACCOUNT,
  DELAY_TIME,
  GOVERNOR_VOTED,
  NOT_GOVERNOR,
  NOT_PERMANENT_GOVERNOR,
  PROPOSAL_DISPLAY,
  PROPOSAL_NOT_ACTIVE,
} from "../../utils/constants";
import {
  defaultDecimal,
  delay,
  getCurrentEpoch,
  getProposalContent,
  getProposalStatus,
  getProposalStatusText,
  getRoundValue,
  shortAddress,
} from "../../utils/utils";
import {
  checkProposalVoting,
  getDaoGlobalState,
  getDaoGovernorList,
  getProposal,
  voteProposal,
} from "../../utils/instructions";
import dayjs from "../../utils/dayjs";
import {
  getGlobalStateFromDB,
  updateGlobalState,
} from "../../be-calls/be-calls";

type PoolProps = {
  proposal: IProposal;
  globalState: IDaoGlobalState;
  governorList: IDaoGovernorList;
  wallet: WalletContextState;
  //key: number;
  //index: number;
  setGlobalState: (gState: IDaoGlobalState) => void;
  setGovernorList: (gList: IDaoGovernorList) => void;
  setMtlGlobalState: (mtlGlobalState: IGlobalState) => void;
};

export const ProposalDetail = ({
  proposal,
  globalState,
  governorList,
  wallet,
  //key,
  //index,
  setGlobalState,
  setGovernorList,
  setMtlGlobalState,
}: PoolProps) => {
  const [proposalInfo, setProposalInfo] = useState<IProposal>(proposal);
  const [gState, setGState] = useState(globalState);
  const [gList, setGList] = useState(governorList);

  const [content, setContent] = useState<string>("");
  const [status, setStatus] = useState<number>(ACTIVE_PROPOSAL);
  const [isVoteable, setIsVoteable] = useState(true);
  const { connection } = useConnection();
  const nf = new Intl.NumberFormat();

  useEffect(() => {
    const { action, firstParam, secondParam } = getProposalContent(
      PROPOSAL_DISPLAY,
      proposalInfo.account.actionFlag,
      proposalInfo.account.id,
      proposalInfo.account.address.toBase58(),
      proposalInfo.account.value.toNumber()
    );
    setContent(action + ": " + firstParam + " " + secondParam);

    const currentEpoch = getCurrentEpoch(gState);
    
    //Set for old data, fix 8 governors  
    if (proposalInfo.account.permanentGovNum == 0) {
      proposalInfo.account.permanentGovNum = 3
      proposalInfo.account.normalGovNum = 8;
    }
    
    //console.log(" permanentGovNum = ", proposalInfo.account.permanentGovNum, " normalGovNum = ", proposalInfo.account.normalGovNum);

    const sts = getProposalStatus(
      gState,
      proposalInfo
    );
    setStatus(sts);

    const state = checkProposalVoting(
      sts,
      proposalInfo,
      gList,
      wallet.publicKey!
    );

    if (state != ABLE_TO_VOTE) setIsVoteable(false);
    else setIsVoteable(true);
  }, [connection, gState, gList, proposalInfo]);

  const voteClicked = async () => {
    const sts = getProposalStatus(
      gState,
      proposalInfo
    );
    setStatus(sts);

    const state = checkProposalVoting(
      sts,
      proposalInfo,
      gList,
      wallet.publicKey!
    );

    // console.log("voteClicked 1 status = ", sts, " state = ", state);

    if (state == ABLE_TO_VOTE) {
      // console.log("voteClicked 2");
      toast("Start voting");
      const tx = await voteProposal(
        connection,
        wallet,
        proposalInfo.account.proposalSeedSrc
      );

      // console.log("voteProposal tx =", tx);

      if (tx) {
        // console.log("voteClicked 3");
        await delay(DELAY_TIME);
        // Update metalend global state to DB
        const tx = await updateGlobalState();
        //console.log("voteClicked 4 metalend updateGlobalState tx =", tx);

        let info: any = await getGlobalStateFromDB();
        if (info.status === "RECEIVED") {
          setMtlGlobalState(info.data as IGlobalState);
        }

        await delay(DELAY_TIME);
        // Update variable gState, gList and in parent page
        const daoGlobalState = await getDaoGlobalState(connection, wallet);

        if (!daoGlobalState) {
          setGState(daoGlobalState!);
          setGlobalState(daoGlobalState!);
        }

        // console.log("voteClicked 5");
        // await delay(DELAY_TIME);
        const daoGovernorList = await getDaoGovernorList(connection, wallet);
        if (!daoGovernorList) {
          setGList(daoGovernorList!);
          setGovernorList(daoGovernorList!);
        }

        // console.log("voteClicked 6");
        // await delay(DELAY_TIME);
        // Update variable proposalInfo
        const pInfo = await getProposal(
          connection,
          wallet,
          proposalInfo.publicKey
        );
        setProposalInfo(pInfo);

        // console.log("voteClicked 7");
        setIsVoteable(false);
        toast("Complete voting");

        // console.log("voteClicked 8");
      } else toast("Voting tx is failed");
    } else {
      setIsVoteable(false);

      if (state == PROPOSAL_NOT_ACTIVE) {
        toast("Proposal is not active to vote");
        // setIsVoteable(false);
      } else if (state == NOT_PERMANENT_GOVERNOR) {
        toast("Require permanent governor to vote");
        // setIsVoteable(false);
      } else if (state == NOT_GOVERNOR) {
        toast("Require governor to vote");
        // setIsVoteable(false);
      } else if (state == GOVERNOR_VOTED) {
        toast("Governor voted before");
        // setIsVoteable(false);
      }
    }
  };

  return (
    <>
      {
        <tr className="">
          <th scope="row" className="py-4 px-2 whitespace-nowrap ">
            <text className="table-text">
              {shortAddress(proposalInfo.publicKey.toBase58())}
            </text>
          </th>
          <td className="py-4 px-2">
            <text className="table-text">{content}</text>
          </td>
          <td className="py-4 px-2">
            <text className="table-text">
              {dayjs
                .unix(proposalInfo.account.timeOfCreation)
                .utc()
                .format("DD-MM-YYYY")}
            </text>
          </td>
          <td className="py-4 px-2">
            <text className="table-text">
              {" "}
              {proposalInfo.account.actionFlag != CHANGE_EXPENSE_ACCOUNT
                ? nf.format(
                    getRoundValue(
                      (proposalInfo.account.votedList.length * 100) /
                        (proposalInfo.account.permanentGovNum +
                          proposalInfo.account.normalGovNum),
                      2
                    )
                  )
                : nf.format(
                    getRoundValue(
                      (proposalInfo.account.votedList.length * 100) /
                       proposalInfo.account.permanentGovNum,
                      2
                    )
                  )}
            </text>
          </td>
          <td className="py-4 px-2">
            <text className="table-text"> {getProposalStatusText(status)}</text>
          </td>
          <td className="py-4 px-2">
            {!isVoteable ? (
               <button className="table-btn">
                <span>Vote</span>{" "}
              </button>
            ) : (
              <EnableButton onClick={voteClicked}>
                <span>Vote</span>{" "}
              </EnableButton>
            )}
          </td>
        </tr>
      }
    </>
  );
};

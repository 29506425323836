import { Dialog, Transition, Popover } from "@headlessui/react";
import { Fragment } from "react";
import { shortAddress, truncateStr } from "../../../utils/utils";
import toast from "react-hot-toast";
import { H4 } from "../../StatusPool/styles";
import { BsInfoCircle } from "react-icons/bs";
import { MdOutlineDesktopWindows } from "react-icons/md";
import { StatusPool } from "../../index";

export function InfoModal({
  isOpen,
  openModal,
  closeModal,
  pInfo,
  index,
  adminInfo,
  feeAccount,
  wallet,
  userAccounts,
  tokenMap,
}) {
  const CopyAction = () => {
    toast.success("Copied Successfully!");
  };
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-[#28333F] p-6 text-left align-middle shadow-xl transition-all">
                  <StatusPool
                    key={index}
                    poolInfo={pInfo}
                    feeVault={adminInfo?.feeVaultTreasury}
                    feeMint={adminInfo?.feeMint}
                    sbFeeVault={adminInfo?.feeVault}
                    userSbVault={feeAccount?.pubkey}
                    extraPeriod={adminInfo?.extraPeriod}
                    wallet={wallet}
                    userAccounts={userAccounts}
                    tokenMap={tokenMap}
                    isDisableBorrowerInfo={true}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
    // <Popover className="relative">
    //             {({ open }) => (
    //                 <>
    //                 <Popover.Button className="flex">
    //                   <H4 className="tracking-wider cursor-pointer hover:text-[#01FCFC] hover:border-b hover:border-[#01C0FC] max-h-[15px]">
    //                     {shortAddress(pInfo.account.mintPoolToken.toBase58())}
    //                   </H4>
    //                   <BsInfoCircle className="ml-1 mt-0.5 cursor-pointer text-xs text-[#01C0FC]" />
    //                 </Popover.Button>
    //                 <Transition
    //                     as={Fragment}
    //                     enter="transition ease-out duration-200"
    //                     enterFrom="opacity-0 translate-y-1"
    //                     enterTo="opacity-100 translate-y-0"
    //                     leave="transition ease-in duration-150"
    //                     leaveFrom="opacity-100 translate-y-0"
    //                     leaveTo="opacity-0 translate-y-1"
    //                 >
    //                   {/*<Popover.Panel style={{ left: '250px', top: '-200px' }} className="absolute z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 transform overflow-hidden rounded-2xl bg-[#28333F] p-6 text-left align-middle shadow-xl transition-all">*/}
    //                   <Popover.Panel style={{top: '-200px' }} className="absolute z-10 mt-3 transform px-4 sm:px-0 transform overflow-hidden rounded-2xl bg-[#28333F] p-6 text-left align-middle shadow-xl transition-all">
    //                     <StatusPool
    //                         key={index}
    //                         poolInfo={pInfo}
    //                         feeVault={adminInfo.feeVaultTreasury}
    //                         feeMint={adminInfo.feeMint}
    //                         sbFeeVault={adminInfo.feeVault}
    //                         userSbVault={feeAccount?.pubkey}
    //                         extraPeriod={adminInfo?.extraPeriod}
    //                         wallet={wallet}
    //                         userAccounts={userAccounts}
    //                         tokenMap={tokenMap}
    //                         isDisableBorrowerInfo={true}
    //                     />
    //                   </Popover.Panel>
    //                 </Transition>
    //                 </>
    //             )}
    //           </Popover>
  );
}

import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { HiOutlineSelector } from "react-icons/hi";
import { IoMdSettings } from "react-icons/io";
import { DEV_WSS_NETWORK, NETWORK } from "../../utils/constants";

export const listEndpoints = [
    {
        name: "solana-devnet",
        endpoint: process.env.REACT_APP_SOLANA_NETWORK_RPC||"https://api.devnet.solana.com",
        wssEndpoint: process.env.REACT_APP_SOLANA_NETWORKWSS_RPC||"wss://api.devnet.solana.com",
    },
    {
        name: "solana-ankr",
        endpoint: NETWORK,
        wssEndpoint: DEV_WSS_NETWORK
    }
];

export const ListRPC = (props) => {
    const onChangeData = (endpoint) => {
        const newEndpoint = listEndpoints.find((el) => el.endpoint == endpoint);
        props.onChangeValue(newEndpoint);
    };

    return (
        <Listbox>
            <div className="relative mt-2.5 text-left">
                <Listbox.Button >
                    <div>
                        <IoMdSettings className="text-white" aria-hidden="true" fontSize="27px"/>
                    </div>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-1 cursor-pointer max-h-28 w-48 z-40 rounded-md  bg-[#1A232B] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm right-0">
                        <Listbox
                            value={props.selected?.endpoint}
                            onChange={onChangeData}
                            disabled={props.disabled}
                        >
                            <div className="relative">
                                <div className="text-white p-2">Network: </div>
                                <Listbox.Button className="relative w-48 cursor-default rounded-lg bg-[#1A232B] py-2 pl-3  text-white text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className="block truncate">
                                        {props.selected?.name}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <HiOutlineSelector
                                            className="h-5 w-5 text-white"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute mt-1 cursor-pointer max-h-28 w-48 z-40 overflow-auto rounded-md  bg-[#1A232B] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {listEndpoints.map(({ name, endpoint }) => (
                                            <Listbox.Option
                                                value={endpoint}
                                                key={endpoint}
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-4 pr-4 ${active
                                                        ? "bg-amber-100 text-[#687D95]"
                                                        : "text-white"
                                                    }`
                                                }
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={`block truncate ${selected
                                                                    ? "font-medium"
                                                                    : "font-normal"
                                                                }`}
                                                        >
                                                            {name}
                                                        </span>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>

    );
};

import styled from "styled-components";

export const TextSection = styled.div`
  width: 505px;
  align-self: stretch;
`;
export const Container = styled.div`
.table-btn{
  background: rgba(1, 192, 252, 0.3);
  border: 1px solid #01C0FC;
  border-radius: 6px;
  padding:1px 7px;
  span{
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      color: #FFFFFF;
  }
}
.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 4.6rem;
  }
   .react-multi-carousel-track{
    padding:1px 43px;
   }
.react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
}
.react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
}
 
 
  width: 100%;
  diplay: flex;
  flex-direction: column;
  gap:0.5rem;
  .second-section {
    display: flex;
    width: 100%;
    gap: 1rem;
   margin-top:1rem;
    .section-heading {
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 30px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
      color: #ffffff;
    }

    .first-half {
      width: 50%;
      max-width:614px;
      height: 186px;
      background: #161d23;
      border-radius: 12px;
      flex: direction;
      flex-direction: column;
      padding: 14px 25px;

      .desc-section {
        display: flex;
        flex-direction: column;
        margin-top: 1.2rem;
        .individual-section {
          display: flex;
          flex-direction: column;
        }

        .heading {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 20px;
          text-transform: uppercase;
          color: #4d647b;
        }
        .text {
          font-family: "Space Grotesk";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
          color: #ffffff;
        }
      }
    }

    .second-half {
      width: 50%;
      height: 186px;
      background: #161d23;
      border-radius: 12px;
      flex: direction;
      flex-direction: column;
      padding: 14px 14px;
      .desc-section {
        display: flex;
        margin-top:0.5rem;
        flex-direction: column;
        .menu-text {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 20px;
          text-transform: uppercase;
          color: #4d647b;
        }

        .menu-number {
          font-family: "Space Grotesk";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 30px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
          color: #ffffff;
        }
        .menu-scroll{
            display: flex;
            justify-content: space-between;
            background: #1F2934;
            border-radius:10px;
        
        }
        .menu-item {
          display: flex;
          gap: 13px;
        }
        .individual-section{
            display: flex;
            flex-direction: column;
        }

        .menu-option {
          display: flex;
          flex-direction: column;
          padding:3px 10px;
          border-radius: 6px;
          height:56px;
          max-height:56px;
        }
      }
    }
  }
`;

export const TitleTxt = styled.div`
  font-size: 21px;
`;

export const DescriptionTxt = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin-top: 11px;
  color: #ffffff;
`;

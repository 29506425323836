import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  body {
    margin:0;
    padding: 0;
    font-family: 'Space Grotesk', sans-serif;
    
   background: #12181F;
   overflow-x: hidden;
   letter-spacing:.5px ;
  }

  html, body{
    height: 100%;
  }
  
  ul {
  margin: 0;
  padding: 0;

}

li{
  list-style: none;
}

a{
  text-decoration: none;
  color: inherit;
}
button{
  border: none;
  outline: none;
}


// navbar wallet button override style
.wallet-adapter-modal-title{
  font-size: 21px;
}
.gradient-text {
  background: -webkit-linear-gradient(90.09deg, #01FCFC 2.67%, #0085FF 104.69%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
letter-spacing: 0.3px;
display: flex;
align-items: center;
text-transform: uppercase;

}
.wallet-adapter-button {
  width: 145px;
  height: 50px;
  padding: 12px 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px !important;
  /* gap: 4px; */

  /* backgrounds/blue green */

  background: linear-gradient(
      180.32deg,
      rgba(34, 151, 241, 0.18) 0.31%,
      rgba(1, 252, 252, 0.18) 125.68%,
      rgba(22, 29, 35, 0) 184.05%
    ),
    #161d23;
  border-radius: 6px;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* or 115% */

  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;

  /* White */

  color: #ffffff;
  cursor: pointer;

  @media (max-width: 600px) {
    display: none;
  }
}
.tele-btn-login {
  display: none;
}


`;

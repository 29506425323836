
import {
    Container,
    Wrapper,
    TopSearchBar,
    ContentWrapper,
    Main,
  } from "./style";
  import DAO from "../../components/DAO"
   
  const DAOUI = () => {
   
    return (
      <>
        <Main>
          <Container>
            <Wrapper>
            
              <ContentWrapper>
              <DAO />
               
              </ContentWrapper>
            </Wrapper>
          </Container>
        </Main>
        
      </>
    );
  };
  
  export default DAOUI;
  
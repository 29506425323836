import React, { useEffect, useState } from "react";
import { IconInput, InputTag } from "../BorrowersPool/styles";
import { useNavigate } from "react-router-dom";

import {
  ButtonWithEffect,
  HeaderText,
  Input,
} from "../BorrowersPool/BorrowersRight/styles";
import calendar from "../../assets/images/calendar.png";
import clock from "../../assets/images/clock.png";
import { CardWrapper, TextGradient,FundedButton } from "./styles";
import { SB_MINT_ADDRESS, USDC_MINT_ADDRESS } from "../../utils/ids";
import {
  getMintDecimalValue,
  useWalletAccounts,
} from "../../utils/walletManager";
import { PublicKey } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { delay, getGMTOnlyDateFromSec, getSymbol } from "../../utils/utils";
import {
  IBorrowingInfo,
  IGlobalState,
  TokenAccount,
} from "../../utils/interfaces";
import {
  createNewPool,
  getGlobalState,
  getRequestPoolInfo,
} from "../../utils/instructions";
import { Button } from "../SelfVerification/styles";
import {
  COUPON_RATE_SCALE,
  decimalUSDC,
  MIN_MARKET_FEE,
  TIME_UNIT_DAY,
  decimalNumberUSDC,
  decimalSB
} from "../../utils/constants";
import { updatePool } from "../../be-calls/be-calls";
import toast from "react-hot-toast";
import { createOnDex } from "../../dex/dex";
import { ListTokens } from "./listTokens";
import { tokensData } from "./tokensData";
import { BsDot } from "react-icons/bs";
import { Tooltip, Whisper } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./index.css";
import { IoIosAddCircle } from "react-icons/io";
import { GlobalStyle } from "../StatusPool/styles";
import { constants } from "fs";
import Swal from "sweetalert2";
import { useUIContext } from "../../context/AppContext";
 

let regexp = /^\d*\.?\d*$/;



const CreatePool = ({ showGuide }: { showGuide: any }) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { userAccounts, tokenMap } = useWalletAccounts();

  const timeUnit = TIME_UNIT_DAY;

  const tokenMint = USDC_MINT_ADDRESS;
  const [feeAccount, setFeeAccount] = useState<TokenAccount | undefined>(
    undefined
  );

  const dtVal = getGMTOnlyDateFromSec(Date.now());
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(dtVal);
  const [subscriptionStartTime, setSubscriptionStartTime] = useState(
    new Date().toLocaleTimeString("en-UK")
  );
  const [poolDuration, setPoolDuration] = useState("9");
  const [poolSubscriptionPeriod, setPoolSubscriptionPeriod] = useState("3");
  const [couponRate, setCouponRate] = useState("10");
  const [goalAmount, setGoalAmount] = useState("2000");
  const [minAmount, setMinAmount] = useState("1000");
  const [selectedToken, setSelectedToken] = useState(tokensData[1]);
  const [collateralToken, setCollateralToken] = useState(tokensData[1].address);
  const [collateralRemain, setCollateralRemain] = useState(0);
  const [collateralAmount, setCollateralAmount] = useState("0");

  const [borrowingInfo, setBorrowingInfo] = useState<IBorrowingInfo>();
  const { adminInfo } = useUIContext();
  const [feeMintDecimal, setFeeMintDecimal] = useState(1);
  const [feeTokenAmount, setFeeTokenAmount] = useState(0);
  const [showCollateral, setShowCollateral] = useState(false);
  let navigate = useNavigate();

  const [SBBalance, setSBBalance] = useState(0);
  const [USDCBalance, setUSDCBalance] = useState(0);
  useEffect(() => {
    if (wallet.publicKey) {
      const sbToken = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === SB_MINT_ADDRESS
      );
      if (sbToken) {
        setSBBalance(Number(sbToken.info.amount) / decimalSB);
      }

      const usdcToken = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === USDC_MINT_ADDRESS
      );
      if (usdcToken) {
        setUSDCBalance(Number(usdcToken.info.amount) / decimalUSDC);
      }
    }
  }, [wallet.connected, userAccounts, wallet.publicKey]);

  const openFeesModal = async () => {
    const createFee = adminInfo.isApproved
      ? Number(adminInfo.requestFeeApprovedConverted)
      : Number(adminInfo.requestFeeUnapprovedConverted);
    const message = `
    <div class="bg-gray-200 py-3 p-4 mt-3 sm:p-1 rounded-md">
      <div class="table2">
        <table class="w-full">
        ${
          createFee > 0
            ? `<tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                 Creation Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${createFee}</b> RZR</span>
              </td>
            </tr>
            <tr>`
            : ""
        }
            <th class="text-left">
              <span class="th_span small_font_td_span">
              Creation Market Fees:  </span>
            </th>
            <td class="text-right">
              <span class="td_span small_font_td_span">
              <b>1.5</b> SOL</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    `;
    await Swal.fire({
      title: "Fees Confirmation",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#01C0FC",
    }).then((result) => {
      if (result.isConfirmed) {
        if(SBBalance < Number(createFee)) {
          toast.error("Not enough balance")
          return 
        }
        createPool();
      }
    });
  };

  const handleChangeValue = (data: any) => {
    setSelectedToken(data);
    if (data.name === "custom") setCollateralToken("");
    else setCollateralToken(data.address);
  };

  useEffect(() => {
    if (wallet.connected) {
      const found = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === SB_MINT_ADDRESS
      );
      //console.log(SB_MINT_ADDRESS);
      setFeeAccount(found);

      getRequestPoolInfo(connection, wallet).then((info) => {
        setBorrowingInfo(info);
      });

      getMintDecimalValue(new PublicKey(SB_MINT_ADDRESS)).then((decimal) => {
        setFeeMintDecimal(decimal);
      });
    }
  }, [connection, userAccounts, wallet, wallet.connected]);

  useEffect(() => {
    if (wallet.connected) {
      const collateral = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === collateralToken
      );
      if (collateral) {
        getMintDecimalValue(new PublicKey(collateralToken)).then(
          (collateralDecimal) => {
            setCollateralRemain(
              Number(collateral.info.amount) / collateralDecimal
            );
          }
        );
      } else {
        setCollateralRemain(0);
      }
    }
  }, [collateralToken, wallet.connected, userAccounts]);

  useEffect(() => {
    if (feeAccount) {
      //console.log(feeAccount);
      setFeeTokenAmount(Number(feeAccount?.info.amount) / feeMintDecimal);
    }
  }, [feeMintDecimal, feeAccount]);

  const applyNewIssueAvailable = () => {
    if (Number(minAmount) > Number(goalAmount)) {
      toast("min amount is larger than goal amount");
      return false;
    }

    if (borrowingInfo !== undefined)
      console.log("Requested pool count : ", borrowingInfo?.poolCount);

    if (Number(poolDuration) <= 0) {
      toast("pool duration is invalid");
      return false;
    }

    if (
      timeUnit !== TIME_UNIT_DAY
      // &&
      // (Number(poolSubscriptionPeriod) * timeUnit < 1 * TIME_UNIT_DAY ||
      //   Number(poolSubscriptionPeriod) * timeUnit > 14 * TIME_UNIT_DAY)
    ) {
      toast("subscription period is invalid");
      return false;
    }

    if (!adminInfo) {
      toast("contract has not been initialized");
      return false;
    }

    if (
      feeTokenAmount <
      adminInfo?.withdrawFeeApproved.toNumber() / feeMintDecimal
    ) {
      console.log(
        feeTokenAmount,
        adminInfo?.withdrawFeeApproved.toNumber(),
        feeMintDecimal
      );
      toast("feeTokenAmount is invalid");
      return false;
    }

    if (borrowingInfo) {
      let isApproved = false;
      adminInfo.approvedList.forEach((approver: any) => {
        if (approver.toBase58() === wallet.publicKey?.toBase58())
          isApproved = true;
      });
      if (isApproved) {
        if (adminInfo.limitPoolCntPerBorrower <= borrowingInfo.poolCount) {
          toast("current user has been limited");
          return false;
        }
      }
    }

    let subscriptStartingTimeInSec =
      new Date(subscriptionStartDate).getTime() / 1000;

    let a = subscriptionStartTime.split(":");
    subscriptStartingTimeInSec += +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

    const date = new Date();
    const offset = date.getTimezoneOffset();
    subscriptStartingTimeInSec += offset * 60;

    if (subscriptStartingTimeInSec < date.getTime() / 1000) {
      toast("subscription start time is invalid");
      return false;
    }

    if (collateralRemain < Number(collateralAmount)) {
      toast("collateral amount is invalid");
      return false;
    }

    return true;
  };

  const createPool = async () => {
    if (!applyNewIssueAvailable()) {
      return;
    }

    let solBanlance = await connection.getBalance(wallet.publicKey!);
    //console.log("solBanlance = ", solBanlance);

    if (solBanlance < MIN_MARKET_FEE) {
      toast(
        "Please deposit more SOL, need min of 1.5 SOL to create market for pool."
      );
      return;
    }

    let subscriptStartingTimeInSec =
      new Date(subscriptionStartDate).getTime() / 1000;
    let a = subscriptionStartTime.split(":");
    subscriptStartingTimeInSec +=
      +a[0] * 60 * 60 + +a[1] * 60 + (a[2] ? +a[2] : 0);

    const date = new Date();
    const offset = date.getTimezoneOffset();
    subscriptStartingTimeInSec += offset * 60;

    if (feeAccount && adminInfo) {
      console.log({ adminInfo: adminInfo });
      console.log(collateralToken);

      toast("Step 1: Start creating pool");
      let info = await createNewPool(
        connection,
        wallet,
        new PublicKey(tokenMint),
        adminInfo?.feeMint,
        adminInfo?.feeVault,
        feeAccount?.pubkey,
        new PublicKey(collateralToken),

        Number(poolDuration) * timeUnit,
        Number(poolSubscriptionPeriod) * timeUnit,
        Number(goalAmount) * decimalUSDC,
        Number(minAmount) * decimalUSDC,
        Number(couponRate) * COUPON_RATE_SCALE,
        subscriptStartingTimeInSec,
        Number(collateralAmount)
      );
      if (info.status === "OK") {
        await updatePool(info.poolKey!);

        toast("New pool is created");
        toast("Step 2: Creating Market");

        console.log(
          "createOnDex pool = ",
          info.poolKey!,
          " pool token = ",
          info.mintPoolToken!
        );

        let data = await connection.getTokenSupply(
          new PublicKey(info.mintPoolToken!)
        );
        //console.log("createOnDex pool token decimal = ", data.value.decimals);

        let result = await createOnDex(
          connection,
          wallet,
          info.mintPoolToken!,
          data.value.decimals,
          USDC_MINT_ADDRESS,
          decimalNumberUSDC,
          info.poolKey!
        );
        console.log(result);
        console.log(result === "OK");
        if (result === "OK") {
          toast("New market is created");
          navigate("/borrowers");
        } else toast("Fail to create the market");
      }
    }
  };

  return (
    <div className="flex justify-center my-first-step">
      <CardWrapper className="w-full">
        <div data-tut="createpool__1" className="main-container flex flex-col relative w-full h-full overflow-hidden text-left rounded-lg style1">
          <div className="flex justify-between px-6 py-3" >
            <span className="label-text text-shadow">New Pool Info </span>
            <span className="label-text">
              <span className="grey-text">Funds Raise:&nbsp;</span> USDC
            </span>
          </div>
          <div className="style59">
            <div className="overflow-y-scroll mr-1 min-h-[12rem] px-5 style61">
              <div data-tut="createpool__2">
              <div className="flex my-other-step">
                <div className="grow px-3">
                  <div className="flex">
                    <HeaderText className="mb-3">
                      Subscription Start Date
                    </HeaderText>

                    {showGuide && (
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            From what date would you like people to be able to{" "}
                            <br />
                            contribute to your raise?
                          </Tooltip>
                        }
                      >
                        <img
                          className="info-dot cursor-pointer"
                          src={
                            "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663306078/Ellipse_3_gpeqbv.svg"
                          }
                          alt="..."
                        />
                      </Whisper>
                    )}
                  </div>

                  <IconInput
                    onChange={(event: any) =>
                      setSubscriptionStartDate(event.target.value)
                    }
                    icon={calendar}
                    width={"100%"}
                    type="date"
                    placeholder="Enter Amount"
                    value={subscriptionStartDate}
                  />
                </div>
                <div className="grow px-3">
                  <div className="flex">
                    <HeaderText className="mb-3">
                      Subscription Start time
                    </HeaderText>
                    {showGuide && (
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            From what time on the start date would you like people to be able to{" "}
                            <br />
                            contribute to your raise?
                          </Tooltip>
                        }
                      >
                        <img
                          className="info-dot cursor-pointer"
                          src={
                            "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663306078/Ellipse_3_gpeqbv.svg"
                          }
                          alt="..."
                        />
                      </Whisper>
                    )}
                  </div>

                  <IconInput
                    onChange={(event: any) =>
                      setSubscriptionStartTime(event.target.value)
                    }
                    icon={clock}
                    width={"100%"}
                    type="time"
                    placeholder="Enter Amount"
                    value={subscriptionStartTime}
                  />
                </div>
              </div>
              <div className="flex mt-6">
                <div className="grow px-3">
                  <div className="flex">
                    <HeaderText className="mb-3">
                      Subscription Period
                    </HeaderText>
                    {showGuide && (
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            For how long would you want to keep the opportunity{" "}
                            <br />
                            for contributions open?
                          </Tooltip>
                        }
                      >
                        <img
                          className="info-dot cursor-pointer"
                          src={
                            "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663306078/Ellipse_3_gpeqbv.svg"
                          }
                          alt="..."
                        />
                      </Whisper>
                    )}
                  </div>

                  <div className="relative">
                    <Input
                      padding={"70px"}
                      width={"100%"}
                      type="text"
                      placeholder="Enter Amount"
                      value={poolSubscriptionPeriod}
                      onInput={(event: any) => {
                        if (regexp.test(event.target.value))
                          setPoolSubscriptionPeriod(event.target.value);
                      }}
                    />
                    <InputTag> Days</InputTag>
                  </div>
                </div>
                <div className="grow px-3">
                  <div className="flex">
                    <HeaderText className="mb-3">Borrow Period</HeaderText>
                    {showGuide && (
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={
                          <Tooltip>For how long would you want to borrow?</Tooltip>
                        }
                      >
                        <img
                          className="info-dot cursor-pointer"
                          src={
                            "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663306078/Ellipse_3_gpeqbv.svg"
                          }
                          alt="..."
                        />
                      </Whisper>
                    )}
                  </div>

                  <div className="relative">
                    <Input
                      padding={"70px"}
                      width={"100%"}
                      type="text"
                      placeholder="Enter Amount"
                      value={poolDuration}
                      onInput={(event: any) => {
                        if (regexp.test(event.target.value))
                          setPoolDuration(event.target.value);
                      }}
                    />
                    <InputTag> Days</InputTag>
                  </div>
                </div>
              </div>
              </div>
              <div data-tut="createpool__3" className="flex mt-6 pt-4 border-div-top">
                <div className="grow px-3">
                  <div className="flex">
                    <HeaderText className="mb-3">Max Borrow Amount</HeaderText>
                    {showGuide && (
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            What is the amount you would want to borrow?
                          </Tooltip>
                        }
                      >
                        <img
                          className="info-dot cursor-pointer"
                          src={
                            "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663306078/Ellipse_3_gpeqbv.svg"
                          }
                          alt="..."
                        />
                      </Whisper>
                    )}
                  </div>

                  <div className="relative">
                    <Input
                      padding={"70px"}
                      width={"100%"}
                      type="text"
                      placeholder="Enter Amount"
                      value={goalAmount}
                      onInput={(event: any) => {
                        if (regexp.test(event.target.value))
                          setGoalAmount(event.target.value);
                      }}
                    />
                    <InputTag>USDC</InputTag>
                  </div>
                </div>
                <div className="grow px-3">
                  <div className="flex">
                    <HeaderText className="mb-3">Min Borrow Amount</HeaderText>
                    {showGuide && (
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            What is the minimum amount of contributions <br />
                            you would be okay with?
                          </Tooltip>
                        }
                      >
                        <img
                          className="info-dot cursor-pointer"
                          src={
                            "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663306078/Ellipse_3_gpeqbv.svg"
                          }
                          alt="..."
                        />
                      </Whisper>
                    )}
                  </div>

                  <div className="relative">
                    <Input
                      padding={"70px"}
                      width={"100%"}
                      type="text"
                      placeholder="Enter Amount"
                      value={minAmount}
                      onInput={(event: any) => {
                        if (regexp.test(event.target.value))
                          setMinAmount(event.target.value);
                      }}
                    />
                    <InputTag>USDC</InputTag>
                  </div>
                </div>
                <div className="grow px-3">
                  <div className="flex">
                    <HeaderText className="mb-3">Borrow Rate</HeaderText>
                    {showGuide && (
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            What interest rate would you want to pay? You can pick{" "}
                            <br />
                            any number for now. Choose a borrowing rate as per
                            <br />
                            current market demands.
                          </Tooltip>
                        }
                      >
                        <img
                          className="info-dot cursor-pointer"
                          src={
                            "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663306078/Ellipse_3_gpeqbv.svg"
                          }
                          alt="..."
                        />
                      </Whisper>
                    )}
                  </div>
                  <div className="relative">
                    <Input
                      padding={"70px"}
                      width={"100%"}
                      type="text"
                      placeholder="Enter Amount"
                      value={couponRate}
                      onChange={(event: any) => {
                        if (regexp.test(event.target.value))
                          setCouponRate(event.target.value);
                      }}
                    />
                    <InputTag>%</InputTag>
                  </div>
                </div>
              </div>

              <div className="flex mt-6 pt-4 border-div-top">
                {showCollateral && (
                  <>
                    <div className="grow px-3">
                      <HeaderText className="mb-3">Select Token</HeaderText>
                      <div className=" ">
                        <ListTokens
                          className="mt-2"
                          selected={selectedToken}
                          disabled={false}
                          onChangeValue={handleChangeValue}
                        />
                      </div>
                    </div>

                    <div className="grow px-3 self-center"></div>
                  </>
                )}
              </div>

              <div className="flex mt-2">
                {showCollateral && (
                  <>
                    <div className="grow px-3">
                      <HeaderText className="mb-3">
                        Collateral Mint Address
                      </HeaderText>
                      <div className="relative">
                        <Input
                          padding={"70px"}
                          width={"100%"}
                          type="text"
                          placeholder="Enter Address"
                          value={collateralToken}
                          onChange={(event: any) => {
                            console.log(event.target.value);
                            setCollateralToken(event.target.value);
                          }}
                        />
                        <InputTag>
                          {collateralToken
                            ? getSymbol(collateralToken, tokenMap)
                            : ""}
                        </InputTag>
                      </div>
                    </div>

                    <div className="grow px-3">
                      <HeaderText className="mb-3">
                        Collateral Amount
                      </HeaderText>
                      <Input
                        width={"100%"}
                        type="text"
                        placeholder="Enter Amount"
                        value={collateralAmount}
                        onChange={(event: any) => {
                          if (regexp.test(event.target.value))
                            setCollateralAmount(event.target.value);
                        }}
                      />
                      <TextGradient>
                        Available Collateral Balance:{" "}
                        {collateralRemain.toFixed(1)}{" "}
                        {collateralToken
                          ? getSymbol(collateralToken, tokenMap)
                          : ""}
                      </TextGradient>
                    </div>
                  </>
                )}
              </div>
            <div data-tut="createpool__4">
              <div
                className="flex gap-2 cursor-pointer w-max"
                
                onClick={() => setShowCollateral(!showCollateral)}
              >
                <IoIosAddCircle className="text-[#01C0FC] text-xl self-center" />
                <text className="text-[#01C0FC] add-collateral">
                  {" "}
                  {showCollateral
                    ? "REMOVE Collateral"
                    : "ADD Collateral(Optional)"}
                </text>

                {showGuide && (
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        On RaiseR, you can post collateral against the amount
                        you <br />
                        want to borrow. The key advantage here is that your
                        collateral <br />
                        cannot get liquidated. If you don’t pay back what you
                        owe, the <br />
                        units of collateral are simply split pro-rata amongst
                        the lenders. <br />
                        If you post an NFT as collateral (and you can), you’ll
                        be notified <br />
                        that in the event of default, the NFT will be auctioned
                        on <a>RaiDex</a> <br />
                        to come up with the distributable proceeds.
                      </Tooltip>
                    }
                  >
                    <img
                      className="info-dot cursor-pointer self-center"
                      style={{ marginTop: "0px", marginRight: "0px" }}
                      src={
                        "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663306078/Ellipse_3_gpeqbv.svg"
                      }
                      alt="..."
                    />
                  </Whisper>
                )}
              </div>

              {/* <Button
                width={"13rem"}
                className=""
                onClick={() => setShowCollateral(!showCollateral)}
              >
                <span>
                  {showCollateral
                    ? "Hide Collateral"
                    : "ADD Collateral(Optional)"}
                    
                </span>
              </Button> */}
              <div className="flex justify-center mt-3 mb-8">
                <FundedButton onClick={openFeesModal} >
                  <text className=" text-white">Create Pool</text>
                </FundedButton>
              </div>
              </div>
            </div>
          </div>
        </div>
      </CardWrapper>
      <GlobalStyle />
    </div>
  );
};

export default CreatePool;

import { PublicKey } from "@solana/web3.js";
import { NETWORK_RPC } from "./constants";

import idl from "./idl/metalend.json";
import dao_idl from "./idl/dao.json";
import aution_idl from "./idl/auction.json";

export const WSOL_MINT_KEY = new PublicKey(
  "So11111111111111111111111111111111111111112"
);

export const LEND_IDL = idl;
export const DAO_IDL = dao_idl;
export const AUCTION_IDL = aution_idl;

export const TOKEN_INFO = {
  mainnet: {},
  devnet: [
    {
      token_name: "SPL-USDC",
      token_symbol: "USDC",
      token_mint: "CyB1ez4yEv3LpxJYs4mc4ti1RpVBNjHkfKLmizCJXWBL",
    },
  ],
  localnet: [
    {
      token_name: "SPL-USDC",
      token_symbol: "USDC",
      token_mint: "DXdV1fxec5HwkvNieXE4bR7LTSBo4EGxVq4RmW8RsnYE",
    },
  ],
};

export const DAO_PROGRAM_ID = new PublicKey(
  NETWORK_RPC === "localnet"
    ? "ENxN7XPT3XuQCho9ETTjnGwatojdu32qaZf6Z3WGWZJo"
    : "ENxN7XPT3XuQCho9ETTjnGwatojdu32qaZf6Z3WGWZJo"
);

export const AUCTION_PROGRAM_ID = new PublicKey(
  NETWORK_RPC === "localnet"
    ? "EUoM419Avr16kENsyQP8DdJDcDQcJ1DsCnaGtLEJo4ZN"
    : "EUoM419Avr16kENsyQP8DdJDcDQcJ1DsCnaGtLEJo4ZN"
);

export const LEND_PROGRAM_ID = new PublicKey(
  NETWORK_RPC === "localnet"
    ? "6Txyh9j2BSn7FyD9dQC4y33RgZPxE2vA7f2f94LjEcUm"
    : "6Txyh9j2BSn7FyD9dQC4y33RgZPxE2vA7f2f94LjEcUm"
);

console.log(NETWORK_RPC);
export const SB_MINT_ADDRESS =
  NETWORK_RPC === "devnet"
    ? "8Mzgyr5j9QxhZF9MghYeFdPFHaDgH2vi1XXbvX46cafk"
    : "8i3dM6LQiHqVsFCES6y5rGAVXEiPiH9nTDAsTqGpmrTC";
export const USDC_MINT_ADDRESS =
  NETWORK_RPC === "devnet"
    ? "CyB1ez4yEv3LpxJYs4mc4ti1RpVBNjHkfKLmizCJXWBL"
    : "DXdV1fxec5HwkvNieXE4bR7LTSBo4EGxVq4RmW8RsnYE";

export let TOKEN_LIST: any =
  NETWORK_RPC === "localnet" ? TOKEN_INFO.localnet : TOKEN_INFO.devnet;

if (NETWORK_RPC === "mainnet-beta") TOKEN_LIST = TOKEN_INFO.mainnet;

export const EMPTY_ADDRESS = "11111111111111111111111111111111";

export const GOVERNOR_ADDRESS = '7XHEUVt5oEKwJHwxGBuUbU4Z4UFuyyLkUqUDgM9VRv2P';
export const APPROVER0_ADDRESS = '8GqsgttSENHvuraLrZ33YfFxDQTCzjWpNH9W85sgwJYL';

export const VLNDR = 'BueX5oFvGStrQhWBxResbg8La8sNqeebiSnJ7gosYXsh'; 
export const LNDR_VAULT_PUBLICKEY = '9o62qqtGyLvnpsuTktraRcu7Vm1PWTqpj9RKVkg6NAJm'; 
export const VLNDR_VAULT_PUBLICKEY = 'ZWUTV8FDReH99eneK5sTXosCoo1Y4FPj4qq3VCABEvL'; 
export const USDC_VAULT_PUBLICKEY = '6tarBUY5ZcYar9ck2CcKEvd5nSiJeZqyTh7qnJyK1vDV';
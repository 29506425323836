import { useDAOContext } from "../../../pages/DAO/data";
import {
  Container,
  BalanceHeading,
  BalanceText,
  ButtonWithEffect,
  Input,
  ButtonWIthAnimate,
  FundedButton
} from "./style";
import * as anchor from "@project-serum/anchor";
import { useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import toast from "react-hot-toast";
import { defaultDecimal, getRoundValue, shortAddress } from "../../../utils/utils";
import { InputTag } from "../../BorrowersPool/styles";
import { decimalSB, decimalUSDC } from "../../../utils/constants";
import { sleep } from "../../../dex/dex";

const DAOStake = () => {
  const { connection } = useConnection();
  const wallet = useWallet();

  const {
    globalState,
    lndrAmount,
    vlndrAmount,
    stakeLndr,
    lndrStake,
    setLndrStake,
    vlndrStake,
    setVlndrStake,
    stakeTime,
    setStakeTime,
    rank,
    stakeVlndr,
    refreshData,
    setRefreshData,
    period,
    totalStakedAmount
  } = useDAOContext();

  const onLockRZR = async () => {
    if(stakeTime <=0 || stakeTime>1825) {
      toast.error("Invalid time, max time is 1825 days(5 years)");
    }
    if (wallet?.connected && lndrStake && stakeTime) {
      await stakeLndr(
        connection,
        wallet,
        lndrStake * decimalSB,
        stakeTime
      );
      await sleep(1000)
      setRefreshData(!refreshData);
      toast.success("Lock RZR successfully!");
    } else {
      toast.error("Please enter infor");
    }
  };

  const checkClaim = () => {
    if (period == "GRACE") {
      toast.error("Invalid period to stake VRZR!");
      return;
    }
    return true;
  };

  const onStakevRZR = async () => {
    if (!checkClaim()) return;
    if (wallet?.connected && vlndrStake) {
      await stakeVlndr(
        connection,
        wallet,
        vlndrStake * decimalSB
      );
      await sleep(1000)
      setRefreshData(!refreshData);
      toast.success("Stake vRZR successfully!");
    } else {
      toast.error("Please enter stake amount");
    }
  };

  const onChangeLndr = (e: any) => {
    const lndrAmountConverted = lndrAmount / decimalSB
    if (Number(e.target.value) > lndrAmountConverted) {
      setLndrStake(lndrAmountConverted);
      return;
    }
    setLndrStake(e.target.value);
  };

  const onChangeStakeTime = (e: any) => {
    if(e.target.value > 1825) return
    setStakeTime(e.target.value);
  };

  const onChangeVlndr = (e: any) => {
    const lndrAmountConverted = vlndrAmount / decimalSB
      
    if (Number(e.target.value) > lndrAmountConverted) {
      setVlndrStake(lndrAmountConverted);
      return;
    }
    setVlndrStake(e.target.value);
  };
  console.log(globalState);
  

  return (
    <Container>
      <div className="heading-warpper">
        <div className="header-one">
          <text className="header-text">lock RZR TO GET VRZR</text>
        </div>
        <div className="header-two">
          <text className="header-text">stake vRZR & Earn Rewards</text>
        </div>
      </div>
      <div className="input-wrapper">
        <div className="input-one">
          <div className="flex">
          <div className="balance-section mr-16">
            <BalanceHeading>rzr balance</BalanceHeading>
            <BalanceText>
              {(lndrAmount &&
              getRoundValue(Number(lndrAmount) / decimalSB, defaultDecimal))
                ||
                0}
            </BalanceText>
          </div>
          <div className="balance-section">
            <BalanceHeading>Total locked RZR</BalanceHeading>
            <BalanceText>
              {(globalState &&
                getRoundValue(Number(totalStakedAmount?.lndr) / decimalSB, defaultDecimal)) ||
                0}
            </BalanceText>
          </div>
          </div>
         
          <div className="section-input">
            <div className="input-box-div">
              <div className="input-box-one">
                <text className="input-label">rzr stake</text>
                <Input
                  width={"100%"}
                  type="number"
                  placeholder="Enter Amount"
                  value={lndrStake}
                  onChange={onChangeLndr}
                />
              </div>
              <div className="input-box-two">
                <text className="input-label">Stake time</text>
                <div className="relative">
                  <Input
                    width={"100%"}
                    max={1825}
                    value={stakeTime}
                    onChange={onChangeStakeTime}
                    type="number"
                    placeholder="Enter Time"
                  />
                  <InputTag>Days</InputTag>
                </div>
              </div>
            </div>
            <text className="you-get">
              you will get{" "}
              {getRoundValue(lndrStake * (stakeTime / 365) * (stakeTime / 365) , defaultDecimal) || 0}{" "}
              vrzr
            </text>
            <div className="lndr-btn">
              <FundedButton onClick={onLockRZR}>
                <text className="btn-text">LOCK RZR</text>
              </FundedButton>
            </div>
          </div>
        </div>

        <div className="input-two">
        <div className="flex">
          <div className="balance-section mr-16">
            <BalanceHeading>vrzr balance</BalanceHeading>
            <BalanceText>
              {(vlndrAmount &&
                getRoundValue(vlndrAmount.toNumber() / decimalSB, defaultDecimal)
                 ) ||
                0}
            </BalanceText>
          </div>
          <div className="balance-section">
            <BalanceHeading>Total staked vRZR </BalanceHeading>
            <BalanceText>
              {(globalState &&
                  getRoundValue(totalStakedAmount?.vlndr?.toNumber() / decimalSB, defaultDecimal)) ||
                0}
            </BalanceText>
          </div>
          </div>
          <div className="section-input">
            <div className="input-box-div">
              <div className="input-box-one">
                <text className="input-label">vrzr stake</text>
                <Input
                  width={"100%"}
                  type="number"
                  placeholder="Enter Amount"
                  value={vlndrStake}
                  onChange={onChangeVlndr}
                />
              </div>
              <div className="input-box-two">                
              </div>
            </div>
            <text className="you-get" style={{ visibility: "hidden" }}>
              you will get 83920 lndr
            </text>
            <div className="lndr-btn">
              <ButtonWIthAnimate onClick={onStakevRZR}>
                <span>stake vrzr</span>
              </ButtonWIthAnimate>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default DAOStake;

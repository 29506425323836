import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaGlasses } from "react-icons/fa";
import { getAuctionItem, getNftSolana } from "../../be-calls/be-calls";
import { decimalUSDC } from "../../utils/constants";
import {
  bidAuction,
  claimBidAuction,
} from "../../utils/instructions";
import { defaultDecimal, getRoundValue, shortAddress } from "../../utils/utils";
import { CardWrapper, GradientText, Input, ButtonEffect } from "./style";
import DefaultNft from "../../assets/images/default-nft.png";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";

const AuctionHistory = ({ item, refreshData, setRefreshData, isHistory }) => {
  const [showPlaceBid, setShowPlaceBid] = useState(false);
  const { connection } = useConnection();
  const wallet = useWallet();
  const [amount, setAmount] = useState();
  const [nftInfo, setNftInfo] = useState();
  
  const placeBid = async () => {
    if (item?.auctionPoolInfo?.currentMaxBidUser?.toBase58() == wallet.publicKey.toBase58()) {
      toast.error("You are already the highest bid!");
      return 
    }
    if (amount <= (item?.auctionPoolInfo?.maxBid.toNumber() / decimalUSDC)) {
      toast.error("You need to bid price higher than highest bid!");
      return 
    }
    await bidAuction(connection, wallet, item, amount);
    setRefreshData(!refreshData);
    toast.success("Place bid successfully!");
  };

  const claimBid = async () => {
    await claimBidAuction(connection, wallet, item);
    setRefreshData(!refreshData);
    toast.success("Claim NFT successfully!");
  };

  useEffect(() => {
      const getItem = async () => {
        try {
          if(item?.account?.collateralToken) {
            const data = await getNftSolana(item?.account?.collateralToken)
            const nftRes = await fetch(data?.data?.metaplex?.metadataUri)
            const nftData = await nftRes.json()
            setNftInfo(nftData);
          }
        } catch(error) {

        }
      };
      getItem();
  }, [item]);

  const nf = new Intl.NumberFormat();

  const CopyAction = () => {
    toast.success("Copied Successfully!");
  };

  return (
    <CardWrapper>
      <div
        className="auction-card relative"
        onMouseEnter={() => setShowPlaceBid(true)}
        onMouseLeave={() => setShowPlaceBid(false)}
      >
        <div className="absolute top-1">
          <div className="card-img">
            <img
              className="nft-image"
              src={nftInfo?.image || DefaultNft}
              alt="img"
            />
          </div>
        </div>
        <div className="absolute top-72">
          <div className="combined-section absolute">
            <div className="second-div">
              <text className="heading">{nftInfo?.name || "N/A"}</text>
              <text className="sub-heading">
                {nftInfo?.name  || "N/A"}
              </text>
              <div className="nft-desc">
                <div className="nft-desc-section">
                  <div className="left-section">
                    <img
                      className="left-logo"
                      src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1662458811/Vector_bymkt0.svg"
                      alt="..."
                    />
                    <text className="left-text">Pool Token</text>
                  </div>
                  <div className="right-section flex">
                    <text className="right-text">
                      {shortAddress(item?.account?.mintPoolToken?.toBase58())}
                    </text>
                    <CopyToClipboard
                        onCopy={CopyAction}
                        text={item?.account?.mintPoolToken?.toBase58()}
                      >
                        <BiCopy className=" ml-1 text-[#01C0FC] text-base cursor-pointer" />
                      </CopyToClipboard>
                  </div>
                </div>

                <div className="nft-desc-section">
                  <div className="left-section">
                    <img
                      className="left-logo"
                      src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1662458811/Vector_2_drygof.svg"
                      alt="..."
                    />
                    <text className="left-text">NFT</text>
                  </div>
                  <div className="right-section flex">
                    <text className="right-text">
                      {shortAddress(item?.account?.collateralToken?.toBase58())}
                    </text>
                    <CopyToClipboard
                        onCopy={CopyAction}
                        text={item?.account?.collateralToken?.toBase58()}
                      >
                        <BiCopy className=" ml-1 text-[#01C0FC] text-base cursor-pointer" />
                      </CopyToClipboard>
                  </div>
                </div>

                <div className="nft-desc-section">
                  <div className="left-section">
                    <img
                      className="left-logo"
                      src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1662458811/Vector_2_drygof.svg"
                      alt="..."
                    />
                    <text className="left-text">Status</text>
                  </div>
                  <div className="right-section">
                    <text className="right-text">{item?.status}</text>
                  </div>
                </div>

                <div className="nft-desc-section">
                  <div className="left-section">
                    <img
                      className="left-logo"
                      src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1662458810/Vector_3_aiinju.svg"
                      alt="..."
                    />
                    <text className="left-text">Highest bid</text>
                  </div>
                  <div className="right-section">
                    <GradientText>
                      {item?.auctionPoolInfo?.maxBid.toNumber() ? (nf.format(
                        getRoundValue(
                          item?.auctionPoolInfo?.maxBid.toNumber() / decimalUSDC,
                          defaultDecimal
                        )
                      ) || 0) + " usdc": "No Bid"}{" "}
                      
                    </GradientText>
                  </div>
                </div>
                <div className="nft-desc-section">
                  <div className="left-section">
                    <text className="left-text"> </text>
                  </div>
                  <div className="right-section" style={{ display: "flex" }}>
                  {item?.auctionPoolInfo?.maxBid.toNumber() ?<><text className="auction-name">
                       {shortAddress(
                        item?.auctionPoolInfo?.currentMaxBidUser?.toBase58()
                      )}
                    </text>
                    <CopyToClipboard
                        onCopy={CopyAction}
                        text={item?.auctionPoolInfo?.currentMaxBidUser?.toBase58()}
                      >
                        <BiCopy className=" ml-1 text-[#01C0FC] text-base cursor-pointer" />
                      </CopyToClipboard>
                      </>: ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="third-section">
            <div className="auction-end">
              <div className="left-section">
                <img
                  className="left-logo"
                  src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1662458819/Vector_dqatpe.svg"
                  alt="..."
                />
                <text className="left-text">{(item?.auctionPoolInfo?.timeEnd?.toNumber() && item?.auctionPoolInfo?.timeEnd?.toNumber() < Date.now() / 1000)? "Ended": "Ending"} at </text>
              </div>
              <div className="right-section">
                <text className="end-date">
                  {item?.auctionPoolInfo?.timeEnd?.toNumber()>0
                    ? `${dayjs
                        .unix(item?.auctionPoolInfo?.timeEnd.toNumber())
                        .utc()
                        .format("MM-DD-YYYY HH:mm:ss")} UTC`
                    : "TBD"}
                </text>
              </div>
            </div>
            <div className="place-bid">
              {!isHistory && ((item?.auctionPoolInfo?.timeEnd?.toNumber() && item?.auctionPoolInfo?.timeEnd?.toNumber() < Date.now() / 1000) ? (
                (item?.auctionPoolInfo?.nftAmount == 1 && wallet?.publicKey?.toBase58() ===
                item?.auctionPoolInfo?.currentMaxBidUser?.toBase58()) ? (
                  <ButtonEffect
                    className="place-bid-button"
                    style={{ margin: "0 auto" }}
                    onClick={claimBid}
                  >
                    <span>Claim</span>
                  </ButtonEffect>
                ) : (
                  <></>
                )
              ) : (
                <>
                  {" "}
                  <Input
                    width={"60%"}
                    type="number"
                    placeholder="Enter Amount"
                    onChange={(e) => setAmount(Number(e.target.value))}
                  />
                  <ButtonEffect className="place-bid-button" onClick={placeBid}>
                    <span>Place Bid</span>
                  </ButtonEffect>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};
export default AuctionHistory;

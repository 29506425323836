import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { Connection, PublicKey } from "@solana/web3.js";
import {  createContext, useContext, useCallback, useEffect, useState } from "react";
import { IDL } from "../../utils/types/dao";
import { Token, TOKEN_PROGRAM_ID, u64 } from "@solana/spl-token";
import { getDaoProgramInstance, getOrCreateAssociatedTokenAccount, stake } from "../../utils/instructions";
import { DAO_PROGRAM_ID, LNDR_VAULT_PUBLICKEY, SB_MINT_ADDRESS, USDC_MINT_ADDRESS, USDC_VAULT_PUBLICKEY, VLNDR, VLNDR_VAULT_PUBLICKEY } from "../../utils/ids";
import { DELAY_TIME, GLOBAL_STATE, RZR_VAULT, USDC_VAULT, VRZR_MINT_TAG, VRZR_VAULT } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { BN } from "@project-serum/anchor";
import { delay, toBuffer } from "../../utils/utils";
import { dataLayout } from "../../utils/layout";

let programId = DAO_PROGRAM_ID;
let lndr = SB_MINT_ADDRESS; 
let vlndr = VLNDR; 
let lndrVaultPubkey = LNDR_VAULT_PUBLICKEY; 
let vlndrVaultPublickey = VLNDR_VAULT_PUBLICKEY; 
let usdcMintPublickey = USDC_MINT_ADDRESS;
let usdcVaultPublickey = USDC_VAULT_PUBLICKEY; 

export interface IGlobalState {
  bump: number,
  epochDuration: anchor.BN,
  graceDuration: anchor.BN,
  preGraceDuration: anchor.BN,
  genesisTime: anchor.BN,
  lndrMint: PublicKey,
  lndrVault: PublicKey,
  minimumProposalCriteria: number,
  minimunVlndrStaking: anchor.BN,
  numGovernor: number,
  numPermanentGovernor: number,
  numProposal: anchor.BN,
  owner: PublicKey,
  totalLndrStaked: anchor.BN,
  totalVlndrStaked: anchor.BN,
  treasuryRevenueDistribution: number,
  usdcVault: PublicKey,
  vlndrMint: PublicKey,
  vlndrVault: PublicKey,
}

export interface IRank {
  rank: PublicKey[]
}

export interface IWeight {
  weight: BN[]
}

export interface ILndrStake {
  nonce: number,
  releaseTime: anchor.BN,
  amount: anchor.BN,
  claimed: boolean,
}

export interface IVlndrStake {
  epoch: number,
  currentWeight: anchor.BN,
  estimatedWeight: anchor.BN,
  estimatedReward: number,
  lastTimeStaked: anchor.BN,
  //rewardClaimed: anchor.BN,
  rewardClaimed: boolean,
  staker: PublicKey,
  totalVlndrStaked: anchor.BN,
  totalWeight: anchor.BN,
}

export interface ITotalVlndrStaked {
  staker: PublicKey,
  totalVlndrStaked: anchor.BN,
}
export interface IVlndrStakeInfo {
  account: {
    staker: PublicKey;
    epoch: number;
    stakeNextEpoch: boolean;
    totalVlndrStaked: BN;
    estimatedWeight: BN;
    currentWeight: BN;
    lastTimeStaked: BN;
    rewardClaimed: boolean;
  };
  publicKey: PublicKey;
}

export interface IEpochStateInfo {
  account: {
    epoch: number;
    totalWeight: BN,
    totalStaked: BN,
    totalReward: BN,
    totalStakedReward: BN,
    pullReward: boolean,
    distributedReward: boolean,
    updateGovernors: boolean,
  };
  publicKey: PublicKey;
}

export interface IGovernorList {
  permanentGovernors: PublicKey[],
  governors: PublicKey[],
}

// async function init(connection: Connection, wallet: any) {
//   let globalState;
//   let vlndrMint;
//   let lndrVault;
//   let usdcVault;
//   let vlndrVault;

//   //const provider = anchor.AnchorProvider.env();
//   //anchor.setProvider(provider);
//   const program = getDaoProgramInstance(connection, wallet);

//   [globalState] = await anchor.web3.PublicKey.findProgramAddress(
//     [ Buffer.from(anchor.utils.bytes.utf8.encode(GLOBAL_STATE))], program.programId
//   );

//   [vlndrMint] = await anchor.web3.PublicKey.findProgramAddress(
//     [ Buffer.from(anchor.utils.bytes.utf8.encode(VRZR_MINT_TAG)), globalState.toBuffer()], program.programId
//   );

//   [lndrVault] = await anchor.web3.PublicKey.findProgramAddress(
//     [ Buffer.from(anchor.utils.bytes.utf8.encode(RZR_VAULT)), globalState.toBuffer()], program.programId
//   );

//   [usdcVault] = await anchor.web3.PublicKey.findProgramAddress(
//     [ Buffer.from(anchor.utils.bytes.utf8.encode(USDC_VAULT)), globalState.toBuffer()], program.programId
//   );

//   [vlndrVault] = await anchor.web3.PublicKey.findProgramAddress(
//     [ Buffer.from(anchor.utils.bytes.utf8.encode(VRZR_VAULT)), globalState.toBuffer()], program.programId
//   );

//   lndr = SB_MINT_ADDRESS;
//   usdcMintPublickey = USDC_MINT_ADDRESS;
//   lndrVaultPubkey = lndrVault.toBase58();
//   vlndr = vlndrMint.toBase58();
//   vlndrVaultPublickey = vlndrVault.toBase58();
//   usdcVaultPublickey = usdcVault.toBase58();

// }

export function getProgramInstance(connection: Connection, wallet: any) {
  const provider = new anchor.AnchorProvider(
    connection,
    wallet,
    anchor.AnchorProvider.defaultOptions()
  );
  const idl = IDL as any;

  const program = new (anchor as any).Program(idl, programId, provider);

  return program;
}

export function getLndr(connection: Connection, wallet: any) {
  const token = new Token(connection, new PublicKey(lndr), TOKEN_PROGRAM_ID, wallet);
  return token as Token;
}

export function getVlndr(connection: Connection, wallet: any) {
  const token = new Token(connection, new PublicKey(vlndr), TOKEN_PROGRAM_ID, wallet);
  return token as Token;
}

export const getGlobalState = async (connection: Connection, wallet: any) => {
  const program = getProgramInstance(connection, wallet);

  const [globalstate_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
  );

  const allList = await program.account.globalState.all();
  if (allList.length === 0) return undefined;
  const globalState = await program.account.globalState.fetch(globalstate_pda);
  return globalState as IGlobalState;
};

export const getRank = async (connection: Connection, wallet: any, epoch: any) => {
  const program = getProgramInstance(connection, wallet);

  const [globalstate_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
  );

  const [rank_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode('ranking')), globalstate_pda.toBuffer(), Buffer.from([epoch])], program.programId
  );

  try {
    const rank = await program.account.rank.fetch(rank_pda);
    return rank as IRank;
  } catch(e) {

  }
};

export const getWeight = async (connection: Connection, wallet: any, epoch: any) => {
  const program = getProgramInstance(connection, wallet);

  const [globalstate_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
  );

  const [weight_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode('weight')), globalstate_pda.toBuffer(), Buffer.from([epoch])], program.programId
  );

  try {
    const weight = await program.account.weight.fetch(weight_pda);
    return weight as IWeight;
  } catch(e) {

  }
};

export const getAllLndrStake = async (connection: Connection, wallet: any, setTotalStaked: any) => {
  const program = getProgramInstance(connection, wallet);

  let nonce;
  const [lndrStakeNonce] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode('lndr-stake-nonce')), wallet.publicKey.toBuffer()], program.programId
  );

  try {
    const lndrStakeNonceFetch = await program.account.lndrStakeNonce.fetch(lndrStakeNonce);
    setTotalStaked(lndrStakeNonceFetch.totalLndrStaked)
    nonce = lndrStakeNonceFetch.nonce;
  } catch (e) {
    nonce = 0;
  }

  let result = await Promise.all(Array.from(Array(nonce).keys()).map(async nonce => {
    const [stakeUser] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('lndr-stakes')), wallet.publicKey.toBuffer(), toBuffer(nonce)], program.programId
    );
    const stake = await program.account.lndrStake.fetch(stakeUser);
    return {
      nonce,
      releaseTime: stake.releaseTime,
      amount: stake.amount,
      claimed: stake.claimed,
    };
  }));
  
  // const nonceList = result
  //   .filter((stake) => {
  //     return !stake.claimed;
  //   })
  //   .filter((stake) => {
  //     console.log(stake);
  //     return stake.releaseTime < Date.now() / 1000;
  //   })
  return result as ILndrStake[];
};

export const getAllVLndrStake = async (connection: Connection, wallet: any, setTotalVlndr: any, epoch: any, setTotalVlndrStakedList: any) => {
  const program = getProgramInstance(connection, wallet);
  /*
  let filters = [
    {
      "dataSize":80
    },
    {
      "memcmp": {
        "offset": 8,
        "bytes": wallet.publicKey.toBase58()
      }
    }
  ];

  const allAccounts = await connection.getProgramAccounts(programId, {
    commitment: connection.commitment,
    filters,
    encoding: 'base64',
  });
  */

  const [globalstate_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
  );

  const allAccs: IVlndrStakeInfo[] = (await program.account.vLndrStake.all());

  let totalVlndrStakedData = allAccs.filter((account : IVlndrStakeInfo) => account.account.epoch == epoch).map((vlndrstake) => { ;
    return {
      staker: vlndrstake.account.staker,
      totalVlndrStaked: vlndrstake.account.totalVlndrStaked
    } as ITotalVlndrStaked;
  });
  
  setTotalVlndrStakedList(totalVlndrStakedData);
  
  const allAccounts: IVlndrStakeInfo[] = allAccs.filter((account : IVlndrStakeInfo) => account.account.staker.toBase58() == wallet.publicKey.toBase58());
  // console.log("allAccounts = ", allAccounts);
  
  await delay(1000);
  const sortedEpochStates: IEpochStateInfo[] = (await program.account.epochState.all()).sort((a: any, b: any) => a?.account.epoch - b?.account.epoch);

  // console.log("sortedEpochStates = ", sortedEpochStates);
  
  let highestEpoch: any = {
      epoch: -1
    }
  // console.log("------------------------------------");
  
  let data: Promise<IVlndrStake>[] = [];

  //const data = await Promise.all(allAccounts
    //.filter((account) => account.account.staker.toBase58() == wallet.publicKey.toBase58())
  //allAccounts.forEach( async (account) => {
    //.map(async (account) => {

  /*
  let maxParallelAccs = 3;
  //let maxParallelAccs = allAccounts.length;

  let groupNum = Math.floor(allAccounts.length / maxParallelAccs);  

  if (allAccounts.length % maxParallelAccs != 0)
    groupNum += 1;
  
  let last: number;  
  
  for (let i = 0; i < groupNum; i++) {    
    if (i + 1 < groupNum)
      last = (i + 1) * maxParallelAccs;
    else 
      last = allAccounts.length;

    let group = allAccounts.slice(i * maxParallelAccs, last);    
  
    
    
    // let groupData = group.map(async (account) => {
    // 
    //   const vlndrstake = await program.account.vLndrStake.fetch(account.pubkey);
    //   //console.log("vlndrstake = ", vlndrstake);
    // 
    //   const [epochState_pda] = await anchor.web3.PublicKey.findProgramAddress(
    //   [ Buffer.from(anchor.utils.bytes.utf8.encode('epoch-state')), globalstate_pda.toBuffer(), Buffer.from([vlndrstake.epoch])], programId
    //   );
    // 
    //   const epochState = await program.account.epochState.fetch(epochState_pda);
    //   if(vlndrstake.epoch> highestEpoch.epoch) {
    //     highestEpoch = vlndrstake
    //   }
    //   
    //   // console.log("staker =", account.account.staker.toBase58(), " staking epoch = ", vlndrstake.epoch, " estimatedWeight = ", vlndrstake.estimatedWeight.toNumber(), " epochState.totalStakedReward = ", epochState.totalStakedReward.toNumber(), " epochState.totalWeight = ", epochState.totalWeight.toNumber(), " estimatedReward = ", vlndrstake.estimatedWeight.mul(epochState.totalStakedReward).div(epochState.totalWeight).toNumber());
    //   return { 
    //     staker: vlndrstake.staker,
    //     epoch: vlndrstake.epoch,
    //     totalVlndrStaked: vlndrstake.totalVlndrStaked,
    //     estimatedWeight: vlndrstake.estimatedWeight,
    //     currentWeight: vlndrstake.currentWeight,
    //     lastTimeStaked: vlndrstake.lastTimeStaked,
    //     rewardClaimed: vlndrstake.rewardClaimed,
    //     totalWeight: epochState.totalWeight,
    //     estimatedReward: vlndrstake.estimatedWeight.mul(epochState.totalStakedReward).div(epochState.totalWeight).toNumber(), 
    //   } as IVlndrStake;
    //   //);
    // });        

    let groupData = group.map(async (vlndrstake) => { 

      const [epochState_pda] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('epoch-state')), globalstate_pda.toBuffer(), Buffer.from([vlndrstake.account.epoch])], programId
      );
  
      const epochState = await program.account.epochState.fetch(epochState_pda);
      if(vlndrstake.account.epoch> highestEpoch.epoch) {
        highestEpoch = vlndrstake.account;
      }
      
      // console.log("staker =", account.account.staker.toBase58(), " staking epoch = ", vlndrstake.epoch, " estimatedWeight = ", vlndrstake.estimatedWeight.toNumber(), " epochState.totalStakedReward = ", epochState.totalStakedReward.toNumber(), " epochState.totalWeight = ", epochState.totalWeight.toNumber(), " estimatedReward = ", vlndrstake.estimatedWeight.mul(epochState.totalStakedReward).div(epochState.totalWeight).toNumber());
      return { 
        staker: vlndrstake.account.staker,
        epoch: vlndrstake.account.epoch,
        totalVlndrStaked: vlndrstake.account.totalVlndrStaked,
        estimatedWeight: vlndrstake.account.estimatedWeight,
        currentWeight: vlndrstake.account.currentWeight,
        lastTimeStaked: vlndrstake.account.lastTimeStaked,
        rewardClaimed: vlndrstake.account.rewardClaimed,
        totalWeight: epochState.totalWeight,
        estimatedReward: vlndrstake.account.estimatedWeight.mul(epochState.totalStakedReward).div(epochState.totalWeight).toNumber(), 
      } as IVlndrStake;
      //);
    }); 

    data = data.concat(...groupData);

    await delay(1000);
  };
  */

  let stakingData = allAccounts.map(async (vlndrstake) => { 
    // console.log("vlndrstake epoch = ", vlndrstake.account.epoch, " sortedEpochStates epoch = ", sortedEpochStates[vlndrstake.account.epoch].account.epoch, " highestEpoch epoch = ", highestEpoch.epoch);
    
    // if (vlndrstake.account.epoch != sortedEpochStates[vlndrstake.account.epoch].account.epoch)
    //   console.log("vlndrstake epoch = ", vlndrstake.account.epoch, " sortedEpochStates epoch = ", sortedEpochStates[vlndrstake.account.epoch].account.epoch);
    
    if(vlndrstake.account.epoch > highestEpoch.epoch) {
        highestEpoch = vlndrstake.account;
    }
    
    // console.log("vlndrstake epoch = ", vlndrstake.account.epoch);
    // console.log(" sortedEpochStates totalStakedReward = ", sortedEpochStates[vlndrstake.account.epoch].account.totalStakedReward.toNumber());
    // console.log(" sortedEpochStates totalWeight = ", sortedEpochStates[vlndrstake.account.epoch].account.totalWeight.toNumber());
    
    return { 
      staker: vlndrstake.account.staker,
      epoch: vlndrstake.account.epoch,
      totalVlndrStaked: vlndrstake.account.totalVlndrStaked,
      estimatedWeight: vlndrstake.account.estimatedWeight,
      currentWeight: vlndrstake.account.currentWeight,
      lastTimeStaked: vlndrstake.account.lastTimeStaked,
      rewardClaimed: vlndrstake.account.rewardClaimed,
      totalWeight: (sortedEpochStates[vlndrstake.account.epoch]) ? sortedEpochStates[vlndrstake.account.epoch].account?.totalWeight : new BN(0),
      estimatedReward: (sortedEpochStates[vlndrstake.account.epoch]) ? vlndrstake.account.estimatedWeight.mul(sortedEpochStates[vlndrstake.account.epoch].account.totalStakedReward).div(sortedEpochStates[vlndrstake.account.epoch].account.totalWeight).toNumber() : 0, 
    } as IVlndrStake;
    //);
  }); 

  data = data.concat(...stakingData);
  
  setTotalVlndr(highestEpoch.totalVlndrStaked);
  // console.log("highestEpoch totalVlndrStaked = ", highestEpoch.totalVlndrStaked);
 
  return Promise.all(data);
};

export const getGovernorList = async (connection: Connection, wallet: any) => {
  const program = getProgramInstance(connection, wallet);

  const [governor_list_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode('governor-list'))], program.programId
  );

  const allList = await program.account.governorList.all();
  if (allList.length === 0) return undefined;
  const governorList = await program.account.governorList.fetch(governor_list_pda);
  return governorList as IGovernorList;
};

export const getLndrAmount = async (connection: Connection, wallet: any) => {
  const lndr = getLndr(connection, wallet);
  try {
    const info = await lndr.getOrCreateAssociatedAccountInfo(wallet.publicKey);
    return info;
  } catch (e) {

  }
};

export const getVlndrAmount = async (connection: Connection, wallet: any) => {
  const lndr = getVlndr(connection, wallet);
  try {
    const info = await lndr.getOrCreateAssociatedAccountInfo(wallet.publicKey);
    return info;
  } catch (e) {
  }
};

const DAOContext = createContext<any>(undefined);

export function useDAOContext() {
  return useContext(DAOContext);
}

export const DAOConsumer = DAOContext.Consumer;

export const DaoProvider = ({children} : any) => { 
  const { connection } = useConnection();
  const wallet = useWallet();
  /*
  useEffect(() => {
    init(connection, wallet);
  }, [connection, wallet]);
  */
  const [globalState, setGlobalState] = useState<IGlobalState>();
  const [governorList, setGovernorList] = useState<IGovernorList>();
  const [vlndrStakeState, setVlndrStakeState] = useState<IVlndrStake[]>();
  const [totalVlndrStakedList, setTotalVlndrStakedList] = useState<ITotalVlndrStaked[]>();
  const [lndrAmount, setLndrAmount] = useState<u64>();
  const [vlndrAmount, setVlndrAmount] = useState<u64>();
  const [lndrStake, setLndrStake] = useState<number>();
  const [vlndrStake, setVlndrStake] = useState<number>();
  const [stakeTime, setStakeTime] = useState<number>();
  const [rank, setRank] = useState<IRank>();
  const [weight, setWeight] = useState<IWeight>();
  const [allStake, setAllStake] = useState<ILndrStake[]>();

  const [period, setPeriod] = useState<string>("");
  const [currentEpoch, setCurrentEpoch] = useState<number>();
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [totalStakedAmount, setTotalStakedAmount] = useState({
    lndr:new anchor.BN(0),
    vlndr:new anchor.BN(0),
  });

  const location = useLocation()

  const stakeLndr = useCallback(async (connection: Connection, wallet: any, amount: number, stakeTime: number) => {
    const program = getProgramInstance(connection, wallet);
  
    const [lndrStakeNonce] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('lndr-stake-nonce')), wallet.publicKey.toBuffer()], program.programId
    );
  
    let nonce;
    
    try {
      const lndrStakeNonceFetch = await program.account.lndrStakeNonce.fetch(lndrStakeNonce);
      nonce = lndrStakeNonceFetch.nonce;
    } catch (error) {
      nonce = 0;
    }
    // console.log("stakeLndr nonce", nonce);
  
    const [stakeUser] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('lndr-stakes')), wallet.publicKey.toBuffer(), toBuffer(nonce)], program.programId
    );
  
    const [globalState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
    );
  
    const lndrMint = new PublicKey(lndr);
    const vlndrMint = new PublicKey(vlndr);
    const lndrVault = new PublicKey(lndrVaultPubkey);

    // console.log("lndr ", lndr); 
    // console.log("vlndr ", vlndr);
    // console.log("lndrVaultPubkey ", lndrVaultPubkey);
  
    const userLndrVault = await getOrCreateAssociatedTokenAccount(
      connection,
      wallet.publicKey,
      lndrMint,
      wallet.publicKey,
      wallet.signTransaction
    );
    const userVlndrVault = await getOrCreateAssociatedTokenAccount(
      connection,
      wallet.publicKey,
      vlndrMint,
      wallet.publicKey,
      wallet.signTransaction
    );
  
    await program.rpc.commitLndr(
      new anchor.BN(amount),
      new anchor.BN(stakeTime*24*60),
      new anchor.BN(nonce),
      {
        accounts: {
          stake: stakeUser,
          globalState,
          lndrVault,
          lndrMint,
          lndrStakeNonce,
          vlndrMint,
          userLndrVault: userLndrVault.address,
          userVlndrVault: userVlndrVault.address,
          authority: wallet.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: anchor.web3.SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY
        },
        instructions: [],
        signers: [],
      }
    );
  }, []);

  const stakeVlndr = useCallback(async (connection: Connection, wallet: any, amount: number) => {
    const program = getProgramInstance(connection, wallet);
  
    const [globalState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
    );

    const [governorList] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("governor-list"))], program.programId
    );

    const [vlndrVault] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("vlndr-vault")), globalState.toBuffer()], program.programId
    );

    const [vlndrStake] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("vlndr-stakes")), wallet.publicKey.toBuffer(), Buffer.from([currentEpoch ? currentEpoch : 0])], program.programId
    );

    const [epochState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("epoch-state")), globalState.toBuffer(), Buffer.from([currentEpoch ? currentEpoch : 0])], program.programId
    );

    const [rank] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("ranking")), globalState.toBuffer(), Buffer.from([currentEpoch ? currentEpoch : 0])], program.programId
    );

    const [weight] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("weight")), globalState.toBuffer(), Buffer.from([currentEpoch ? currentEpoch : 0])], program.programId
    );
  
    const vlndrMint = new PublicKey(vlndr);
  
    const userVlndrVault = await getOrCreateAssociatedTokenAccount(
      connection,
      wallet.publicKey,
      vlndrMint,
      wallet.publicKey,
      wallet.signTransaction
    );
  
    const tx = await program.rpc.stakeVlndr(
      new anchor.BN(amount),
      currentEpoch,
      {
        accounts: {
          vlndrStake,
          epochState,
          globalState,
          governorList,
          vlndrMint,
          rank,
          weight,
          vlndrVault,
          userVlndrVault: userVlndrVault.address,
          authority: wallet.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: anchor.web3.SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        },
        instructions: [],
        signers: [],
      }
    );

    console.log("Stake vlndr wallet.publicKey = ", wallet.publicKey.toBase58(), " amount = ", amount," tx = ", tx);
  }, [currentEpoch]);

  const unstake = useCallback(async (connection: Connection, wallet: any, nonce: number) => {
    const program = getProgramInstance(connection, wallet);
    
    const [stakeUser] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('lndr-stakes')), wallet.publicKey.toBuffer(), toBuffer(nonce)], program.programId
    );
  
    const [lndrStakeNonce] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('lndr-stake-nonce')), wallet.publicKey.toBuffer()], program.programId
    );
  
    const [globalState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
    );
  
    const lndrMint = new PublicKey(lndr);
    // const vlndrMint = new PublicKey(vlndr);
    const lndrVault = new PublicKey(lndrVaultPubkey);
    const userLndrVault = await getOrCreateAssociatedTokenAccount(
      connection,
      wallet.publicKey,
      lndrMint,
      wallet.publicKey,
      wallet.signTransaction
    );

    // const userVlndrVault = await getOrCreateAssociatedTokenAccount(
    //   connection,
    //   wallet.publicKey,
    //   vlndrMint,
    //   wallet.publicKey,
    //   wallet.signTransaction
    // );
  
    await program.rpc.claimLndr(
      new anchor.BN(nonce),
      {
        accounts: {
          stake: stakeUser,
          lndrStakeNonce,
          globalState,
          userLndrVault: userLndrVault.address,
          lndrVault,
          lndrMint,
          // vlndrMint,          
          // userVlndrVault: userVlndrVault.address,
          authority: wallet.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: anchor.web3.SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY
        },
        instructions: [],
        signers: [],
      }
    );
  }, []);

  const yesGov = useCallback(async (connection: Connection, wallet: any, epoch: number) => {
    const program = getProgramInstance(connection, wallet);

    const [globalState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
    );

  
    const [vlndrStake] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('vlndr-stakes')), wallet.publicKey.toBuffer(), Buffer.from([epoch])], program.programId
    );

    const [epochState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("epoch-state")), globalState.toBuffer(), Buffer.from([epoch])], program.programId
    );

    const [rank] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("ranking")), globalState.toBuffer(), Buffer.from([epoch])], program.programId
    );
    
    await program.rpc.willingToBecomeGovernor(
      epoch,
      {
        accounts: {
          vlndrStake,
          globalState,
          rank,
          epochState,
          authority: wallet.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: anchor.web3.SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY
        },
        instructions: [],
        signers: [],
      }
    );
  }, []);

  const claimRewardAndWithdrawVlndr = useCallback(async (connection: Connection, wallet: any, nonce: number) => {
    const program = getProgramInstance(connection, wallet);

    const [globalState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
    );
  
    const [vlndrStake] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('vlndr-stakes')), wallet.publicKey.toBuffer(), Buffer.from([nonce])], program.programId
    );

    const [epochState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("epoch-state")), globalState.toBuffer(), Buffer.from([nonce])], program.programId
    );

    const [rank] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("ranking")), globalState.toBuffer(), Buffer.from([nonce])], program.programId
    );

    const [nextVlndrStake] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('vlndr-stakes')), wallet.publicKey.toBuffer(), Buffer.from([nonce + 1])], program.programId
    );

    const [nextEpochState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("epoch-state")), globalState.toBuffer(), Buffer.from([nonce + 1])], program.programId
    );

    const [nextRank] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("ranking")), globalState.toBuffer(), Buffer.from([nonce + 1])], program.programId
    );

    const [nextWeight] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("weight")), globalState.toBuffer(), Buffer.from([nonce + 1])], program.programId
    );

    const usdcMint = new PublicKey(usdcMintPublickey);
    const usdcVault = new PublicKey(usdcVaultPublickey);
    
    const userUsdcVault = await getOrCreateAssociatedTokenAccount(connection, wallet.publicKey, usdcMint, wallet.publicKey, wallet.signTransaction);
    
    
    const vlndrMint = new PublicKey(vlndr);
    const vlndrVault = new PublicKey(vlndrVaultPublickey);
    
    const userVlndrVault = await getOrCreateAssociatedTokenAccount(
      connection,
      wallet.publicKey,
      vlndrMint,
      wallet.publicKey,
      wallet.signTransaction
    );
  
    await program.rpc.claimRewardAndWithdrawVlndr(
      nonce,
      {
        accounts: {
          vlndrStake,
          globalState,
          epochState,
          rank,
          userUsdcVault: userUsdcVault.address,
          usdcVault,          
          vlndrVault,
          userVlndrVault: userVlndrVault.address,
          nextVlndrStake,
          nextEpochState,
          nextRank,
          nextWeight,
          authority: wallet.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: anchor.web3.SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY
        },
        instructions: [],
        signers: [],
      }
    );
  }, []);

  const claimReward = useCallback(async (connection: Connection, wallet: any, nonce: number) => {
    const program = getProgramInstance(connection, wallet);

    const [globalState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
    );
  
    const [vlndrStake] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('vlndr-stakes')), wallet.publicKey.toBuffer(), Buffer.from([nonce])], program.programId
    );

    const [epochState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("epoch-state")), globalState.toBuffer(), Buffer.from([nonce])], program.programId
    );

    
    const usdcMint = new PublicKey(usdcMintPublickey);
    const usdcVault = new PublicKey(usdcVaultPublickey);
    
    const userUsdcVault = await getOrCreateAssociatedTokenAccount(connection, wallet.publicKey, usdcMint, wallet.publicKey, wallet.signTransaction);
    
    await program.rpc.claimReward(
      nonce,
      {
        accounts: {
          vlndrStake,
          userUsdcVault: userUsdcVault.address,
          epochState,
          usdcVault,
          globalState,
          authority: wallet.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: anchor.web3.SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY
        },
        instructions: [],
        signers: [],
      }
    );
  }, []);

  const continueStakeVlndr = useCallback(async (connection: Connection, wallet: any, epoch: number) => {
    const program = getProgramInstance(connection, wallet);

    const [globalState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('global-state'))], program.programId
    );

    const [oldVlndrStake] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('vlndr-stakes')), wallet.publicKey.toBuffer(), Buffer.from([epoch])], program.programId
    );
  
    const [vlndrStake] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode('vlndr-stakes')), wallet.publicKey.toBuffer(), Buffer.from([epoch + 1])], program.programId
    );

    const [epochState] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("epoch-state")), globalState.toBuffer(), Buffer.from([epoch + 1])], program.programId
    );

    const [rank] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("ranking")), globalState.toBuffer(), Buffer.from([epoch + 1])], program.programId
    );

    const [weight] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("weight")), globalState.toBuffer(), Buffer.from([epoch + 1])], program.programId
    );

    const [governorList] = await anchor.web3.PublicKey.findProgramAddress(
      [ Buffer.from(anchor.utils.bytes.utf8.encode("governor-list"))], program.programId
    );
    
    await program.rpc.claimReward(
      epoch,
      {
        accounts: {
          oldVlndrStake,
          vlndrStake,
          governorList,
          rank,
          weight,
          epochState,
          globalState,
          authority: wallet.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: anchor.web3.SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY
        },
        instructions: [],
        signers: [],
      }
    );
  }, []);

  const setTotalLndr = (value: any) => {
    
    setTotalStakedAmount(prevState => ({...prevState, lndr: value}));
  }

  const setTotalVlndr = (value: any) => {
    
    setTotalStakedAmount(prevState => ({...prevState, vlndr: value}));
  }
  
  useEffect(() => {
    if (connection && wallet && wallet.publicKey !== null) {
      getGlobalState(connection, wallet).then(globalState => {
        setGlobalState(globalState);
        
        const now = (Date.now() - Date.now() % 1000) / 1000;
  
        let epochDuration = Number(globalState?.epochDuration) + Number(globalState?.graceDuration) + Number(globalState?.preGraceDuration);
        const duration = globalState ? now - globalState?.genesisTime.toNumber() : 0;
        const remain = globalState ? duration % epochDuration : 0;
        let period;
        const currentEpoch = globalState ? (duration - duration % epochDuration)/epochDuration : 0;
        setCurrentEpoch(currentEpoch);
        
        if (remain < Number(globalState?.epochDuration)) {
          period = 'EPOCH';
        } else if (remain >= Number(globalState?.epochDuration) && remain < Number(globalState?.epochDuration) + Number(globalState?.preGraceDuration)) {
          period = 'PRE_GRACE';
        } else {
          period = 'GRACE';
        }
        setPeriod(period);
      });
    }
  }, [wallet, connection])
  
  
  useEffect(() => {    
    if(!(location?.pathname.endsWith("dao") || location?.pathname.endsWith("top-stakers"))) return
    if (connection && wallet && wallet.publicKey !== null && currentEpoch != undefined) {
      getGovernorList(connection, wallet).then(setGovernorList);
      
      getLndrAmount(connection, wallet).then(lndrAmount => {
        lndrAmount && setLndrAmount(lndrAmount.amount);
      });
  
      getVlndrAmount(connection, wallet).then(vlndrAmount => {
        vlndrAmount && setVlndrAmount(vlndrAmount.amount);
      });
      getAllVLndrStake(connection, wallet, setTotalVlndr, currentEpoch, setTotalVlndrStakedList).then(setVlndrStakeState);
  
      getRank(connection, wallet, currentEpoch).then(setRank);

      getWeight(connection, wallet, currentEpoch).then(setWeight);
  
      getAllLndrStake(connection, wallet, setTotalLndr).then((stake) => setAllStake(stake));
    }
  }, [wallet, connection, currentEpoch, refreshData, location?.pathname]);

  const value = {
    globalState,
    governorList,
    lndrAmount,
    currentEpoch,
    period,
    vlndrAmount,
    stakeLndr,
    setLndrStake,
    vlndrStake,
    setVlndrStake,
    stakeTime,
    setStakeTime,
    rank,
    weight,
    stakeVlndr,
    vlndrStakeState,
    allStake,
    lndrStake,
    unstake,
    claimRewardAndWithdrawVlndr,
    claimReward,
    refreshData,
    setRefreshData,
    continueStakeVlndr,
    yesGov,
    totalStakedAmount,
    totalVlndrStakedList
  };

  return (
    <DAOContext.Provider value={value}>
      <div className="app-element">{children}</div>
    </DAOContext.Provider>
  );

  // return (
  //   <div className="flex justify-center" style={{ marginTop: 50 }}>
  //     <CardWrapper className="w-full" style={{ display: "flex", flexDirection: "column"}}>
  //       <div className="main-container flex flex-col relative w-full h-full overflow-hidden text-left rounded-lg style1">
  //         <div className="flex justify-center px-6 py-3">
  //           <span className="label-text text-shadow">Dao Control</span>
  //         </div>

  //       </div>
  //       <div className="label-text text-shadow">{`ProgramID: ${programId}`}</div>
  //       <div className="label-text text-shadow">{`period status: ${period}`}</div>
  //       {period === 'PRE_GRACE' && <div className="label-text text-shadow">WILLING BECOME GOVERNORS: <button onClick={() => connection && wallet} style={{ backgroundColor: "red" }} /*disabled={!stake.claimed && stake.releaseTime.toNumber() < Date.now()/1000}*/>YES</button></div>}
  //       <div className="label-text text-shadow">{`current Epoch: ${currentEpoch}`}</div>
  //       <div className="label-text text-shadow">{`epochDuration: ${globalState ? globalState?.epochDuration.toNumber() : ""} s`}</div>
  //       <div className="label-text text-shadow">{`preGraceDuration: ${globalState ? globalState?.preGraceDuration.toNumber() : ""} s`}</div>
  //       <div className="label-text text-shadow">{`graceDuration: ${globalState ? globalState?.graceDuration.toNumber() : ""} s`}</div>
  //       <div className="label-text text-shadow">{`genesisTime: ${globalState ? globalState?.genesisTime.toNumber() : ""}`}</div>
  //       <div className="label-text text-shadow">{`numGovernor: ${globalState ? globalState?.numGovernor : ""}`}</div>
  //       <div className="label-text text-shadow">{`numPermanentGovernor: ${globalState ? globalState?.numPermanentGovernor : ""}`}</div>
  //       <div className="label-text text-shadow">{`PermanentGovernor 1: ${governorList ? governorList?.permanentGovernors[0].toBase58() : ""}`}</div>
  //       <div className="label-text text-shadow">{`PermanentGovernor 2: ${governorList ? governorList?.permanentGovernors[1].toBase58() : ""}`}</div>
  //       <div className="label-text text-shadow">{`PermanentGovernor 3: ${governorList ? governorList?.permanentGovernors[2].toBase58() : ""}`}</div>
  //       <div className="label-text text-shadow">Stake RZR to get vRZR</div>
  //       <div className="label-text text-shadow">Balance RZR {lndrAmount && lndrAmount.div((new anchor.BN(10)).pow(new anchor.BN(6))).toNumber()}</div>
  //       <button className="label-text text-shadow" onClick={() => connection && wallet && lndrStake && stakeTime && stakeLndr(connection, wallet, (new anchor.BN(lndrStake)).mul((new anchor.BN(10)).pow(new anchor.BN(6))).toNumber(), stakeTime)}>
  //         <input type="text" value={lndrStake} placeholder="lndrStake" style={{ color: "black", marginRight: 5 }} onChange={(e) => setLndrStake(Number(e.target.value))}>
  //         </input>
  //         <input type="text" value={stakeTime} placeholder="stakeTime" style={{ color: "black", marginRight: 5 }} onChange={(e) => setStakeTime(Number(e.target.value))}>
  //         </input>
  //         <p style={{ backgroundColor: "red", width: 300 }} >Stake RZR</p></button>

  //       <div className="label-text text-shadow">UnStaked RZR</div>
  //       {allStake && (
  //         <table style={{ width: 1200 }}>
  //           <tr>
  //             <th style={{ textAlign: "left", color: "#fff" }}>NONCE</th>
  //             <th style={{ textAlign: "left", color: "#fff" }}>AMOUNT</th>
  //             <th style={{ textAlign: "left", color: "#fff" }}>CLAIMED</th>
  //             <th style={{ textAlign: "left", color: "#fff" }}>RELEASE TIME</th>
  //             <th style={{ textAlign: "left", color: "#fff" }}></th>
  //           </tr>
  //           {allStake && (
  //             allStake
  //               .map((stake) => (
  //                 <tr>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.nonce}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.amount.div((new anchor.BN(10)).pow(new anchor.BN(6))).toNumber()}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.claimed ? "CLAIMED" : "UNCLAIMED"}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.releaseTime.toNumber()}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>
  //                     <button onClick={() => connection && wallet && unstake(connection, wallet, stake.nonce)} style={{ backgroundColor: "red" }} /*disabled={!stake.claimed && stake.releaseTime.toNumber() < Date.now()/1000}*/>CLAIM</button>
  //                   </td>
  //                 </tr>
  //               ))
  //           )}
  //         </table>
  //       )}

  //       <div className="label-text text-shadow">Balance VRZR {vlndrAmount && vlndrAmount.toNumber()}</div>
  //       <button className="label-text text-shadow"onClick={() => connection && wallet && vlndrStake && stakeVlndr(connection, wallet, vlndrStake)}>
  //         <input type="text" value={vlndrStake} placeholder="vlndrStake" style={{ color: "black", marginRight: 5 }} onChange={(e) => setVlndrStake(Number(e.target.value))}>
  //         </input>
  //         <p style={{ backgroundColor: "red", width: 300 }} >Stake VRZR</p></button>

  //       {
  //         rank && rank.rank.length > 0 && <>
  //           {
  //             rank.rank.map((item, index) => <div className="label-text text-shadow">{`Rank ${index}: ${rank ? rank.rank[index].toBase58() : ""}`}</div>)
  //           }
  //         </>
  //       }
  //       <div className="label-text text-shadow">UnStaked VRZR & REWARD</div>
  //       {vlndrStakeState && (
  //         <table style={{ width: 1200 }}>
  //           <tr>
  //             <th style={{ textAlign: "left", color: "#fff" }}>EPOCH</th>
  //             <th style={{ textAlign: "left", color: "#fff" }}>VRZR STAKE</th>
  //             <th style={{ textAlign: "left", color: "#fff" }}>ESTIMATED WEIGHT</th>
  //             <th style={{ textAlign: "left", color: "#fff" }}>TOTAL WEIGHT</th>
  //             <th style={{ textAlign: "left", color: "#fff" }}>REWARD</th>
  //             <th style={{ textAlign: "left", color: "#fff" }}>CLAIMED</th>
  //           </tr>
  //           {vlndrStakeState && (
  //             vlndrStakeState
  //               .map((stake) => (
  //                 <tr>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.epoch}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.totalVlndrStaked.toNumber()}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.estimatedWeight.toNumber()}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.totalWeight.toNumber()}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.estimatedReward}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>{stake.rewardClaimed ? "true" : "false"}</td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>
  //                     <button onClick={() => connection && wallet && claimRewardAndWithdrawVlndr(connection, wallet, stake.epoch)} style={{ backgroundColor: "red" }} /*disabled={!stake.claimed && stake.releaseTime.toNumber() < Date.now()/1000}*/>CLAIM REWARD & VRZR</button>
  //                   </td>
  //                   <td style={{ textAlign: "left", color: "#fff" }}>
  //                     <button onClick={() => connection && wallet && claimReward(connection, wallet, stake.epoch)} style={{ backgroundColor: "red" }} /*disabled={!stake.claimed && stake.releaseTime.toNumber() < Date.now()/1000}*/>CLAIM REWARD</button>
  //                   </td>
  //                   {
  //                     currentEpoch && stake.epoch === currentEpoch - 1 && (
  //                       <td style={{ textAlign: "left", color: "#fff" }}>
  //                         <button onClick={() => connection && wallet && continueStakeVlndr(connection, wallet, stake.epoch)} style={{ backgroundColor: "red" }} /*disabled={!stake.claimed && stake.releaseTime.toNumber() < Date.now()/1000}*/>CONTINUE STAKE</button>
  //                       </td>
  //                     )
  //                   }
  //                 </tr>
  //               ))
  //           )}
  //         </table>
  //       )}
  //     </CardWrapper>
  //   </div>
  // );
};

import { TextSection, TitleTxt, DescriptionTxt, Container } from "./style";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDAOContext } from "../../../pages/DAO/data";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { shortAddress } from "../../../utils/utils";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const GOV_TAB_TYPE = {
  permanent: "permanentGovernorsList",
  rotational: "governorsList",
};

const DAOControl = () => {
  const [listGov, setListGov] = useState({
    permanentGovernorsList: [],
    governorsList: [],
  });
  const [currentListGov, setCurrentListGov] = useState([]);

  const [activeTabGov, setActiveTabGov] = useState(GOV_TAB_TYPE.permanent);
  const { connection } = useConnection();
  const wallet = useWallet();
  const {
    globalState,
    currentEpoch,
    period,
    governorList,
    yesGov,
    rank
  } = useDAOContext();

  useEffect(() => {
    if (governorList?.permanentGovernors) {
      const newListGov = {};
      newListGov.permanentGovernorsList = mapElementGov(
        governorList?.permanentGovernors,
        "PERMANENT "
      );
      newListGov.governorsList = mapElementGov(
        governorList?.governors,
        "ROTATIONAL "
      );
      setCurrentListGov(newListGov[GOV_TAB_TYPE.permanent]);
      setListGov(newListGov);
    }
  }, [governorList]);

  const mapElementGov = (list, title) => {
    return list.map((el, index) => (
      <div className="individual-section">
        <text className="menu-text">
          {title} gov {index + 1}
        </text>
        <text className="menu-number">{shortAddress(el?.toBase58())}</text>
      </div>
    ));
  };

  const onChangeTab = (value) => {
    setActiveTabGov(value);
    setCurrentListGov(listGov[value]);
  };

  console.log("currentListGov",currentListGov);
  return (
    <Container className="mt-10">
      <TextSection>
        <TitleTxt className="gradient-text">Dao Control</TitleTxt>
      </TextSection>
      <div className="second-section">
        <div className="first-half">
          <text className="section-heading">Duration</text>

          <div className="desc-section">
            <div class="grid grid-rows-2 grid-flow-col gap-5">
              <div className="individual-section">
                <text className="heading">PERIOD STATUS</text>
                <text className="text">
                  {period}{" "}                  
                </text>
              </div>
              <div className="individual-section">
                <text className="heading">CURRENT EPOCH</text>
                <text className="text">{currentEpoch}</text>
              </div>
              <div className="individual-section">
                <text className="heading">EPOCH DURATION</text>
                <text className="text">
                  {Number(globalState?.epochDuration)}s
                </text>
              </div>

              <div className="individual-section">
                <text className="heading">PREGRACE DURATION</text>
                <text className="text">
                  {Number(globalState?.preGraceDuration)}s
                </text>
              </div>
              <div className="individual-section">
                <text className="heading">GRACE DURATION</text>
                <text className="text">
                  {Number(globalState?.graceDuration)}s
                </text>
              </div>
              <div className="individual-section">
                <text className="heading">GENESIS TIME</text>
                <text className="text">
                  {dayjs
                    .unix(globalState?.genesisTime)
                    .utc()
                    .format("DD-MM-YYYY")}
                </text>
              </div>
            </div>
          </div>
        </div>
        <div className="second-half">
          <text className="section-heading">
            Total governors ({globalState?.numGovernor})
          </text>
          <div className="desc-section">
            <div className="menu-item">
              <div
                className="menu-option cursor-pointer"
                style={
                  activeTabGov == GOV_TAB_TYPE.permanent
                    ? { background: "rgb(31 41 52)" }
                    : {}
                }
                onClick={() => onChangeTab(GOV_TAB_TYPE.permanent)}
              >
                <text className="menu-text">permanent governors</text>
                <text className="menu-number">
                  {globalState?.numPermanentGovernor}
                </text>
              </div>
              <div
                className="menu-option cursor-pointer"
                style={
                  activeTabGov == GOV_TAB_TYPE.rotational
                    ? { background: "rgb(31 41 52)" }
                    : {}
                }
                onClick={() => onChangeTab(GOV_TAB_TYPE.rotational)}
              >
                <text className="menu-text">rotational governors</text>
                <text className="menu-number">
                  {governorList?.governors.length}
                </text>
              </div>
            </div>
            <div className="menu-scroll">
              <Carousel
                ssr
                partialVisbile
                itemClass="image-item"
                responsive={responsive}
              >
                {currentListGov}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default DAOControl;

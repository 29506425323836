import styled from "styled-components";

// import Inter from "../../assets/fonts/Inter-Regular.ttf";
// import SpaceGrotesk from "../../assets/fonts/SpaceGrotesk-Regular.ttf";

export const Wrapper = styled.div`
  width: 100%;
   
   .main{
    background: radial-gradient(50.9% 68.48% at 98.94% 1.28%, #01D6FC 0%, #1F6BA5 21.31%, #1F2934 51.61%, #1A232B 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border-radius: 18px;
 
    width: 750px;
    height: 400px;

    .content-section{
        display: flex;
  flex-direction: column;
  justify-content: center;
  height:100%;
       gap:1.2rem;
        .header{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 500;
            font-size: 45px;
            line-height: 47px;
            text-align: center;
            letter-spacing: -1px;
            font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
            color: #FFFFFF;
        }

        .sub-header{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 500;
            font-size: 62px;
            line-height: 62px;
            text-align: center;
            letter-spacing: -1px;
            font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
            background: linear-gradient(90.09deg, #01FCFC 2.67%, #0085FF 104.69%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        .lend-btn{
            background: #01C0FC;
            border-radius: 6px;
            width:fit-content;
            padding: 9px 14px;
            margin: 0 auto;
            margin-top:2rem;
            width: 160px;
            height: 47px;
            span{
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 15px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
                color: #12181F;
         
            }
        }
    }
   }
  
  . 

`
export const ButtonWithEffect = styled.button`
  padding: 9px 14px;
  margin: 0 auto;
  margin-top:2rem;
  cursor: pointer;
  width: 160px;
  height: 47px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  text-align: center;
  span {
    font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 15px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
                color: #12181F;
         
  }
  transition: all 0.3s linear;

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;

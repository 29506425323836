import styled from "styled-components";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

export const NavMain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;

  @media (max-width: 600px) {
    height: 70px;
  }
`;

export const NavMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 1280px) {
    gap: 5px;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const MobileNavLogo = styled(Link)`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const NavLogo = styled(Link)`
  display: flex;
  align-items: center;
  gap: 11px;
`;
export const Span = styled.span``;

export const Image = styled.img`
  width: 124.84px;
  height: 26.49px;
  @media (max-width: 1530px) {
    width: 99.84px;
    height: 21.49px;
  }
`;
export const TextImage = styled.img``;
export const NavList = styled.ul`
  display: flex;
  align-items: center;
  gap: 25px;

  @media (min-width: 768px) {
    display: none;
  }
`;
export const NavListItem = styled.li``;

export const NavListLink = styled(Link)`
  /* app-body/14 bold */

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  text-align: center;

  /* backgrounds/G3 */

  color: #687d95;
`;

export const DropdownList = styled.ul``;
export const BorrowersDropdownList = styled.ul``;

export const NavMenu = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 100;

  ${DropdownList} {
    position: absolute;
    top: 48px;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s;
    background: rgb(26, 35, 43);
    padding: 22px 20px 22px 25px;
    width: 270px;
    border-radius: 6px;
    z-index: 1;
  }
  ${BorrowersDropdownList} {
    position: absolute;
    top: 48px;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s;
    background: rgb(26, 35, 43);
    padding: 22px 20px 22px 25px;
    width: 230px;
    border-radius: 6px;
    z-index: 1;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  &:not(:last-child) {
    margin-right: 10px;
    @media (max-width: 1500px) {
      margin-right: 20px;
    }
    @media (max-width: 1250px) {
      margin-right: 0px;
    }
  }

  &:hover > ${DropdownList} {
    opacity: 1;
    visibility: visible;
    box-shadow: -11px 6px 26px -14px rgba(0, 0, 0, 0.75);
  }
  &:hover > ${BorrowersDropdownList} {
    opacity: 1;
    visibility: visible;
    box-shadow: -11px 6px 26px -14px rgba(0, 0, 0, 0.75);
  }
`;
export const ChevronIcon = styled.img`
  transform: rotate(-90deg);
  opacity: 0;
  transition: 0.5s;
  display: block;
`;

export const DropdownLink = styled(Link)`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: 0.5s;
  
`;
export const DropdownLinkItem = styled(Link)`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: 0.5s;
`;

export const DorpdownItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:hover ${ChevronIcon} {
    opacity: 1;
    margin-left: 16px;
  }

  &:hover ${DropdownLink} {
    padding-left: 3px;
    color:white;
  }

  &:hover:first-child ${DropdownLink} {
    color: rgba(1, 252, 252, 1);
  }
  &:hover:nth-child(2) ${DropdownLink} {
    color: rgba(37, 255, 59, 1);
  }
  &:hover:nth-child(3) ${DropdownLink} {
    color: rgba(255, 208, 41, 1);
  }
  &:hover:last-child ${DropdownLink} {
    color: rgba(179, 199, 217, 1);
  }
`;

export const BorrowersDorpdownItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:hover ${ChevronIcon} {
    opacity: 1;
    margin-left: 16px;
  }

  &:hover ${DropdownLinkItem} {
    padding-left: 3px;
  }

  &:nth-child(2) ${DropdownLinkItem} {
    //color: #18b2de;
  }
  &:hover ${DropdownLinkItem} {
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
`;

export const NavLinks = styled.span`
cursor: pointer;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 6px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #687d95;
  padding: 6px 16px;
  border-radius: 6px;
  transition: 0.5s;
  &:hover {
    background: rgb(31, 40, 51);
  }
`;

export const DownArrow = styled.img`
  width: 11px;
  height: 11px;
`;

export const GetDoxxedBtn = styled.button`
  width: 97px;
  height: 30px;
  border-radius: 45px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  color: #8eda87;
  background: transparent;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #8eda87;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background: #8eda87;
    color: #000;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;

  @media (max-width: 600px) {
    display: none;
  }
  .balance-warpper{
    display: flex;
    align-items: flex-end;
    gap: 30px;
    
    .sb-balance{
      position: relative;
      display: flex;
      align-items: center;
      gap: 3px;
      .heading{
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        display: block;
      }
       
      .sb-span{
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        color: #687d95;
        display: block;
      }
      .line{
        position: absolute;
        width: 20px;
        height: 0px;
        right: -25.3px;
        top: 10px;
        border: 1px solid #28333f;
        transform: rotate(90deg);
      }

    }

    .two{
      .h2{
        font-family: "Space Grotesk";
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          text-align: right;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
          color: #3d4f61;
      }

      .usdv-balance{
        position: relative;
        display: flex;
        align-items: center;
        gap: 3px;

        .heading{
          font-family: "Space Grotesk";
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
          background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          display: block;
        }
         
        .sb-span{
          font-family: "Space Grotesk";
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
          color: #687d95;
          display: block;
        }
      }
    }
  }
`;

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 30px;

  
`;

export const SbBalance = styled.h1`
  position: relative;
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const VerticleLine = styled.div`
  position: absolute;
  width: 20px;
  height: 0px;
  right: -25.3px;
  top: 10px;
  border: 1px solid #28333f;
  transform: rotate(90deg);
`;

export const Heading = styled.span`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: block;
`;

export const UsdcBalanceWrapper = styled.div``;
export const H2 = styled.h2`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  text-align: right;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #3d4f61;
`;
export const UsdcBalance = styled(SbBalance)`
  /* app-heading/H3 */
`;

export const SbSpan = styled.span`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #687d95;
  display: block;
`;
export const UsdcSpan = styled(SbSpan)``;
export const Button = styled.button`
  width: 119px;
  height: 40px;
  padding: 12px 13px;
  background: linear-gradient(
      180.32deg,
      rgba(34, 151, 241, 0.18) 0.31%,
      rgba(1, 252, 252, 0.18) 125.68%,
      rgba(22, 29, 35, 0) 184.05%
    ),
    #161d23;
  border-radius: 6px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  cursor: pointer;
`;

export const NavbarButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ButtonIcon = styled.img`
  width: 6px;
  height: 6px;
`;

import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import "./index.css";
import { RiCloseCircleLine } from "react-icons/ri";
import { ModalWrapper } from "./styles";
import { useNavigate } from "react-router-dom";

export default function RedirectModal({ openModal, closeModal, isOpen }) {

    const navigate = useNavigate();
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center py-10 px-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="select-none   relative transform overflow-hidden rounded-2xl  modal-container p-6 text-left align-middle shadow-xl transition-all"
                  style={{ width: "580px", maxWidth: "580px" }}
                >
                  <RiCloseCircleLine
                    className="text-white text-2xl absolute  top-1 right-1 z-10 cursor-pointer"
                    onClick={closeModal}
                  />

                  <ModalWrapper className="flex flex-col text-center justify-center gap-5 input-div items-center">
                    <text className="redirect-header">
                      {" "}
                      Self verification data submitted
                    </text>
                    <text className="text-white redirect-desc">
                      {" "}
                      To boost your profile strength, please complete credora
                      verification.
                    </text>
                    <button
                      type="button "
                      className="modal-button"
                      onClick={()=>navigate('/xmarginverification')}


                    >
                      <span className="text-base text-white">Proceed</span>
                    </button>
                    {/* 
                    <div className='flex gap-6 mt-10'>
                        <img src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653499/akar-icons_twitter-fill_vkn6xc.svg" alt='twitter' className='w-8'/>
                        <img src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653480/akar-icons_discord-fill_ewmxlp.svg" alt='dicsord' className='w-8'/>
                        <img src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653480/akar-icons_twitter-fill_gaaszb.png" alt='telegram' className='w-8'/>
                    </div> */}
                  </ModalWrapper>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

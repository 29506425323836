import { useCallback, useEffect, useState } from "react";
import { PublicKey, Connection } from "@solana/web3.js";
import {
  AccountLayout,
  u64,
  MintLayout,
  MintInfo,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { useWallet } from "@solana/wallet-adapter-react";
import { DEV_WSS_NETWORK, NETWORK, NETWORK_RPC } from "./constants";
import { TokenAccount } from "./interfaces";
import { deserializeAccount } from "./utils";
import { ENV, TokenInfo, TokenListProvider } from "@solana/spl-token-registry";
import { SB_MINT_ADDRESS, USDC_MINT_ADDRESS } from "./ids";
import {tokensData} from "../components/CreatePool/tokensData";

const connection = new Connection(NETWORK, {wsEndpoint: DEV_WSS_NETWORK});

const getAccountInfo = async (pubKey: PublicKey) => {
  const info = await connection.getAccountInfo(pubKey);

  if (info === null) throw new Error("Failed to find mint account");

  const buffer = Buffer.from(info.data);
  const data = deserializeAccount(buffer);

  const details = {
    pubkey: pubKey,
    account: {
      ...info,
    },
    info: data,
  } as TokenAccount;

  return details;
};

const getMintInfo = async (pubKey: PublicKey) => {
  const info = await connection.getAccountInfo(pubKey);
  if (info === null) throw new Error("Failed to find mint account");

  const data = Buffer.from(info.data);
  return deserializeMint(data);
};
class AccountUpdateEvent extends Event {
  static type = "AccountUpdate";
  id: string;
  constructor(id: string) {
    super(AccountUpdateEvent.type);
    this.id = id;
  }
}
class EventEmitter extends EventTarget {
  raiseAccountUpdated(id: string) {
    this.dispatchEvent(new AccountUpdateEvent(id));
  }
}

const accountEmitter = new EventEmitter();
const userAccounts = new Map<string, TokenAccount>();
const userMints = new Map<string, Promise<MintInfo>>();

export const useWalletAccounts = () => {
  const { wallet, connected, publicKey } = useWallet();
  const [accountList, setAccountList] = useState<TokenAccount[]>([]);
  const [accountUpdated, setAccountUpdated] = useState(false);

  const selectUserAccounts = useCallback(() => {
    return [...userAccounts.values()].filter(
      (a) => a.info.owner.toBase58() === publicKey?.toBase58()
    );
  }, [publicKey]);

  useEffect(() => {
    const getAllAccounts = async (walletAddress: string) => {
      const accounts = await connection.getTokenAccountsByOwner(
        new PublicKey(walletAddress),
        {
          programId: TOKEN_PROGRAM_ID,
        }
      );

      accounts.value
        .map((info) => {
          const data = deserializeAccount(info.account.data);
          const details = {
            pubkey: info.pubkey,
            account: { ...info.account },
            info: data,
          } as TokenAccount;
          return details;
        })
        .forEach((acc) => {
          userAccounts.set(acc.pubkey.toBase58(), acc);
        });
    };
    const getOwnerAccountInfo = async () => {
      if (connected) {
        await getAllAccounts(publicKey!.toBase58());
        setAccountList(selectUserAccounts());
      }
    };
    getOwnerAccountInfo();
  }, [connected, publicKey, selectUserAccounts]);

  useEffect(() => {
    if (!wallet || publicKey) {
      setAccountList([]);
    } else {
      const tokenSubID = connection.onProgramAccountChange(
        TOKEN_PROGRAM_ID,
        (info) => {
          const id = info.accountId as unknown as string;
          if (
            AccountLayout.span &&
            info.accountInfo.data.length === AccountLayout.span
          ) {
            const data = deserializeAccount(info.accountInfo.data);
            const details = {
              pubkey: new PublicKey(info.accountId as unknown as string),
              account: {
                ...info.accountInfo,
              },
              info: data,
            } as TokenAccount;
            // console.log("Sub token account updated:" + id);
            if (userAccounts.has(details.info.owner.toBase58())) {
              console.log("Sub token account updated:" + id);
              userAccounts.set(id, details);
              setAccountUpdated(!accountUpdated);
              accountEmitter.raiseAccountUpdated(id);
            }
          } else if (info.accountInfo.data.length === MintLayout.span) {
            if (userMints.has(id)) {
              const data = Buffer.from(info.accountInfo.data);
              const mint = deserializeMint(data);
              userMints.set(id, new Promise((resolve) => resolve(mint)));
              accountEmitter.raiseAccountUpdated(id);
              setAccountUpdated(!accountUpdated);
            }
            accountEmitter.raiseAccountUpdated(id);
          }
        },
        "singleGossip"
      );
      return () => {
        connection.removeProgramAccountChangeListener(tokenSubID);
      };
    }
  }, [connected, wallet, publicKey, accountUpdated]);

  const [tokenMap, setTokenMap] = useState<Map<string, TokenInfo>>(new Map());

  useEffect(() => {
    new TokenListProvider().resolve().then((tokens) => {
      const chainID =
        NETWORK_RPC === "mainnet-beta"
          ? ENV.MainnetBeta
          : NETWORK_RPC === "devnet"
          ? ENV.Devnet
          : ENV.Testnet;

      const tokenList = tokens.filterByChainId(chainID).getList();

      const tokenMap = tokenList.reduce((map, item) => {
        map.set(item.address, item);
        return map;
      }, new Map());

      tokensData.map((tokenMint) => {
        if (!tokenMap.get(tokenMint.address)) {
          tokenMap.set(tokenMint.address, {
            chainId: chainID,
            address: tokenMint.address,
            name: tokenMint.name,
            decimals: tokenMint.decimals,
            symbol: tokenMint.symbol,
            logoURI: undefined,
          });
        }
      })

      // if (!tokenMap.get(SB_MINT_ADDRESS)) {
      //   tokenMap.set(SB_MINT_ADDRESS, {
      //     chainId: chainID,
      //     address: SB_MINT_ADDRESS,
      //     name: "SuperBond",
      //     decimals: 8,
      //     symbol: "RZR",
      //     logoURI: undefined,
      //   });
      // }

      if (!tokenMap.get(USDC_MINT_ADDRESS)) {
        tokenMap.set(USDC_MINT_ADDRESS, {
          chainId: chainID,
          address: USDC_MINT_ADDRESS,
          name: "SPL-USDC",
          decimals: 6,
          symbol: "USDC",
          logoURI: undefined,
        });
      }
      setTokenMap(tokenMap);
    });
  }, [connected, setTokenMap]);

  return {
    userAccounts: accountList,
    walletAddress: publicKey,
    accountUpdated: accountUpdated,
    tokenMap: tokenMap,
  };
};

const deserializeMint = (data: Buffer) => {
  if (data.length !== MintLayout.span) throw new Error("Not a valid Mint");

  const mintInfo = MintLayout.decode(data);
  if (mintInfo.mintAuthorityOption === 0) {
    mintInfo.mintAuthority = null;
  } else {
    mintInfo.mintAuthority = new PublicKey(mintInfo.mintAuthority);
  }
  mintInfo.supply = u64.fromBuffer(mintInfo.supply);
  mintInfo.isInitialized = mintInfo.isInitialized !== 0;
  if (mintInfo.freezeAuthorityOption === 0) {
    mintInfo.freezeAuthority = null;
  } else {
    mintInfo.freezeAuthority = new PublicKey(mintInfo.freezeAuthority);
  }
  return mintInfo as MintInfo;
};

export const getAccountBalance = async (
  accountPubkey: PublicKey,
  mintPubkey: string
) => {
  if (!accountPubkey) return 0;
  const account = await getAccountInfo(accountPubkey);
  const mint = await getMintInfo(new PublicKey(mintPubkey));
  const balanceA = convert(account, mint, 1.0);
  return balanceA;
};

export const getMintDecimalValue = async (mintAddr: PublicKey) => {
  const mintInfo = await getMintInfo(mintAddr);
  const precision = Math.pow(10, mintInfo?.decimals || 0);
  return precision;
};

export const getDecimals = async (mintAddr: PublicKey) => {
  const mintInfo = await getMintInfo(mintAddr);
  return mintInfo?.decimals || 0;
};

export function convert(
  account?: TokenAccount,
  mint?: MintInfo,
  rate: number = 1.0
): number {
  if (!account) return 0;
  const precision = Math.pow(10, mint?.decimals || 0);
  return (Number(account.info.amount) / precision) * rate;
}

import { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import toast from "react-hot-toast";
import {
  ButtonWithEffect,
  HeaderText,
  Input,
  TextArea,
} from "../BorrowersPool/BorrowersRight/styles";
import { FundedButton } from "./styles";
import { InputTag } from "../BorrowersPool/styles";
import { useWallet } from "@solana/wallet-adapter-react";
import { sendMailVerify, verifySocialInfo } from "../../be-calls/be-calls";
import { SOCIAL_TYPE } from ".";
import OtpInput from "react-otp-input";
import "./index.css";

type VerifyModalProp = {
  isOpen: boolean;
  openModal: any;
  closeModal: any;
  getUserData: any;
  email: string;
  setMailVeried: any;
};

export default function VerifyMailModal({
  isOpen,
  openModal,
  closeModal,
  getUserData,
  email,
  setMailVeried,
}: VerifyModalProp) {
  const [token, setToken] = useState("");
  const [error, setError] = useState(false);
  const [tokenMatched, setTokenMatched] = useState(false);
  const wallet = useWallet();

  const onDataSubmit = async () => {
    try {
      if (!token) {
        toast.error("Please click send code and enter your code");
        return;
      }
      if (wallet.publicKey) {
        const address = wallet.publicKey.toBase58();
        const data = await verifySocialInfo(
          { token },
          SOCIAL_TYPE.email,
          address
        );
        getUserData(address);
        setTokenMatched(true);
        toast.success("Verify mail success!");
        setMailVeried(true);

        setTimeout(() => {
          closeModal();
          setTokenMatched(false);
          setToken('')
        }, 1500);
      }
    } catch (er: any) {
      setError(true);
      if (er?.message) {
        toast.error(er?.message);
        return;
      }
      toast.error("Verify mail failed!");
    }
  };

  useEffect(() => {
    if (isOpen && wallet?.publicKey) sendMail();
  }, [wallet?.publicKey, isOpen]);

  useEffect( () => () => {
    setError(false)
    setTokenMatched(false)
    setToken("")
  } , [] );

  const sendMail = async () => {
    if (wallet?.publicKey) {
      try {
        await sendMailVerify(email, wallet.publicKey.toBase58());
        toast.success("Sent mail success!");
      } catch (er: any) {
        console.log(er);
        if (er?.message) {
          toast.error(er?.message);
          return;
        }
        toast.error("Sent mail failed!");
      }
    }
  };

  const handleOtpChange = (otp: any) => {
    setToken(otp);
    setError(false);
  };

  const getInlineStyle = () => {
    if (tokenMatched) {
      return {
        width: "64px",
        height: "64px",
        margin: "0 10px",
        fontSize: "2rem",
        borderRadius: 10,
        color: "#3D4F61",
        background: "#28333F",
        boxShadow: "0px 1px 18px 2px rgba(124, 250, 76, 0.34)",
        border: "1px solid #2CDD3E"
      };
    }
    return {
      width: "64px",
      height: "64px",
      margin: "0 10px",
      fontSize: "2rem",
      borderRadius: 10,
      color: "#3D4F61",
      background: "#1F2934",
    };
  };
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[600px] h-[372px] transform overflow-hidden rounded-2xl bg-[#28333F] p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-col justify-center items-center gap-5 mt-5">
                    <div className="flex flex-col items-center">
                      <text className="modal-heading">
                        enter the 5-digit code sent to:
                      </text>
                      <text className="email-heading">
                        {email}
                      </text>
                    </div>

                    <div className="flex flex-col items-center gap-3">
                      <text className="otp-header">your Verification Code</text>
                      <OtpInput
                        value={token}
                        onChange={(otp: any) => handleOtpChange(otp)}
                        hasErrored={error}
                        inputStyle={getInlineStyle()}
                        errorStyle={{
                          width: "64px",
                          height: "64px",
                          margin: "0 10px",
                          fontSize: "2rem",
                          borderRadius: 10,
                          border: "1px solid #EF4444",
                          background: "#1F2934",
                          boxShadow: "none",
                        }}
                        focusStyle={{
                          width: "64px",
                          height: "64px",
                          margin: "0 10px",
                          fontSize: "2rem",
                          borderRadius: 10,
                          border: "1px solid #01C0FC",
                          background: "#1F2934",
                          boxShadow:
                            "0px 1px 18px 2px rgba(34, 151, 241, 0.34)",
                          outline: "none",
                          color: "#01C0FC",
                          userSelect: "none",
                        }}
                        numInputs={5}
                      />
                      <div className="flex items-center">
                        <text className="get-code">Didn’t get the code?</text>
                        <text className="resend" onClick={()=>sendMail()}>Resend</text>
                      </div>
                    </div>

                    <FundedButton onClick={onDataSubmit}>
                      <span>Submit</span>
                    </FundedButton>
                  </div>

                  {/* <div className="grow px-3">
                    <HeaderText className="mb-3 mt-3">
                      Code will be sent to {email}.
                    </HeaderText>
                    <div className="relative">
                      <Input
                        padding={"70px"}
                        width={"100%"}
                        type="text"
                        placeholder="Enter Code"
                        onChange={(event: any) =>
                          setToken(event.target.value.toString())
                        }
                        value={token}
                      />
                      <InputTag
                        style={{
                          top: "4px",
                          backgroundColor: "#1a232b",
                          paddingLeft: "8px",
                          paddingRight: "0px",
                        }}
                      >
                        <ButtonWithoutEffect style={{}} onClick={sendMail}>
                          <text className="btn-text">Send Code</text>
                        </ButtonWithoutEffect>
                      </InputTag>
                    </div>
                  </div> */}
                  {/* <div className="flex justify-center mt-3 mb-6">
                    <ButtonWithEffect
                      style={{
                        height: "45px",
                        maxHeight: "45px",
                      }}
                      onClick={onDataSubmit}
                    >
                      <text className="btn-text">Submit</text>
                    </ButtonWithEffect>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

import styled from "styled-components";

export const DropdownContainer = styled.div``;

export const DropdownContent = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

export const DropdownItem = styled.li`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: left;

  /* identical to box height, or 163% */
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  text-align: center;

  /* backgrounds/G3 */

  color: #687d95;

  transition: 0.3s;

  &:hover {
    color: #ffffff;
  }
`;

import styled from "styled-components";

export const Main = styled.div`
  padding-bottom: 200px;
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
`;
export const TopSearchBar = styled.div``;
export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

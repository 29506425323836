import React, { useEffect, useRef, useState } from "react";
import { ButtonWithEffect, HeaderText, Input } from "./BorrowersRight/styles";
import { CloseButton, IconInput, InputTag, ModalWrapper } from "./styles";

import calendar from "../../assets/images/calendar.png";
import clock from "../../assets/images/clock.png";
import { useOutsideClick } from "./useOutsideClick";
import { IGlobalState, IPoolInfo } from "../../utils/interfaces";
import {
  getMintDecimalValue,
  useWalletAccounts,
} from "../../utils/walletManager";
import { PublicKey } from "@solana/web3.js";
import { COUPON_RATE_SCALE, decimalUSDC, TIME_UNIT_DAY } from "../../utils/constants";
import { getDateFromSec, getSymbol, getTimeFromSecNone } from "../../utils/utils";
import { getGlobalState, updatePrePool } from "../../utils/instructions";
import { updatePool } from "../../be-calls/be-calls";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import toast from "react-hot-toast";

interface ModalTypes {
  setShowModal: (e: boolean) => void;
  showModal: boolean;
  setPoolInfo: (poolInfo: IPoolInfo) => void;
  poolInfo: IPoolInfo;
  type: string;
}

export default function Modal({
  setShowModal,
  showModal,
  setPoolInfo,
  poolInfo,
  type,
}: ModalTypes) {
  const { connection } = useConnection();
  const wallet = useWallet();

  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, () => setShowModal(false));

  const { userAccounts, tokenMap } = useWalletAccounts();

  const [subscriptionStartDate, setSubscriptionStartDate] = useState("");
  const [subscriptionStartTime, setSubscriptionStartTime] = useState("");
  const [poolDuration, setPoolDuration] = useState(0);
  const [poolSubscriptionPeriod, setPoolSubscriptionPeriod] = useState(0);
  const [couponRate, setCouponRate] = useState(poolInfo.account.couponRate / COUPON_RATE_SCALE);
  const [goalAmount, setGoalAmount] = useState(0);
  const [minAmount, setMinAmount] = useState(0);

  const collateralToken = poolInfo.account.collateralToken.toBase58();
  const [collateralRemain, setCollateralRemain] = useState(0);
  const [collateralAmount, setCollateralAmount] = useState(0);

  const [collateralDecimal, setCollateralDecimal] = useState(1);
  const [adminInfo, setAdminInfo] = useState<IGlobalState | undefined>(
    undefined
  );

  const timeUnit = TIME_UNIT_DAY;

  useEffect(() => {
    preventDocBodyScrolling();

    function preventDocBodyScrolling() {
      const width = document.body.clientWidth;
      const hasVerticalScrollBar =
        window.innerWidth > document.documentElement.clientWidth;
      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = hasVerticalScrollBar ? "scroll" : "";
      document.body.style.width = `${width}px`;
      document.body.style.position = "fixed";
    }

    function restoreDocBodyScrolling() {
      document.body.style.overflowX = "";
      document.body.style.overflowY = "";
      document.body.style.width = "";
      document.body.style.position = "";
    }
    return () => {
      restoreDocBodyScrolling(); // cleanup on unmount
    };
  }, []);

  useEffect(() => {
    const collateral = userAccounts.find(
      (acc) => acc.info.mint.toBase58() === collateralToken
    );
    if (collateral) {
      getMintDecimalValue(new PublicKey(collateralToken)).then((value) => {
        setCollateralDecimal(value);
        setCollateralRemain(Number(collateral.info.amount) / value);
      });
    } else {
      setCollateralRemain(0);
    }

    getGlobalState(connection, wallet).then((info) => {
      setAdminInfo(info);
    });
  }, [collateralToken, connection, poolInfo, userAccounts, wallet]);

  useEffect(() => {
    setGoalAmount(Number(poolInfo.account.goal) / decimalUSDC);
    setMinAmount(Number(poolInfo.account.min) / decimalUSDC);

    setPoolDuration(poolInfo.account.lendingPeriod / timeUnit);
    setPoolSubscriptionPeriod(poolInfo.account.subscriptionPeriod / timeUnit);

    let subscriptStartTimeInSec = poolInfo.account.subscriptionStartTime;
    const date = new Date();
    const offset = date.getTimezoneOffset();
    subscriptStartTimeInSec -= offset * 60;

    setSubscriptionStartDate(getDateFromSec(subscriptStartTimeInSec));
    setSubscriptionStartTime(getTimeFromSecNone(subscriptStartTimeInSec));

    setCollateralAmount(
      Number(poolInfo.account.collateralAmount) / collateralDecimal
    );
  }, [collateralDecimal, poolInfo, timeUnit]);

  const updatePoolAvailable = () => {
    if (minAmount > goalAmount) {
      toast("min amount is larger than goal amount");
      return false;
    }

    if (Number(poolDuration) <= 0) {
      toast("pool duration is invalid");
      return false;
    }

    if (
      timeUnit !== TIME_UNIT_DAY
      // &&
      // (Number(poolSubscriptionPeriod) * timeUnit < 1 * TIME_UNIT_DAY ||
      //   Number(poolSubscriptionPeriod) * timeUnit > 14 * TIME_UNIT_DAY)
    ) {
      toast("subscription period is invalid");
      return false;
    }

    if (!adminInfo) {
      toast("contract has not been initialized");
      return false;
    }

    let subscriptStartingTimeInSec =
      new Date(subscriptionStartDate).getTime() / 1000;

    let a = subscriptionStartTime.split(":");
    subscriptStartingTimeInSec += +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

    const date = new Date();
    const offset = date.getTimezoneOffset();
    subscriptStartingTimeInSec += offset * 60;

    if (subscriptStartingTimeInSec < date.getTime() / 1000) {
      toast("subscription start time is invalid");
      return false;
    }
    // if (
    //   collateralRemain < collateralAmount ||
    //   collateralAmount <
    //     poolInfo.account.collateralAmount.toNumber() / collateralDecimal
    // ) {
    //   toast("collateral amount is invalid");
    //   return false;
    // }

    return true;
  };

  const updateClick = async () => {
    if (!updatePoolAvailable() || !adminInfo) {
      return;
    }

    let subscriptStartingTimeInSec =
      new Date(subscriptionStartDate).getTime() / 1000;
    let a = subscriptionStartTime.split(":");
    subscriptStartingTimeInSec +=
      +a[0] * 60 * 60 + +a[1] * 60 + (a[2] ? +a[2] : 0);

    const date = new Date();
    const offset = date.getTimezoneOffset();
    subscriptStartingTimeInSec += offset * 60;

    toast("Start updating pool");
    let info = await updatePrePool(
      connection,
      wallet,

      poolInfo.publicKey,
      adminInfo?.feeMint,
      adminInfo?.feeVault,
      new PublicKey(collateralToken),

      Number(poolDuration) * timeUnit,
      Number(poolSubscriptionPeriod) * timeUnit,
      goalAmount * decimalUSDC,
      minAmount * decimalUSDC,
      couponRate * COUPON_RATE_SCALE,
      subscriptStartingTimeInSec,
      collateralAmount
    );

    if (!info.tx) {
      toast("Updating pool tx is failed");
    } else if (info.status === "OK") {
      await updatePool(info.poolKey!);
      toast("Complete updating pool");
    }

    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <ModalWrapper className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div
              ref={dropDownRef}
              className="relative w-auto mt-6 sm:my-0 mx-auto sm:max-w-full"
            >
              {/*content*/}
              <div className="flex flex-col relative w-full h-full overflow-hidden text-left rounded-lg style1">
                <CloseButton onClick={() => setShowModal(false)}>×</CloseButton>
                <div className="flex justify-between px-6 py-3">
                  <span className="label-text text-shadow">
                    {type === "edit" ? "edit" : "new"} Pool Info{" "}
                  </span>
                  <span className="label-text">
                    <span className="grey-text">Funds Raise:&nbsp;</span> USDC
                  </span>
                </div>
                <div className="style59">
                  <div className="overflow-y-scroll mr-1 min-h-[12rem] px-5 style61">
                    <div className="flex">
                      <div className="grow px-3">
                        <HeaderText className="mb-3">
                          Subscription Start Date
                        </HeaderText>
                        <IconInput
                          icon={calendar}
                          width={"100%"}
                          type="date"
                          placeholder="Enter Amount"
                          onChange={(event: any) =>
                            setSubscriptionStartDate(event.target.value)
                          }
                          value={subscriptionStartDate}
                        />
                      </div>
                      <div className="grow px-3">
                        <HeaderText className="mb-3">
                          Subscription Start time
                        </HeaderText>
                        <IconInput
                          icon={clock}
                          width={"100%"}
                          type="time"
                          placeholder="Enter Amount"
                          value={subscriptionStartTime}
                          onChange={(event: any) =>
                            setSubscriptionStartTime(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="flex mt-6">
                      <div className="grow px-3">
                        <HeaderText className="mb-3">
                          Subscription Period
                        </HeaderText>
                        <div className="relative">
                          <Input
                            paddingRight={"70px"}
                            width={"100%"}
                            type="text"
                            placeholder="Enter Amount"
                            value={poolSubscriptionPeriod}
                            onChange={(event: any) =>
                              setPoolSubscriptionPeriod(event.target.value)
                            }
                          />
                          <InputTag> Days</InputTag>
                        </div>
                      </div>
                      <div className="grow px-3">
                        <HeaderText className="mb-3">
                          Borrowing Period
                        </HeaderText>
                        <div className="relative">
                          <Input
                            paddingRight={"70px"}
                            width={"100%"}
                            type="text"
                            placeholder="Enter Amount"
                            value={poolDuration}
                            onChange={(event: any) =>
                              setPoolDuration(event.target.value)
                            }
                          />
                          <InputTag> Days</InputTag>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-6 pt-4 border-div-top">
                      <div className="grow px-3">
                        <HeaderText className="mb-3">
                          Max Issuance Amount
                        </HeaderText>
                        <Input
                          width={"100%"}
                          type="text"
                          placeholder="Enter Amount"
                          value={goalAmount}
                          onChange={(event: any) =>
                            setGoalAmount(Number(event.target.value))
                          }
                        />
                      </div>
                      <div className="grow px-3">
                        <HeaderText className="mb-3">
                          Min Issuance Amount
                        </HeaderText>
                        <Input
                          width={"100%"}
                          type="text"
                          placeholder="Enter Amount"
                          value={minAmount}
                          onChange={(event: any) =>
                            setMinAmount(Number(event.target.value))
                          }
                        />
                      </div>
                      <div className="grow px-3">
                        <HeaderText className="mb-3">Issuance Rate</HeaderText>
                        <Input
                          width={"100%"}
                          type="text"
                          placeholder="Enter Amount"
                          value={couponRate}
                          onChange={(event: any) =>
                            setCouponRate(Number(event.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="flex mt-6 pt-4 border-div-top">
                      <div className="grow px-3">
                        <HeaderText className="mb-3">
                          Collateral Mint Address
                        </HeaderText>
                        <div className="relative">
                          <Input
                            padding={"70px"}
                            width={"100%"}
                            type="text"
                            placeholder="Enter Address"
                            value={collateralToken}
                            disabled={true}
                          />
                          <InputTag>
                            {getSymbol(collateralToken, tokenMap)}
                          </InputTag>
                        </div>
                      </div>
                      <div className="grow px-3">
                        <HeaderText className="mb-3">
                          Collateral Amount
                        </HeaderText>
                        <Input
                          width={"100%"}
                          type="text"
                          placeholder="Enter Amount"
                          readOnly={collateralDecimal==1}
                          value={collateralAmount}
                          onChange={(event: any) =>
                            setCollateralAmount(Number(event.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="flex justify-center mt-3">
                      <ButtonWithEffect onClick={updateClick}>
                        <text className="btn-text">
                          {type === "edit" ? "Update" : "Create"} Pool
                        </text>
                      </ButtonWithEffect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalWrapper>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { FilterSearch, BorrowersPool } from "../../../components";
import { IGlobalState, IPoolInfo } from "../../../utils/interfaces";
import { useWalletAccounts } from "../../../utils/walletManager";
import LoadingBar from 'react-top-loading-bar'
import Modal from "../../../components/BorrowersPool/PoolModal";
import {
  Container,
  Wrapper,
  TopSearchBar,
  ContentWrapper,
  Main,
} from "./styles";

import { ButtonWithoutEffect } from "../../../pages/Lenders/ActiveSubscriptionPool/styles";
import {
  getGlobalStateFromDB,
  getPoolsForBorrower,
} from "../../../be-calls/be-calls";
import EmptyPage from "../../LendEmptyPage";

const ActiveBorrowersPools = () => {
  const wallet = useWallet();
  const { userAccounts, tokenMap } = useWalletAccounts();
  const [progress, setProgress] = useState(0)
  const [myIssuesWithUsers, setMyIssuesWithUsers] = useState<any[]>([]);
  const [dataLoaded, setDataLoaded] = useState<any>(false);
  const [adminInfo, setAdminInfo] = useState<IGlobalState | undefined>(
    undefined
  );
  const [showCount, setShowCount] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [poolInfo, setPoolInfo] = useState<IPoolInfo | undefined>(undefined);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(progress + 10);
    }, 1000);
    if(dataLoaded) {
      setProgress(100);
      clearTimeout(timeout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [dataLoaded]);

  useEffect(() => {
    if (wallet.connected === true) {
      getPoolsForBorrower(wallet.publicKey!.toBase58(), 0, showCount).then(
        (info: any) => {
          if (info.status === "RECEIVED") {
            let allPoolList: IPoolInfo[] = info.data;
            //console.log("showCount = ", showCount, " allPoolList = ", allPoolList);
            setMyIssuesWithUsers(allPoolList);
            setDataLoaded(true)
          }
        }
      );
    }
  }, [wallet.connected, showCount, wallet.publicKey]);

  useEffect(() => {
    if (wallet.publicKey) {
      getGlobalStateFromDB().then((info: any) => {
        if (info.status === "RECEIVED") {
          let adminInfo: IGlobalState = info.data;
          setAdminInfo(adminInfo);
        }
      });
    }
  }, [wallet.connected, wallet.publicKey]);

  const showMore = () => {
    if (myIssuesWithUsers.length >= showCount) setShowCount(showCount + 5);
  };

  return (
    <>
      <Main>
      <LoadingBar color="#01C0FC" progress={progress} onLoaderFinished={() => setProgress(0)} />
        <Container>
          <Wrapper>
            {/* <TopSearchBar>
              <FilterSearch />
            </TopSearchBar> */}
            <ContentWrapper className="mt-10">
              {dataLoaded && adminInfo && myIssuesWithUsers.length ? (
                myIssuesWithUsers
                  .filter((_myIssue, i) => i < showCount)
                  .map((pool) => (
                    <BorrowersPool
                      active={true}
                      extraPeriod={adminInfo?.extraPeriod}
                      poolInfo={pool}
                      userAccounts={userAccounts}
                      adminInfo={adminInfo}
                      setShowModal={setShowModal}
                      setPoolInfo={setPoolInfo}
                      tokenMap={tokenMap}
                    />
                  ))
              ) : (
                <></>
              )}

              {adminInfo && myIssuesWithUsers && myIssuesWithUsers.length ? (
                <div className="flex justify-center">
                  <ButtonWithoutEffect onClick={showMore}>
                    <span>Load More</span>
                  </ButtonWithoutEffect>
                </div>
              ) : (
                <div className="flex justify-center mt-10">
                  {dataLoaded && adminInfo && myIssuesWithUsers && (
                      <EmptyPage text={"Borrow"} url={"/create-pool"} />
                  )}
                </div>
              )}
            </ContentWrapper>
          </Wrapper>
        </Container>
      </Main>
      {showModal && poolInfo ? (
        <Modal
          setShowModal={setShowModal}
          showModal={showModal}
          type="edit"
          setPoolInfo={setPoolInfo}
          poolInfo={poolInfo}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ActiveBorrowersPools;

import styled from "styled-components";

export const MainContainer = styled.div.attrs(
  (props: { heightBottom: String; heightTop: String }) => props
)`
  width: 364px;
  margin: 0 auto;
  height: ${(props) =>
    props.heightTop === "COLLATERALIZED POOL" &&
    props.heightBottom === "Active Subscription"
      ? "327px"
      : props.heightTop === "COLLATERALIZED POOL" &&
        props.heightBottom === "Funded"
      ? "327px"
      : props.heightTop === "COLLATERALIZED POOL" &&
        props.heightBottom === "Pre–Subscription pool"
      ? "323px"
      : props.heightTop === "COLLATERALIZED POOL" &&
        props.heightBottom === "expired pool"
      ? "323px"
      : props.heightTop === "Non–Collateral Pool" &&
        props.heightBottom === "Active Subscription"
      ? "323px"
      : props.heightTop === "Non–Collateral Pool" &&
        props.heightBottom === "Funded"
      ? "327px"
      : props.heightTop === "Non–Collateral Pool" &&
        props.heightBottom === "Pre–Subscription pool"
      ? "323px"
      : props.heightTop === "Non–Collateral Pool" &&
        props.heightBottom === "expired pool"
      ? "323px"
      : ""};
  @media (min-width: 767px) {
    width: 582px;

    height: ${(props) =>
      props.heightTop === "COLLATERALIZED POOL" &&
      props.heightBottom === "Active Subscription"
        ? "416px"
        : props.heightTop === "COLLATERALIZED POOL" &&
          props.heightBottom === "Funded"
        ? "363px"
        : props.heightTop === "COLLATERALIZED POOL" &&
          props.heightBottom === "Pre–Subscription pool"
        ? "323px"
        : props.heightTop === "COLLATERALIZED POOL" &&
          props.heightBottom === "expired pool"
        ? "323px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "Active Subscription"
        ? "353px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "Funded"
        ? "323px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "Pre–Subscription pool"
        ? "323px"
        : props.heightTop === "Non–Collateral Pool" &&
          props.heightBottom === "expired pool"
        ? "323px"
        : ""};
  }

  /* backgrounds/B2 */

  background: #161d23;
  border-radius: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */

  @media (max-width: 600px) {
    padding-top: 15px;

    border-radius: 16px;
    background: #161d23;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 64px;
  @media (max-width: 600px) {
    gap: 10px;
  }
`;
export const Left = styled.div`
flex: 1;
`;
export const Right = styled.div`
flex: 1;
`;
export const Title = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height, or 178% */

  text-transform: uppercase;
  letter-spacing: 0.3px;

  /* backgrounds/G2 */

  color: #4d647b;
`;
export const Description = styled.h2`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  /* identical to box height, or 250% */

  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;

  /* White */

  color: #ffffff;
`;

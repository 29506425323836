import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  LedgerWalletAdapter,
  SlopeWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar } from "./components";
import {
  ActiveSubscriptionPools,
  ExpiredPools,
  FundedPools,
  PreSubscriptionPools,
  ActiveBorrowersPools,
  CreateNewPool,
  MyAccount,
  SelfVerification,
  XmarginVerification,
  Governance,
  MyProposals,
  AuctionHistoryUI,
  DAOUI,
  DaoAccount,
  TopStakers,
  EmptyPage
} from "./pages";
import Admin from "./pages/Admin";
import { GlobalStyle } from "./styles/styles";
import { NETWORK_RPC, NETWORK } from "./utils/constants";
import "./index.css";
import { Toaster } from "react-hot-toast";
import { AppUIProvider, useUIContext } from "./context/AppContext";
import { listEndpoints } from "./components/Navbar/RPCDropdown";
import { DaoProvider } from "./pages/DAO/data";
import "./style.less";
import Joyride from 'react-joyride';
import {CustomProvider} from "rsuite"
require("@solana/wallet-adapter-react-ui/styles.css");

const Context: FC<{ children: ReactNode }> = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  let network: any = undefined;
  network = WalletAdapterNetwork.Devnet;
  if (NETWORK_RPC === "mainnet-beta") network = WalletAdapterNetwork.Mainnet;
  if (NETWORK_RPC === "localnet") network = "localnet";
  // You can also provide a custom RPC endpoint.
  const [endpoint, setendpoint] = useState(
    localStorage.getItem("rpcEndpoint")
      ? JSON.parse(localStorage.getItem("rpcEndpoint") || "{}")
      : listEndpoints[0]
  );

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolflareWalletAdapter(),
    ],
    [network]
  );
    
  return (
    <ConnectionProvider endpoint={endpoint?.endpoint} config={{wsEndpoint: endpoint?.wssEndpoint, commitment: 'confirmed'}}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

function App() {
  
 
  return (
    <CustomProvider theme="dark">
    <Context>
      <BrowserRouter>
        <AppUIProvider>
          <DaoProvider>
            <GlobalStyle />
            <Toaster
              position="bottom-left"
              reverseOrder={true}
              toastOptions={{
                style: {
                  borderRadius: 0,
                  padding: "16px",
                  color: "#000",
                  background: "#7AE7FF",
                },
              }}
            />
            <Navbar />
            <Routes>
              <Route
                path="active-subscription-pools"
                element={<ActiveSubscriptionPools />}
              />
              <Route path="funded-pools" element={<FundedPools />} />
              <Route path="expired-pools" element={<ExpiredPools />} />
              <Route
                path="pre-subscription-pools"
                element={<PreSubscriptionPools />}
              />
              <Route path="borrowers" element={<ActiveBorrowersPools />} />
              <Route path="create-pool" element={<CreateNewPool />} />
              <Route path="account" element={<MyAccount />} />
              <Route path="selfverification" element={<SelfVerification />} />
              <Route
                path="xmarginverification"
                element={<XmarginVerification />}
              />
              <Route path="admin" element={<Admin />} />
              <Route path="governance" element={<Governance />} />
              <Route path="proposal" element={<MyProposals />} />
              <Route path="auction" element={<AuctionHistoryUI />} />
              <Route path="dao" element={<DAOUI />} />
              <Route path="dao-account" element={<DaoAccount />} />
              <Route path="top-stakers" element={<TopStakers />} />
              {/* <Route path="empty" element={<EmptyPage />} /> */}
            </Routes>
          </DaoProvider>
        </AppUIProvider>
      </BrowserRouter>
    </Context>
  </CustomProvider>
  );
}

export default App;

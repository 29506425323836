import styled from "styled-components";
import { Input } from "./BorrowersRight/styles";

// import Inter from "../../assets/fonts/Inter-Regular.ttf";
// import SpaceGrotesk from "../../assets/fonts/SpaceGrotesk-Regular.ttf";

export const CardWrapper = styled.div`
  font-family: "Space Grotesk";
  background: #161d23;
  border-radius: 16px;
  width: 1033px;
  display: flex;
`;

export const HorizentalBar=styled.hr.attrs(
  (props: { sidebarColor: String }) => props)`
  position: absolute;
  height: 82px;
  width: 4px;
  left: 0px;
  top: 20px;
  border: 0;
  background: #01fcfc;
  box-shadow: 0px 1px 6px 2px rgb(1 252 252 / 23%);
  border-radius: 0 10px 10px 0;
  background: ${(props) =>
    props.sidebarColor === "Active Subscription"
      ? "#01fcfc"
      : props.sidebarColor === "Funded"
      ? "#25FF3B"
      : props.sidebarColor === "Pre-Subscription"
      ? "#FFD029"
      : props.sidebarColor === "Defaulted" || props.sidebarColor === "Successful" || props.sidebarColor === "Pool Ended"
      ? "#B3C7D9"
      : "#01FCFC"};

`

export const PoolText=styled.p.attrs(
  (props: { titleColor: String }) => props)`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: ${(props) =>
    props.titleColor === "Active Subscription"
      ? "#01FCFC"
      : props.titleColor === "Funded"
      ? "#25FF3B"
      : props.titleColor === "Pre-Subscription"
      ? "#FFD029"
      : props.titleColor === "Defaulted" || props.titleColor === "Successful" || props.titleColor === "Pool Ended"
      ? "#B3C7D9"
      : "#01FCFC"};

  `
export const DetailLowePart = styled.div.attrs(
  (props: { active: Boolean }) => props
)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 25px 0;
  gap: 16px;
  width: 396px;
  height: 123px;
  background: ${(props) =>
    props.active ? "rgba(16, 67, 83, 0.65)" : "#1F2934"};
  border-radius: 8px;
  margin-top: 15px;
  position: relative;
  hr {
    // position: absolute;
    // height: 82px;
    // width: 4px;
    // left: 0px;
    // top: 20px;
    // border: 0;
    // background: #01fcfc;
    // box-shadow: 0px 1px 6px 2px rgb(1 252 252 / 23%);
    // border-radius: 0 10px 10px 0;
  }
  .div1,
  .div2 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
  .div3 {
    display: flex;
    justify-content: end;
    flex-direction: row;
    min-width: 90px;
  }
  .btn {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    /* or 250% */
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  }
  .white {
    color: #ffffff;
  }
  .red {
    color: #e95231;
  }
  .label1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #687d95;
  }
  .label2 {
    // font-family: "Space Grotesk";
    // font-style: normal;
    // font-weight: 700;
    // font-size: 13px;
    // line-height: 20px;
    // display: flex;
    // align-items: center;
    // letter-spacing: 0.3px;
    // text-transform: uppercase;
    // font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    // color: #01fcfc;
    display: block;
  }
  .label3 {
    display: content;
  }
  .sub-label {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #01c0fc;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;

export const DetailCard = styled.div.attrs(
  (props: { active: Boolean }) => props
)`
  width: 438px;
  margin: 6px;
  height: 100%;
  background: ${(props) =>
    props.active
      ? "linear-gradient(180.07deg, rgba(34, 151, 241, 0.3) 0.07%, rgba(1, 252, 252, 0.3) 39.82%), #161D23;"
      : "#1A232B"};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  .first-half,
  .second-half {
    flex-grow: 1;
  }
  .first-half {
    display: flex;
    flex-direction: column;
    margin: 30px 20px 0px;
    .upper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .label1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #687d95;
      }
      .label2 {
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .label3 {
      display: contents;
    }
    .lower {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 6px 25px 0;
      gap: 16px;
      width: 396px;
      height: 123px;
      background: rgba(16, 67, 83, 0.65);
      border-radius: 8px;
      margin-top: 15px;
      position: relative;
      hr {
        position: absolute;
        height: 82px;
        width: 7px;
        left: 0px;
        top: 20px;
        border: 0;
        background: #01fcfc;
        box-shadow: 0px 1px 6px 2px rgb(1 252 252 / 23%);
        border-radius: 0 10px 10px 0;
      }
      .div1,
      .div2 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
      }
      .div3 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        min-width: 90px;
      }
      .btn {
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 30px;
        /* or 250% */
        cursor: pointer;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
      }
      .white {
        color: #ffffff;
      }
      .red {
        color: #e95231;
      }
    }
    .label1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #687d95;
    }
    .label2 {
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
      color: #01fcfc;
    }
    .sub-label {
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
      color: #01c0fc;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
  .second-half {
    margin: 8px 3px 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 2px;
    background: #1a232b;
    border-radius: 10px;
    .div2-header {
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
      color: #ffffff;
      display: inline;
    }
    .second-part1 {
      width: 100%;
      background: #1f2934;
      padding: 12px 12px 0;
    }
    .up-arrow {
      font-size: 1.5rem;
      color: #01c0fc;
      margin-top: 3px;
      cursor: pointer;
    }
    .down-arrow {
      font-size: 1.5rem;
      color: #687d95;
      margin-top: 3px;
      cursor: pointer;
    }
    .arrow-control {
      display: flex;
      padding-bottom: 8px;
    }
    .col1-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      text-transform: uppercase;
      color: #4d647b;
      min-width: 220px;
      display: inherit;
    }
    .col2-text {
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
      color: #ffffff;
    }
    .part2-table {
      display: flex;
      &:last-child {
        padding-bottom: 20px;
      }
    }
    .second-part2 {
      width: 100%;
      border-top: 2px solid #1a232b;
      background: #1f2934;
      padding: 8px 12px 0;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;
  gap: 10px;
  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;

export const DisabledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;
  gap: 10px;
  cursor: pointer;
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, gray 2.67%, gray 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  background: #3d4f61;
  top: -17px;
  right: -12px;
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #abbdd1;
  text-shadow: none;
  opacity: 1;
  z-index: 1;
  font-size: 1.3rem;
  font-weight: 700;
  &:focus {
    outline: none;
  }
`;
export const ModalWrapper = styled.div`
  .style61::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 10px;
    background-color: #f5f5f500;
  }
  .style61::-webkit-scrollbar-track {
    background: transparent;
    -webkit-box-shadow: inset 0 0 20px rgba(142, 142, 142, 0.3);
    margin: 0 580px;
    border-radius: 10px;
  }

  .style61::-webkit-scrollbar-thumb {
    background-color: #7cfa4c;
    -webkit-box-shadow: none;
    border-bottom: none;
    border-radius: 10px;
    border-top: none;
  }

  .style61::-webkit-scrollbar-track-piece:end {
    background: transparent;
  }

  .style61::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }

  z-index: 101;
  .bg-gradient:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  p {
    margin-bottom: 3px;
  }
  .text-white-50 {
    color: #8f9092;
  }
  .style1 {
    height: calc(100vh - 70px);
    max-height: 579px;
    max-width: 603px;
    background: linear-gradient(
        180.07deg,
        rgba(34, 151, 241, 0.3) 0.07%,
        rgba(1, 252, 252, 0.3) 8.81%
      ),
      #161d23;
    box-shadow: 0px 1px 19px 2px rgba(1, 252, 252, 0.12);
    border-radius: 12px;
    position: initial;
  }
  @media (max-width: 640px) {
    .style1 {
      height: 100vh;
    }
  }

  .style59 {
    flex-grow: 1;
    position: relative;
    background: #161d23;
    border-radius: 10px;
    margin: 0 3px 2px;
  }
  /* .style60{overflow: visible; height: 0px; width: 0px; height: 95%;} */
  .style61 {
    margin-top: 30px;
    position: relative;
    height: 100%;
    width: 603px;
    overflow: auto;
    will-change: transform;
  }
  .style62 {
    width: 100%;
  }
  /* .style113{width: 449px; height: 542px;} */

  .border-div-top {
    border-top: 1px solid #3d4f61;
  }
  .label-text {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    /* identical to box height, or 200% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;

    /* White */

    color: #ffffff;

    /* x margin */
  }
  .text-shadow {
    text-shadow: 3px 2px 10px rgba(1, 252, 252, 0.55);
  }
  .grey-text {
    color: #687d95;
  }
`;
export const IconInput = styled(Input)`
  background-image: url(${({ icon }) => icon});
  cursor: pointer;
  background-size: 13px;
  background-position-x: 96%;
  padding-right: 8px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  position: relative;
  ::-webkit-calendar-picker-indicator {
    opacity: 0; //removes awful triangle indicator
    cursor: pointer;
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    height: auto;
  }
`;
export const InputTag = styled.div`
  border-left: 1px solid #ccc;
  position: absolute;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  top: 12px;
  right: 9px;
  padding-left: 16px;
  padding-right: 8px;
`;

import * as anchor from "@project-serum/anchor";
import {
  AccountMeta,
  Commitment,
  Connection,
  PublicKey,
  Signer,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  AccountLayout,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  SignerWalletAdapterProps,
  WalletNotConnectedError,
} from "@solana/wallet-adapter-base";
import {
  AUCTION_IDL,
  AUCTION_PROGRAM_ID,
  DAO_IDL,
  DAO_PROGRAM_ID,
  EMPTY_ADDRESS,
  LEND_IDL,
  LEND_PROGRAM_ID,
  SB_MINT_ADDRESS,
  USDC_MINT_ADDRESS,
} from "./ids";
import {
  GLOBAL_STATE_TAG,
  LENDING_VAULT_TAG,
  BORROWER_TAG,
  FEE_TAG,
  LENDER_TAG,
  POOL_TOKEN_TAG,
  COLLATERAL_VAULT_TAG,
  INIT_STATE_TAG,
  BONUS_VAULT_TAG,
  DAO_GLOBAL_STATE_TAG,
  DAO_GOVERNOR_LIST_TAG,
  CHANGE_PERMANENT_GOVERNOR,
  CHANGE_FEE_VAULT_BURNED_RATE,
  VRZR_STAKES_TAG,
  CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VRZR,
  ABLE_TO_PROPOSE,
  NEED_PERMANENT_GOVERNOR,
  NEED_MORE_VRZR,
  GOVERNOR_LIST,
  GLOBAL_STATE,
  ACTIVE_PROPOSAL,
  PROPOSAL_NOT_ACTIVE,
  CHANGE_EXPENSE_ACCOUNT,
  ABLE_TO_VOTE,
  NOT_PERMANENT_GOVERNOR,
  GOVERNOR_VOTED,
  NOT_GOVERNOR,
  USDC_VAULT,
  FEE_VAULT_TAG,
  TREASURY_VAULT_TAG,
  LENDING_VAULT_AUCTION_TAG,
} from "./constants";
import { dataLayout } from "./layout";
import {
  IGlobalState,
  ILender,
  IPoolInfo,
  IApprovalInfoForAdminRequest,
  IBorrowingInfo,
  IApprovalInfoForWithdrawRequest,
  IProposal,
  IDaoGlobalState,
  IDaoGovernorList,
} from "./interfaces";
import { getMintDecimalValue } from "./walletManager";
import toast from "react-hot-toast";
import { BN } from "@project-serum/anchor";
import { Program } from "@project-serum/anchor";
//import { Metalend } from '../utils/types/metalend';
//import { Dao } from '../utils/types/dao';

const defaultAccounts = {
  tokenProgram: TOKEN_PROGRAM_ID,
  systemProgram: anchor.web3.SystemProgram.programId,
  rent: anchor.web3.SYSVAR_RENT_PUBKEY,
  // clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
};

/******************************************************
 *
 * DAO
 *
 *****************************************************/
 export function getDaoProgramInstance(connection: Connection, wallet: any) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();
  const provider = new anchor.AnchorProvider(
    connection,
    wallet,
    anchor.AnchorProvider.defaultOptions()
  );
  // Read the generated IDL.
  const idl = DAO_IDL as any;

  // Address of the deployed program.
  const programId = DAO_PROGRAM_ID;

  // Generate the program client from IDL.
  const program = new (anchor as any).Program(idl, programId, provider);

  //const program = anchor.workspace.Dao as Program<Dao>;

  return program;
}

export const getAllProposals = async (
  connection: Connection,
  wallet: any
) => {
  const daoProgram = getDaoProgramInstance(connection, wallet);
  const proposalList: IProposal[] = await daoProgram.account.proposal.all();

  return proposalList;
};

export const getProposal = async (
  connection: Connection,
  wallet: any,
  publicKey: PublicKey
) => {
  const daoProgram = getDaoProgramInstance(connection, wallet);
  const data = await daoProgram.account.proposal.fetch(publicKey);

  const proposal: IProposal = {
    account: {
      ...data
    },
    publicKey: publicKey,
  } 
  return proposal;
};

export const getDaoGlobalState = async (
  connection: Connection,
  wallet: any
) => {
  const daoProgram = getDaoProgramInstance(connection, wallet);

  const [daoGlobalStatePda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(DAO_GLOBAL_STATE_TAG))],
    daoProgram.programId
  );

  const allList = await daoProgram.account.globalState.all();
  if (allList.length === 0) return undefined;
  const daoGlobalState = await daoProgram.account.globalState.fetch(daoGlobalStatePda);
  
  return daoGlobalState as IDaoGlobalState;
};

export const getDaoGovernorList = async (
  connection: Connection,
  wallet: any
) => {
  const daoProgram = getDaoProgramInstance(connection, wallet);

  const [daoGovernorListPda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(DAO_GOVERNOR_LIST_TAG))],
    daoProgram.programId
  );

  const allList = await daoProgram.account.governorList.all();
  if (allList.length === 0) return undefined;
  const daoGovernorList = await daoProgram.account.governorList.fetch(daoGovernorListPda);
  
  return daoGovernorList as IDaoGovernorList;
};

export const checkProposalChange = async (
  connection: Connection,
  wallet: any, 
  actionFlag: number, 
  governorList: IDaoGovernorList, 
  userKey: PublicKey, 
  minVlndr: number,
  currentEpoch: number
  ) => {   
  const daoProgram = getDaoProgramInstance(connection, wallet);
    
  const [vlndrStakePda] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode(VRZR_STAKES_TAG)), userKey.toBuffer(), Buffer.from([currentEpoch])], daoProgram.programId
  );

  try { 
    let vlndrStake = await daoProgram.account.vLndrStake.fetch(vlndrStakePda);

    // console.log("checkProposalChange currentEpoch = ", currentEpoch, " userKey = ", userKey.toBase58(), " totalVlndrStaked = ", vlndrStake.totalVlndrStaked.toNumber(), " minVlndr = ", minVlndr);
    if (vlndrStake.totalVlndrStaked.toNumber() < minVlndr) 
      return NEED_MORE_VRZR;
    else 
      if (actionFlag == CHANGE_PERMANENT_GOVERNOR || 
          actionFlag == CHANGE_FEE_VAULT_BURNED_RATE ||
          actionFlag == CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VRZR) {
  
        let found = governorList.permanentGovernors.find(
          (permanentGovernor) => permanentGovernor.toBase58() === userKey.toBase58()
        );  
          
        if (found)
          return ABLE_TO_PROPOSE;
        else 
          return NEED_PERMANENT_GOVERNOR;
      }
      else 
        return ABLE_TO_PROPOSE;
  } catch (e: any) {
    // console.log(e.message);
    return NEED_MORE_VRZR;
    // return ABLE_TO_PROPOSE;
  }   
}

export const createProposal = async (
  connection: Connection,
  wallet: any,
  actionFlag: number,
  id: number,
  address: PublicKey,
  value: BN,
  currentEpoch: number
) => {
  // console.log("createProposal 1 actionFlag = ", actionFlag, " id = ", id, " address = ", address.toBase58(), " value = ", value.toNumber(), " currentEpoch = ", currentEpoch);
  const daoProgram = getDaoProgramInstance(connection, wallet);
        
  let proposalSeedSrc = anchor.web3.Keypair.generate().publicKey;
        
  let proposalPda: any;  
  [proposalPda] = await anchor.web3.PublicKey.findProgramAddress([proposalSeedSrc.toBuffer()], daoProgram.programId);
  
  const [vlndrStakePda] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode(VRZR_STAKES_TAG)), wallet.publicKey.toBuffer(), Buffer.from([currentEpoch])], daoProgram.programId
  );

  let governorListPda: any;
  [governorListPda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(GOVERNOR_LIST))], daoProgram.programId
  );

  let globalStatePda: any;
  [globalStatePda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(GLOBAL_STATE))], daoProgram.programId
  );

  try {
    // console.log("createProposal 2 value = ", value.toNumber());
    const tx = await daoProgram.methods
      .createProposal(
          actionFlag,
          id,
          address,
          value,
          currentEpoch
      ).accounts({
          proposal: proposalPda,
          proposalSeedSrc: proposalSeedSrc,
          vlndrStake: vlndrStakePda,
          governorList: governorListPda,
          globalState: globalStatePda,
          authority: wallet.publicKey,
          ...defaultAccounts
      })
      .signers([])
      .rpc(); 
         
    // console.log("createProposal 3");  
    let obj = {
      status: "OK",
      publicKey: proposalPda,
      tx: tx,
    };    

    return obj; 
  } catch (e: any) {
    console.log(e.message);

    let obj = {
      status: "ERROR",
      publicKey: null,
      tx: null,
    };    

    return obj;     
  }
}

export const checkProposalVoting = (
  status: number, 
  proposal: IProposal,
  governorList: IDaoGovernorList,
  userKey: PublicKey,
) => {
  if (status != ACTIVE_PROPOSAL) {
    return PROPOSAL_NOT_ACTIVE;
  } else { 
    /*
    if (proposal.account.votedList.includes(userKey)) {
      return GOVERNOR_VOTED;
    }
    else {
      let foundPG = governorList.permanentGovernors.includes(userKey);

      if (proposal.account.actionFlag == CHANGE_EXPENSE_ACCOUNT) {
        if (foundPG) {
          return ABLE_TO_VOTE;
        } else 
          return NOT_PERMANENT_GOVERNOR;
      }
      else {
        let foundNG = governorList.governors.includes(userKey);

        if (foundPG || foundNG) {
          return ABLE_TO_VOTE;
        } else {}  
          return NOT_GOVERNOR;
      }
    }
    */
    
    let found = proposal.account.votedList.find(
      (voter) => voter.toBase58() === userKey.toBase58()
    ); 

    if (found)
      return GOVERNOR_VOTED;
    else {
      let foundPG = governorList.permanentGovernors.find(
        (permanentGovernor) => permanentGovernor.toBase58() === userKey.toBase58()
      );  
  
      if (proposal.account.actionFlag == CHANGE_EXPENSE_ACCOUNT) {     
        if (foundPG) {
          return ABLE_TO_VOTE;
        } else 
          return NOT_PERMANENT_GOVERNOR;
      } else {  
        let foundNG = governorList.governors.find(
          (governor) => governor.toBase58() === userKey.toBase58()
        );  
  
        if (foundPG || foundNG) {
          return ABLE_TO_VOTE;
        } else {
          return NOT_GOVERNOR;
        } 
      }
    }  
  }
}

export const voteProposal = async (
  connection: Connection,
  wallet: any,
  proposalSeedSrc: PublicKey
) => {
  //console.log("voteProposal 1");
  const daoProgram = getDaoProgramInstance(connection, wallet);

  let proposalPda: any  
  [proposalPda] = await anchor.web3.PublicKey.findProgramAddress([proposalSeedSrc.toBuffer()], daoProgram.programId);
  
  let governorListPda: any;
  [governorListPda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(GOVERNOR_LIST))], daoProgram.programId
  );

  let globalStatePda: any;
  [globalStatePda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(GLOBAL_STATE))], daoProgram.programId
  );

  const metalend = getProgramInstance(connection, wallet);

  let mtlGlobalStatePda: any;
  [mtlGlobalStatePda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)], 
    metalend.programId
  );   

  try {
    //console.log("voteProposal 2");
    const tx = await daoProgram.methods
      .voteProposal(
        proposalSeedSrc
      ).accounts({
          proposal: proposalPda,
          proposalSeedSrc: proposalSeedSrc,
          governorList: governorListPda,
          globalState: globalStatePda,
          metalendGlobalState: mtlGlobalStatePda,
          metalendProgram: metalend.programId,
          // governor: DAO_PROGRAM_ID, 
          authority: wallet.publicKey,
          ...defaultAccounts
      })
      .signers([])
      .rpc();   

    //console.log("voteProposal 3");   
    return tx;
  } catch (e: any) {
    console.log(e.message);
  }
}

/******************************************************
 *
 * Set & Get & Request & Approve *** GlobalState ***
 *
 *****************************************************/

// Get Methods
export const getLendingInfo = async (
  connection: Connection,
  wallet: any,
  poolKey: PublicKey
) => {
  const program = getProgramInstance(connection, wallet);
  const lenderList: ILender[] = await program.account.lender.all();

  const lender = lenderList.find((lender) => {
    return (
      lender.account.owner.toBase58() === wallet.publicKey.toBase58() &&
      lender.account.pool.toBase58() === poolKey.toBase58()
    );
  });
  return lender;
};

export function getProgramInstance(connection: Connection, wallet: any) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  const provider = new anchor.AnchorProvider(
    connection,
    wallet,
    anchor.AnchorProvider.defaultOptions()
  );
  // Read the generated IDL.
  const idl = LEND_IDL as any;

  // Address of the deployed program.
  const programId = LEND_PROGRAM_ID;

  // Generate the program client from IDL.
  const program = new (anchor as any).Program(idl, programId, provider);

  return program;
}

export const getGlobalState = async (connection: Connection, wallet: any) => {
  const program = getProgramInstance(connection, wallet);

  const [globalstate_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  const allList = await program.account.globalState.all();
  if (allList.length === 0) return undefined;
  const globalState = await program.account.globalState.fetch(globalstate_pda);
  return globalState as IGlobalState;
};

export const getRequestPoolInfo = async (
  connection: Connection,
  wallet: any
) => {
  const program = getProgramInstance(connection, wallet);

  const [borrowing_info_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(BORROWER_TAG), wallet.publicKey.toBuffer()],
    program.programId
  );
  let bInfo: IBorrowingInfo | undefined;
  try {
    bInfo = await program.account.borroweringInfo.fetch(borrowing_info_pda);
  } catch (e) {}
  return bInfo;
};

export const getAllPendingUpdation = async (
  connection: Connection,
  wallet: any
) => {
  const program = getProgramInstance(connection, wallet);

  const allPendings: IApprovalInfoForAdminRequest[] =
    await program.account.approvalInfoForAdminRequest.all();
  for await (const pending of allPendings) {
    const [transaction_pda] = await anchor.web3.PublicKey.findProgramAddress(
      [pending.account.requestAdmin.toBuffer(), pending.publicKey.toBuffer()],
      program.programId
    );
    const transaction_info =
      await program.account.contractInnerTransactionInfo.fetch(transaction_pda);
    pending.transaction = transaction_info;
  }

  return allPendings;
};

export const getAllPendingWithdraw = async (
  connection: Connection,
  wallet: any
) => {
  const program = getProgramInstance(connection, wallet);

  const allPendings: IApprovalInfoForWithdrawRequest[] =
    await program.account.approvalInfoForWithdrawRequest.all();
  for await (const pending of allPendings) {
    const [transaction_pda] = await anchor.web3.PublicKey.findProgramAddress(
      [pending.account.requestAdmin.toBuffer(), pending.publicKey.toBuffer()],
      program.programId
    );
    const transaction_info =
      await program.account.contractInnerTransactionInfo.fetch(transaction_pda);
    pending.transaction = transaction_info;
  }

  return allPendings;
};

export const getAllPoolsWithLenders = async (
  connection: Connection,
  wallet: any
) => {
  const program = getProgramInstance(connection, wallet);
  const issueList: IPoolInfo[] = await program.account.pool.all();
  const lenderList: ILender[] = await program.account.lender.all();

  for await (const issue of issueList) {
    issue.usersList = [];
    lenderList.forEach((lender) => {
      if (lender.account.pool.toBase58() === issue.publicKey.toBase58())
        issue.usersList.push(lender);
    });
  }
  return issueList;
};

export const getAllPoolsLendersForBorrower = async (
  connection: Connection,
  wallet: any
) => {
  const program = getProgramInstance(connection, wallet);
  const issueList: IPoolInfo[] | [] = await program.account.pool.all();
  const lenderList: ILender[] | [] = await program.account.lender.all();

  const myPoolList: IPoolInfo[] = [];
  for await (const issue of issueList) {
    const [pda] = await anchor.web3.PublicKey.findProgramAddress(
      [wallet.publicKey.toBuffer(), issue.publicKey.toBuffer()],
      program.programId
    );
    if (issue.account.owner.toBase58() === pda.toBase58()) {
      myPoolList.push(issue);
    }

    if (issue.account.owner.toBase58() === wallet.publicKey.toBase58()) {
      myPoolList.push(issue);
    }
  }

  for await (const issue of myPoolList) {
    issue.usersList = [];
    lenderList.forEach((lender) => {
      if (lender.account.pool.toBase58() === issue.publicKey.toBase58())
        issue.usersList.push(lender);
    });
  }
  return myPoolList;
};

// Transaction methods
/******************************************************
 *
 * 			           Admin Side
 *
 *****************************************************/
export const createGlobalState = async (
  connection: Connection,
  wallet: any,

  governor: PublicKey,
  admins: PublicKey[],
  admin_approval: number,
  fee_mint_sb: PublicKey,
  fee_mint_usdc: PublicKey,

  fee_issue_amount_approved: anchor.BN,
  fee_issue_amount_unapproved: anchor.BN,
  fee_withdrawal_approved: anchor.BN,
  fee_withdrawal_unapproved: anchor.BN,

  approved_list: PublicKey[],
  pool_limit: number,

  commitFee: anchor.BN,
  claimFee: anchor.BN,
  gasFee: anchor.BN,
  extraPeriod: number,
  feeBurnedRate: number
) => {
  const program = getProgramInstance(connection, wallet);

  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );
  const [init_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(INIT_STATE_TAG)],
    program.programId
  );

  // const randomKeyPairSB = await anchor.web3.Keypair.generate();
  // const randomKeyPairUSDC = await anchor.web3.Keypair.generate();
  const [feeVault] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from(FEE_VAULT_TAG), global_pda.toBuffer()], program.programId);
  const [feeVaultTreasury] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from(TREASURY_VAULT_TAG), global_pda.toBuffer()], program.programId);

  const [sb_vault_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(FEE_TAG), fee_mint_sb.toBuffer()],
    program.programId
  );
  const [usdc_vault_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(FEE_TAG), fee_mint_usdc.toBuffer()],
    program.programId
  );

  const dao = getDaoProgramInstance(connection, wallet);

  // const daoAdmin = anchor.web3.Keypair.generate();
  const daoAdmin = dao.programId;

  const [globalState] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode(GLOBAL_STATE))], dao.programId
  );

  const [daoUsdcVault] = await anchor.web3.PublicKey.findProgramAddress(
    [ Buffer.from(anchor.utils.bytes.utf8.encode(USDC_VAULT)), globalState.toBuffer()], dao.programId
  );
  
  console.log("createGlobalState globalState = ", globalState.toBase58(), " daoAdmin = ", daoAdmin.toBase58(), " daoUsdcVault = ", daoUsdcVault.toBase58());
  
  let instructions: TransactionInstruction[] = [];

  try {
    const tx = await program.rpc.createGlobalState(
      governor,
      admins,
      admin_approval,
      fee_issue_amount_approved,
      fee_issue_amount_unapproved,
      fee_withdrawal_approved,
      fee_withdrawal_unapproved,
      approved_list,
      pool_limit,
      commitFee,
      claimFee,
      gasFee,
      extraPeriod,
      daoAdmin,
		  daoUsdcVault,
      feeBurnedRate,
      {
        accounts: {
          globalState: global_pda,
          initState: init_pda,

          //feeVault: randomKeyPairSB.publicKey,
          feeVault: feeVault,
          feeMint: fee_mint_sb,

          feeVaultTreasury: feeVaultTreasury,
          //feeVaultTreasury: randomKeyPairUSDC.publicKey,
          treasuryMint: fee_mint_usdc,

          //vaultSbAuthority: sb_vault_pda,
          //vaultUsdcAuthority: usdc_vault_pda,
          authority: wallet.publicKey,

          ...defaultAccounts,
        },
        instructions: instructions,
        signers: [],
        //signers: [randomKeyPairSB, randomKeyPairUSDC],
      }
    );
    return tx;
  } catch (e: any) {
    console.log(e.message);
  }
};

// export const requestGlobalStateUpdationByAdmin = async (
//   connection: Connection,
//   wallet: any,

//   flag: number,
//   id: number,
//   address: PublicKey,
//   value: anchor.BN
// ) => {
//   const program = getProgramInstance(connection, wallet);
//   const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
//     [Buffer.from(GLOBAL_STATE_TAG)],
//     program.programId
//   );
//   const multisig_seed = anchor.web3.Keypair.generate().publicKey;
//   const glb_update_keypair = anchor.web3.Keypair.generate();
//   const [updation_key] = await anchor.web3.PublicKey.findProgramAddress(
//     [wallet.publicKey.toBuffer(), multisig_seed.toBuffer()],
//     program.programId
//   );

//   console.log(
//     "Requesting point keys:",
//     multisig_seed.toBase58(),
//     wallet.publicKey.toBase58(),
//     updation_key.toBase58()
//   );
//   const ix = await program.instruction.updateGlobalStateByAdmin(
//     new anchor.BN(flag),
//     new anchor.BN(id),
//     address,
//     value,
//     {
//       accounts: {
//         updationKey: updation_key,
//         globalState: global_pda,
//         glbUpdateApprovalInfo: glb_update_keypair.publicKey,
//       },
//       instructions: [],
//       signers: [],
//     }
//   );
//   const mapped = ix.keys
//     .map((meta: { pubkey: { equals: (arg0: anchor.web3.PublicKey) => any } }) =>
//       meta.pubkey.equals(updation_key) ? { ...meta, isSigner: false } : meta
//     )
//     .concat({
//       pubkey: program.programId,
//       isWritable: false,
//       isSigner: false,
//     });

//   const [glb_update_transaction] =
//     await anchor.web3.PublicKey.findProgramAddress(
//       [wallet.publicKey.toBuffer(), glb_update_keypair.publicKey.toBuffer()],
//       program.programId
//     );

//   try {
//     const tx = await program.rpc.requestGlobalStateUpdation(ix.keys, ix.data, {
//       accounts: {
//         requestAdmin: wallet.publicKey,
//         glbUpdateApprovalInfo: glb_update_keypair.publicKey,
//         glbUpdateTransaction: glb_update_transaction,
//         glbMultisigSeed: multisig_seed,
//         globalState: global_pda,
//         ...defaultAccounts,
//       },
//       remainingAccounts: mapped,
//       instruction: [],
//       signers: [glb_update_keypair],
//     });
//     return tx;
//   } catch (e: any) {
//     console.log(e.message);
//   }
// };

export const updateGlobalStateByGovernor = async (
  connection: Connection,
  wallet: any,

  flag: number,
  id: number,
  address: PublicKey,
  value: anchor.BN
) => {
  const program = getProgramInstance(connection, wallet);
  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  try {
    const tx = await program.rpc.updateGlobalStateByGovernor(
      new anchor.BN(flag),
      new anchor.BN(id),
      address,
      value,
      {
        accounts: {
          globalState: global_pda,
          authority: wallet.publicKey,
          ...defaultAccounts,
        },
        instructions: [],
        signers: [],
      }
    );
    return tx;
  } catch (e: any) {
    console.log(e.message);
  }
};

export const requestWithdrawal = async (
  connection: Connection,
  wallet: any,

  destAddress: PublicKey,
  value: anchor.BN,
  feeVault: PublicKey,
  feeMint: PublicKey,
  type: string
) => {
  const program = getProgramInstance(connection, wallet);
  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );
  const [fee_vault_authority] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(FEE_TAG), feeMint.toBuffer()],
    program.programId
  );

  let tx;
  if (type === "SB") {
    tx = await program.rpc.withdrawAdminTokenSb(
      // address,
      value,
      {
        accounts: {
          globalState: global_pda,
          feeVault: feeVault,
          destVault: destAddress,
          feeVaultAuthority: fee_vault_authority,
          authority: wallet.publicKey,
          ...defaultAccounts,
        },
        instructions: [],
        signers: [],
      }
    );
    alert(tx);
  } else if (type === "USDC") {
    tx = await program.rpc.withdrawAdminTokenUsdc(
      // address,
      value,
      {
        accounts: {
          globalState: global_pda,
          feeVault: feeVault,
          destVault: destAddress,
          feeVaultAuthority: fee_vault_authority,
          authority: wallet.publicKey,
          ...defaultAccounts,
        },
        instructions: [],
        signers: [],
      }
    );
    alert(tx);
  } else {
    return "No transaction";
  }
};

// export const approvePendingUpdation = async (
//   connection: Connection,
//   wallet: any,
//   pendingUpdation: PublicKey
// ) => {
//   const program = getProgramInstance(connection, wallet);

//   const issue = await program.account.approvalInfoForAdminRequest.fetch(
//     pendingUpdation
//   );

//   const [multisig_pda] = await anchor.web3.PublicKey.findProgramAddress(
//     [issue.requestAdmin.toBuffer(), issue.gblMultisigSeed.toBuffer()],
//     program.programId
//   );
//   console.log(issue.gblMultisigSeed.toBase58());
//   const [glb_update_transaction_pda] =
//     await anchor.web3.PublicKey.findProgramAddress(
//       [issue.requestAdmin.toBuffer(), pendingUpdation.toBuffer()],
//       program.programId
//     );

//   const issueTransactionInfo =
//     await program.account.contractInnerTransactionInfo.fetch(
//       glb_update_transaction_pda
//     );

//   const mapped = issueTransactionInfo.accounts
//     .map((meta: { pubkey: { equals: (arg0: anchor.web3.PublicKey) => any } }) =>
//       meta.pubkey.equals(multisig_pda) ? { ...meta, isSigner: false } : meta
//     )
//     .concat({
//       pubkey: program.programId,
//       isWritable: false,
//       isSigner: false,
//     });

//   try {
//     const tx = await program.rpc.approveGlobalStateUpdation({
//       accounts: {
//         admin: wallet.publicKey,
//         glbMultisig: multisig_pda, //[issueDetail.authority, issueDetail.pool]
//         glbUpdateApprovalInfo: pendingUpdation,
//         glbUpdateTransaction: glb_update_transaction_pda, //[issueDetail.authority, issueDetail.key]
//         systemProgram: anchor.web3.SystemProgram.programId,
//       },
//       remainingAccounts: mapped,
//       instructions: [],
//       signers: [],
//     });
//     return tx;
//   } catch (e: any) {
//     console.log(e.message);
//   }
// };

/******************************************************
 *
 * 			           Borrower Side
 *
 *****************************************************/

export const createNewPool = async (
  connection: Connection,
  wallet: any,

  lending_token_mint: PublicKey,
  feeMint: PublicKey,
  adminFeeVault: PublicKey,
  borrowerFeeVault: PublicKey,
  collateralToken: PublicKey,

  lending_duration: number,
  subscription_period: number,
  goal: number,
  min: number,
  coupon_rate: number,
  subscription_start_in_sec: number,
  collateral_amount: number
) => {
  const program = getProgramInstance(connection, wallet);
  console.log(wallet, program, 'walletwallet');
  
  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );
  
  const randomKey = anchor.web3.Keypair.generate().publicKey;
  const [poolSeed_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [randomKey.toBuffer()],
    program.programId
  );

  const [lendingStableVault_pda] =
    await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from(LENDING_VAULT_TAG), poolSeed_pda.toBuffer()],
      program.programId
    );

  const [lendingStableVaultAuction_pda] =
  await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from(LENDING_VAULT_AUCTION_TAG), poolSeed_pda.toBuffer()],
      program.programId
  );

  const [poolCollateralVault] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(COLLATERAL_VAULT_TAG), poolSeed_pda.toBuffer()],
    program.programId
  );

  const [poolAuthorityPDA] = await anchor.web3.PublicKey.findProgramAddress(
    [lending_token_mint.toBuffer(), poolSeed_pda.toBuffer()],
    program.programId
  );

  const [collateralAuthority] = await anchor.web3.PublicKey.findProgramAddress(
    [collateralToken.toBuffer(), poolSeed_pda.toBuffer()],
    program.programId
  );

  const [mintPoolToken] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(POOL_TOKEN_TAG), poolSeed_pda.toBuffer()],
    program.programId
  );

  const [borrowingInfo_Pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(BORROWER_TAG), wallet.publicKey.toBuffer()],
    program.programId
  );

  const userCollateralVault = await getOrCreateAssociatedTokenAccount(
    connection,
    wallet.publicKey,
    collateralToken,
    wallet.publicKey,
    wallet.signTransaction
  );

  const collateralDecimal = await getMintDecimalValue(
    new PublicKey(collateralToken)
  );
    console.log(program.methods, 'program.methods');
    
  try {
    const tx = await program.methods.createPool(
      lending_duration, // lending period
      subscription_period, // subscription period
      new anchor.BN(goal), // goal amount
      new anchor.BN(min), // min amount
      coupon_rate, // coupon rate
      subscription_start_in_sec, // subscription start time
      new anchor.BN(collateral_amount * collateralDecimal), // collateral amount
    ).accounts({
          borrowingInfo: borrowingInfo_Pda,
          pool: poolSeed_pda, // [poolSeedSrc]
          poolSeedSrc: randomKey,
          lendingMint: lending_token_mint, //
          lendingVault: lendingStableVault_pda, // [pool,]
          poolUsdcVault: lendingStableVaultAuction_pda,
          poolAuthority: poolAuthorityPDA, // [lendingMint, pool]
          feeMint: feeMint,
          mintPoolToken: mintPoolToken,
          collateralToken: collateralToken,
          userCollateralVault: userCollateralVault.address,
          poolCollateralVault: poolCollateralVault,
          authority: wallet.publicKey,
          globalState: global_pda,
          sbFeeVault: adminFeeVault,
          feeVault: borrowerFeeVault,
          collateralAuthority: collateralAuthority,
          ...defaultAccounts,
        }).rpc()

    let obj = {
      status: "OK",
      poolKey: poolSeed_pda.toBase58(),
      mintPoolToken: mintPoolToken.toBase58(),
      tx: 'tx',
    };

    return obj;
  } catch (e: any) {
    console.log(e);
    let obj = {
      status: "ERROR",
      poolKey: null,
      mintPoolToken: null,
      tx: null,
    };
    return obj;
  }
};

export const updatePrePool = async (
  connection: Connection,
  wallet: any,

  pool: PublicKey,
  feeMint: PublicKey,
  adminFeeVault: PublicKey,
  collateralToken: PublicKey,

  lending_duration: number,
  subscription_period: number,
  goal: number,
  min: number,
  coupon_rate: number,
  subscription_start_in_sec: number,
  collateral_amount: number
) => {
  const program = getProgramInstance(connection, wallet);
  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  const [poolCollateralVault] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(COLLATERAL_VAULT_TAG), pool.toBuffer()],
    program.programId
  );

  const userCollateralVault = await getOrCreateAssociatedTokenAccount(
    connection,
    wallet.publicKey,
    collateralToken,
    wallet.publicKey,
    wallet.signTransaction
  );

  const borrowerFeeVault = await getOrCreateAssociatedTokenAccount(
    connection,
    wallet.publicKey,
    new PublicKey(SB_MINT_ADDRESS),
    wallet.publicKey,
    wallet.signTransaction
  );

  const collateralDecimal = await getMintDecimalValue(collateralToken);

  try {
    const tx = await program.rpc.updatePool(
      lending_duration, // lending period
      subscription_period, // subscription period
      new anchor.BN(goal), // goal amount
      new anchor.BN(min), // min amount
      coupon_rate, // coupon rate
      subscription_start_in_sec, // subscription start time
      new anchor.BN(collateral_amount * collateralDecimal), // collateral amount
      {
        accounts: {
          pool: pool, // [poolSeedSrc]
          feeMint: feeMint,
          sbFeeVault: adminFeeVault,
          userSbVault: borrowerFeeVault.address,
          collateralToken: collateralToken,
          userCollateralVault: userCollateralVault.address,
          poolCollateralVault: poolCollateralVault,
          authority: wallet.publicKey,
          globalState: global_pda,
          ...defaultAccounts,
        },
        instructions: [],
        signers: [],
      }
    );

    let obj = {
      status: "OK",
      poolKey: pool.toBase58(),
      tx: tx,
    };

    return obj;
  } catch (e: any) {
    console.log(e.message);

    let obj = {
      status: "ERROR",
      poolKey: null,
      tx: null,
    };
    return obj;
  }
};

export const cancelPool = async (
  connection: Connection,
  wallet: any,
  pool_pubkey: PublicKey,
  feeMint: PublicKey,
  sbFeeVault: PublicKey,
  userSbVault: PublicKey,
  collateralToken: PublicKey,
  poolCollateralVault: PublicKey
) => {
  const program = getProgramInstance(connection, wallet);
  const [borrowing_info_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(BORROWER_TAG), wallet.publicKey.toBuffer()],
    program.programId
  );

  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  const userCollateralVault = await getOrCreateAssociatedTokenAccount(
    connection,
    wallet.publicKey,
    collateralToken,
    wallet.publicKey,
    wallet.signTransaction
  );

  const [collateralAuthority] = await anchor.web3.PublicKey.findProgramAddress(
    [collateralToken.toBuffer(), pool_pubkey.toBuffer()],
    program.programId
  );

  try {
    const tx = await program.rpc.cancel({
      accounts: {
        authority: wallet.publicKey,
        borrowingInfo: borrowing_info_pda,
        pool: pool_pubkey,
        globalState: global_pda,
        feeMint: feeMint,
        sbFeeVault: sbFeeVault,
        userSbVault: userSbVault,
        poolCollateralVault: poolCollateralVault,
        userCollateralVault: userCollateralVault.address,
        collateralAuthority: collateralAuthority,
        ...defaultAccounts,
      },
      instructions: [],
      signers: [],
    });
    return tx;
  } catch (e: any) {
    console.log(e);
  }
};

export const takeCollateral = async (
  connection: Connection,
  wallet: any,
  pool_pubkey: PublicKey,
  feeMint: PublicKey,
  sbFeeVault: PublicKey,
  userSbVault: PublicKey,
  collateralToken: PublicKey,
  poolCollateralVault: PublicKey
) => {
  const program = getProgramInstance(connection, wallet);

  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  const userCollateralVault = await getOrCreateAssociatedTokenAccount(
    connection,
    wallet.publicKey,
    collateralToken,
    wallet.publicKey,
    wallet.signTransaction
  );

  const [collateralAuthority] = await anchor.web3.PublicKey.findProgramAddress(
    [collateralToken.toBuffer(), pool_pubkey.toBuffer()],
    program.programId
  );

  try {
    const tx = await program.rpc.takeCollateral({
      accounts: {
        authority: wallet.publicKey,
        pool: pool_pubkey,
        globalState: global_pda,
        feeMint: feeMint,
        sbFeeVault: sbFeeVault,
        userSbVault: userSbVault,
        poolCollateralVault: poolCollateralVault,
        userCollateralVault: userCollateralVault.address,
        collateralAuthority: collateralAuthority,
        ...defaultAccounts,
      },
      instructions: [],
      signers: [],
    });

    let obj = {
      status: "OK",
      tx: tx,
    };

    return obj;
  } catch (e: any) {
    console.log(e.message);

    let obj = {
      status: "ERROR",
      tx: null,
    };
    return obj;
  }
};

export const payBonus = async (
  connection: Connection,
  wallet: any,
  pool: PublicKey,
  feeMint: PublicKey,
  sbFeeVault: PublicKey,
  userSbVault: PublicKey,
  bonusToken: PublicKey,
  bonusAmount: number,
  prevBonus: number
) => {
  const program = getProgramInstance(connection, wallet);

  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  const userBonusVault = await getOrCreateAssociatedTokenAccount(
    connection,
    wallet.publicKey,
    bonusToken,
    wallet.publicKey,
    wallet.signTransaction
  );

  const [bonusAuthority] = await anchor.web3.PublicKey.findProgramAddress(
    [bonusToken.toBuffer(), pool.toBuffer()],
    program.programId
  );

  const [poolBonusVault] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(BONUS_VAULT_TAG), pool.toBuffer()],
    program.programId
  );

  try {
    let instruction: TransactionInstruction[] = [];
    if (prevBonus > 0) {
      const tx = await program.rpc.addBonus(new anchor.BN(bonusAmount), {
        accounts: {
          pool: pool,
          feeMint: feeMint,
          sbFeeVault: sbFeeVault,
          userSbVault: userSbVault,
          bonusToken: bonusToken,
          userBonusVault: userBonusVault.address,
          poolBonusVault: poolBonusVault,
          bonusAuthority: bonusAuthority,
          globalState: global_pda,
          authority: wallet.publicKey,
          ...defaultAccounts,
        },
        instruction: instruction,
        signers: [],
      });
      return tx;
    } else {
      const tx = await program.rpc.payBonus(new anchor.BN(bonusAmount), {
        accounts: {
          pool: pool,
          feeMint: feeMint,
          sbFeeVault: sbFeeVault,
          userSbVault: userSbVault,
          bonusToken: bonusToken,
          userBonusVault: userBonusVault.address,
          poolBonusVault: poolBonusVault,
          bonusAuthority: bonusAuthority,
          globalState: global_pda,
          authority: wallet.publicKey,
          ...defaultAccounts,
        },
        instruction: instruction,
        signers: [],
      });
      return tx;
    }
  } catch (e: any) {
    console.log(e.message);
  }
};

/******************************************************
 *
 * 			           Lender Side
 *
 *****************************************************/

export const stake = async (
  connection: Connection,
  wallet: any,
  pool: PublicKey,
  amount: number,
  accPubkey: PublicKey,
  lendingMint: PublicKey, 
  feeVault: PublicKey,
  feeMint: PublicKey,
  sbFeeVault: PublicKey,
  userSbVault: PublicKey,
  mintPoolToken: PublicKey
) => {
  const program = getProgramInstance(connection, wallet);
  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  const [user_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(LENDER_TAG), pool.toBuffer(), wallet.publicKey.toBuffer()],
    program.programId
  );
  const [lending_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(LENDING_VAULT_TAG), pool.toBuffer()],
    program.programId
  );
  let instruction: TransactionInstruction[] = [];

  toast("Step 1: Creating Account");
  const userPoolToken = await getOrCreateAssociatedTokenAccount(
    connection,
    wallet.publicKey,
    mintPoolToken,
    wallet.publicKey,
    wallet.signTransaction
  );

  try {
    toast("Step 2: Committing Funds");
    // console.log("commitBalance feeMint = ", feeMint.toBase58());
    
    const tx = await program.rpc.commitBalance(new anchor.BN(amount), {
      accounts: {
        globalState: global_pda,
        authority: wallet.publicKey,
        pool: pool,
        lender: user_pda,
        lendingVault: lending_pda,
        lendingMint: lendingMint,
        userVault: accPubkey,
        feeVault: feeVault,
        feeMint: feeMint, 
        sbFeeVault: sbFeeVault,
        userSbVault: userSbVault,
        mintPoolToken: mintPoolToken,
        userPoolTokenVault: userPoolToken.address,
        ...defaultAccounts,
      },
      instruction: instruction,
      signers: [],
    });

    console.log("commitBalance tx = ", tx);

    let obj = {
      status: "OK",
      tx: tx,
      lenderKey: user_pda.toBase58(),
    };
    return obj;
  } catch (e: any) {
    console.log(e.message);

    let obj = {
      status: "ERROR",
      lenderKey: null,
      tx: null,
    };
    return obj;
  }
};

export const withdrawLocked = async (
  connection: Connection,
  wallet: any,
  acc: PublicKey,
  amount: number,
  pool_lending_mint: PublicKey,
  pool: PublicKey,
  feeVault: PublicKey,
  feeMint: PublicKey,
  sbFeeVault: PublicKey,
  userSbVault: PublicKey
) => {
  const program = getProgramInstance(connection, wallet);
  const [lendingVaultPda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(LENDING_VAULT_TAG), Buffer.from(pool.toBuffer())],
    program.programId
  );
  const [poolAuthorityPDA] = await anchor.web3.PublicKey.findProgramAddress(
    [pool_lending_mint.toBuffer(), pool.toBuffer()],
    program.programId
  );

  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  try {
    let instruction: TransactionInstruction[] = [];
    const tx = await program.rpc.withdrawCommitment(new anchor.BN(amount), {
      accounts: {
        globalState: global_pda,
        authority: wallet.publicKey,
        pool: pool,
        lendingVault: lendingVaultPda,
        poolAuthority: poolAuthorityPDA,
        claimVault: acc,
        feeVault: feeVault,
        feeMint: feeMint,
        sbFeeVault: sbFeeVault,
        userSbVault: userSbVault,
        ...defaultAccounts,
      },
      instruction: instruction,
      signers: [],
    });
    return tx;
  } catch (e: any) {
    console.log(e.message);
  }
};

export const refund = async (
  connection: Connection,
  wallet: any,
  acc: PublicKey,
  pool: PublicKey,
  feeMint: PublicKey,
  sbFeeVault: PublicKey,
  userSbVault: PublicKey,
  refundAmount: number
) => {
  const program = getProgramInstance(connection, wallet);
  const [lendingVaultPda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(LENDING_VAULT_TAG), Buffer.from(pool.toBuffer())],
    program.programId
  );

  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  try {
    let instruction: TransactionInstruction[] = [];
    const tx = await program.rpc.refundWithdrawal(new anchor.BN(refundAmount), {
      accounts: {
        authority: wallet.publicKey,
        pool: pool,
        lendingVault: lendingVaultPda,
        disclaimVault: acc,
        globalState: global_pda,
        feeMint: feeMint,
        sbFeeVault: sbFeeVault,
        userSbVault: userSbVault,
        ...defaultAccounts,
      },
      instruction: instruction,
      signers: [],
    });
    return tx;
  } catch (e: any) {
    console.log(e.message);
  }
};

export const claimByLender = async (
  connection: Connection,
  wallet: any,
  pool: PublicKey,
  poolSeedSrc: PublicKey,
  pool_lending_mint: PublicKey,
  user_acc: PublicKey,
  feeVault: PublicKey,
  feeMint: PublicKey,
  sbFeeVault: PublicKey,
  userSbVault: PublicKey,
  bonusToken: PublicKey,
  poolBonusVault: PublicKey,
  mintPoolToken: PublicKey,
  collateralToken: PublicKey,
  poolCollateralVault: PublicKey,
  amount: anchor.BN
) => {
  const program = getProgramInstance(connection, wallet);
  const [global_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_STATE_TAG)],
    program.programId
  );

  const [user_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(LENDER_TAG), pool.toBuffer(), wallet.publicKey.toBuffer()],
    program.programId
  );

  const [lendingVaultPda] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(LENDING_VAULT_TAG), Buffer.from(pool.toBuffer())],
    program.programId
  );

  const [poolSeed_pda] = await anchor.web3.PublicKey.findProgramAddress(
    [poolSeedSrc.toBuffer()],
    program.programId
  );

  const [lendingStableVaultAuction_pda] =
  await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from(LENDING_VAULT_AUCTION_TAG), poolSeed_pda.toBuffer()],
      program.programId
  );

  const [poolAuthorityPda] = await anchor.web3.PublicKey.findProgramAddress(
    [pool_lending_mint.toBuffer(), pool.toBuffer()],
    program.programId
  );

  const [bonusAuthority] = await anchor.web3.PublicKey.findProgramAddress(
    [bonusToken.toBuffer(), pool.toBuffer()],
    program.programId
  );

  const userPoolToken = await getOrCreateAssociatedTokenAccount(
    connection,
    wallet.publicKey,
    mintPoolToken,
    wallet.publicKey,
    wallet.signTransaction
  );

  const userCollateralVault = await getOrCreateAssociatedTokenAccount(
    connection,
    wallet.publicKey,
    collateralToken,
    wallet.publicKey,
    wallet.signTransaction
  );

  const [collateralAuthority] = await anchor.web3.PublicKey.findProgramAddress(
    [collateralToken.toBuffer(), pool.toBuffer()],
    program.programId
  );

  let userBonusVault = undefined;
  if (bonusToken.toBase58() === EMPTY_ADDRESS) {
    userBonusVault = userCollateralVault;
  } else {
    try {
      userBonusVault = await getOrCreateAssociatedTokenAccount(
        connection,
        wallet.publicKey,
        bonusToken,
        wallet.publicKey,
        wallet.signTransaction
      );
    } catch (e) {
      userBonusVault = userCollateralVault;
    }
  }
console.log({
  globalState: global_pda,
  authority: wallet.publicKey,
  pool: pool,
  lender: user_pda,
  poolAuthority: poolAuthorityPda,
  lendingVault: lendingVaultPda,
  poolUsdcVault: lendingStableVaultAuction_pda,
  userVault: user_acc,
  feeVault: feeVault,
  feeMint: feeMint,
  sbFeeVault: sbFeeVault,
  userSbVault: userSbVault,
  mintPoolToken: mintPoolToken,
  userBonusVault: userBonusVault.address,
  poolBonusVault:
    poolBonusVault.toBase58() === EMPTY_ADDRESS
      ? userBonusVault.address
      : poolBonusVault,
  bonusAuthority: bonusAuthority,
  userPoolTokenVault: userPoolToken.address,
  userCollateralVault: userCollateralVault.address,
  poolCollateralVault: poolCollateralVault,
  collateralAuthority: collateralAuthority,
  ...defaultAccounts,
});

  try {
    const tx = await program.rpc.claimByLender(amount, {
      accounts: {
        globalState: global_pda,
        authority: wallet.publicKey,
        pool: pool,
        lender: user_pda,
        poolAuthority: poolAuthorityPda,
        lendingVault: lendingVaultPda,
        poolUsdcVault: lendingStableVaultAuction_pda,
        userVault: user_acc,
        feeVault: feeVault,
        feeMint: feeMint,
        sbFeeVault: sbFeeVault,
        userSbVault: userSbVault,
        mintPoolToken: mintPoolToken,
        userBonusVault: userBonusVault.address,
        poolBonusVault:
          poolBonusVault.toBase58() === EMPTY_ADDRESS
            ? userBonusVault.address
            : poolBonusVault,
        bonusAuthority: bonusAuthority,
        userPoolTokenVault: userPoolToken.address,
        userCollateralVault: userCollateralVault.address,
        poolCollateralVault: poolCollateralVault,
        collateralAuthority: collateralAuthority,
        ...defaultAccounts,
      },
      instruction: [],
      signers: [],
    });
    return tx;
  } catch (e: any) {
    console.log(e);
  }
};

export const transferBonus = async (
  connection: Connection,
  publicKey: PublicKey,
  signTransaction: any,
  feeMintAddress: string,
  feeVault: string,
  gasFee: number,
  mintAddress: string,
  toPubkeys: string[],
  amount: number[]
) => {
  if (!toPubkeys || toPubkeys.length === 0 || !amount) return;

  try {
    if (!publicKey || !signTransaction) throw new WalletNotConnectedError();

    const transaction = new Transaction();

    const fromFeeTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      publicKey,
      new PublicKey(feeMintAddress),
      publicKey,
      signTransaction
    );
    transaction.add(
      createTransferInstruction(
        fromFeeTokenAccount.address, // source
        new PublicKey(feeVault), // dest
        publicKey,
        gasFee,
        [],
        TOKEN_PROGRAM_ID
      )
    );

    const mint = new PublicKey(mintAddress);
    const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      publicKey,
      mint,
      publicKey,
      signTransaction
    );
    const toPublicKeys = toPubkeys.map((toPubkey) => new PublicKey(toPubkey));

    const toTokenAccounts = [];

    for await (const toPublicKey of toPublicKeys) {
      const toTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        publicKey,
        mint,
        toPublicKey,
        signTransaction
      );

      toTokenAccounts.push(toTokenAccount);
    }

    for (let i = 0; i < toTokenAccounts.length; i++) {
      transaction.add(
        createTransferInstruction(
          fromTokenAccount.address, // source
          toTokenAccounts[i].address, // dest
          publicKey,
          amount[i],
          [],
          TOKEN_PROGRAM_ID
        )
      );
    }

    const blockHash = await connection.getRecentBlockhash();
    transaction.feePayer = await publicKey;
    transaction.recentBlockhash = await blockHash.blockhash;
    const signed = await signTransaction(transaction);
    await connection.sendRawTransaction(signed.serialize());
  } catch (error: any) {
    console.log(error);
  }
};

// Get Token account
export async function getOrCreateAssociatedTokenAccount(
  connection: Connection,
  payer: PublicKey,
  mint: PublicKey,
  owner: PublicKey,
  signTransaction: SignerWalletAdapterProps["signTransaction"],
  allowOwnerOffCurve = false,
  commitment?: Commitment,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
) {
  const associatedToken = await getAssociatedTokenAddress(
    mint,
    owner,
    allowOwnerOffCurve,
    programId,
    associatedTokenProgramId
  );

  // This is the optimal logic, considering TX fee, client-side computation, RPC roundtrips and guaranteed idempotent.
  // Sadly we can't do this atomically.
  let account;
  try {
    account = await getAccountInfo(
      connection,
      associatedToken,
      commitment,
      programId
    );
  } catch (error: any) {
    // TokenAccountNotFoundError can be possible if the associated address has already received some lamports,
    // becoming a system account. Assuming program derived addressing is safe, this is the only case for the
    // TokenInvalidAccountOwnerError in this code path.
    if (
      error.message === "TokenAccountNotFoundError" ||
      error.message === "TokenInvalidAccountOwnerError"
    ) {
      // As this isn't atomic, it's possible others can create associated accounts meanwhile.
      try {
        const transaction = new Transaction().add(
          createAssociatedTokenAccountInstruction(
            payer,
            associatedToken,
            owner,
            mint,
            programId,
            associatedTokenProgramId
          )
        );

        const blockHash = await connection.getRecentBlockhash();
        transaction.feePayer = await payer;
        transaction.recentBlockhash = await blockHash.blockhash;
        const signed = await signTransaction(transaction);

        const signature = await connection.sendRawTransaction(
          signed.serialize()
        );

        await connection.confirmTransaction(signature);
      } catch (error: unknown) {
        // Ignore all errors; for now there is no API-compatible way to selectively ignore the expected
        // instruction error if the associated account exists already.
      }

      // Now this should always succeed
      account = await getAccountInfo(
        connection,
        associatedToken,
        commitment,
        programId
      );
    } else {
      throw error;
    }
  }

  if (!account.mint.equals(mint.toBuffer()))
    throw Error("TokenInvalidMintError");
  if (!account.owner.equals(owner.toBuffer()))
    throw new Error("TokenInvalidOwnerError");

  return account;
}

export function createAssociatedTokenAccountInstruction(
  payer: PublicKey,
  associatedToken: PublicKey,
  owner: PublicKey,
  mint: PublicKey,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
): TransactionInstruction {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedToken, isSigner: false, isWritable: true },
    { pubkey: owner, isSigner: false, isWritable: false },
    { pubkey: mint, isSigner: false, isWritable: false },
    { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
    { pubkey: programId, isSigner: false, isWritable: false },
    { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false },
  ];

  return new TransactionInstruction({
    keys,
    programId: associatedTokenProgramId,
    data: Buffer.alloc(0),
  });
}

export enum TokenInstruction {
  InitializeMint = 0,
  InitializeAccount = 1,
  InitializeMultisig = 2,
  Transfer = 3,
  Approve = 4,
  Revoke = 5,
  SetAuthority = 6,
  MintTo = 7,
  Burn = 8,
  CloseAccount = 9,
  FreezeAccount = 10,
  ThawAccount = 11,
  TransferChecked = 12,
  ApproveChecked = 13,
  MintToChecked = 14,
  BurnChecked = 15,
  InitializeAccount2 = 16,
  SyncNative = 17,
  InitializeAccount3 = 18,
  InitializeMultisig2 = 19,
  InitializeMint2 = 20,
}

/**
 * Construct a Transfer instruction
 *
 * @param source       Source account
 * @param destination  Destination account
 * @param owner        Owner of the source account
 * @param amount       Number of tokens to transfer
 * @param multiSigners Signing accounts if `owner` is a multisig
 * @param programId    SPL Token program account
 *
 * @return Instruction to add to a transaction
 */
export function createTransferInstruction(
  source: PublicKey,
  destination: PublicKey,
  owner: PublicKey,
  amount: number,
  multiSigners: Signer[] = [],
  programId = TOKEN_PROGRAM_ID
): TransactionInstruction {
  const keys = addSigners(
    [
      { pubkey: source, isSigner: false, isWritable: true },
      { pubkey: destination, isSigner: false, isWritable: true },
    ],
    owner,
    multiSigners
  );

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    {
      instruction: TokenInstruction.Transfer,
      amount: new TokenAmount(amount).toBuffer(),
    },
    data
  );

  return new TransactionInstruction({ keys, programId, data });
}

export function addSigners(
  keys: AccountMeta[],
  ownerOrAuthority: PublicKey,
  multiSigners: Signer[]
): AccountMeta[] {
  if (multiSigners.length) {
    keys.push({ pubkey: ownerOrAuthority, isSigner: false, isWritable: false });
    for (const signer of multiSigners) {
      keys.push({
        pubkey: signer.publicKey,
        isSigner: true,
        isWritable: false,
      });
    }
  } else {
    keys.push({ pubkey: ownerOrAuthority, isSigner: true, isWritable: false });
  }
  return keys;
}

class TokenAmount extends anchor.BN {
  /**
   * Convert to Buffer representation
   */
  toBuffer(): Buffer {
    const a = super.toArray().reverse();
    const b = Buffer.from(a);
    if (b.length === 8) {
      return b;
    }

    if (b.length >= 8) {
      throw new Error("TokenAmount too large");
    }

    const zeroPad = Buffer.alloc(8);
    b.copy(zeroPad);
    return zeroPad;
  }

  /**
   * Construct a TokenAmount from Buffer representation
   */
  static fromBuffer(buffer: Buffer): TokenAmount {
    if (buffer.length !== 8) {
      throw new Error(`Invalid buffer length: ${buffer.length}`);
    }

    return new anchor.BN(
      [...buffer]
        .reverse()
        .map((i) => `00${i.toString(16)}`.slice(-2))
        .join(""),
      16
    );
  }
}

export enum AccountState {
  Uninitialized = 0,
  Initialized = 1,
  Frozen = 2,
}

export async function getAccountInfo(
  connection: Connection,
  address: PublicKey,
  commitment?: Commitment,
  programId = TOKEN_PROGRAM_ID
) {
  const info = await connection.getAccountInfo(address, commitment);
  if (!info) throw new Error("TokenAccountNotFoundError");
  if (!info.owner.equals(programId))
    throw new Error("TokenInvalidAccountOwnerError");
  if (info.data.length !== AccountLayout.span)
    throw new Error("TokenInvalidAccountSizeError");

  const rawAccount = AccountLayout.decode(Buffer.from(info.data));

  return {
    address,
    mint: rawAccount.mint,
    owner: rawAccount.owner,
    amount: rawAccount.amount,
    delegate: rawAccount.delegateOption ? rawAccount.delegate : null,
    delegatedAmount: rawAccount.delegatedAmount,
    isInitialized: rawAccount.state !== AccountState.Uninitialized,
    isFrozen: rawAccount.state === AccountState.Frozen,
    isNative: !!rawAccount.isNativeOption,
    rentExemptReserve: rawAccount.isNativeOption ? rawAccount.isNative : null,
    closeAuthority: rawAccount.closeAuthorityOption
      ? rawAccount.closeAuthority
      : null,
  };
}

export async function getAssociatedTokenAddress(
  mint: PublicKey,
  owner: PublicKey,
  allowOwnerOffCurve = false,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
): Promise<PublicKey> {
  if (!allowOwnerOffCurve && !PublicKey.isOnCurve(owner.toBuffer()))
    throw new Error("TokenOwnerOffCurveError");

  const [address] = await PublicKey.findProgramAddress(
    [owner.toBuffer(), programId.toBuffer(), mint.toBuffer()],
    associatedTokenProgramId
  );

  return address;
}

export function getAuctionProgramInstance(connection: Connection, wallet: any) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  const provider = new anchor.AnchorProvider(
    connection,
    wallet,
    anchor.AnchorProvider.defaultOptions()
  );
  // Read the generated IDL.
  const idl = AUCTION_IDL as any;

  // Address of the deployed program.
  const programId = AUCTION_PROGRAM_ID;

  // Generate the program client from IDL.
  const program = new (anchor as any).Program(idl, programId, provider);

  return program;  
}
export const USDC_COMMIT_TAG = 'usdc-commit';
export const GLOBAL_STATE_AUCTION_TAG = 'global-auction-state';
export const AUCTION_TAG = 'auction-state';
const usdcDecimal = 6;

export const getAuctionPoolInfo = async (connection: Connection,
  wallet: any, listPool: any) => {
  const program = getAuctionProgramInstance(connection, wallet)
  console.log(listPool);
  
  const data = await Promise.all(listPool.map(async (pool: any) => {
    try {
      let poolToken:PublicKey = new PublicKey(pool.account.poolSeedSrc);
    
      const [auctionState] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(anchor.utils.bytes.utf8.encode(AUCTION_TAG)), poolToken.toBuffer()], program.programId
      );
      const auctionPoolInfo = await program.account.auctionState.fetch(auctionState);
      const nftAmount =  (await connection.getTokenAccountBalance(auctionPoolInfo.nftPda)).value.uiAmount
      auctionPoolInfo.nftAmount = nftAmount
      return auctionPoolInfo
    } catch (e) {
      console.log(e);
      return null;
    }
  }));
  console.log(data);
  
  return data
}

export async function bidAuction( connection: Connection,
  wallet: any, pool: any,amount: number) {
  const program = getAuctionProgramInstance(connection, wallet)

  let poolToken:PublicKey = new PublicKey(pool.account.poolSeedSrc);
  const [auctionState] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(AUCTION_TAG)), poolToken.toBuffer()], program.programId
  );
  console.log(auctionState.toBase58(), poolToken.toBase58(), program.account.auctionState, amount);
  
  const auctionPoolInfo = await program.account.auctionState.fetch(auctionState);
  const auctionUsdcTokenAccount = auctionPoolInfo.usdcPda;
  const currentMaxBidUsdcUser = auctionPoolInfo.currentMaxBidUsdcUser;
  const [usdcCommitState1] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(USDC_COMMIT_TAG)), wallet.publicKey.toBuffer()], program.programId
);
const [globalAuctionState] = await anchor.web3.PublicKey.findProgramAddress(
  [Buffer.from(anchor.utils.bytes.utf8.encode(GLOBAL_STATE_AUCTION_TAG))], program.programId
);

const usdcToken = await getOrCreateAssociatedTokenAccount(connection, wallet.publicKey,
  pool?.account?.lendingMint,
  wallet.publicKey,
  wallet.signTransaction);
  
  const trxPlaceBidUser1 = await program.rpc.commitUsdc(new anchor.BN(amount * (10 ** usdcDecimal)), {
    accounts: 
      {
        accountCommit: usdcCommitState1,
        globalAuctionState: globalAuctionState,
        auctionState: auctionState,
        usdcUserVault: usdcToken.address,
        usdcAuctionVault: auctionUsdcTokenAccount,
        usdcCurrentVault: currentMaxBidUsdcUser,
        poolToken: poolToken,
        authority: wallet.publicKey,
        ...defaultAccounts,
    }
  })
                 
}

export async function claimBidAuction( connection: Connection,
  wallet: any, pool: any) {
    const program = getAuctionProgramInstance(connection, wallet)
    const [usdcCommitState1] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from(anchor.utils.bytes.utf8.encode(USDC_COMMIT_TAG)), wallet.publicKey.toBuffer()], program.programId
  );
  const [globalAuctionState] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(GLOBAL_STATE_AUCTION_TAG))], program.programId
  );
  let poolToken:PublicKey = new PublicKey(pool.account.poolSeedSrc);
  const [auctionState] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(AUCTION_TAG)), poolToken.toBuffer()], program.programId
  );
  const auctionPoolInfo = await program.account.auctionState.fetch(auctionState);
  const nftUserAcc = await getOrCreateAssociatedTokenAccount(connection, wallet.publicKey,
    pool.account.collateralToken,
    wallet.publicKey,
    wallet.signTransaction);
    
    const trxUser3ClaimNft = await program.rpc.claimNft({accounts: {
      accountCommit: usdcCommitState1,
      globalAuctionState: globalAuctionState,
      auctionState: auctionState,
      nftMint: pool.account.collateralToken,
      userNftPda: nftUserAcc.address,
      auctionUsdcAccount: auctionPoolInfo.usdcPda,
      auctionNftAccount: auctionPoolInfo.nftPda,
      poolToken: poolToken,
      usdcRefundAddress: auctionPoolInfo.usdcRefundAddress,
      authority: wallet.publicKey,
      ...defaultAccounts,
  }})
  }

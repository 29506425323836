import styled from "styled-components";

export const Main = styled.div`
  padding-bottom: 200px;
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  .table-btn{
    background: rgba(1, 192, 252, 0.3);
    border: 1px solid #01C0FC;
    border-radius: 6px;
    padding:1px 7px;
    span{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
        color: #FFFFFF;
    }
  }
`;
export const TitleTxt = styled.div`
  font-size: 21px;
`;

export const Wrapper = styled.div`
  width: 100%;
  .menu-color {
    color: #4D647B;
    letter-spacing: 0.3px;
text-transform: uppercase;
 a {
  cursor: pointer;
 }
  }
  .active-item {
  }
`;
export const TopSearchBar = styled.div``;
export const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  width:100%;
`;

import {
  TableHeaderText,
  TableWarpper,
  SectionHeader,
  TableText,
  ClaimButton,
  DisableButton,
} from "./style";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Avatar } from "rsuite";

import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useDAOContext, ITotalVlndrStaked } from "../../pages/DAO/data";
import { shortAddress } from "../../utils/utils";
import { decimalSB } from "../../utils/constants";
import toast from "react-hot-toast";
import { Tooltip, Whisper } from "rsuite";
import {HiOutlineInformationCircle} from "react-icons/hi";
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
const Stakers = () => {
  const CopyAction = () => {
    toast.success("Copied Successfully!");
  };

  const wallet = useWallet();
  const {
    rank,
    weight,
    totalVlndrStakedList,
    yesGov,
    currentEpoch,
    period,
    refreshData,
    setRefreshData,
  } = useDAOContext();

  const { connection } = useConnection();

  const confirmGov = async () => {
    if (wallet?.connected) {
      try {
        if (period === "PRE_GRACE") {
          await yesGov(connection, wallet, currentEpoch);
          setRefreshData(!refreshData);
          toast.success("Become GOVERNORS successfully!");
        } else {
          toast.error("Invalid period to become GOVERNORS!");
        }
      } catch (error) {
        toast.error("Invalid period to become GOVERNORS!");
      }
    }
  };

  const checkGov = (index) => {
    /*
    const check = rank?.rank.findIndex(
      (item) => item.toBase58() === wallet.publicKey.toBase58()
    );
    */
    return (
      period === "PRE_GRACE" &&
      rank?.rank[index].toBase58() === wallet.publicKey.toBase58() &&
      !rank?.becomeGovernor[index]
    );
  };
  //console.log(rank);
  //console.log(weight);
  return (
    <div className="flex flex-col max-w-[800px] w-[800px] mx-auto my-0 gap-5">
      <div className="flex gap-3 ">
        <text className="text-xl self-center">Your Rank:</text>
        <Avatar circle style={{ background: "#000" }}>
          {rank?.rank.findIndex(
            (item) => item.toBase58() === wallet.publicKey.toBase58()
          ) + 1}
        </Avatar>
      </div>
      <TableWarpper>
        <table className="table-class w-full text-sm text-left text-gray-500 dark:text-gray-400 [border-spacing:0.75rem]">
          <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
            <tr className="text-white">
              <th scope="col" colSpan={5} className="py-3">
                <SectionHeader borderColor="#106064">Top Stakers</SectionHeader>
              </th>
            </tr>
          </thead>
          <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
            <tr className="text-white">
              <th scope="col" className="pt-3 w-[20%]">
                <TableHeaderText>Rank
                <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        Rank is decided based on weight
                        
                      </Tooltip>
                    }
                  >
                    <InfoOutlineIcon className="cursor-pointer ml-1 text-[#8EDA87]"
                    />
                  </Whisper>
                  
                </TableHeaderText>
              </th>
              <th scope="col" className="pt-3 w-[27%]">
                <TableHeaderText>Address</TableHeaderText>
              </th>
              <th scope="col" className="pt-3 w-[27%]">
                <TableHeaderText>
                  Weight{" "}
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                      
                        Weight calculation formula:
                        <br />
                        c_weight += total_vrzr_staked * (current_time -
                        last_time_staked) * 10000 / total_epoch_duration
                        <br />
                        <br />
                        weight = c_weight + total_vrzr_staked * (epoch_end_time
                        - current_time) * 10000 / total_epoch_duration
                      </Tooltip>
                    }
                  >
                    <InfoOutlineIcon className="cursor-pointer ml-1 text-[#8EDA87]"
                    />
                  </Whisper>
                </TableHeaderText>
              </th>
              <th scope="col" className="pt-3 w-[27%]">
                <TableHeaderText>Staked vRZR</TableHeaderText>
              </th>
              <th scope="col" className="pt-3 w-[27%]">
                <TableHeaderText>Claim governorship</TableHeaderText>
              </th>
            </tr>
          </thead>
          <tbody>
            {rank && rank?.rank.length > 0 && (
              <>
                {rank?.rank.map((item, index) => (
                  <tr className="table-row dark:bg-gray-800 text-white">
                    <td className="px-6  table-section1">
                      <TableText>{index + 1}</TableText>
                    </td>
                    <td className="px-6  table-section1">
                      <TableText>
                        <text className="tracking-wider cursor-pointer hover:text-[#01FCFC] hover:border-b hover:border-[#01C0FC] max-h-[15px]">
                          {rank
                            ? shortAddress(rank.rank[index].toBase58())
                            : ""}
                        </text>
                        <CopyToClipboard
                          onCopy={CopyAction}
                          text={rank ? rank.rank[index].toBase58() : ""}
                        >
                          <BiCopy className=" ml-1 text-[#01C0FC] text-base cursor-pointer" />
                        </CopyToClipboard>
                      </TableText>
                    </td>
                    <td className="px-6  table-section1">
                      <TableText>
                        {weight && weight.weight && weight.weight[index]
                          ? Number(weight.weight[index]) / decimalSB + " "
                          : " "}
                      </TableText>
                    </td>
                    <td className="px-6  table-section1">
                      <TableText>
                        {rank &&
                        rank.rank &&
                        rank.rank[index] &&
                        totalVlndrStakedList &&
                        totalVlndrStakedList.filter(
                          (item) =>
                            item &&
                            item.staker &&
                            item.staker.toBase58() ===
                              rank.rank[index].toBase58()
                        )[0]
                          ? Number(totalVlndrStakedList
                              .filter(
                                (item) =>
                                  item.staker.toBase58() ===
                                  rank.rank[index].toBase58()
                              )[0]
                              .totalVlndrStaked)/ decimalSB
                          : ""}
                      </TableText>
                    </td>
                    <td className="px-6  table-section1">
                      {checkGov(index) ? (
                        <ClaimButton onClick={confirmGov}>
                          <span>Claim</span>{" "}
                        </ClaimButton>
                      ) : (
                        <DisableButton>
                          <span>Claim</span>{" "}
                        </DisableButton>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </TableWarpper>
    </div>
  );
};

export default Stakers;

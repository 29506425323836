import React, { useState } from "react";
import { DropdownContainer, DropdownContent, DropdownItem } from "./styles";

const SortDropdown = (props: any) => {
  const { setSortState } = props

  const onChangeState = (key: string, value?: number) => {
    setSortState([{sortBy: key, orderBy: value||1}])
  }

  return (
    <DropdownContainer>
      <DropdownContent>
        <DropdownItem onClick={() => onChangeState("subscriptionStartTime")}>
        Subscription Start Date
        </DropdownItem>
        <DropdownItem onClick={() => onChangeState("poolFinishedTime")}>
        Subscription End Date
        </DropdownItem>
        <DropdownItem onClick={() => onChangeState("totalLockedAmount", -1)}>
        Funds Commited
        </DropdownItem>
        <DropdownItem onClick={() => onChangeState("poolFinishedTime")}>
        Subscription Remaining Time
        </DropdownItem>
        <DropdownItem onClick={() => onChangeState("goal", -1)}>
        Max Borrow
        </DropdownItem>
        <DropdownItem onClick={() => onChangeState("couponRate", -1)}>
        Borrow Yield
        </DropdownItem>
        <DropdownItem onClick={() => onChangeState("creditScore")}>
        Credit Score
        </DropdownItem>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default SortDropdown;

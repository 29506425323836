import styled from "styled-components";

export const DropdownContainer = styled.div``;

export const WrapperBg = styled.div``;

export const DropdownContent = styled.ul`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const DropdownItem = styled.li`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const WrapperItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  /* identical to box height, or 163% */
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  text-align: center;

  /* backgrounds/G3 */

  color: #687d95;
  padding-left: 30px;
  transition: 0.3s;

  &:hover {
    color: #ffffff;
  }
`;

// .attrs({ type: "checkbox" })
export const Input = styled.input`
  position: absolute;
  left: 0;
  right: 0;

  border-radius: 50%;
  vertical-align: middle;

  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  width: 18px;
  height: 18px;

  background: rgb(40, 51, 63);

  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  &:hover {
    background: rgba(1, 192, 252, 0.1);
  }

  svg {
    fill: red;
  }
  &:checked {
    &::after {
      content: url("/check.svg");
    }
  }
`;

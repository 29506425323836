import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  useEffect,
  FC,
} from "react";
import { getGlobalStateFromDB } from "../be-calls/be-calls";
import { listEndpoints } from "../components/Navbar/RPCDropdown";
import { IGlobalState } from "../utils/interfaces";
interface AppUIContext {
  endpoint: any;
  setEndpoint?: any;
  adminInfo?: any;
}
const UIContext = createContext<any>({
  endpoint: listEndpoints[0],
});

export function useUIContext() {
  return useContext(UIContext);
}

export const UIConsumer = UIContext.Consumer;

export function AppUIProvider({ children }: any) {
  const [endpoint, setendpoint] = useState(
    localStorage.getItem("rpcEndpoint")
      ? JSON.parse(localStorage.getItem("rpcEndpoint") || "{}")
      : listEndpoints[0]
  );
  const [showGuide, setshowGuide] = useState(
    localStorage.getItem("showGuide") !== "off"
  );
  const [showWalkthrough, setshowWalkthrough] = useState(
    localStorage.getItem("showWalkthrough") !== "off"
  );
  const [adminInfo, setAdminInfo] = useState<IGlobalState | undefined>();
  const [isDisConnecting, setIsDisConnecting] = useState(false);
  const { connection } = useConnection();
  const wallet = useWallet();

  useEffect(() => {
    if (wallet.connected && wallet.publicKey) {      
      getGlobalStateFromDB().then((info: any) => {
        if (info.status === "RECEIVED") {
          let adminInfo: IGlobalState = info.data;
          adminInfo.isApproved = !!adminInfo.approvedList.find(
            (approver: any) =>
              approver.toBase58() === wallet.publicKey?.toBase58()
          );
          setAdminInfo(adminInfo);
        }
      });
    }
  }, [connection, wallet]);

  useEffect(() => {
    if (wallet.disconnecting) {
      setIsDisConnecting(true);
    }
    if (!wallet.disconnecting && isDisConnecting) {
      window.location.reload();
    }
  }, [wallet]);

  const setEndpoint = (endpoint: any) => {
    localStorage.setItem("rpcEndpoint", JSON.stringify(endpoint || ""));
    setendpoint(endpoint);
    window.location.reload();
  };

  const setShowGuide = (value: boolean) => {
    if (value) {
      localStorage.setItem("showGuide", "on");
    } else {
      localStorage.setItem("showGuide", "off");
    }
    setshowGuide(value);
  };

  const setShowWalkthrough = (value: boolean) => {
    if (value) {
      localStorage.setItem("showWalkthrough", "on");
    } else {
      localStorage.setItem("showWalkthrough", "off");
    }
    setshowWalkthrough(value);
  };

  const value = {
    endpoint,
    setEndpoint,
    adminInfo,
    setShowGuide,
    showGuide,
    setShowWalkthrough,
    showWalkthrough,
  };

  return (
    <UIContext.Provider value={value}>
      <div className="app-element">{children}</div>
    </UIContext.Provider>
  );
}

export const withContext = (Component: FC) => {
  return (props: any) => {
    return (
      <UIContext.Consumer>
        {(globalState) => {
          return <Component {...globalState} {...props} />;
        }}
      </UIContext.Consumer>
    );
  };
};

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 29px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;

  width: 269px;
  height: 40px;
  /* backgrounds/B2 */

  background: #161d23;
  border-radius: 6px;
`;

export const InputSearch = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  /* backgrounds/B2 */

  background: #161d23;
  border-radius: 6px;

  padding: 10px 10px 10px 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  /* color: #687d95; */
  color: #ffffff;
  outline: none;
  border: 1px solid transparent;
  transition: border 0.3s ease;

  &:hover {
    border: 1px solid rgba(1, 192, 252, 0.3);
  }
  &:focus {
    border: 1px solid rgba(1, 192, 252, 0.8);
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }
`;

export const SearchIcon = styled.img`
  position: absolute;

  top: 50%;
  left: 12px;
  font-size: 20px;
  transform: translateY(-50%);
  width: 13px;
  height: 13px;
`;

export const FilterSortButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  padding-left: 6px;
`;

export const Text = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 163% */

  display: flex;
  align-items: center;
  text-align: center;

  /* backgrounds/G3 */
  /* transition: 0.3s; */

  color: #687d95;
`;

export const FilterDropdownContainer = styled.div`
  position: absolute;
  top: 45px;
  right: -166%;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  background: rgb(26, 35, 43);
  padding: 22px 16px;
  width: 215px;
  border-radius: 6px;
  z-index: 1;
`;
export const FilterWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px 0;

  &:hover > ${FilterDropdownContainer} {
    opacity: 1;
    visibility: visible;
    box-shadow: -11px 6px 26px -14px rgba(0, 0, 0, 0.75);
  }

  &:hover ${Text} {
    color: rgb(170, 188, 209);
  }

  &:hover svg {
    stroke: rgb(170, 188, 209);
  }
`;

export const SortDropdownContainer = styled(FilterDropdownContainer)`
  padding: 22px 18px;
  left: -170%;
  width: 240px;
`;

export const SortWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px 0;

  &:hover > ${SortDropdownContainer} {
    opacity: 1;
    visibility: visible;
    box-shadow: -11px 6px 26px -14px rgba(0, 0, 0, 0.75);
  }

  &:hover ${Text} {
    color: rgb(170, 188, 209);
  }

  &:hover svg {
    stroke: rgb(170, 188, 209);
  }
`;

import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import ProposalTable from "../../components/Governance";
import { getDaoGlobalState, getDaoGovernorList, getAllProposals } from "../../utils/instructions";
import { IDaoGlobalState, IDaoGovernorList, IProposal, } from "../../utils/interfaces";
import { Container, Wrapper, Main } from "./styles";

const Governance = () => {
  const { connection } = useConnection();
  const wallet = useWallet();

  const [proposalList, setProposalList] = useState<IProposal[]>([]);
  const [daoGlobalState, setDaoGlobalState] = useState<IDaoGlobalState | undefined>(undefined);
  const [daoGovernorList, setDaoGovernorList] = useState<IDaoGovernorList | undefined>(undefined);
  
  useEffect(() => {
    if (wallet.publicKey) {
      getAllProposals(connection, wallet).then((proposalList: IProposal[]) => {
        setProposalList(proposalList);
         // console.log("governance proposalList = ", proposalList);
      });

      getDaoGlobalState(connection, wallet).then((daoGlobalState: any) => {
        setDaoGlobalState(daoGlobalState);
        // console.log("governance daoGlobalState = ", daoGlobalState);
        // console.log("governance lndrMint = ", daoGlobalState.lndrMint.toBase58());
      });

      getDaoGovernorList(connection, wallet).then((daoGovernorList: any) => {
        setDaoGovernorList(daoGovernorList);
        // console.log("governance daoGovernorList = ", daoGovernorList);
      });
    }
  }, [wallet, connection]);

  return (
    <Main>
      <Container>
        { proposalList && daoGlobalState && daoGovernorList ? 
          ( <Wrapper>
              <ProposalTable
                proposals={proposalList}
                globalState={daoGlobalState}
                governorList={daoGovernorList}
                wallet={wallet}
              />
            </Wrapper>
          ) : (
            <>no info</>
          )}
      </Container>
    </Main>
  );
};

export default Governance;

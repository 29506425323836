import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import {
  getGlobalStateFromDB,
  getPoolsFromOffset,
} from "../../be-calls/be-calls";
import { CommitedPools } from "../../components/Account";
import { SB_MINT_ADDRESS } from "../../utils/ids";
import { IGlobalState, IPoolInfo, TokenAccount } from "../../utils/interfaces";
import { useWalletAccounts } from "../../utils/walletManager";
import { Container, Wrapper, Main } from "./styles";

const MyAccount = () => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { userAccounts, tokenMap } = useWalletAccounts();

  const [poolWithOwnersList, setPoolWithOwnersList] = useState<IPoolInfo[]>([]);
  const [poolDataLoaded,setPoolDataLoaded]=useState<Boolean>(false)
  const [adminInfo, setAdminInfo] = useState<IGlobalState | undefined>(
    undefined
  );
  const [feeAccount, setFeeAccount] = useState<TokenAccount | undefined>(
    undefined
  );

  useEffect(() => {
    if (wallet.connected === true && wallet.publicKey) {
      console.log("wallet",wallet.publicKey?.toBase58())
      /*

      getGlobalState(connection, wallet).then((adminInfo) =>
        setAdminInfo(adminInfo)
      );
      */
      getGlobalStateFromDB().then((info: any) => {
        if (info.status === "RECEIVED") {
          let adminInfo: IGlobalState = info.data;
          setAdminInfo(adminInfo);
        }
      });

      const found = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === SB_MINT_ADDRESS
      );
      setFeeAccount(found);
    }
  }, [connection, userAccounts, wallet]);

  useEffect(() => {
    if (wallet.publicKey) {
      getPoolsFromOffset(0, null, true).then((info: any) => {
        let allPools: IPoolInfo[] = [];

        if (info.status === "RECEIVED") allPools = info.data;

        const poolWithOwners = allPools.filter(
          (pool) =>
            pool.usersList.findIndex(
              (user) =>
                user.account.owner.toBase58() === wallet.publicKey?.toBase58()
            ) !== -1
        );

        const poolWithSwapList = allPools.filter((pool) => {
          const found = userAccounts.find((account) => {
            return (
              account.info.mint.toBase58() ===
              pool.account.mintPoolToken.toBase58()
            );
          });
          if (!found) return false;

          if (
            pool.usersList.find(
              (account) =>
                account.account.owner.toBase58() ===
                wallet.publicKey?.toBase58()
            )
          )
            return false;

          if (found.info.amount.toNumber() === 0) return false;

          return true;
        });
        
        setPoolWithOwnersList(poolWithOwners.concat(poolWithSwapList));
        setPoolDataLoaded(true)
      });
    }
  }, [wallet.connected, userAccounts, wallet, connection]);

  return (
    <Main>
      {/* <Button onClick={() => signMessage && signMessage(encodedMessage)}>
        AAAAAAAAA
      </Button> */}
      <Container>
        {adminInfo && feeAccount ? (
          <Wrapper>
            <CommitedPools
              pools={poolWithOwnersList}
              adminInfo={adminInfo}
              tokenMap={tokenMap}
              userAccounts={userAccounts}
              wallet={wallet}
              feeAccount={feeAccount}
              poolDataLoaded={poolDataLoaded}
            />
            {/* <SecondTable /> */}
          </Wrapper>
        ) : (
          <></>
        )}
      </Container>
    </Main>
  );
};

export default MyAccount;

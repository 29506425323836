import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { HiOutlineSelector } from "react-icons/hi";

export const TOKEN_NAME_ID = {
  ETH: 1,
  SOL: 2,
  POLY: 3,
  BNB: 4,
}

export const chains = [
  { id: TOKEN_NAME_ID.ETH, name: "ETH" },
  { id: TOKEN_NAME_ID.SOL, name: 'SOL' },
  // { id: TOKEN_NAME_ID.POLY, name: 'POLY' },
  // { id: TOKEN_NAME_ID.BNB, name: 'BNB' },
];
export const DropDown = (props) => {
  console.log({selected: props.selected});
  const indexAddress = props.indexAddress;
  const address = props.address;
  const [selected, setSelected] = useState(props.selected ? props.selected : chains[0]);
  const onChangeData = (token) => {
    if (token){
      setSelected(token);
      props.onChangeValue({...token, indexAddress: indexAddress, address: address});
    }
  }

  return (
    <Listbox value={selected} onChange={onChangeData}>
      <div className="relative mt-1">
        <Listbox.Button className="relative mt-1 w-24 cursor-default rounded-lg bg-[#1A232B] py-2 pl-3  text-white text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <span className="block truncate">{selected.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <HiOutlineSelector
              className="h-5 w-5 text-white"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 cursor-pointer max-h-28 w-48 z-40 overflow-auto rounded-md  bg-[#1A232B] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {chains.map((chain, chainIdx) => (
              <Listbox.Option
                key={chainIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-4 pr-4 ${
                    active ? "bg-amber-100 text-[#687D95]" : "text-white"
                  }`
                }
                value={chain}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {chain.name}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

import styled from "styled-components";

export const TableWarpper = styled.div`
  overflow-x: auto;
  poistion: relative;
  background: #1a232b;
  border-radius: 12px;
  width:800px;
  max-width:800px;
  margin:0 auto;
  padding: 20px;
  .table-row {
    background: #1a232b;
  }
  .table-section {
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
    background: #161d23;
    padding: 1rem 1rem;
  }
  .table-class {
    border-collapse: separate;
    border-spacing: 0 3px;
  }
  .table-section1 {
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
        border-radius: 0 10px 10px 0;
      }
    position: relative;
    padding: 0.5rem 1rem;
    background: linear-gradient(
        180.1deg,
        rgba(34, 151, 241, 0.15) -32.56%,
        rgba(1, 252, 252, 0.15) 99.93%
      ),
      #161d23;
  }
  hr {
  }
`;

export const VLine = styled.hr`
  position: absolute;
  height: 30px;
  width: 4px;
  left: 0px;
  top: 17px;
  border: 0;
  background: ${({ color }) => color};
  box-shadow: 0px 1px 6px 2px ${({ color }) => color}3d;
  border-radius: 0 10px 10px 0;
`;

export const FirstTableRowHeader = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: ${(props) =>
    props.type === "Active Subscription"
      ? "#01fcfc"
      : props.type === "Funded"
      ? "#25FF3B"
      : props.type === "Pre-Subscription"
      ? "#FFD029"
      : props.type === "Defaulted" || props.type === "Successful" || props.type === "Pool Ended"
      ? "#B3C7D9"
      :props.type === "Finished Unfunded" || props.type === "Finished Cancelled" || props.type === "Finished Successful" || props.type === "Finished Defaulted" || props.type === "Pool Finished" 
      ? "#B3C7D9"
      : "#01FCFC"};
`;

export const SectionHeader = styled.text`
  font-family: "Space Grotesk";
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  margin: 0 0.9rem;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  padding-bottom: 0.8rem;
`;

export const TableText = styled.text`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  letter-spacing: -0.02em;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
`;

export const TableHeaderText = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  /* justify-content: center; */
  text-transform: uppercase;
  color: #4d647b;
`;

export const ClaimButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;

  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, #01c0fc 2.67%, #01c0fc 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: #91a5f4;
    background: linear-gradient(135deg, #91a5f4 0%, #b08cf9 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
  &:hover {
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 5px #01c0fc);
    background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    animation-name: animation4;
    animation-duration: 0.3s;
  }
`;

export const DisableButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;

  cursor: pointer;
  /* width: 69px; */
  height: 33px;
  /* primary/semi green-blue */
  background: linear-gradient(100deg, gray 2.67%, gray 200%);
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
  transition: all 0.3s linear;

  .button:before {
    background: gray;
    background: linear-gradient(135deg, gray 0%, gray 86%);
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @keyframes animation4 {
    0% {
      background: linear-gradient(0.09deg, #01fcfc 2.67%, #0085ff 200%);
    }
    20% {
      background: linear-gradient(20.09deg, #01fcfc 2.67%, #0085ff 180%);
    }
    40% {
      background: linear-gradient(40.09deg, #01fcfc 2.67%, #0085ff 160%);
    }
    60% {
      background: linear-gradient(60.09deg, #01fcfc 2.67%, #0085ff 140%);
    }
    80% {
      background: linear-gradient(80.09deg, #01fcfc 2.67%, #0085ff 120%);
    }
    100% {
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
    }
  }
`;
 
export const TitleTxt= styled.text`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  background: linear-gradient(90.09deg, #01FCFC 2.67%, #0085FF 104.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`
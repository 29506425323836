import styled from "styled-components";

export const TableWarpper = styled.div`
  width: 100%;
  .table-btn{
    background: #334150;
    border-radius: 6px;
    padding:1px 7px;
    span{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
        color: #687D95;

    }
  }


  .table-warpper{
    width:100%;
    display: flex;
    gap: 5px;
  }
  .header-div {
    padding: 10px 3px;
  }
  .table-header-main {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #ffffff;
  }
   
  }
  .table-two {
    width: 100%;
    th{
        text-align: inherit;
    }
    .table-card {
      table {
        border-collapse: separate;
        border-spacing: 0px;
        border-radius: 1.1em;
        tr:nth-child(odd) {
          background-color: #12181f;
        }
        tr:nth-child(even) {
          background-color: #161d23;
        }
      }

      background: #1a232b;
      border-radius: 8px;
      padding: 10px 10px;
      diplay: flex;
      flex-direction: column;
      .line-break {
        border-bottom: 2px solid #28333f;
      }
      .table-header {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        text-transform: uppercase;
        color: #687d95;
      }
      .table-text {
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
        color: #ffffff;
      }
    }
  }

  
`;

import { XMarginVerification } from "../../../components";
import { Container, Wrapper, ContentWrapper, Main } from "./styles";

const XmarginVerification = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <ContentWrapper>
            <XMarginVerification />
          </ContentWrapper>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default XmarginVerification;

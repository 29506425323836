import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { CommitedPools } from "../../components/Account";
import { SB_MINT_ADDRESS } from "../../utils/ids";
import { IGlobalState, IProposal, TokenAccount } from "../../utils/interfaces";
import { useWalletAccounts } from "../../utils/walletManager";
import { Container, Wrapper, Main } from "./styles";

const MyProposals = () => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { userAccounts, tokenMap } = useWalletAccounts();

  const [proposalList, setProposalList] = useState<IProposal[]>([]);

  useEffect(() => {
    if (wallet.connected === true && wallet.publicKey) {     
    }
  }, [connection, userAccounts, wallet]);

  useEffect(() => {
    /*
    if (wallet.publicKey) {
      getPoolsFromOffset(0, null, true).then((info: any) => {
        let allPools: IPoolInfo[] = [];

        if (info.status === "RECEIVED") allPools = info.data;

        const poolWithOwners = allPools.filter(
          (pool) =>
            pool.usersList.findIndex(
              (user) =>
                user.account.owner.toBase58() === wallet.publicKey?.toBase58()
            ) !== -1
        ); 
      });
    }
    */
  }, [wallet.connected, userAccounts, wallet, connection]);

  return (
    <Main>
      {/*
      <Container>
        {adminInfo && feeAccount ? (
          <Wrapper>
            <CommitedPools
              pools={poolWithOwnersList}
              adminInfo={adminInfo}
              tokenMap={tokenMap}
              userAccounts={userAccounts}
              wallet={wallet}
              feeAccount={feeAccount}
            />
          </Wrapper>
        ) : (
          <></>
        )}
      </Container>
      */}
    </Main>
  );
};

export default MyProposals;

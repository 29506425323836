import { useState } from "react";
import { Wrapper, Input, Button } from "./style";
import { ListTokens } from "./list";

import Accountable from "./AccountTable";

const Account = () => {
  const [showBothInput, setShowBothInput] = useState(true);
  return (
    <Wrapper>
      <div className="input-section">
        <text className="heading">PROPOSE A CHANGE</text>
        <div className="line-breaker" />
        <div className="input-wrapper">
          <div className="first-half">
            <ListTokens className="" disabled={false} />
          </div>
          <div className="second-half">
            <div
              className="combined-section"
              style={{ visibility: showBothInput ? "" : "hidden" }}
            >
              <text className="label">position</text>
              <Input width={"290px"} type="text" placeholder="Enter Position" />
            </div>

            <div className="combined-section">
              <text className="label">address</text>
              <Input width={"290px"} type="text" placeholder="Enter address" />
            </div>

            <div className="combined-section">
              <text className="label" style={{ visibility: "hidden" }}>
                address
              </text>
              <Button>
               PROPOSE
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Accountable />
    </Wrapper>
  );
};
export default Account;

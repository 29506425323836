import { TableWarpper, TableCard } from "./style";

const Accountable = () => {
  let filledArray = new Array(5).fill("hello");
  return (
    <TableWarpper>
    <div className="table-warpper">
     
      <div className="table-two">
        <div className="table-card">
          <div className="header-div">
            <text className="table-header-main">PROPOSAL DETAILS</text>
          </div>

          <div className="line-break" />
          <div className="">
            <table className="w-full ">
              <thead className="bg-gray-[#1A232B]">
                <th scope="col" className="py-3 px-2">
                  <text className="table-header">ADDRESS</text>
                </th>
                <th scope="col" className="py-3 px-2">
                  <text className="table-header">CONTENT</text>
                </th>
                <th scope="col" className="py-3 px-2">
                  <text className="table-header">DATE</text>
                </th>
                <th scope="col" className="py-3 px-2">
                  <text className="table-header">ACCEPTANCE RATE</text>
                </th>
                <th scope="col" className="py-3 px-2">
                  <text className="table-header">STATUS</text>
                </th>
                <th scope="col" className="py-3 px-2">
                  <text className="table-header">Action</text>
                </th>
              </thead>
              <tbody>
                {filledArray.map((value) => (
                  <tr className="">
                    <th scope="row" className="py-4 px-2 whitespace-nowrap ">
                      <text className="table-text">9REm...RisQ4</text>
                    </th>
                    <td className="py-4 px-2">
                      <text className="table-text">Change gas fee: New value 6000</text>
                    </td>
                    <td className="py-4 px-2">
                      <text className="table-text">07-09-2022</text>
                    </td>
                    <td className="py-4 px-2">
                      <text className="table-text">0</text>
                    </td>
                    <td className="py-4 px-2">
                      <text className="table-text">Failed proposal</text>
                    </td>
                    <td className="py-4 px-2">
                    <button className="table-btn">
                       <span>Vote</span>
                    </button>
                       
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </TableWarpper>
  );
};
export default Accountable;

import { TokenInfo } from "@solana/spl-token-registry";
import { WalletContextState } from "@solana/wallet-adapter-react";
import {
  IGlobalState,
  IPoolInfo,
  TokenAccount,
} from "../../../utils/interfaces";
import { CommitedPool } from "./CommitedPool";
import { TableHeaderText, TableWarpper, SectionHeader } from "./styles";
import EmptyPage from "../../../pages/LendEmptyPage";
import LoadingBar from 'react-top-loading-bar'
import {useState,useEffect} from "react"

type PoolProps = {
  pools: IPoolInfo[];
  userAccounts: TokenAccount[];
  tokenMap: Map<string, TokenInfo>;
  adminInfo: IGlobalState;
  wallet: WalletContextState;
  feeAccount: TokenAccount;
  poolDataLoaded:Boolean
};

const CommitedPools = ({
  pools,
  userAccounts,
  tokenMap,
  adminInfo,
  wallet,
  feeAccount,
  poolDataLoaded
}: PoolProps) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(progress + 10);
    }, 1000);
    if(poolDataLoaded) {
      setProgress(100);
      clearTimeout(timeout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [poolDataLoaded]);

  console.log(pools)
  return (
    <>
     <LoadingBar color="#01C0FC" progress={progress} onLoaderFinished={() => setProgress(0)} />
      {poolDataLoaded &&  pools && pools.length ? (
        <TableWarpper>
          <table className="table-class w-full text-sm text-left text-gray-500 dark:text-gray-400 [border-spacing:0.75rem]">
            <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
              <tr className="text-white">
                <th scope="col" colSpan={5} className="py-3">
                  <SectionHeader borderColor="#106064">
                    pool details
                  </SectionHeader>
                </th>
                <th scope="col" colSpan={4} className="py-3">
                  <SectionHeader borderColor="#3d4f61">
                    User details
                  </SectionHeader>
                </th>
                {/* <th scope="col" colSpan={4} className="py-3">
              <SectionHeader borderColor="#06c0fc">rewards</SectionHeader>
            </th> */}
              </tr>
            </thead>
            <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
              <tr className="text-white">
                <th scope="col" className="pt-3">
                  <TableHeaderText>Borrower</TableHeaderText>
                </th>
                <th scope="col" className="pt-3">
                  <TableHeaderText>Pool Token</TableHeaderText>
                </th>
                <th scope="col" className="pt-3">
                  <TableHeaderText>Funds raised</TableHeaderText>
                </th>
                <th scope="col" className="pt-3">
                  <TableHeaderText>Collateral</TableHeaderText>
                </th>
                <th scope="col" className="pt-3">
                  {/* <TableHeaderText>Pool Token  <br />AMOUNT</TableHeaderText> */}
                  <TableHeaderText>
                    Total pool token <br /> issued
                  </TableHeaderText>
                </th>
                <th scope="col" className="pt-3">
                  <TableHeaderText>My Initial Exposure</TableHeaderText>
                </th>
                {/* <th scope="col" className="pt-3">
              <TableHeaderText>Principal/Collateral</TableHeaderText>
            </th> */}
                <th scope="col" className="pt-3">
                  <TableHeaderText>Bonus EARNED</TableHeaderText>
                </th>
                <th scope="col" className="pt-3">
                  <TableHeaderText>Action</TableHeaderText>
                </th>
              </tr>
            </thead>
            <tbody>
              {pools.map((poolInfo: IPoolInfo, index) => (
                <CommitedPool
                  poolInfo={poolInfo}
                  adminInfo={adminInfo}
                  userAccounts={userAccounts}
                  wallet={wallet}
                  tokenMap={tokenMap}
                  index={index}
                  feeAccount={feeAccount}
                />
              ))}
            </tbody>
          </table>
        </TableWarpper>
      ) : (
        <>
          {poolDataLoaded && pools && (
            <div className="flex justify-center mt-10">
             <EmptyPage text={"Lend"} url={"/active-subscription-pools"}  />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default CommitedPools;

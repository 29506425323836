import { useState } from "react";
import { FaGlasses } from "react-icons/fa";
import DAOStake from "./Stake"
import DAOTable from "./DAOTable";
import DAOControl from "./DAOControl";
import {Wrapper} from "./style"

const DAO = () => {
  const [showPlaceBid, setShowPlaceBid] = useState(false);

  return (
    <Wrapper>
      <DAOControl />
      <DAOStake />
      <DAOTable />
    </Wrapper>
  );
};
export default DAO;

import { TokenInfo } from "@solana/spl-token-registry";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import React, { useEffect, useState } from "react";
import {
  decimalNumberUSDC,
  decimalUSDC,
  DELAY_TIME,
  EXTRA_PERIOD, FINISHED_POOL,
  INVALID_POOL,
  LENDING_EXPIRED,
  LENDING_PERIOD,
} from "../../../utils/constants";
import { EMPTY_ADDRESS, SB_MINT_ADDRESS } from "../../../utils/ids";
import { payBonus, refund, withdrawLocked } from "../../../utils/instructions";
import {
  IGlobalState,
  IPoolInfo,
  TokenAccount,
} from "../../../utils/interfaces";
import {
  getAccountBalance,
  getMintDecimalValue,
} from "../../../utils/walletManager";
import { Button } from "../styles";
import {
  Wrapper,
  DisableButton,
  HeaderText,
  GradientText,
  SecondSectionWrapper,
  MenuText,
  Input,
  ButtonWithEffect,
  MenuWrapper,
  ButtonWithoutEffect,
  InputTag,
} from "./styles";
import { TextGradient } from "../../CreatePool/styles";
import toast from "react-hot-toast";
import { delay, equal_with_epsilon_error, getRoundValue, getSymbol ,defaultDecimal} from "../../../utils/utils";
import { getPool, updatePool } from "../../../be-calls/be-calls";
import {ListTokens} from "../../CreatePool/listTokens";
import {tokensData} from "../../CreatePool/tokensData";
import {GlobalStyle} from "../../StatusPool/styles"
import Swal from "sweetalert2";
import { useUIContext } from "../../../context/AppContext";


let regexp = /^\d*\.?\d*$/;

type PropsDates = {
  poolInfo: IPoolInfo;
  userAccounts: TokenAccount[];
  adminInfo: IGlobalState;
  poolState: number;
  poolStateText:string,
  tokenMap: Map<string, TokenInfo>;
  setPoolInfo: any
};

const BorrowersRight = ({
  poolInfo,
  userAccounts,
  poolState,
  tokenMap,
  poolStateText,
  setPoolInfo
}: PropsDates) => {
  const [pInfo, setPInfo] = useState<IPoolInfo>(poolInfo);
  const { connection } = useConnection();
  const wallet = useWallet();
  const [activeMenu, setActiveMenu] = useState("repay");
  const nf = new Intl.NumberFormat();
  const [fundAccount, setFundAccount] = useState<any>(undefined);
  const [feeAccount, setFeeAccount] = useState<TokenAccount | undefined>(
    undefined
  );
  const [bonus, setBonus] = useState("0");
  const [bonusRemain, setBonusRemain] = useState(0);
  const [selectedToken, setSelectedToken] = useState(tokensData[0]);
  // const [mintAddress, setMintAddress] = useState(
  //   pInfo.account.bonusTokenMint.toBase58()
  // );
  const [mintAddress, setMintAddress] = useState('')
  const [refundAmount, setRefundAmount] = useState(0);
  const {adminInfo} = useUIContext()

  const openFeesModal = async () => {
    const percentFee = (adminInfo.isApproved ? Number(adminInfo.withdrawFeeApproved): Number(adminInfo.withdrawFeeUnapproved)) / 10000
    const withdrawFee = getRoundValue(Number(pInfo.account.totalLockedAmount.toNumber() / decimalUSDC) * percentFee, defaultDecimal)
    const message = `
    <div class="bg-gray-200 py-3 p-4 mt-3 sm:p-1 rounded-md">
      <div class="table2">
        <table class="w-full">
          ${(withdrawFee > 0) ? `<tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                Withdrawal Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${withdrawFee}</b> USDC</span>
              </td>
            </tr>`: ''}

            ${(Number(adminInfo.gasFee) > 0) && `<tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                  Gas Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${Number(adminInfo.gasFeeConverted)}</b> RZR</span>
              </td>
            </tr>`}
        </table>
      </div>
    </div>
    `;
    await Swal.fire({
      title: "Fees Confirmation",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#01C0FC",
    }).then((result) => {
      if (result.isConfirmed) {
        withdrawClick();
      }
    });
   
  };

   
  useEffect(() => {
    const found = userAccounts.find(
      (acc) => acc.info.mint.toBase58() === SB_MINT_ADDRESS
    );
    setFeeAccount(found);

    const tempFundAccount = userAccounts.find(
      (acc) => acc.info.mint.toBase58() === pInfo.account.lendingMint.toBase58()
    );
    setFundAccount(tempFundAccount);
  }, [userAccounts, pInfo.account.lendingMint]);

  const withdrawClick = async () => {
    if (!feeAccount || !fundAccount) {
      toast("fee account or fund account is null");
      return;
    }

    toast("Start withdrawing commitment");
    let tx = await withdrawLocked(
      connection,
      wallet,
      fundAccount.pubkey,
      pInfo.account.totalLockedAmount.toNumber() -
        pInfo.account.totalClaimedAmount.toNumber(),
      pInfo.account.lendingMint,
      pInfo.publicKey,
      adminInfo.feeVaultTreasury, // USDC
      adminInfo.feeMint,
      adminInfo.feeVault, // RZR
      feeAccount.pubkey
    );

    if (!tx) toast("Withdrawing commitment is failed");
    else {
      await delay(DELAY_TIME);
      let updatedPoolInfo = await updatePool(pInfo.publicKey.toBase58());

      if (updatedPoolInfo.status === "RECEIVED") {
        await delay(DELAY_TIME);
        //Update new pool data from DB to pInfo
        let poolData = await getPool(pInfo.publicKey.toBase58());

        if (poolData.status === "RECEIVED") {
          setPInfo(poolData.data as IPoolInfo);
          setPoolInfo(poolData.data as IPoolInfo);
          toast("Complete withdrawing commitment");
        }
      }
    }
  };

  const isNotWithdrawable = () => {
    // if (poolState === LENDING_PERIOD) {
    //   return true;
    // } else {
    //   return (
    //       (poolState !== INVALID_POOL && poolState !== LENDING_PERIOD) ||
    //       pInfo.account.totalClaimedAmount.toNumber() === pInfo.account.totalLockedAmount.toNumber() ||
    //       pInfo.account.isWithdrawed === 1
    //   );
    // }
    //console.log("isNotWithdrawable poolState = ", poolState, " totalLockedAmount = ", pInfo.account.totalLockedAmount.toNumber(), " min = ", pInfo.account.min.toNumber(), " isWithdrawed = ", pInfo.account.isWithdrawed); 
    return (
        (poolState !== INVALID_POOL && poolState !== LENDING_PERIOD) ||
        pInfo.account.totalLockedAmount.toNumber() < pInfo.account.min.toNumber() ||
        pInfo.account.isWithdrawed === 1
    );
  };

  const payBonusClicked = async () => {
    const message = `
    <div class="bg-gray-200 py-3 p-4 mt-3 sm:p-1 rounded-md">
      <div class="table2">
        <table class="w-full">
            <tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                  Gas Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${Number(adminInfo.gasFeeConverted)}</b> RZR</span>
              </td>
            </tr>
        </table>
      </div>
    </div>
    `;
    let ProceedForStake=false
    await Swal.fire({
      title: "Fees Confirmation",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#01C0FC",
    }).then((result) => {
      if (result.isConfirmed) {
        ProceedForStake = true
      }
    });
    if (!ProceedForStake) return;


    if (mintAddress === EMPTY_ADDRESS) {
      toast("Bonus token mint address is empty");
      return;
    }
    const bonusToken = (pInfo.account.bonusTokenMint && !(pInfo.account.bonusTokenMint.toBase58() === EMPTY_ADDRESS)) ? pInfo.account.bonusTokenMint : new PublicKey(mintAddress);
    const bonusDecimal = await getMintDecimalValue(bonusToken);

    if (bonusRemain < Number(bonus)) {
      toast("Insufficient Amount");
      return;
    }

    if (Number(bonus) <= 0) {
      toast("Bonus cant be 0");
      return;
    }

    if (!feeAccount) {
      toast("fee account is null");
      return;
    }

    toast("Start paying bonus");
    try {
      let tx = await payBonus(
        connection,
        wallet,
        pInfo.publicKey,
        adminInfo.feeMint,
        adminInfo.feeVault,
        feeAccount.pubkey,
        bonusToken,
        Number(bonus) * bonusDecimal,
        pInfo.account.bonusAmount.toNumber()
      );

      if (!tx) toast("Paying bonus is failed");
      else {
        setBonus("0");

        await delay(DELAY_TIME);
        let updatedPoolInfo = await updatePool(pInfo.publicKey.toBase58());

        if (updatedPoolInfo.status === "RECEIVED") {
          await delay(DELAY_TIME);
          //Update new pool data from DB to pInfo
          let poolData = await getPool(pInfo.publicKey.toBase58());

          if (poolData.status === "RECEIVED") {
            setPInfo(poolData.data as IPoolInfo);
            setPoolInfo(poolData.data as IPoolInfo);
            toast("Complete paying bonus");
          }
        }
      }
    } catch (e) {
      toast("Bonus token mint address is invalid");
    }
  };

  const refundClick = async () => {
    const message = `
    <div class="bg-gray-200 py-3 p-4 mt-3 sm:p-1 rounded-md">
      <div class="table2">
        <table class="w-full">
            <tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                  Gas Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${Number(adminInfo.gasFeeConverted)}</b> RZR</span>
              </td>
            </tr>
        </table>
      </div>
    </div>
    `;
    let ProceedForStake=false
    await Swal.fire({
      title: "Fees Confirmation",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#01C0FC",
    }).then((result) => {
      if (result.isConfirmed) {
        ProceedForStake = true
      }
    });
    if (!ProceedForStake) return;

    if (!feeAccount) {
      toast("fee account is null");
      return;
    }

    if (!fundAccount) {
      toast("usdc account is null");
      return;
    }

    if (
      refundAmount * decimalUSDC >
      pInfo.account.totalClaimedAmount.toNumber() + pInfo.account.totalClaimedInterestAmount.toNumber()
    ) {
      toast("refund amount is invalid");
      return;
    }

    toast("Start refund");
    let tx = await refund(
      connection,
      wallet,
      fundAccount.pubkey,
      pInfo.publicKey,
      adminInfo.feeMint,
      adminInfo.feeVault,
      feeAccount.pubkey,
      refundAmount * decimalUSDC
    );

    if (!tx) toast("Refund is failed");
    else {
      await delay(DELAY_TIME);
      setRefundAmount(0);
      let updatedPoolInfo = await updatePool(pInfo.publicKey.toBase58());

      if (updatedPoolInfo.status === "RECEIVED") {
        await delay(DELAY_TIME);
        //Update new pool data from DB to pInfo
        let poolData = await getPool(pInfo.publicKey.toBase58());

        if (poolData.status === "RECEIVED") {
          setPInfo(poolData.data as IPoolInfo);
          setPoolInfo(poolData.data as IPoolInfo);
          toast("Complete refund");
        }
      }
    }
  };

  const amountChange = (e: any) => {
    if (regexp.test(e.target.value)) {
      if (activeMenu === "bonus") {
        setBonus(e.target.value);
      } else if (activeMenu === "repay") {
        const value = parseFloat(e.target.value);
        //console.log(value);
        if (isNaN(value)) setRefundAmount(0);
        else {
          if (value > (pInfo.account.totalClaimedAmount.toNumber() + pInfo.account.totalClaimedInterestAmount.toNumber()) / decimalUSDC)
            setRefundAmount(
                (pInfo.account.totalClaimedAmount.toNumber() + pInfo.account.totalClaimedInterestAmount.toNumber()) / decimalUSDC
            );
          else setRefundAmount(value);
        }
      }
    }
  };

  const mintAddressChange = async (e: any) => {
    setMintAddress(e.target.value);
  };

  const handleChangeValue = (data: any) => {
     setSelectedToken(data)
   if(data.name==='custom') setMintAddress('')
   else setMintAddress(data.address);
  }

  useEffect(() => {
    const currentMintAddress = (pInfo.account.bonusTokenMint && !(pInfo.account.bonusTokenMint.toBase58() === EMPTY_ADDRESS)) ? pInfo.account.bonusTokenMint.toBase58() : mintAddress;
    const tempBonusAccount = userAccounts.find(
      (acc) => acc.info.mint.toBase58() === currentMintAddress
    );
    if (tempBonusAccount?.pubkey) {
      getAccountBalance(tempBonusAccount?.pubkey, currentMintAddress).then(
        (tempBonusAmount) => setBonusRemain(tempBonusAmount)
      );
    } else {
      setBonusRemain(0);
    }
  }, [mintAddress, userAccounts]);

// console.log({pInfo: pInfo.account.bonusTokenMint ? pInfo.account.bonusTokenMint.toBase58() : mintAddress});
// console.log("sitanshi", pInfo.account)
  return (
    <Wrapper>
      
      <div className="first-section">
        <div className="flex flex-col">
          {/* <HeaderText>funds withdrawn</HeaderText>
          <GradientText>
            {nf.format(pInfo.account.totalClaimedAmount.toNumber() / decimalUSDC)} usdc
          </GradientText> */}
          {!isNotWithdrawable() && (getRoundValue(pInfo.account.totalLockedAmount.toNumber() / decimalUSDC, defaultDecimal)) > 0
          && (pInfo.account.totalClaimedAmount.toNumber() / decimalUSDC)===0 &&
            <>
            <HeaderText>Withdrawable Balance</HeaderText>
            <GradientText>
            {nf.format(getRoundValue(pInfo.account.totalLockedAmount.toNumber() / decimalUSDC, defaultDecimal))} USDC
          </GradientText>

            </>
          }

        {isNotWithdrawable() &&
        (pInfo.account.totalClaimedAmount.toNumber() / decimalUSDC)===0 &&
            <>
            <HeaderText>TOTAL COMMITED</HeaderText>
            <GradientText>
            {nf.format(getRoundValue(pInfo.account.totalLockedAmount.toNumber() / decimalUSDC, defaultDecimal))} USDC
          </GradientText>
            </>
          }

{isNotWithdrawable()  &&
        (pInfo.account.totalClaimedAmount.toNumber() / decimalUSDC)>0 &&
            <>
            <HeaderText>Funds borrowed</HeaderText>
            <GradientText>
            {nf.format(pInfo.account.totalClaimedAmount.toNumber() / decimalUSDC)} usdc
          </GradientText>
            </>
          }

        </div>
        {isNotWithdrawable() ? (<></>
          // <DisableButton>
          //   <span>withdraw</span>
          // </DisableButton>
        ) : (
          <Button onClick={openFeesModal}>
            <span>Withdraw</span>
          </Button>
        )}
      </div>
      <SecondSectionWrapper>
        <div className="menu-option">
          <MenuWrapper active={activeMenu === "repay" ? true : false}>
            <MenuText
              active={activeMenu === "repay" ? true : false}
              onClick={() => setActiveMenu("repay")}
            >
              Repay Amount
            </MenuText>
          </MenuWrapper>
            {poolStateText==="Funded" && <MenuWrapper active={activeMenu === "bonus" ? true : false}>
            <MenuText
              active={activeMenu === "bonus" ? true : false}
              onClick={() => setActiveMenu("bonus")}
            >
              Pay bonus
            </MenuText>
          </MenuWrapper>}
        </div>
        <div className="input-box-wrapper">
          {activeMenu === "bonus" && (
            <div className="flex flex-col">
            <div className="flex justify-between">
            <div className="relative w-8/12 mr-3">
                <Input
                  padding={"70px"}
                  width={"100%"}
                  type="text"
                  placeholder="Add Bonus Mint Address"
                  disabled={
                    poolState !== LENDING_PERIOD ||
                    pInfo.account.bonusAmount.toNumber() > 0
                  }
                  value={(pInfo.account.bonusTokenMint && !(pInfo.account.bonusTokenMint.toBase58() === EMPTY_ADDRESS)) ? pInfo.account.bonusTokenMint.toBase58() : mintAddress}
                  onChange={mintAddressChange}
                />
                <InputTag>{((pInfo.account.bonusTokenMint && !(pInfo.account.bonusTokenMint.toBase58() === EMPTY_ADDRESS)) ? pInfo.account.bonusTokenMint.toBase58() : mintAddress)
                    ? getSymbol((pInfo.account.bonusTokenMint && !(pInfo.account.bonusTokenMint.toBase58() === EMPTY_ADDRESS)) ? pInfo.account.bonusTokenMint.toBase58() : mintAddress, tokenMap):''}</InputTag>
                
              </div>
            <ListTokens
                disabled={poolState !== LENDING_PERIOD || pInfo.account.bonusAmount.toNumber() > 0}
                className="" selected={selectedToken}
                onChangeValue={handleChangeValue}/>
            </div>
            
              <TextGradient>
                Available Bonus Balance- {bonusRemain.toFixed(1)}{" "}
                {mintAddress ?getSymbol(mintAddress, tokenMap):''}
              </TextGradient>
            </div>
          )}

          {activeMenu === "bonus" ? (
            <Input
              type="text"
              disabled={poolState !== LENDING_PERIOD}
              placeholder="Enter Amount"
              value={bonus}
              onChange={amountChange}
            />
          ) : (
            <Input
              type="text"
              placeholder="Enter Amount"
              disabled={
                ( pInfo.account.isWithdrawed === 0 || ((poolState !== LENDING_PERIOD) && (poolState !== EXTRA_PERIOD)) ||
                  (equal_with_epsilon_error(pInfo.account.totalClaimedAmount.toNumber(), 0, decimalNumberUSDC) && equal_with_epsilon_error(pInfo.account.totalClaimedInterestAmount.toNumber(), 0, decimalNumberUSDC)) )
              }
              value={refundAmount}
              onChange={amountChange}
            />
          )}
        </div>
        {activeMenu === "repay" && (
          <div className={` liability-section`}>
            <text className="liability-text">Remaining Liability</text>
            <text className="liability-text">
            {new Intl.NumberFormat("en", {maximumFractionDigits: 6}).format(getRoundValue((pInfo.account.totalClaimedAmount.toNumber() + pInfo.account.totalClaimedInterestAmount.toNumber()) / decimalUSDC, decimalNumberUSDC))} USDC
            </text>
          </div>
        )}

        {activeMenu === "bonus" ? (
          poolState === LENDING_PERIOD ? (
            <ButtonWithEffect onClick={payBonusClicked}>
              <text className="btn-text">Pay</text>
            </ButtonWithEffect>
          ) : (
            <ButtonWithoutEffect>
              <text className="btn-text">Pay</text>
            </ButtonWithoutEffect>
          )
        ) : ( pInfo.account.isWithdrawed === 0 || ((poolState !== LENDING_PERIOD) && (poolState !== EXTRA_PERIOD)) ||
             (equal_with_epsilon_error(pInfo.account.totalClaimedAmount.toNumber(), 0, decimalNumberUSDC) && equal_with_epsilon_error(pInfo.account.totalClaimedInterestAmount.toNumber(), 0, decimalNumberUSDC)) ) ? (
          <ButtonWithoutEffect>
            <text className="btn-text">Repay Funds</text>
          </ButtonWithoutEffect>
        ) : (
          <ButtonWithEffect onClick={refundClick}>
            <text className="btn-text">Repay Funds</text>
          </ButtonWithEffect>
        )}
      </SecondSectionWrapper>
      <GlobalStyle />
    </Wrapper>
  );
};

export default BorrowersRight;

import { /* Dialog, */ Transition, Popover } from "@headlessui/react";
import { Fragment } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  getTokenNameFromAddress,
  shortAddress,
  truncateStr,
} from "../../utils/utils";
import toast from "react-hot-toast";
import { H4 } from "./styles";
import { BsInfoCircle } from "react-icons/bs";
import { MdOutlineDesktopWindows } from "react-icons/md";
// export default function BorrowerInfoModal({
//   isOpen,
//   openModal,
//   closeModal,
//   verifiedData,
// }) {
//   const CopyAction = () => {
//     toast.success("Copied Successfully!");
//   };
//   console.log({ verifiedData: verifiedData });
//   return (
//     <div>
//       <Transition appear show={isOpen} as={Fragment}>
//         <Dialog as="div" className="relative z-10" onClose={closeModal}>
//           <Transition.Child
//             as={Fragment}
//             enter="ease-out duration-300"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <div className="fixed inset-0 bg-black bg-opacity-25" />
//           </Transition.Child>

//           <div className="fixed inset-0 overflow-y-auto">
//             <div className="flex min-h-full items-center justify-center  text-center">
//               <Transition.Child
//                 as={Fragment}
//                 enter="ease-out duration-300"
//                 enterFrom="opacity-0 scale-95"
//                 enterTo="opacity-100 scale-100"
//                 leave="ease-in duration-200"
//                 leaveFrom="opacity-100 scale-100"
//                 leaveTo="opacity-0 scale-95"
//               >
//                 <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-[#28333F] p-6 text-left align-middle shadow-xl transition-all">
//                   {verifiedData && (
//                     <div className="flex flex-col gap-1">
//                       <div className="text-[#1184A6] text-xs font-semibold uppercase">
//                         borrower
//                       </div>
//                       <div className="flex gap-5">
//                         <span className="text-white uppercase text-xs font-bold">
//                           {`${verifiedData.solanaAddress ? shortAddress(verifiedData.solanaAddress) : "N/A"}`}
//                         </span>
//                         <span className="text-[#687D95] capitalize text-xs font-semibold">
//                           {`Name: ${verifiedData.name ? verifiedData.name : "N/A"}`}
//                         </span>
//                       </div>

//                       <div className="text-[#4D647B] text-xs font-medium">
//                         {`About: ${verifiedData.about ? verifiedData.about : "N/A"}`}
//                       </div>

//                       <div className="flex gap-2 mt-3 text-xs text-white">
//                         {verifiedData.email !== "" ? (
//                           <a
//                             href={"mailto:" + verifiedData.email}
//                             target="_blank"
//                             rel="noreferrer"
//                           >
//                             {" "}
//                             <HiOutlineMail className="text-xl inline outline-none" />
//                           </a>
//                         ) : null}

//                         {verifiedData.discordUrl !== "" ? (
//                           <a
//                             href={verifiedData.discordUrl}
//                             target="_blank"
//                             rel="noreferrer"
//                           >
//                             <img
//                               src={
//                                 "https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653370/akar-icons_discord-fill_gdrb55.svg"
//                               }
//                               alt="discord"
//                             />
//                           </a>
//                         ) : null}
//                         {verifiedData.twitterUrl !== "" ? (
//                           <a
//                             href={verifiedData.discordUrl}
//                             target="_blank"
//                             rel="noreferrer"
//                           >
//                             {" "}
//                             <img
//                               src={
//                                 "https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653499/akar-icons_twitter-fill_vkn6xc.svg"
//                               }
//                               alt="twitter"
//                             />{" "}
//                           </a>
//                         ) : null}
//                         {verifiedData.telegramUrl !== "" ? (
//                           <a
//                             href={verifiedData.telegramUrl}
//                             target="_blank"
//                             rel="noreferrer"
//                           >
//                             {" "}
//                             <img
//                               src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653480/akar-icons_twitter-fill_gaaszb.png"
//                               alt="telegram"
//                             />
//                           </a>
//                         ) : null}
//                       </div>

//                       <div className="flex flex-col mt-3">
//                         <div className="text-[#1184A6] text-xs font-semibold uppercase">
//                           Verified Ownership of
//                         </div>
//                         <div className="grid grid-cols-2 mt-2 gap-2">
//                           {verifiedData.addresses.length > 0
//                             ? verifiedData.addresses.map(
//                               (verified_address, index) => (
//                                 <div className="flex gap-2">
//                                   <span className="text-[#3D4F61] uppercase text-[11px] font-semibold">
//                                     eth
//                                   </span>
//                                   <span className="text-[#01C0FC] uppercase text-[11px] font-semibold">
//                                     {truncateStr(verified_address.address, 3)}
//                                   </span>
//                                   <CopyToClipboard
//                                     onCopy={CopyAction}
//                                     text={verified_address.address}
//                                   >
//                                     <BiCopy className="text-[#3D4F61] text-base" />
//                                   </CopyToClipboard>
//                                 </div>
//                               )
//                             )
//                             : ""}
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 </Dialog.Panel>
//               </Transition.Child>
//             </div>
//           </div>
//         </Dialog>
//       </Transition>
//     </div>
//   );
// }

export function BorrowerInfoModal({ pool, verifiedData }) {
  console.log(verifiedData);

  const CopyAction = () => {
    toast.success("Copied Successfully!");
  };

  const GetWebUrl = (url) => {
    let setUrl = "";
    if (url.startsWith("https")) setUrl = url;
    if (url.startsWith("https") && url.includes("www")) setUrl = url;
    if (url.startsWith("www") && !url.includes("https"))
      setUrl = `https://${verifiedData.website}`;
    else if (!url.startsWith("www") && !url.includes("https"))
      setUrl = `https://www.${verifiedData.website}`;

    return setUrl;
  };
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="flex">
            <H4 className="tracking-wider cursor-pointer hover:text-[#01FCFC] hover:border-b hover:border-[#01C0FC] max-h-[15px]">
              {pool ? pool.middleContent.statusContent.borrowers.name : "N/A"}
            </H4>
            <BsInfoCircle className="ml-1 mt-0.5 cursor-pointer text-xs text-[#01C0FC]" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              style={{ left: "270px", bottom: "15px" }}
              className="absolute z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 transform overflow-hidden rounded-2xl bg-[#28333F] p-6 text-left align-middle shadow-xl transition-all"
            >
              {verifiedData && (
                <div className="flex flex-col gap-1">
                  <div className="text-[#01C0FC] text-xs font-semibold uppercase">
                    borrower
                  </div>
                  <div
                    className="flex"
                    style={{ gap: verifiedData.solanaAddress ? "1.5rem" : "" }}
                  >
                    <span className="text-white uppercase text-xs font-bold">
                      {pool.middleContent.statusContent.borrowers.amount}
                    </span>
                    {verifiedData.name ? (
                      <>
                        <span className="text-[#687D95] capitalize text-xs font-semibold">
                          <text className="text-[#687D95]">Name: </text>
                          {verifiedData.name}
                        </span>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="text-[#687D95] text-xs font-medium">
                    {verifiedData.about ? (
                      <>
                        <text className="text-[#687D95]">About: </text>
                        <text className="text-[#687D95] whitespace-pre-line">
                          {verifiedData.about}
                        </text>
                      </>
                    ) : (
                      ""
                    )}
                    {verifiedData?.discordUrl || verifiedData?.telegramUrl ? (
                      <div className="text-[#687D95] text-xs font-medium">
                        Socials Added By
                      </div>
                    ) : (
                      ""
                    )}
                    {verifiedData?.discordUrl ? (
                      <div className="text-[#687D95] text-xs font-medium">
                        Discord User: {verifiedData.discordUrl}
                      </div>
                    ) : (
                      ""
                    )}

                    {verifiedData?.telegramUrl ? (
                      <div className="text-[#687D95] text-xs font-medium">
                        Telegram User: {verifiedData.telegramUrl}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="flex gap-2 mt-3 text-xs text-white">
                    {verifiedData.email !== undefined &&
                    verifiedData.email !== "" ? (
                      <a
                        href={"mailto:" + verifiedData.email}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <HiOutlineMail className="text-xl inline outline-none" />
                      </a>
                    ) : null}

                    {verifiedData.discordServer !== undefined &&
                    verifiedData.discordServer !== "" ? (
                      <a
                        href={verifiedData?.discordServer}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={
                            "https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653370/akar-icons_discord-fill_gdrb55.svg"
                          }
                          alt="discord"
                        />
                      </a>
                    ) : null}
                    {verifiedData.twitterUrl !== undefined &&
                    verifiedData.twitterUrl !== "" ? (
                      <a
                        href={`https://twitter.com/${verifiedData.twitterUrl}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <img
                          src={
                            "https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653499/akar-icons_twitter-fill_vkn6xc.svg"
                          }
                          alt="twitter"
                        />{" "}
                      </a>
                    ) : null}
                    {verifiedData.teleGroup !== undefined &&
                    verifiedData.teleGroup !== "" ? (
                      <a
                        href={verifiedData?.teleGroup}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <img
                          src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653480/akar-icons_twitter-fill_gaaszb.png"
                          alt="telegram"
                        />
                      </a>
                    ) : null}

                    {verifiedData.website !== undefined &&
                    verifiedData.website !== "" ? (
                      <a
                        href={GetWebUrl(verifiedData.website)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <MdOutlineDesktopWindows className="text-xl inline outline-none" />
                      </a>
                    ) : null}
                  </div>
                  {verifiedData.addresses.length > 0 ? (
                    <div className="flex flex-col mt-3">
                      <div className="text-[#01C0FC] text-xs font-semibold uppercase">
                        Verified Ownership of
                      </div>
                      <div className="grid grid-cols-2 mt-2 gap-2">
                        {verifiedData.addresses.map(
                          (verified_address, index) => (
                            <div className="flex gap-2">
                              <span className="text-white uppercase text-[14px] font-semibold">
                                {getTokenNameFromAddress(
                                  verified_address.address
                                )}
                              </span>
                              <span className="text-[#01C0FC] uppercase text-[14px] font-semibold">
                                {truncateStr(verified_address.address, 3)}
                              </span>
                              <CopyToClipboard
                                onCopy={CopyAction}
                                text={verified_address.address}
                              >
                                <BiCopy className="text-[#01C0FC] text-base cursor-pointer" />
                              </CopyToClipboard>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

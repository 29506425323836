import { useEffect, useState } from "react";
import { SearchImg } from "../../assets";
import FilterDropdown from "./FilterDropdown/FilterDropdown";
import SortDropdown from "./SortDropdown";

import {
  Container,
  FilterWrapper,
  SortWrapper,
  SearchWrapper,
  SearchIcon,
  Text,
  InputSearch,
  FilterContainer,
  FilterSortButtons,
  FilterDropdownContainer,
  SortDropdownContainer,
} from "./styles";

const FilterSearch = (props: any) => {
  const {filterState, setFilterState, sortState, setSortState, onSearchPool} = props

  return (
    <Container className="mt-10">
      <FilterContainer>
        <SearchWrapper>
          <InputSearch type="text" placeholder="Search..." onChange={(e) => onSearchPool(e.target.value)} />
          <SearchIcon src={SearchImg} alt="Search" />
        </SearchWrapper>
      </FilterContainer>

      <FilterSortButtons>
        <FilterWrapper>
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.75 0.75C0.75 0.551088 0.829018 0.360322 0.96967 0.21967C1.11032 0.0790175 1.30109 0 1.5 0H12.5C12.6989 0 12.8897 0.0790175 13.0303 0.21967C13.171 0.360322 13.25 0.551088 13.25 0.75C13.25 0.948912 13.171 1.13968 13.0303 1.28033C12.8897 1.42098 12.6989 1.5 12.5 1.5H1.5C1.30109 1.5 1.11032 1.42098 0.96967 1.28033C0.829018 1.13968 0.75 0.948912 0.75 0.75V0.75ZM2.75 4.75C2.75 4.55109 2.82902 4.36032 2.96967 4.21967C3.11032 4.07902 3.30109 4 3.5 4H10.5C10.6989 4 10.8897 4.07902 11.0303 4.21967C11.171 4.36032 11.25 4.55109 11.25 4.75C11.25 4.94891 11.171 5.13968 11.0303 5.28033C10.8897 5.42098 10.6989 5.5 10.5 5.5H3.5C3.30109 5.5 3.11032 5.42098 2.96967 5.28033C2.82902 5.13968 2.75 4.94891 2.75 4.75V4.75ZM4.75 8.75C4.75 8.55109 4.82902 8.36032 4.96967 8.21967C5.11032 8.07902 5.30109 8 5.5 8H8.5C8.69891 8 8.88968 8.07902 9.03033 8.21967C9.17098 8.36032 9.25 8.55109 9.25 8.75C9.25 8.94891 9.17098 9.13968 9.03033 9.28033C8.88968 9.42098 8.69891 9.5 8.5 9.5H5.5C5.30109 9.5 5.11032 9.42098 4.96967 9.28033C4.82902 9.13968 4.75 8.94891 4.75 8.75V8.75Z"
              fill="#4D647B"
            />
          </svg>

          <Text>Filter</Text>

          <FilterDropdownContainer>
            <FilterDropdown filterState={filterState} setFilterState={setFilterState}/>
          </FilterDropdownContainer>
        </FilterWrapper>

        <SortWrapper>
          <svg
            width="10"
            height="14"
            viewBox="0 0 10 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.57135 8.77996L4.99929 12.1223L1.42723 8.77996C1.27044 8.6336 1.05779 8.55137 0.836054 8.55137C0.614319 8.55137 0.401665 8.6336 0.244875 8.77996C0.0880841 8.92633 0 9.12484 0 9.33183C0 9.53882 0.0880841 9.73733 0.244875 9.8837L4.40811 13.7701C4.48552 13.8429 4.57761 13.9008 4.67907 13.9402C4.78054 13.9797 4.88937 14 4.99929 14C5.10921 14 5.21804 13.9797 5.31951 13.9402C5.42097 13.9008 5.51306 13.8429 5.59047 13.7701L9.7537 9.8837C9.83134 9.81123 9.89292 9.72519 9.93494 9.6305C9.97695 9.53581 9.99858 9.43432 9.99858 9.33183C9.99858 9.22934 9.97695 9.12785 9.93494 9.03316C9.89292 8.93847 9.83134 8.85244 9.7537 8.77996C9.67607 8.70749 9.5839 8.65 9.48247 8.61078C9.38103 8.57156 9.27232 8.55137 9.16253 8.55137C9.05273 8.55137 8.94402 8.57156 8.84258 8.61078C8.74115 8.65 8.64898 8.70749 8.57135 8.77996V8.77996ZM1.42723 5.22004L4.99929 1.87774L8.57135 5.22004C8.64875 5.29289 8.74084 5.35071 8.84231 5.39017C8.94377 5.42964 9.05261 5.44995 9.16253 5.44995C9.27244 5.44995 9.38128 5.42964 9.48274 5.39017C9.58421 5.35071 9.6763 5.29289 9.7537 5.22004C9.83175 5.14778 9.89369 5.06181 9.93596 4.96709C9.97824 4.87237 10 4.77078 10 4.66817C10 4.56556 9.97824 4.46396 9.93596 4.36925C9.89369 4.27453 9.83175 4.18856 9.7537 4.1163L5.59047 0.229917C5.51306 0.157064 5.42097 0.0992393 5.31951 0.059778C5.21804 0.0203167 5.10921 0 4.99929 0C4.88937 0 4.78054 0.0203167 4.67907 0.059778C4.57761 0.0992393 4.48552 0.157064 4.40811 0.229917L0.244875 4.1163C0.16724 4.18877 0.105657 4.27481 0.0636411 4.3695C0.0216254 4.46419 0 4.56568 0 4.66817C0 4.87516 0.0880841 5.07367 0.244875 5.22004C0.401665 5.3664 0.614319 5.44863 0.836054 5.44863C1.05779 5.44863 1.27044 5.3664 1.42723 5.22004V5.22004Z"
              fill="#4D647B"
            />
          </svg>
          <Text>Sort</Text>

          <SortDropdownContainer>
            <SortDropdown sortState={sortState} setSortState={setSortState}/>
          </SortDropdownContainer>
        </SortWrapper>
      </FilterSortButtons>
    </Container>
  );
};

export default FilterSearch;

import {
  useConnection,
  useWallet,
  WalletContextState,
} from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import {
  checkProposalChange,
  createProposal,
  getAllProposals,
  getDaoGlobalState,
  getDaoGovernorList,
  getProposal,
} from "../../utils/instructions";
import {
  IDaoGlobalState,
  IDaoGovernorList,
  IGlobalState,
  IProposal,
} from "../../utils/interfaces";
import { InputTag } from "../BorrowersPool/styles";
import { actionsData } from "./actionsData";
import { ListActions } from "./listActions";
import { ProposalDetail } from "./ProposalDetail";
import {
  TableHeaderText,
  TableWarpper,
  SectionHeader,
  TableText,
  EnableButton,
  DisableButton,
  Input,
  Button,
  TableDetailWarpper,
} from "./styles";
import toast from "react-hot-toast";
import {
  CHANGE_APPROVED_ADDRESS,
  CHANGE_APPROVED_REQUEST_FEE,
  CHANGE_GAS_FEE,
  CHANGE_GOVERNOR_ADDRESS,
  CHANGE_MIN_VRZR,
  CHANGE_PERMANENT_GOVERNOR,
  CHANGE_UNAPPROVED_REQUEST_FEE,
  decimalSB,
  DELAY_TIME,
  CHANGE_EXPENSE_ACCOUNT,
  NEED_MORE_VRZR,
  NEED_PERMANENT_GOVERNOR,
} from "../../utils/constants";
import { PublicKey } from "@solana/web3.js";
import { BN } from "@project-serum/anchor";
import { EMPTY_ADDRESS, SB_MINT_ADDRESS } from "../../utils/ids";
import { delay, getCurrentContent, getCurrentEpoch } from "../../utils/utils";
import { getMintDecimalValue } from "../../utils/walletManager";
import { getGlobalStateFromDB } from "../../be-calls/be-calls";

let regexp = /^\d*\.?\d*$/;

type ProposalProps = {
  proposals: IProposal[];
  globalState: IDaoGlobalState;
  governorList: IDaoGovernorList;
  wallet: WalletContextState;
};

const ProposalTable = ({
  proposals,
  globalState,
  governorList,
  wallet,
}: ProposalProps) => {
  const [selectedAction, setSelectedAction] = useState(actionsData[0]);
  const [firstValue, setFirstValue] = useState<any>();
  const [secondValue, setSecondValue] = useState<string>("");
  const [proposalList, setProposalList] = useState(proposals);
  const [gState, setGState] = useState(globalState);
  const [gList, setGList] = useState(governorList);
  const [mtlGlobalState, setMtlGlobalState] = useState<IGlobalState>();
  const [feeDecimalValue, setFeeDecimalValue] = useState(decimalSB);
  const { connection } = useConnection();

  /*
  const getFeeDecimalValue = async () => {
    const decValue = await getMintDecimalValue(new PublicKey(SB_MINT_ADDRESS));
    console.log("feeDecimalValue = ", decValue);
    setFeeDecimalValue(decValue);
  }  
  */
  useEffect(() => {
    if (wallet.connected) {
      getDaoGlobalState(connection, wallet)
        .then((daoGlobalState: any) => {
          setGState(daoGlobalState);
        })
        .then(() => {
          getDaoGovernorList(connection, wallet).then(
            (daoGovernorList: any) => {
              setGList(daoGovernorList);
            }
          );
        })
        .then(() => {
          getGlobalStateFromDB().then((info: any) => {
            if (info.status === "RECEIVED") {
              let mGlobalState = info.data as IGlobalState;
              setMtlGlobalState(mGlobalState);

              const { firstValue: fValue, secondValue: sValue } =
                getCurrentContent(
                  mGlobalState,
                  gState,
                  gList,
                  selectedAction.actionFlag,
                  0
                );
              setFirstValue(fValue);
              setSecondValue(sValue);
            }
          });
        });
    }
  }, [wallet, connection]);

  const handleChangeValue = (data: any) => {
    setSelectedAction(data);

    /*
    if (mtlGlobalState) {
      const {firstValue: fValue, secondValue: sValue} = getCurrentContent(mtlGlobalState, gState, gList, data.actionFlag, 0);
      setFirstValue(fValue);
      setSecondValue(sValue);
    } 
    */
    getDaoGlobalState(connection, wallet)
      .then((daoGlobalState: any) => {
        setGState(daoGlobalState);
      })
      .then(() => {
        getDaoGovernorList(connection, wallet).then((daoGovernorList: any) => {
          setGList(daoGovernorList);
        });
      })
      .then(() => {
        getGlobalStateFromDB().then((info: any) => {
          if (info.status === "RECEIVED") {
            let mGlobalState = info.data as IGlobalState;
            setMtlGlobalState(mGlobalState);

            const { firstValue: fValue, secondValue: sValue } =
              getCurrentContent(
                mGlobalState,
                gState,
                gList,
                data.actionFlag,
                0
              );
            setFirstValue(fValue);
            setSecondValue(sValue);
          }
        });
      });
  };

  const firstValueInput = (event: any) => {
    let value: any = "";
    if(!selectedAction.firstParam.toLowerCase().includes("address"))  {
      value = Math.floor(Number(event.target.value))
    } else {
      value = event.target.value;
    }
    setFirstValue(value);
    if(selectedAction?.valid) {
      if (value > selectedAction?.valid?.max) {
        setFirstValue(selectedAction?.valid?.max);
      }
    }

    if (
      (selectedAction.actionFlag == CHANGE_APPROVED_ADDRESS ||
        selectedAction.actionFlag == CHANGE_PERMANENT_GOVERNOR) &&
      mtlGlobalState
    ) {
      let id: number;

      try {
        id = value
      } catch (error) {
        id = 0;
      }

      //const {firstValue: fValue, secondValue: sValue} = getCurrentContent(mtlGlobalState, gState, gList, selectedAction.actionFlag, id);
      //setFirstValue(fValue);
      //setSecondValue(sValue);

      getDaoGlobalState(connection, wallet)
        .then((daoGlobalState: any) => {
          setGState(daoGlobalState);
        })
        .then(() => {
          getDaoGovernorList(connection, wallet).then(
            (daoGovernorList: any) => {
              setGList(daoGovernorList);
            }
          );
        })
        .then(() => {
          getGlobalStateFromDB().then((info: any) => {
            if (info.status === "RECEIVED") {
              let mGlobalState = info.data as IGlobalState;
              setMtlGlobalState(mGlobalState);

              const { firstValue: fValue, secondValue: sValue } =
                getCurrentContent(
                  mGlobalState,
                  gState,
                  gList,
                  selectedAction.actionFlag,
                  id
                );
              setSecondValue(sValue);
            }
          });
        });
    }
  };

  const proposeClicked = async () => {
    let actionFlag: number = selectedAction.actionFlag;
    let id: number;
    let address: PublicKey;
    let value: BN;
    let currentEpoch: number;

    currentEpoch = getCurrentEpoch(gState);
    //console.log("currentEpoch = ", currentEpoch);

    const state = await checkProposalChange(
      connection,
      wallet,
      actionFlag,
      gList,
      wallet.publicKey!,
      gState.minimunVlndrStaking.toNumber(),
      currentEpoch
    );

    // console.log("First value = ", firstValue, "Second value = ", secondValue);

    if (state == NEED_PERMANENT_GOVERNOR) {
      toast("Require the permanent governor to create this proposal");
    } else if (state == NEED_MORE_VRZR) {
      toast("Need to stake more vRZR");
    } else {
      if (
        actionFlag == CHANGE_GOVERNOR_ADDRESS ||
        actionFlag == CHANGE_EXPENSE_ACCOUNT
      ) {
        id = 0;
        address = new PublicKey(firstValue);
        value = new BN(0);
      } else if (
        actionFlag == CHANGE_APPROVED_ADDRESS ||
        actionFlag == CHANGE_PERMANENT_GOVERNOR
      ) {
        try {
          id = parseInt(firstValue);
        } catch (error) {
          id = 0;
        }
        address = new PublicKey(secondValue);
        value = new BN(0);
      } else {
        id = 0;
        address = new PublicKey(EMPTY_ADDRESS);

        //console.log("proposeClicked feeDecimalValue = ", feeDecimalValue);

        if (
          actionFlag == CHANGE_APPROVED_REQUEST_FEE ||
          actionFlag == CHANGE_UNAPPROVED_REQUEST_FEE ||
          actionFlag == CHANGE_GAS_FEE ||
          actionFlag == CHANGE_MIN_VRZR
        )
          value = new BN(parseInt(firstValue) * feeDecimalValue);
        else value = new BN(firstValue);
      }

      toast("Start creating proposal");
      let info = await createProposal(
        connection,
        wallet,
        actionFlag,
        id,
        address,
        value,
        currentEpoch
      );

      if (info.status === "OK") {
        await delay(DELAY_TIME);
        // Update new proposals

        const proposal = await getProposal(connection, wallet, info.publicKey);
        setProposalList((prevState) => [...prevState, proposal]);

        toast("Complete creating proposal");
      } else toast("Creating proposal tx is failed");
    }
  };

  return (
    <TableWarpper>
      <table className="table-class w-full text-sm text-left text-gray-500 dark:text-gray-400 [border-spacing:0.75rem] input-section">
        <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
          <tr className="text-white">
            <th scope="col" colSpan={7} className="py-3">
              <SectionHeader borderColor="#106064">
                Propose a change
              </SectionHeader>
            </th>
          </tr>
        </thead>
        <div className="input-wrapper">
          <div className="first-half">
            <ListActions
              className="mt-2"
              selected={selectedAction}
              disabled={false}
              onChangeValue={handleChangeValue}
            />
          </div>
          <div className="second-half">
            <div className="combined-section">
              <text className="label">{selectedAction.firstParam}</text>
              <Input
                width={"290px"}
                type={selectedAction.firstParam.toLowerCase().includes("address") ?"text": "number"}
                value={firstValue}
                placeholder={`Enter ${selectedAction.firstParam}`}
                onInput={firstValueInput}
              />
            </div>
            {selectedAction.secondParam ? (
              <div className="combined-section">
                <text
                  className="label"
                  style={{
                    visibility: selectedAction.secondParam
                      ? "visible"
                      : "hidden",
                  }}
                >
                  {selectedAction.secondParam
                    ? selectedAction.secondParam
                    : "Address"}
                </text>
                <Input
                  width={"290px"}
                  type={selectedAction.secondParam.toLowerCase().includes("address") ?"text": "number"}
                  placeholder={`Enter ${selectedAction.secondParam}`}
                  value={secondValue}
                  onInput={(event: any) => {
                    setSecondValue(event.target.value);
                  }}
                />
              </div>
            ) : (
              <> </>
            )}
            <div className="combined-section">
              {selectedAction?.note ? (
                <text className="label">{selectedAction.note}</text>
              ) : (
                <text className="label" style={{ visibility: "hidden" }}>
                  address
                </text>
              )}

              <Button onClick={proposeClicked}>PROPOSE</Button>
            </div>
          </div>
        </div>
      </table>
      <TableDetailWarpper>
        <div className="table-warpper">
          <div className="table-two">
            <div className="table-card">
              <div className="header-div">
                <text className="table-header-main">PROPOSAL DETAILS</text>
              </div>

              <div className="line-break" />
              <div className="">
                <table className="w-full ">
                  <thead className="bg-gray-[#1A232B]">
                    <th scope="col" className="py-3 px-2">
                      <text className="table-header">ADDRESS</text>
                    </th>
                    <th scope="col" className="py-3 px-2">
                      <text className="table-header">CONTENT</text>
                    </th>
                    <th scope="col" className="py-3 px-2">
                      <text className="table-header">DATE</text>
                    </th>
                    <th scope="col" className="py-3 px-2">
                      <text className="table-header">ACCEPTANCE RATE</text>
                    </th>
                    <th scope="col" className="py-3 px-2">
                      <text className="table-header">STATUS</text>
                    </th>
                    <th scope="col" className="py-3 px-2">
                      <text className="table-header">Action</text>
                    </th>
                  </thead>
                  <tbody>
                    {proposalList.map((proposal: IProposal) => (
                      <ProposalDetail
                        proposal={proposal}
                        globalState={gState}
                        governorList={gList}
                        wallet={wallet}
                        setGlobalState={setGState}
                        setGovernorList={setGList}
                        setMtlGlobalState={setMtlGlobalState}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </TableDetailWarpper>
    </TableWarpper>
  );
};
export default ProposalTable;

import * as anchor from "@project-serum/anchor";
import { AccountLayout, TOKEN_PROGRAM_ID, u64 } from "@solana/spl-token";
import { TokenInfo } from "@solana/spl-token-registry";
import { Connection, PublicKey } from "@solana/web3.js";
import { IGovernorList } from "../pages/DAO/data";
import { getCreditScore } from "../be-calls/be-calls";
import {
  ACTIVE_PROPOSAL,
  AWAITING_FOR_SUBSCRIPTION,
  CANCELLED_PROPOSAL,
  CHANGE_APPROVED_ADDRESS,
  CHANGE_APPROVED_LIMIT,
  CHANGE_APPROVED_REQUEST_FEE,
  CHANGE_APPROVED_WITHDRAW_FEE,
  CHANGE_CLAIM_FEE,
  CHANGE_COMMIT_FEE,
  CHANGE_EXTRA_PERIOD,
  CHANGE_FEE_VAULT_BURNED_RATE,
  CHANGE_GAS_FEE,
  CHANGE_GOVERNOR_ADDRESS,
  CHANGE_MIN_VRZR,
  CHANGE_PERMANENT_GOVERNOR,
  CHANGE_UNAPPROVED_REQUEST_FEE,
  CHANGE_UNAPPROVED_WITHDRAW_FEE,
  CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VRZR,
  COUPON_RATE_SCALE,
  decimalNumberUSDC,
  decimalSB,
  decimalUSDC,
  EXTRA_PERIOD,
  EXTRA_PERIOD_BLOCK,
  FAILED_PROPOSAL,
  FINISHED_POOL,
  INVALID_POOL,
  LENDING_EXPIRED,
  LENDING_PERIOD,
  CHANGE_EXPENSE_ACCOUNT,
  NETWORK,
  PROPOSAL_DISPLAY,
  SUBSCRIPTION_PERIOD,
  SUCCEED_PROPOSAL,
  TIME_UNIT_DAY,
  TIME_UNIT_HOUR,
  TIME_UNIT_HOUR_TEXT,
  VRZR_STAKES_TAG,
  NETWORK_RPC,
  DEV_WSS_NETWORK,
} from "./constants";
import { SB_MINT_ADDRESS, USDC_MINT_ADDRESS } from "./ids";
import { IDaoGlobalState, IDaoGovernorList, IGlobalState, ILender, IPoolInfo, IProposal, TokenAccount } from "./interfaces";

export const getPoolState = (
  poolInfo: IPoolInfo,
  extraPeriod: number,
  decimalBalance: number
) => {
  // 0: awaiting for subscription started
  // 1: subscription period
  // 2: invalid(subscription time expires, not reach min amount at least)
  // 3: lending period
  // 4: lending time expired
  // 5: extra period
  // 6: pool finished
  const currentTime = Math.round(new Date().getTime() / 1000);

  const totalLocked = poolInfo.account.totalLockedAmount.toNumber();
  const totalUnstake = poolInfo.account.totalUnstakedAmount.toNumber();

  const subscriptionStart = poolInfo.account.subscriptionStartTime;
  const subscriptionPeriod = poolInfo.account.subscriptionPeriod;
  const lendingStartTime =
    poolInfo.account.lendingStartedTime === 0 &&
    currentTime >= subscriptionStart + subscriptionPeriod
      ? subscriptionStart + subscriptionPeriod
      : poolInfo.account.lendingStartedTime;
  const lendingPeriod = poolInfo.account.lendingPeriod;
  const minAmount = poolInfo.account.min.toNumber();
  const goalAmount = poolInfo.account.goal.toNumber();

  let tmp_pool_state = poolInfo.account.state;

  switch (poolInfo.account.state) {
    case AWAITING_FOR_SUBSCRIPTION:
      if (subscriptionStart < currentTime) {
        if (currentTime < subscriptionStart + subscriptionPeriod) {
          tmp_pool_state = SUBSCRIPTION_PERIOD;
        } else {
          tmp_pool_state = FINISHED_POOL;
        }
      }
      break;
    case SUBSCRIPTION_PERIOD:
      if (totalLocked >= goalAmount) {
        tmp_pool_state = LENDING_PERIOD;
      } else if (currentTime > subscriptionStart + subscriptionPeriod) {
        if (totalLocked < minAmount) {
          tmp_pool_state = INVALID_POOL;
        } else {
          tmp_pool_state = LENDING_PERIOD;
        }
      }
      break;
    case INVALID_POOL:
      if (currentTime > lendingStartTime + lendingPeriod) {
        tmp_pool_state = LENDING_EXPIRED;
      } else {
        tmp_pool_state = INVALID_POOL;
      }
      break;
    case LENDING_PERIOD:
      if (
        currentTime >
        lendingStartTime + lendingPeriod + extraPeriod * EXTRA_PERIOD_BLOCK
      ) {
        tmp_pool_state = LENDING_EXPIRED;
      } else if (currentTime > lendingStartTime + lendingPeriod) {
        if (
          getRoundValue(poolInfo.account.totalClaimedAmount.toNumber() / decimalBalance, defaultDecimal) === 0
        ) {
          tmp_pool_state = LENDING_EXPIRED; // to lending expired
        } else {
          tmp_pool_state = EXTRA_PERIOD; // extra period
        }
      }
      break;
    case EXTRA_PERIOD:
      if (
        currentTime >
        lendingStartTime + lendingPeriod + extraPeriod * EXTRA_PERIOD_BLOCK
      ) {
        tmp_pool_state = LENDING_EXPIRED;
      } else if (poolInfo.account.totalClaimedAmount.toNumber() === 0) {
        tmp_pool_state = LENDING_EXPIRED;
      }
      break;
    case LENDING_EXPIRED:
      if ( /*(poolInfo.cancelledState > 0) ||*/ (equal_with_epsilon_error(totalUnstake, totalLocked, decimalNumberUSDC)) )
        tmp_pool_state = FINISHED_POOL;
      break;
    default:
      break;
  }

  let poolStateText = getPoolStateText(tmp_pool_state, poolInfo);
  //let poolStateText = "Subscription Period";

  return { poolState: tmp_pool_state, poolStateText: poolStateText };
};

export const getPoolStateText = (state: number, poolInfo: IPoolInfo) => {
  let poolStateText = "Subscription Period";

  //Todo cancel by borrower
  if ((poolInfo.cancelledState > 0) && (state != FINISHED_POOL)) {
    return "Cancelled";
  } else {
    if (state == AWAITING_FOR_SUBSCRIPTION)
      return "Pre-Subscription";
    else if (state == SUBSCRIPTION_PERIOD)
      return "Active Subscription";
    else if (state == INVALID_POOL)
      return "Unfunded";
    else if (state ==  LENDING_PERIOD)  
      return "Funded";
    else if (state ==  EXTRA_PERIOD)  
      return "Grace Period";
    else if (state == LENDING_EXPIRED) {
      if (poolInfo.account.totalLockedAmount.toNumber() < poolInfo.account.min.toNumber()) 
        return "Unfunded";
      else if (/*poolInfo.account.isWithdrawed == 1 &&*/ equal_with_epsilon_error(poolInfo.account.totalClaimedAmount.toNumber(), 0, decimalNumberUSDC) && equal_with_epsilon_error(poolInfo.account.totalClaimedInterestAmount.toNumber(), 0, decimalNumberUSDC)) 
        return "Successful";
      else 
        return "Defaulted";
    } else if (state == FINISHED_POOL) {
      if (poolInfo.cancelledState > 0) 
        return "Finished Cancelled";
      else if (poolInfo.account.totalLockedAmount.toNumber() < poolInfo.account.min.toNumber()) 
        return "Finished Unfunded";  
      else if (/*poolInfo.account.isWithdrawed == 1 &&*/ equal_with_epsilon_error(poolInfo.account.totalClaimedAmount.toNumber(), 0, decimalNumberUSDC) && equal_with_epsilon_error(poolInfo.account.totalClaimedInterestAmount.toNumber(), 0, decimalNumberUSDC)) 
        return "Finished Successful";
      else 
        return "Finished Defaulted";
    }  
  }
  /*
  switch (state) {
    case AWAITING_FOR_SUBSCRIPTION:
      poolStateText = "Pre-Subscription";
      break;
    case SUBSCRIPTION_PERIOD:
      poolStateText = "Active Subscription";
      break;
    case INVALID_POOL:
      poolStateText = "Unfunded";
      break;
    case LENDING_PERIOD:
      poolStateText = "Funded";
      break;
    case EXTRA_PERIOD:
      poolStateText = "Grace Period";
      break; 
    case LENDING_EXPIRED:
      poolStateText = "Pool Ended";
      break;
    case FINISHED_POOL:
      poolStateText = "Pool Finished";
      break;
  }
  */
  return poolStateText;
};

export const equal_with_epsilon_error = (a: number, b: number, exp: number) => { 
  let delta: number;

  if (a > b) {
      delta = a - b;    
  } else {
      delta = b - a;
  }
  
  let epsilon_error = (exp < precise.length) ? precise[exp] : 10 ** exp;
 

  if (delta < epsilon_error) {
      return true;
  } else { 
      return false;
  }
}     

export const getBorrowingCouponState = (
  poolInfo: IPoolInfo,
  extraPeriod: number,
  decimalBalance: number
) => {
  let currentTime = Math.round(new Date().getTime() / 1000);
  currentTime =
    currentTime >
    poolInfo.account.lendingStartedTime + poolInfo.account.lendingPeriod
      ? poolInfo.account.lendingStartedTime + poolInfo.account.lendingPeriod
      : currentTime;
  const totalLocked = poolInfo.account.totalLockedAmount.toNumber();

  const interestPeriod = poolInfo.account.lendingPeriod;

  if (
    getPoolState(poolInfo, extraPeriod, decimalBalance).poolState <
    LENDING_PERIOD
  )
    return undefined;

  const total_deposit =
    (totalLocked *
      poolInfo.account.couponRate *
      poolInfo.account.lendingPeriod) /
    (365 * TIME_UNIT_DAY * 100 * COUPON_RATE_SCALE);

  const totalInterest = getRoundValue(
    (totalLocked *
      poolInfo.account.couponRate *
      poolInfo.account.lendingPeriod) /
      (365 * TIME_UNIT_DAY * 100 * COUPON_RATE_SCALE) /
      decimalBalance, defaultDecimal
  );

  return {
    remainingTime: convSecToTime(
      interestPeriod - (currentTime - poolInfo.account.lendingStartedTime)
    ),
    notPaidInterest: Math.min(
      (totalLocked * poolInfo.account.couponRate) / (100 * COUPON_RATE_SCALE),
      0
    ),
    couponPeriod:
      poolInfo.account.lendingPeriod / TIME_UNIT_HOUR +
      " " +
      TIME_UNIT_HOUR_TEXT,
    couponInterest: totalInterest,

    requiredTotalDeposit: total_deposit,
    availableCommitment:
      poolInfo.account.goal.toNumber() -
      poolInfo.account.totalLockedAmount.toNumber(),
  };
};

export const getUSDCAmountFromPoolToken = (
  poolInfo: IPoolInfo,
  poolTokenAmount: number): number => {

  const lendingPeriod = poolInfo.account.lendingPeriod;  

  const scale = 3153600000 * COUPON_RATE_SCALE;

  let scaled_conversion_rate = poolInfo.account.couponRate * lendingPeriod;
  let usdcAmount = poolTokenAmount * scale / (scale + scaled_conversion_rate);

  //console.log("poolTokenAmount = ", poolTokenAmount," usdcAmount = ", usdcAmount," scaled_conversion_rate = ", scaled_conversion_rate);
  
  return usdcAmount;
}

export const getTotalInterest = (
  lendingPeriod: number,
  couponRate: number,
  userCommited: number,
) => {
  return (userCommited * lendingPeriod * couponRate) / (365 * TIME_UNIT_DAY * 100 * COUPON_RATE_SCALE);
}
export const getUnclaimedInterest = (
  poolInfo: IPoolInfo,
  userCommited: number,
  userInfo: ILender
) => {
  const lendingStartTime = poolInfo.account.lendingStartedTime;

  if (lendingStartTime === 0) {
  //console.log("getUnclaimedInterest 1 unclaimedInterest = 0");
    return 0;
  }  
  if (poolInfo.account.isWithdrawed == 1) {
  //  console.log("getUnclaimedInterest 2 pooltoken = ", poolInfo.account.mintPoolToken.toBase58(), " userConvertedUSDCAmount = ", userCommited, " unclaimedInterest = ", Math.max(Math.floor(
  //    getTotalInterest(poolInfo.account.lendingPeriod, poolInfo.account.couponRate, userCommited) - userInfo.account.claimedInterest.toNumber()
  //  ), 0));

    return Math.max(Math.floor(
      getTotalInterest(poolInfo.account.lendingPeriod, poolInfo.account.couponRate, userCommited) - userInfo.account.claimedInterest.toNumber()
    ), 0);
  } else {
    //console.log("getUnclaimedInterest 3 unclaimedInterest = 0");
    return 0;
  }
};

export const getUnclaimedInterestByNone = (
  poolInfo: IPoolInfo,
  userCommited: number
) => {
  const lendingStartTime = poolInfo.account.lendingStartedTime;

  if (lendingStartTime === 0) return 0;

  if (poolInfo.account.isWithdrawed == 1)
    return Math.max(Math.floor(getTotalInterest(poolInfo.account.lendingPeriod, poolInfo.account.couponRate, userCommited)), 0);
  else
    return 0;
};

export const getLendingCouponState = (
  poolInfo: IPoolInfo,
  userInfo: ILender | undefined,
  extraPeriod: number,
  decimalBalance: number
) => {
  if (!userInfo) return undefined;

  let currentTime = Math.round(new Date().getTime() / 1000);
  currentTime =
    currentTime >
    poolInfo.account.lendingStartedTime + poolInfo.account.lendingPeriod
      ? poolInfo.account.lendingStartedTime + poolInfo.account.lendingPeriod
      : currentTime;

  if (
    getPoolState(poolInfo, extraPeriod, decimalBalance).poolState <
    LENDING_PERIOD
  )
    return undefined;
  const totalInterest = getRoundValue(
    (userInfo.account.lockedBalance.toNumber() *
      poolInfo.account.lendingPeriod *
      poolInfo.account.couponRate) /
      (365 * TIME_UNIT_DAY * 100 * COUPON_RATE_SCALE) /
      decimalBalance, defaultDecimal
  );

  return {
    remainingTime: convSecToTime(
      poolInfo.account.lendingPeriod -
        (currentTime - poolInfo.account.lendingStartedTime)
    ),
    notPaidInterest: Math.floor(
      totalInterest - userInfo.account.claimedInterest.toNumber()
    ),
    couponPeriod: poolInfo.account.lendingPeriod / TIME_UNIT_HOUR + " Hours",
    couponInterest: totalInterest,
    requiredTotalDeposit: 0,
    availableCommitment:
      poolInfo.account.goal.toNumber() -
      poolInfo.account.totalLockedAmount.toNumber(),
  };
};
export const truncateStr = (str: string, n: number) => {
  if (!str) return "";
  return str.length > n
    ? str.substr(0, n - 1) + "..." + str.substr(str.length - n, str.length - 1)
    : str;
};

const convSecToTime = (sec: number) => {
  let H, M, S;
  const day = Math.round((sec - (sec % TIME_UNIT_DAY)) / TIME_UNIT_DAY);
  sec = sec % TIME_UNIT_DAY;
  const hh = Math.round((sec - (sec % TIME_UNIT_HOUR)) / TIME_UNIT_HOUR);
  sec = sec % TIME_UNIT_HOUR;
  const mm = Math.round((sec - (sec % 60)) / 60);
  const ss = sec % 60;

  H = hh;
  if (hh < 9) H = "0" + hh;
  M = mm;
  if (mm < 9) M = "0" + mm;
  S = ss;
  if (ss < 9) S = "0" + ss;

  if (day === 0) return H + ":" + M + ":" + S;
  else return day + " day " + H + ":" + M + ":" + S;
};

export const getGMTOnlyDateFromSec = (sec: number) => {
  const date = new Date(sec);
  const date1 = new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: "UTC",
    })
  );
  return (
    date1.getFullYear() +
    "-" +
    (date1.getMonth() + 1 < 10
      ? "0" + (date1.getMonth() + 1)
      : date1.getMonth() + 1) +
    "-" +
    (date1.getDate() < 9 ? "0" + (date1.getDate() + 1) : date1.getDate() + 1)
  );
};

export const getDateFromSec = (sec: number) => {
  if (sec < 1100000000) return "N/A";

  const date = new Date(sec * 1000);
  const date1 = new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: "UTC",
    })
  );
  return (
    date1.getFullYear() +
    "-" +
    isOneNumber(date1.getMonth() + 1) +
    "-" + isOneNumber(date1.getDate())
  );
};

const isOneNumber = (value: number) => {
  return value > 9 ? value : "0" + value;
};

export const getTimeFromSecNone = (sec: number) => {
  if (sec < 1100000000) return "N/A";

  const date = new Date(sec * 1000);
  const date1 = new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: "UTC",
    })
  );
  return (
    isOneNumber(date1.getHours()) +
    ":" +
    isOneNumber(date1.getMinutes()) +
    ":" +
    isOneNumber(date1.getSeconds())
  );
};

export const getTimeFromSec = (sec: number) => {
  if (sec < 1100000000) return "N/A";

  const date = new Date(sec * 1000);
  const date1 = new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: "UTC",
    })
  );
  return (
    date1.getHours() +
    ":" +
    date1.getMinutes() +
    ":" +
    date1.getSeconds() +
    " GMT"
  );
};

export const getBNfromUint8Array = (
  arr: Uint8Array,
  start: number,
  count: number
) => {
  let buffer = Buffer.from(arr);
  return buffer.readUIntBE(start, count);
};

export const shortAddress = (address: string | undefined) => {
  if (!address) return "";
  return (
    address.substring(0, 4) + "..." + address.substring(address.length - 4)
  );
};

export const getAllAccounts = async (walletAddress: string) => {
  const connection = new Connection(NETWORK, {wsEndpoint: DEV_WSS_NETWORK});

  const accounts = await connection.getTokenAccountsByOwner(
    new PublicKey(walletAddress),
    {
      programId: TOKEN_PROGRAM_ID,
    }
  );

  return accounts.value.map((info) => {
    const data = deserializeAccount(info.account.data);
    const details = {
      pubkey: info.pubkey,
      account: { ...info.account },
      info: data,
    } as TokenAccount;
    return details;
  });
};

export const deserializeAccount = (data: Buffer) => {
  if (!AccountLayout.decode) return;

  const accountInfo = AccountLayout.decode(data);
  accountInfo.mint = new PublicKey(accountInfo.mint);
  accountInfo.owner = new PublicKey(accountInfo.owner);
  accountInfo.amount = u64.fromBuffer(accountInfo.amount);

  if (accountInfo.delegateOption === 0) {
    accountInfo.delegate = null;
    accountInfo.delegateAmount = new u64(0);
  } else {
    accountInfo.delegate = new PublicKey(accountInfo.delegate);
    accountInfo.delegatedAmount = u64.fromBuffer(accountInfo.delegatedAmount);
  }
  accountInfo.isInitialized = accountInfo.state !== 0;
  accountInfo.isFrozen = accountInfo.state === 2;

  if (accountInfo.isNativeOption === 1) {
    accountInfo.rentExemptReserve = u64.fromBuffer(accountInfo.isNative);
    accountInfo.isNative = true;
  } else {
    accountInfo.rentExemptReserve = null;
    accountInfo.isNative = false;
  }

  if (accountInfo.closeAuthorityOption === 0) {
    accountInfo.closeAuthority = null;
  } else {
    accountInfo.closeAuthority = new PublicKey(accountInfo.closeAuthority);
  }

  return accountInfo;
};

//max 9 decimals
const precise: number[] = [1, 10, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000, 1000000000]
export const defaultDecimal = 3;
export const getRoundValue = (value: number, decimal: number) => {
  let ps = (decimal < precise.length) ? precise[decimal] : 10 ** decimal;
  return Math.floor(value * ps) / ps; 
};

export function delay(timeout: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}

const decimals: any = {};

export function getDecimal(pubKey: string) {
  //console.log("decimals = ", decimals);

  if (decimals[pubKey]) return decimals[pubKey];
  else return undefined;
}

export function setDecimal(pubKey: string, decimal: number) {
  return (decimals[pubKey] = decimal);
}

export const getSymbol = (pk : string, tokenMap: Map<string, TokenInfo>) => {
  if (pk === SB_MINT_ADDRESS)
    return "RZR";
  else if (pk === USDC_MINT_ADDRESS)
    return "USDC";
  else if (tokenMap.get(pk)) 
    return tokenMap.get(pk)?.symbol;
  else 
    return "";  
}

export const secondsToTime = (secs:number) =>{
  let days = Math.floor(secs / (24*60 * 60));

  let secsNew = secs - days * (24*60*60);
  let hours = Math.floor(secsNew / (60 * 60));

  let divisor_for_minutes = secsNew % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    "d": days,
    "h": twoDigit(hours),
    "m": twoDigit(minutes),
    "s": twoDigit(seconds)
  };
  return obj;
}
export const twoDigit = (myNumber: number)=> {
  return ("0" + myNumber).slice(-2);
};

export const formatDateTimeInDayAndHours = (timeSubscription: number): string => {
  let ret = "";
  const timeConverted = secondsToTime(timeSubscription);
  ret = `${timeConverted.d}D:${timeConverted.h}H:${timeConverted.m}M`;
  //console.log({formatDateTimeInDayAndHours: {
  //    newData: ret,
  //    oldData: formatDateTimeInDayAndHoursOld(timeSubscription)
  //  }});
  return ret;
}

export const formatDateTimeInDayAndHoursOld = (timeSubscription: number): string => {
  let ret = "";
  const tmpHours:number = timeSubscription ? timeSubscription / TIME_UNIT_HOUR : 0;
  const days = tmpHours ? Math.floor((tmpHours / 24)) : 0;
  const hours = tmpHours ? (tmpHours % 24) : 0;
  ret = `${days} ${(days > 0) ? "days" : "day"} ${hours.toFixed(5)} ${(hours > 0) ? "hours" : "hour"}`;
  return ret;
}

export const getTokenNameFromAddress = (address: string): string => {
  let symbool = "";
  symbool = address.toLowerCase().startsWith("0x") ? "ETH" : "SOL";
  return symbool;
}

export const getProposalContent = (type: number, actionFlag: number, id: number, address: string, value: number) => {
  let action: string = "";
  let firstParam: string = ""; // id or value
  let secondParam: string = ""; // address
  
  switch (actionFlag) {
    case CHANGE_APPROVED_ADDRESS: 
      action = "Change approved borrower";
      break;

    case CHANGE_APPROVED_LIMIT: 
      action = "Change limit pool count";
      break;

    case CHANGE_APPROVED_REQUEST_FEE: 
      action = "Change request fee for approved borrowers";
      break;

    case CHANGE_APPROVED_WITHDRAW_FEE: 
      action = "Change withdrawal fee for approved borrowers";
      break;

    case CHANGE_UNAPPROVED_REQUEST_FEE: 
      action = "Change request fee for unapproved borrowers";
      break;

    case CHANGE_UNAPPROVED_WITHDRAW_FEE: 
      action = "Change withdrawal fee for unapproved borrowers";
      break;

    case CHANGE_COMMIT_FEE: 
      action = "Change commit fee";
      break;
    
    case CHANGE_CLAIM_FEE: 
      action = "Change claim fee";
      break;

    case CHANGE_GAS_FEE: 
      action = "Change gas fee";
      break;
    
    case CHANGE_EXTRA_PERIOD: 
      action = "Change extra period";
      break;

    case CHANGE_GOVERNOR_ADDRESS: 
      action = "Change governor address";
      break;

    case CHANGE_FEE_VAULT_BURNED_RATE: 
      action = "Change fee vault burned rate";
      break;  

    case CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VRZR: 
      action = "Change USDC distribution rate";
      break;
      
    case CHANGE_EXPENSE_ACCOUNT: 
      action = "Change expense account";
      break;

    case CHANGE_MIN_VRZR: 
      action = "Change min vRZR";
      break;

    case CHANGE_PERMANENT_GOVERNOR: 
      action = "Change permanent governor";
      break;
      
    default:
      break;
  }  

  if ((actionFlag == CHANGE_GOVERNOR_ADDRESS) || (actionFlag == CHANGE_EXPENSE_ACCOUNT)) {
    firstParam = "New address";
    if (type == PROPOSAL_DISPLAY) 
      firstParam = firstParam + " " + address; 
  } 
  else if ((actionFlag == CHANGE_APPROVED_ADDRESS) || (actionFlag == CHANGE_PERMANENT_GOVERNOR)) {
    firstParam = "Position";
    secondParam = "New address";
    if (type == PROPOSAL_DISPLAY) { 
      firstParam = firstParam + " " + id; 
      secondParam = secondParam + " " + address;  
    }   
  } else {
    firstParam = "New value";
    if (type == PROPOSAL_DISPLAY) {   
      if ( (actionFlag == CHANGE_APPROVED_REQUEST_FEE) || 
             (actionFlag == CHANGE_UNAPPROVED_REQUEST_FEE) ||
             (actionFlag == CHANGE_GAS_FEE) ||
             (actionFlag == CHANGE_MIN_VRZR) )  
          value = getRoundValue(value / decimalSB, defaultDecimal);

      firstParam = firstParam + " " + value;
    }     
  }  

  return {action: action, firstParam: firstParam, secondParam: secondParam};
}

export const getCurrentContent = (globalState: IGlobalState, daoGlobalState: IDaoGlobalState, governorList: IGovernorList, actionFlag: number, id: number) => {
  let firstValue: string = ""; // id or value
  let secondValue: string = ""; // address

  switch (actionFlag) {
    case CHANGE_APPROVED_ADDRESS: 
      firstValue = id.toString();
      
      if (id < globalState.approvedList.length)
        secondValue = globalState.approvedList[id].toBase58();
      break;

    case CHANGE_APPROVED_LIMIT: 
      firstValue = globalState.limitPoolCntPerBorrower.toString();
      break;

    case CHANGE_APPROVED_REQUEST_FEE: 
      firstValue = getRoundValue(globalState.requestFeeApproved.toNumber() / decimalSB, defaultDecimal).toString();
      break;

    case CHANGE_APPROVED_WITHDRAW_FEE: 
      firstValue = globalState.withdrawFeeApproved.toNumber().toString();
      break;

    case CHANGE_UNAPPROVED_REQUEST_FEE: 
      firstValue = getRoundValue(globalState.requestFeeUnapproved.toNumber() / decimalSB, defaultDecimal).toString();
      break;

    case CHANGE_UNAPPROVED_WITHDRAW_FEE: 
      firstValue = globalState.withdrawFeeApproved.toNumber().toString();
      break;

    case CHANGE_COMMIT_FEE: 
      firstValue = globalState.commitFee.toNumber().toString();
      break;
    
    case CHANGE_CLAIM_FEE: 
      firstValue = globalState.claimFee.toNumber().toString();
      break;

    case CHANGE_GAS_FEE: 
      firstValue = getRoundValue(globalState.gasFee.toNumber() / decimalSB, defaultDecimal).toString();
      break;
    
    case CHANGE_EXTRA_PERIOD: 
      firstValue = globalState.extraPeriod.toString();
      break;

    case CHANGE_GOVERNOR_ADDRESS: 
      firstValue = globalState.governor.toBase58();
      break;

    case CHANGE_FEE_VAULT_BURNED_RATE: //TODO: Add burned rate to metalend 
      firstValue = "";
      break;  

    case CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VRZR: 
      firstValue = daoGlobalState.treasuryRevenueDistribution.toString();
      break;
      
    case CHANGE_EXPENSE_ACCOUNT: // TODO: Need to update expense address to mtl contract
      firstValue = daoGlobalState.expenseAccount.toBase58();
      break;

    case CHANGE_MIN_VRZR: 
      firstValue = getRoundValue(daoGlobalState.minimunVlndrStaking.toNumber() / decimalSB, defaultDecimal).toString();
      break;

    case CHANGE_PERMANENT_GOVERNOR:      
      firstValue = id.toString();
      
      if (id < governorList.permanentGovernors.length)
        secondValue = governorList.permanentGovernors[id].toBase58();
      break;
      
    default:
      break;
  }  

  return {firstValue: firstValue, secondValue: secondValue};    
}

export const getCurrentEpoch = (globalState: IDaoGlobalState) => {
  let currentTime = Date.now() / 1000; // Convert from ms to second
  let currentEpoch = Math.floor((currentTime - globalState.genesisTime.toNumber()) / (globalState.epochDuration.toNumber() + globalState.graceDuration.toNumber() + globalState.preGraceDuration.toNumber()));
  
  return currentEpoch;
}

export const getProposalStatus = (
  globalState: IDaoGlobalState,
  proposal: IProposal
) => {
  let status = proposal.account.status;
  let epoch = proposal.account.epoch;

  let currentEpoch = getCurrentEpoch(globalState);
  //console.log("getProposalStatus 1 status = ", status, " currentEpoch = ", currentEpoch, " epoch = ", epoch);

  if ((status == ACTIVE_PROPOSAL) && (currentEpoch != epoch)) {
    let minVotes: number;
    let numerator: number;
    let denominator: number; 

    if (proposal.account.actionFlag == CHANGE_EXPENSE_ACCOUNT) {
      numerator = proposal.account.permanentGovNum * 2;
      denominator = 3;
    }
    else {
      numerator = (proposal.account.permanentGovNum + proposal.account.normalGovNum) * globalState.minimumProposalCriteria;
      denominator = 100;
    }

    minVotes = Math.floor(numerator / denominator);
    if (numerator % denominator  != 0) {
      minVotes += 1; 
    }

    if (proposal.account.votedList.length >= minVotes) 
      status = SUCCEED_PROPOSAL;   
    else 
      status = FAILED_PROPOSAL; 
  }
          
  //console.log("getProposalStatus 2 status = ", status);
  return status;
}

export const getProposalStatusText = (status: number) => {
  let statusText: string = "";  
  
  switch (status) {
    case ACTIVE_PROPOSAL:
      statusText = "Active proposal";
      break;
    
    case SUCCEED_PROPOSAL:
      statusText = "Succeed proposal";
      break;
      
    case FAILED_PROPOSAL:
      statusText = "Failed proposal";
      break;
    
    case CANCELLED_PROPOSAL:
      statusText = "Cancelled proposal";
      break;

    default:
      break;      
  }

  return statusText;
} 

export const getCreditScoreByPool = async (pools: IPoolInfo[]) => {
  const res = await getCreditScore()
  
  const newPools = pools.map(el => {
    el.credit = res?.data?.find((credit: any) => credit?.walletAddress === el.account.owner.toBase58())
    return el
  })
  return newPools
}

export const getSolScanUrl = (address: string) => {
  return NETWORK_RPC === "devnet"
  ? `https://solscan.io/token/${address}?cluster=devnet`
  : `https://solscan.io/account/${address}`
}

export const toBuffer = (nonce: number) => {
  let buf = Buffer.alloc(8);
  let view = new DataView(buf.buffer);
  view.setBigInt64(0, BigInt(nonce), true); // litle endian

  console.log("toBuffer = ", buf);

  return buf;
}
import { useEffect, useState } from "react";
import { InputTag } from "../BorrowersPool/styles";
import {
  ButtonWithEffect,
  HeaderText,
  Input,
  TextArea,
} from "../BorrowersPool/BorrowersRight/styles";
import {
  CardWrapper,
  ButtonWithoutEffect,
  Button,
  FundedButton,
  NewCardWrapper,
  VerifyButton,
} from "./styles";
import { CgProfile } from "react-icons/cg";
import { FaTwitter, FaTelegram, FaDiscord } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  getVerifiedData,
  getMessage,
  update,
  verifySocialInfo,
  BASE_URL,
} from "../../be-calls/be-calls";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { shortAddress } from "../../utils/utils";
import useForceUpdate from "use-force-update";
import { chains, DropDown, TOKEN_NAME_ID } from "./dropdown";
import { MdOutlineDesktopWindows } from "react-icons/md";
import { convertLastAddress } from "../../dex/dex";
import { PublicKey } from "@solana/web3.js";
import { Tooltip } from "../StatusPool/tooltip";
import RedirectModal from "../XMarginVerification/redirect-modal";
import { Input as RsuiteInput, InputGroup, Whisper } from "rsuite";
import { useSearchParams } from "react-router-dom";
import TelegramLoginButton, { TelegramUser } from "telegram-login-button";
import VerifyMailModal from "./VerifyModal";
import { FaUserAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
import { AiFillPlusCircle } from "react-icons/ai";

var isFocus: boolean[] = [];
var addresses: any[] = [];
var salt = 0;
export const SOCIAL_TYPE = {
  discord: 1,
  telegram: 2,
  email: 3,
};
const Selfverification = () => {
  const forceUpdate = useForceUpdate();
  const [numberOfInputBox, setNumberOfInputBoxt] = useState(0);
  let [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);
  const closeRedirectModal = () => {
    setIsRedirectModalOpen(false);
  };

  const openRedirectModal = () => {
    setIsRedirectModalOpen(true);
  };

  const [userData, setUserData] = useState<any>();
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [telegramUrl, setTelegramUrl] = useState("");
  const [discordUrl, setDiscordUrl] = useState("");
  const [telegramGrUrl, setTelegramGrUrl] = useState("");
  const [discordServerUrl, setDiscordServerUrl] = useState("");
  const [email, setEmail] = useState("");
  const [copyEmail, setCopyEmail] = useState("");
  const [about, setAbout] = useState("");
  const [mailChanged, setMailChanged] = useState(false);
  const [mailVeried, setMailVeried] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const wallet = useWallet();
  const { signMessage } = useWallet();

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const getUserData = async (publicKey: string) => {
    let user_data = await getVerifiedData(publicKey);

    if (user_data && user_data.data.message === "SUCCESS") {
      setUserData(user_data.data.user);
      setName(user_data.data.user.name);
      setWebsite(user_data.data.user.website);
      setTwitterUrl(user_data.data.user.twitterUrl);
      setFacebookUrl(user_data.data.user.facebookUrl);
      setTelegramUrl(user_data.data.user.telegramUrl);
      setDiscordUrl(user_data.data.user.discordUrl);
      setTelegramGrUrl(user_data.data.user.teleGroup);
      setDiscordServerUrl(user_data.data.user.discordServer);
      setEmail(user_data.data.user.email);
      setCopyEmail(user_data.data.user.email);
      setAbout(user_data.data.user.about);
      salt = user_data.data.user.salt;
      if (user_data.data.user.addresses) {
        setNumberOfInputBoxt(user_data.data.user.addresses.length);
        addresses = user_data.data.user.addresses;
        //console.log(addresses);
        let length = addresses.length;
        for (var i = 0; i < length; i++) {
          isFocus[i] = true; //to show all data
        }
      }
      if (user_data.data.user.email) {
        setMailChanged(true);
      }
    } else {
      //create new account
      let msg = await getMessage(publicKey);
    }
  };
  const onDataSubmit = async () => {
    if (twitterUrl) {
      if (!isValidTwiterId(twitterUrl)) {
        toast.error("Please enter your valid Twiter username");
        return;
      }
    }
    if (website) {
      if (!isValidWebsite(website)) {
        toast.error("Please enter your valid URL");
        return;
      }
    }
    if (email) {
      if (!isValidEmail(email)) {
        toast.error("Please enter your valid email");
        return;
      }
    }

    if (telegramGrUrl) {
      if (!isValidTelegramId(telegramGrUrl)) {
        toast.error("Please enter your valid telegram group");
        return;
      }
    }

    if (discordServerUrl) {
      if (!isValidDiscordId(discordServerUrl)) {
        toast.error("Please enter your valid discord server");
        return;
      }
    }
    if (email !== userData?.email && !mailVeried) {
      toast.error("Verify your email");
      return;
    }

    // if (name === "") {
    //   toast.error("Please enter your name");
    //   return;
    // }

    let length = addresses.length;
    let isValidated = true;
    let add_tocheck = [];
    for (let i = 0; i < length; i++) {
      console.log(addresses[i].chainData);
      //Check ETH address and signature
      if (addresses[i].chainData.id === TOKEN_NAME_ID.ETH) {
        const isValid = ethers.utils.isAddress(addresses[i].address.toString());
        if (!isValid || addresses[i].address === "") {
          toast.error(
            shortAddress(addresses[i].address) + " is not a valid ETH address"
          );
          isValidated = false;
        }
      }
      //Check SOL address and signature
      if (addresses[i].chainData.id === TOKEN_NAME_ID.SOL) {
        let isValid = false;
        try {
          isValid = PublicKey.isOnCurve(addresses[i].address);
        } catch (e) {
          console.log(e);
        }
        if (!isValid || addresses[i].address === "") {
          toast.error(
            shortAddress(addresses[i].address) + " is not a valid SOL address"
          );
          isValidated = false;
        }
      }

      if (addresses[i].signature === "") {
        toast.error(
          shortAddress(addresses[i].address) + " need to be verified"
        );
        isValidated = false;
      }
      add_tocheck[i] = addresses[i];
    }

    //check duplication address
    add_tocheck.sort((a, b) => a.address.localeCompare(b.address)); // b - a for reverse sort
    if (add_tocheck.length > 0) {
      for (let i = 0; i < length - 1; i++) {
        if (add_tocheck[i].address === add_tocheck[i + 1].address) {
          toast.error("Duplicate Address");
          return;
        }
      }
    }

    if (!isValidated) return;

    if (wallet.connected === true && wallet.publicKey) {
      toast("Please sign the message to submit data");
      const message =
        wallet.publicKey && (await getMessage(wallet.publicKey.toBase58()));
      const messageUint8 = new TextEncoder().encode(message?.data?.message);
      const signed: any = signMessage && (await signMessage(messageUint8));
      let signature = Buffer.from(signed).toString("hex");
      if (signature) {
        let res = await update(
          wallet.publicKey.toBase58(),
          signature,
          twitterUrl,
          facebookUrl,
          discordUrl,
          telegramUrl,
          name,
          website,
          email,
          about,
          "test",
          addresses,
          telegramGrUrl,
          discordServerUrl
        );
        if (res.status !== "SUCCESS") {
          toast("Data submitted for change");
          // openRedirectModal();
        } else toast.error(res.data.message);
      }
    } else {
      toast.error("Please connect to Solana Network");
    }
  };
  const addMoreAddress = async () => {
    addresses.push({ address: "", signature: "", chainData: chains[0] });
    isFocus.push(false);
    setNumberOfInputBoxt(numberOfInputBox + 1);
  };

  const removeAddress = async (index: number) => {
    addresses.splice(index, 1);
    isFocus.splice(index, 1);
    let length = addresses.length;
    for (var i = 0; i < length; i++) {
      isFocus[i] = true; //to show all data
    }
    setNumberOfInputBoxt(numberOfInputBox - 1);
  };
  const onFocusInput = async (event: any, index: number) => {
    isFocus[index] = true;
  };

  const onChangeAddress = async (event: any, index: number) => {
    isFocus[index] = false;
    addresses[index].address = event.target.value.toString();
  };

  const onVerifyETH = async (index: number) => {
    try {
      if (addresses[index].signature) {
        toast(shortAddress(addresses[index].address) + " is verified");
        return;
      }
      if (addresses[index].address === "") {
        toast.error("Address cant be empty");
        return;
      }
      if (window.ethereum === null) {
        toast.error("Ethererum Wallet is not available");
        return;
      }
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let signer = await provider.getSigner();

      if (signer === undefined || window.ethereum.selectedAddress === null) {
        await window.ethereum.request({method: 'eth_requestAccounts'});
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = await provider.getSigner();
      }
      if (
        window.ethereum.selectedAddress.toLowerCase() !==
        addresses[index].address.toLowerCase()
      ) {
        toast.error("Signer Address not match or Wallet not connected");
        return;
      }
      const ethereumSignarue = await provider
        .getSigner()
        .signMessage(`${salt} ${addresses[index].address.toLowerCase()}`);
      //console.log(ethereumSignarue);
      if (ethereumSignarue) {
        addresses[index].signature = ethereumSignarue;
        toast(shortAddress(addresses[index].address) + " is signed. Please submit your signature and address.");
        forceUpdate();
      } else {
        toast.error(
          shortAddress(addresses[index].address) + " is NOT verified"
        );
      }
    } catch (e) {
      toast.error("Ethererum Wallet is locked or not available");
    }
  };
  const onVerifySOL = async (index: number) => {
    try {
      if (addresses[index].signature) {
        toast(shortAddress(addresses[index].address) + " is verified");
        return;
      }
      if (addresses[index].address === "") {
        toast.error("Address cant be empty");
        return;
      }

      // Get Signature
      const message =
        wallet.publicKey && (await getMessage(wallet.publicKey.toBase58()));
      const messageUint8 = new TextEncoder().encode(message?.data?.message);
      const signed: any = signMessage && (await signMessage(messageUint8));
      let solSignature = Buffer.from(signed).toString("hex");

      // let solSignature = null;
      if (solSignature) {
        addresses[index].signature = solSignature;
        toast(shortAddress(addresses[index].address) + " signed. Please submit your signature and address.");
        forceUpdate();
      } else {
        toast.error(
          shortAddress(addresses[index].address) + " is NOT verified"
        );
      }
    } catch (e) {
      toast.error("Solana Wallet is locked or not available");
    }
  };
  const onVerifyOther = async (index: number) => {
    try {
      toast.error(`This chain is not supported!`);
    } catch (e) {
      toast.error("Other Wallet is locked or not available");
    }
  };

  const onVerify = async (index: number, indexOfListVerifyAddress: any) => {
    try {
      switch (addresses[index].chainData.id) {
        case TOKEN_NAME_ID.SOL:
          await onVerifySOL(index);
          break;
        case TOKEN_NAME_ID.ETH:
          await onVerifyETH(index);
          break;
        default:
          await onVerifyOther(index);
          break;
      }
    } catch (e) {
      toast.error("Verify address is not available");
    }
  };

  const isValidEmail = (email: string) => {
    // /\S+@\S+\.\S+/
    // const regex = new RegExp(`[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$`);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(email);
  };

  const isValidWebsite = (url: string) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(url);
  };

  const isValidTelegramId = (accountId: string) => {
    const regex = new RegExp(
      "(https?://)?(www[.])?(telegram|t).me/([a-zA-Z0-9_-]*)/?$"
    );
    return regex.test(accountId);
  };
  const isValidDiscordId = (accountId: string) => {
    const regex = new RegExp(
      "(?:https?://)?discord(?:(?:app)?.com/invite|.gg)/?[a-zA-Z0-9]+/?"
    );
    return regex.test(accountId);
  };
  const isValidTwiterId = (accountId: string) => {
    const regex = new RegExp("(?=.*\\w)[\\w]{1,15}$");
    // const regex = new RegExp('^@(?=.*\\w)[\\w]{1,15}$');
    return regex.test(accountId);
  };

  const onChangeEmail = async (event: any, index: number) => {
    const emailValue = event.target.value.toString();
    setEmail(emailValue);
  };
  const onSelectedChain = async (data: any) => {
    if (data && data.indexAddress !== null) {
      addresses[data.indexAddress].chainData = data;
      console.log(addresses[data.indexAddress].chainData);
    }
  };

  const verifySocial = (type: number) => {
    if (checkVerifyTw()) return;
    window.open(process.env.REACT_APP_DISCORD_REDIRECT_LINK, "_self");
  };

  const checkVerifyTw = () => {
    if (!twitterUrl) {
      toast.error("You need to verify Twitter account first");
      return true;
    }
  };

  useEffect(() => {
    if (wallet.connected === true && wallet.publicKey) {
      getUserData(wallet.publicKey.toBase58());

      const verify = async (address: string) => {
        const code = searchParams.get("code");
        if (code) {
          const data = await verifySocialInfo(
            { code },
            SOCIAL_TYPE.discord,
            address
          );
          console.log(data);
          getUserData(address);
          setSearchParams(searchParams.delete("code") as any);
        }
      };
      verify(wallet.publicKey.toBase58());
    }
  }, [wallet.connected, wallet.publicKey]);

  const handleTelegramResponse = (user: TelegramUser) => {
    console.log(user);
  };

  const teleLogin = () => {
    if (checkVerifyTw()) return;
    if (wallet.connected) {
      (window as any).Telegram.Login.auth(
        { bot_id: "5772202020", request_access: true },
        async (data: any) => {
          if (!data) {
            // authorization failed
            return;
          }
          const dataTele = await verifySocialInfo(
            data,
            SOCIAL_TYPE.telegram,
            wallet.publicKey?.toBase58()
          );
          if (wallet.publicKey) {
            getUserData(wallet.publicKey.toBase58());
          }
          // Here you would want to validate data like described there https://core.telegram.org/widgets/login#checking-authorization
        }
      );
    }
  };

  const twLogin = async () => {
    if (!twitterUrl) {
      window.open(
        `${BASE_URL}/twitter?address=${wallet.publicKey?.toBase58()}`,
        "_self"
      );
    }
  };

  const mailAuthen = () => {
    if (!isValidEmail(email)) {
      toast.error("Please enter your valid email");
      return;
    }
    openModal();
  };

  const handleEmailChange = (value: any) => {
    if (value !== copyEmail) setMailChanged(false);
    setEmail(value);
  };

  return (
    <div className="flex flex-col justify-center mx-auto my-0">
      <RedirectModal
        closeModal={closeRedirectModal}
        openModal={openRedirectModal}
        isOpen={isRedirectModalOpen}
      />
      <NewCardWrapper>
        <text className="section-heading">Self verification</text>
        <text className="sub-header">
          Verify your credentials in order to have a better score
        </text>

        <div className="personal-info gap-5">
          <text className="section-title">Personal Information</text>
          <div className="each-section flex flex-col">
            <text className="each-secton-label">Full Name</text>
            <InputGroup inside>
              <InputGroup.Addon>
                <FaUserAlt className="text-[#4D647B]" />
              </InputGroup.Addon>
              <RsuiteInput
                placeholder="Enter Name"
                value={name}
                onChange={(event: any) => setName(event.toString())}
                type="text"
              />
            </InputGroup>
          </div>

          <div className="each-section flex flex-col">
            <text className="each-secton-label">Email</text>
            <InputGroup inside>
              <InputGroup.Addon>
                <Tooltip message={"name@subdomain.domain"}>
                  <IoMdMail className="text-[#4D647B]" />
                </Tooltip>
              </InputGroup.Addon>
              <RsuiteInput
                type="text"
                placeholder="Enter Email"
                value={email}
                onChange={(event: any) => handleEmailChange(event.toString())}
              />
              <InputGroup.Addon>
                {wallet?.connected && !mailChanged && (
                  <VerifyButton onClick={mailAuthen} style={{marginTop:'-3px'}}>
                    {" "}
                    <span>Verify</span>
                  </VerifyButton>
                )}
                {wallet?.connected && mailChanged && (
                  <IoCheckmarkCircle className="text-[#8EDA87] text-xl  self-cente -mt-1" />
                )}
              </InputGroup.Addon>
            </InputGroup>
          </div>

          <div className="each-section flex flex-col">
            <text className="each-secton-label">Website</text>
            <InputGroup inside>
              <InputGroup.Addon>
                <Tooltip message={"https://subdomain.domain/"}>
                  <img
                    className="cursor-pointer"
                    src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1665490072/Vector_4_flqbuy.svg"
                    alt="..."
                  />
                </Tooltip>
              </InputGroup.Addon>
              <RsuiteInput
                type="text"
                placeholder="Enter your website name"
                onChange={(event: any) => setWebsite(event.toString())}
                value={website}
              />
            </InputGroup>
          </div>

          <div className="each-section flex flex-col">
            <text className="each-secton-label">About yourself</text>
            <TextArea
              width={"100%"}
              placeholder="Write a short bio"
              onChange={(event: any) => setAbout(event.target.value.toString())}
              value={about}
            />
          </div>
        </div>

        <div className="personal-info gap-5">
          <text className="section-title">Social Media</text>
          <text className="section-desc">
            To verify all social media chanels, you must first verify your
            Twitter account. Add by clicking verify buttons.
          </text>
          <div className="each-section-social flex justify-between">
            <div className="first-section flex gap-2">
              <FaTwitter
                className={`${
                  twitterUrl ? "text-[#ABBDD1] text-[14px]" : "text-[#4D647B] text-[14px]"
                } text-lg self-center`}
              />
              <text
                className={`${
                  twitterUrl ? "text-[#ABBDD1] text-[14px]" : "text-[#3d4f61] text-[12px] uppercase"
                } social-address self-center`}
              >
                {twitterUrl ? twitterUrl : "Twitter"}
              </text>
            </div>
            <div className="flex">
              {wallet?.connected && !twitterUrl && (
                <VerifyButton className="self-center" onClick={twLogin}>
                  {" "}
                  <span>Verify</span>
                </VerifyButton>
              )}
              {wallet?.connected && twitterUrl && (
                <IoCheckmarkCircle className="text-[#8EDA87] text-xl  self-cente -mt-1" />
              )}
            </div>
          </div>

          {!discordUrl && (
            <div className="each-section-social flex justify-between">
              <div className="first-section flex gap-2">
                <FaDiscord className={`text-[#4D647B] text-lg self-center`} />
                <text className={` text-[#3d4f61] social-address self-center text-[12px] uppercase`}>
                  Discord
                </text>
              </div>
              <div className="flex">
                {wallet?.connected && twitterUrl && (
                  <VerifyButton
                    className="self-center"
                    onClick={() => verifySocial(SOCIAL_TYPE.discord)}
                  >
                    {" "}
                    <span>Verify</span>
                  </VerifyButton>
                )}
              </div>
            </div>
          )}

          {twitterUrl && wallet?.connected && discordUrl && (
            <div className="social-expand flex flex-col justify-between">
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <FaDiscord    className={`${
                  discordUrl ? "text-[#ABBDD1]" : "text-[#4D647B] "
                } text-lg self-center`} />
                  <text className="user-name">{discordUrl}</text>
                </div>
                <IoCheckmarkCircle className="text-[#8EDA87] text-xl  self-cente -mt-1" />
              </div>
              <div className="flex flex-col">
                <text className="grp-header">your discord server link</text>
                <InputGroup inside>
                  <RsuiteInput
                    type="text"
                    placeholder="Enter link"
                    value={discordServerUrl}
                    onChange={(event: any) =>
                      setDiscordServerUrl(event.toString())
                    }
                  />
                </InputGroup>
              </div>
            </div>
          )}

          {!telegramUrl && (
            <div className="each-section-social flex justify-between">
              <div className="first-section flex gap-2">
                <FaTelegram className={`text-[#4D647B] text-lg self-center`} />
                <text className={` text-[#3d4f61] social-address self-center text-[12px] uppercase`}>
                  Telegram
                </text>
              </div>
              <div className="flex">
                {wallet?.connected && twitterUrl && (
                  <VerifyButton className="self-center" onClick={teleLogin}>
                    {" "}
                    <span>Verify</span>
                  </VerifyButton>
                )}
                  <TelegramLoginButton
                    className="tele-btn-login"
                    botName="metalend_verify_bot"
                    dataOnauth={handleTelegramResponse}
                  />
              </div>
            </div>
          )}

          {twitterUrl && wallet?.connected && telegramUrl && (
            <div className="social-expand flex flex-col justify-between">
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <FaTelegram className={`${
                  telegramUrl ? "text-[#ABBDD1]" : "text-[#4D647B] "
                } text-lg self-center`} />
                  <text className="user-name">{telegramUrl}</text>
                </div>
                <IoCheckmarkCircle className="text-[#8EDA87] text-xl  self-center -mt-1" />
              </div>
              <div className="flex flex-col">
                <text className="grp-header">YOUR TELEGRAM GROUP LINK</text>
                <InputGroup inside>
                  <RsuiteInput
                    type="text"
                    placeholder="Enter link"
                    value={telegramGrUrl}
                    onChange={(event: any) =>
                      setTelegramGrUrl(event.toString())
                    }
                  />
                </InputGroup>
              </div>
            </div>
          )}
        </div>

        <div className="personal-info gap-5">
          <text className="section-title">Verify Other Address Ownership</text>

          {Array(numberOfInputBox)
            .fill(1)
            .map((el, i) => (
              <div className="flex " key={i}>
                <div className="">
                  <HeaderText className="mb-3">Select Chain</HeaderText>
                  <DropDown
                    className="mt-1"
                    onChangeValue={onSelectedChain}
                    indexAddress={i}
                    address={isFocus[i] ? addresses[i]?.address : null}
                    selected={
                      isFocus[i]
                        ? chains.find(
                            (x) => x.id === addresses[i]?.chainData?.id
                          )
                        : chains[0]
                    }
                  />
                </div>
                <div className="grow px-3">
                  <HeaderText className="mb-3">Enter Address</HeaderText>
                  {/* <div className="flex w-full ">
                          <div className="relative grow">
                            <Input
                              key={i}
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder="Enter Address"
                              onBlur={(event: any) => onChangeAddress(event, i)}
                              onFocus={(event: any) => onFocusInput(event, i)}
                              value={isFocus[i] ? addresses[i]?.address : null}
                            />
                            <InputTag
                              style={{ top: "4px", backgroundColor: "#1a232b" }}
                            >
                              <ButtonWithoutEffect
                                onClick={() => onVerify(i, el)}
                              >
                                <text className="btn-text">
                                  {addresses[i]?.signature
                                    ? "Verified"
                                    : "Verify"}
                                </text>
                              </ButtonWithoutEffect>
                            </InputTag>
                          </div>

                          {
                            <AiFillDelete
                              className="text-xl text-white mt-2 cursor-pointer"
                              onClick={() => removeAddress(i)}
                            />
                          }
                        </div> */}

                  <InputGroup inside>
                    <RsuiteInput
                      key={i}
                      type="text"
                      placeholder="Enter Address"
                      onBlur={(event: any) => onChangeAddress(event, i)}
                      onFocus={(event: any) => onFocusInput(event, i)}
                      value={isFocus[i] ? addresses[i]?.address : null}
                    />
                    <InputGroup.Addon>
                      {addresses[i]?.signature ? (
                        <IoCheckmarkCircle className="text-[#8EDA87] text-xl -mt-1" />
                      ) : (
                        <VerifyButton
                          className="z-10"
                          onClick={() => onVerify(i, el)}
                          style={{marginTop:'-3px'}}
                        >
                          {" "}
                          <span>Verify</span>
                        </VerifyButton>
                      )}

                      <AiFillDelete
                        onClick={() => removeAddress(i)}
                        className="text-xl text-[#4D647B] cursor-pointer ml-2 -mt-1"
                      />
                    </InputGroup.Addon>
                  </InputGroup>
                </div>
              </div>
            ))}

          <text className="add-chain-btn" onClick={() => addMoreAddress()}>
            <AiFillPlusCircle className="text-[#01C0FC] inline" /> add new
            blockchain address
          </text>
        </div>

        <FundedButton onClick={onDataSubmit}>
          <span>Submit</span>
        </FundedButton>
      </NewCardWrapper>
      {/* <CardWrapper className="w-full">
        <div className="main-container flex flex-col relative w-full h-full overflow-hidden text-left rounded-lg style1">
          <div className="flex justify-center px-6 py-3">
            <span className="label-text text-shadow">Self Verification</span>
          </div>
          <div className="style59">
            <div className="overflow-y-scroll mr-1 min-h-[12rem] px-5 style61">
              <div className="flex">
                <div className="grow px-3">
                  <HeaderText className="mb-3">Name</HeaderText>
                  <div className="relative">
                    <Input
                      padding={"70px"}
                      width={"100%"}
                      type="text"
                      placeholder="Enter Name"
                      onChange={(event: any) =>
                        setName(event.target.value.toString())
                      }
                      value={name}
                    />
                    <InputTag>
                      {" "}
                      <CgProfile className="text-xl" />
                    </InputTag>
                  </div>
                </div>
                <div className="grow px-3">
                  <HeaderText className="mb-3">Website Name</HeaderText>
                  <div className="relative">
                    <Input
                      padding={"70px"}
                      width={"100%"}
                      type="text"
                      placeholder="Enter your website name"
                      onChange={(event: any) =>
                        setWebsite(event.target.value.toString())
                      }
                      value={website}
                    />
                    <InputTag>
                      {" "}
                      <Tooltip message={"https://subdomain.domain/"}>
                        <MdOutlineDesktopWindows className="text-xl" />
                      </Tooltip>
                    </InputTag>
                  </div>
                </div>
              </div>
              <div className="flex mt-6">
                <div className="grow px-3">
                  <HeaderText className="mb-3">Email</HeaderText>
                  <div className="relative">
                    <Input
                      padding={"129px"}
                      width={"100%"}
                      type="text"
                      placeholder="Enter Email"
                      value={email}
                      onChange={onChangeEmail}
                    />
                    <InputTag
                      style={
                        wallet?.connected
                          ? {
                              top: "4px",
                              backgroundColor: "#1a232b",
                              paddingLeft: "8px",
                            }
                          : {}
                      }
                    >
                      {wallet?.connected ? (
                        <ButtonWithoutEffect
                          style={{ marginRight: "6px" }}
                          onClick={mailAuthen}
                        >
                          <text className="btn-text">
                            {mailChanged ? "Change" : "Verify"}
                          </text>
                        </ButtonWithoutEffect>
                      ) : (
                        ""
                      )}
                      <Tooltip message={"name@subdomain.domain"}>
                        <MdEmail className="text-xl" />
                      </Tooltip>
                    </InputTag>
                  </div>
                </div>
                <div className="grow px-3">
                  <HeaderText className="mb-3">Twitter</HeaderText>
                  <div className="relative">
                    <Input
                      padding={"129px"}
                      width={"100%"}
                      type="text"
                      placeholder="Click Verify"
                      value={twitterUrl}
                      readOnly={true}
                    />
                    <InputTag
                      style={
                        wallet?.connected
                          ? {
                              top: "4px",
                              backgroundColor: "#1a232b",
                              paddingLeft: "8px",
                            }
                          : {}
                      }
                    >
                      {wallet?.connected ? (
                        <ButtonWithoutEffect
                          style={{ marginRight: "6px" }}
                          className={twitterUrl ? "noHover" : ""}
                          onClick={twLogin}
                        >
                          <text className="btn-text">
                            {twitterUrl ? "Verified" : "Verify"}
                          </text>
                        </ButtonWithoutEffect>
                      ) : (
                        ""
                      )}
                      <FaTwitter className="text-xl" />
                    </InputTag>
                  </div>
                </div>
              </div>
              <div className="flex mt-6">
                {twitterUrl && (
                  <div className="grow px-3">
                    <HeaderText className="mb-3">Discord</HeaderText>
                    <div className="relative">
                      <Input
                        padding={"129px"}
                        width={"100%"}
                        type="text"
                        placeholder="Click Verify"
                        readonly
                        value={discordUrl}
                        readOnly={true}
                      />

                      <InputTag
                        style={
                          wallet?.connected
                            ? {
                                top: "4px",
                                backgroundColor: "#1a232b",
                                paddingLeft: "8px",
                              }
                            : {}
                        }
                      >
                        {" "}
                        {wallet?.connected ? (
                          <ButtonWithoutEffect
                            style={{ marginRight: "6px" }}
                            onClick={() => verifySocial(SOCIAL_TYPE.discord)}
                          >
                            <text className="btn-text">
                              {discordUrl ? "Change" : "Verify"}
                            </text>
                          </ButtonWithoutEffect>
                        ) : (
                          ""
                        )}
                        <FaDiscord className="text-xl" />
                      </InputTag>
                    </div>
                  </div>
                )}
                {twitterUrl && (
                  <div className="grow px-3">
                    <HeaderText className="mb-3">Telegram</HeaderText>
                    <div className="relative">
                      <Input
                        padding={"129px"}
                        width={"100%"}
                        type="text"
                        placeholder="Click Verify"
                        value={telegramUrl}
                        readOnly={true}
                      />
                      <InputTag
                        style={
                          wallet?.connected
                            ? {
                                top: "4px",
                                backgroundColor: "#1a232b",
                                paddingLeft: "8px",
                              }
                            : {}
                        }
                      >
                        {" "}
                        {wallet?.connected ? (
                          <ButtonWithoutEffect
                            style={{ marginRight: "6px" }}
                            onClick={teleLogin}
                          >
                            <text className="btn-text">
                              {telegramUrl ? "Change" : "Verify"}
                            </text>
                          </ButtonWithoutEffect>
                        ) : (
                          ""
                        )}
                        <TelegramLoginButton
                          className="tele-btn-login"
                          botName="metalend_verify_bot"
                          dataOnauth={handleTelegramResponse}
                        />
                        <FaTelegram className="text-xl" />
                      </InputTag>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex mt-6">
                {discordUrl && (
                  <div className="grow px-3">
                    <HeaderText className="mb-3">
                      {" "}
                      YOUR DISCORD SERVER LINK
                    </HeaderText>
                    <div className="relative">
                      <Input
                        padding={"70px"}
                        width={"100%"}
                        type="text"
                        placeholder="Enter Your Server"
                        value={discordServerUrl}
                        onChange={(event: any) =>
                          setDiscordServerUrl(event.target.value.toString())
                        }
                      />
                      <InputTag>
                        {" "}
                        <Tooltip
                          message={"https://discord.com/invite/JPNTpy5m2x"}
                        >
                          <FaDiscord className="text-xl" />
                        </Tooltip>
                      </InputTag>
                    </div>
                  </div>
                )}
                {telegramUrl && (
                  <div className="grow px-3">
                    <HeaderText className="mb-3">
                      {" "}
                      YOUR TELEGRAM GROUP LINK
                    </HeaderText>
                    <div className="relative">
                      <Input
                        padding={"70px"}
                        width={"100%"}
                        type="text"
                        placeholder="Enter Your Group"
                        value={telegramGrUrl}
                        onChange={(event: any) =>
                          setTelegramGrUrl(event.target.value.toString())
                        }
                      />

                      <InputTag>
                        {" "}
                        <Tooltip message={"https://t.me/metalend_fi"}>
                          <FaTelegram className="text-xl" />
                        </Tooltip>
                      </InputTag>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex mt-6 pt-4 border-div-top">
                <div className="grow px-3">
                  <HeaderText className="mb-3">About Yourself</HeaderText>
                  <TextArea
                    width={"100%"}
                    placeholder="Enter about yourself"
                    onChange={(event: any) =>
                      setAbout(event.target.value.toString())
                    }
                    value={about}
                  />
                </div>
              </div>

              <div className=" mt-6 pt-4 border-div-top">
                <HeaderText className="mb-3 ml-5">
                  Verify Address Ownership
                </HeaderText>
                {Array(numberOfInputBox)
                  .fill(2)
                  .map((el, i) => (
                    <div className="flex " key={i}>
                      <div className="px-6">
                        <HeaderText className="mb-3">Select Chain</HeaderText>
                        <DropDown
                          onChangeValue={onSelectedChain}
                          indexAddress={i}
                          address={isFocus[i] ? addresses[i]?.address : null}
                          selected={
                            isFocus[i]
                              ? chains.find(
                                  (x) => x.id === addresses[i]?.chainData?.id
                                )
                              : chains[0]
                          }
                        />
                      </div>
                      <div className="grow px-3">
                        <HeaderText className="mb-3">Enter Address</HeaderText>
                        <div className="flex w-full ">
                          <div className="relative grow">
                            <Input
                              key={i}
                              padding={"70px"}
                              width={"100%"}
                              type="text"
                              placeholder="Enter Address"
                              onBlur={(event: any) => onChangeAddress(event, i)}
                              onFocus={(event: any) => onFocusInput(event, i)}
                              value={isFocus[i] ? addresses[i]?.address : null}
                            />
                            <InputTag
                              style={{ top: "4px", backgroundColor: "#1a232b" }}
                            >
                              <ButtonWithoutEffect
                                onClick={() => onVerify(i, el)}
                              >
                                <text className="btn-text">
                                  {addresses[i]?.signature
                                    ? "Verified"
                                    : "Verify"}
                                </text>
                              </ButtonWithoutEffect>
                            </InputTag>
                          </div>

                          {
                            <AiFillDelete
                              className="text-xl text-white mt-2 cursor-pointer"
                              onClick={() => removeAddress(i)}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <Button width={"12rem"} className="w-64 mt-3">
                <span onClick={() => addMoreAddress()}>
                  Add Blockchain Address+
                </span>
              </Button>
              <div className="flex justify-center mt-3 mb-6">
                <FundedButton onClick={onDataSubmit}>
                  <text className="btn-text">Submit</text>
                </FundedButton>
              </div>
            </div>
          </div>
        </div>
      
      </CardWrapper> */}

      <VerifyMailModal
        openModal={openModal}
        isOpen={isOpen}
        closeModal={closeModal}
        getUserData={getUserData}
        setMailVeried={setMailVeried}
        email={email}
      />
    </div>
  );
};

export default Selfverification;

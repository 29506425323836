import { Wrapper, FundedButton } from "./styles";
import { useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";

const XMarginVerification = () => {
  return (
    <Wrapper>
      <div className="part-one">
        <div className="text-one">
          <text className="header">Boost your credibility</text>
          <text className="sub-header">with an onchain Credit Score</text>
        </div>
        <text className="text-two">
          On RaiseR, you can opt-in to provide a score that reflects your{" "}
          <br /> activity and raises your chances of a successful raise.
        </text>
      </div>

      <div className="part-two">
        {/* <div className="section-one">
          <img
            src={
              "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663053009/Credora_Logo_-_White_and_updated_Green-01_1_lqocph.png"
            }
            alt="..."
          />
          <text className="section-one-text">
            Credit X has partnered with Credora to allow users to easily <br />{" "}
            share, on a zero-knowledge basis, their credit-worthiness
          </text>
        </div> */}

        <div className="section-two">
          <div className="first-half flex flex-col gap-14">
            <div className="section-one">
              <img
                src={
                  "https://res.cloudinary.com/drr1rnoxf/image/upload/v1663053009/Credora_Logo_-_White_and_updated_Green-01_1_lqocph.png"
                }
                alt="..."
              />
              <text className="section-one-text">
                Credit X has partnered with Credora to allow users to easily{" "}
                <br /> share, on a zero-knowledge basis, their credit-worthiness
              </text>
            </div>
            <img
              src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1663061239/Frame_14251_fgvvy0.png"
              alt="..."
            />
          </div>
          <div className="second-half mt-8">
            <text className="header">How it works:</text>
            <div className="each-part">
              <div className="img">
                <text className="text">1</text>
              </div>
              <text className="desc">
                Create a Borrower Profile on Credora
                {/* <span  onClick={() =>
                  window.open(
                    "https://credora.io/request-access/"
                  )
                } className="credora-link text-blue-120 ml-1 border-b-2 border-blue-120 cursor-pointer">here</span> */}
              </text>
            </div>

            <div className="each-part">
              <div className="img">
                <text className="text">2</text>
              </div>
              <text className="desc">
                Once your information is processed, <br />
                simply share your credit score with RaiseR <br />
                from your Credora dashboard.
              </text>
            </div>

            <div className="each-part">
              <div className="img">
                <text className="text">3</text>
              </div>
              <text className="desc">
                Your score will be linked to your borrowing <br />
                address, and boost your profile, while your <br /> personal
                information will be guarded at Credora
              </text>
            </div>

            {/* <button   className="modal-button ml-14 mt-3" onClick={() =>
                  window.open(
                    "https://credora.io/request-access/"
                  )
                } >
                  <span className="btn-text">Create Credora Profile <BsArrowRightShort className="arrow-btn text-2xl text-[#8EDA87]"/></span>
                </button> */}

            <FundedButton className="ml-14 mt-3"
              onClick={() => window.open("https://credora.io/request-access/")}
            >
              <span className="btn-text">
                Create Credora Profile{" "}
                <BsArrowRightShort className="arrow-btn text-2xl text-[#8EDA87]" />
              </span>
            </FundedButton>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default XMarginVerification;

import { PublicKey } from "@solana/web3.js";
import axios, { AxiosError } from "axios";
import { ILender, IPoolInfo } from "../utils/interfaces";
import { BN } from "@project-serum/anchor";
import { CREDORA_CLIENTID, CREODORA_CLIENTSECRET, MORALIS_KEY, decimalSB } from "../utils/constants";

let API_ENV = "devnet";
export const BASE_URL =
  API_ENV === "localnet"
    ? "http://localhost:3411"
    : "https://testnet-api.raiserdapp.com";

const createPoolInfo = (poolData: any, withLenders?: boolean): IPoolInfo => {
  let account = {
    owner: new PublicKey(poolData.owner),
    lendingMint: new PublicKey(poolData.lendingMint),
    lendingVault: new PublicKey(poolData.lendingVault),
    poolSeedSrc: new PublicKey(poolData.poolSeedSrc),
    poolUsdcVault: new PublicKey(poolData.poolUsdcVault),
    mintPoolToken: new PublicKey(poolData.mintPoolToken),

    lendingPeriod: poolData.lendingPeriod,
    subscriptionPeriod: poolData.subscriptionPeriod,
    goal: new BN(poolData.goal),
    min: new BN(poolData.min),
    approvedTime: poolData.approvedTime,

    totalLockedAmount: new BN(poolData.totalLockedAmount),
    totalMintedAmount: new BN(poolData.totalMintedAmount),
    totalClaimedAmount: new BN(poolData.totalClaimedAmount),
    totalUnstakedAmount: new BN(poolData.totalUnstakedAmount),
    totalClaimedInterestAmount: new BN(poolData.totalClaimedInterestAmount),
    totalAuctionUsdcAmount: new BN(poolData.totalAuctionUsdcAmount),

    state: poolData.state,

    lendingStartedTime: poolData.lendingStartedTime,
    poolFinishedTime: poolData.poolFinishedTime,

    couponRate: poolData.couponRate,
    subscriptionStartTime: poolData.subscriptionStartTime,

    collateralToken: new PublicKey(poolData.collateralToken),
    collateralVault: new PublicKey(poolData.collateralVault),
    collateralAmount: new BN(poolData.collateralAmount),
    extraPeriod: poolData.extraPeriod,

    bonusTokenMint: new PublicKey(poolData.bonusTokenMint),
    bonusTokenVault: new PublicKey(poolData.bonusTokenVault),
    bonusAmount: new BN(poolData.bonusAmount),

    bump: poolData.bump,
    isWithdrawed: poolData.isWithdrawed,
    count: poolData.count,
  };

  let lenders: ILender[] = [];
  if (withLenders) {
    for (let j = 0; j < poolData.usersList.length; j++) {
      let lenderData = poolData.usersList[j];

      let lender: ILender = {
        account: {
          owner: new PublicKey(lenderData.owner),
          pool: new PublicKey(lenderData.pool),
          lockedBalance: new BN(lenderData.lockedBalance),
          totalCommitment: new BN(lenderData.totalCommitment),
          lastClaimedTime: lenderData.lastClaimedTime,
          claimedInterest: new BN(lenderData.claimedInterest),
          claimedBonus: new BN(lenderData.claimedBonus),
          claimedCollateral: new BN(lenderData.claimedCollateral),
        },
        publicKey: new PublicKey(lenderData.publicKey),
      };

      lenders.push(lender);
    }
  }

  let pool: IPoolInfo = {
    publicKey: new PublicKey(poolData.publicKey),
    account: account,
    usersList: lenders,
    cancelledState: poolData.cancelledState
  };

  return pool;
};

export const getPool = async (publicKey: string, withLenders?: boolean) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getPool",
      method: "post",
      data: {
        publicKey: publicKey,
        withLenders: withLenders,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (data.status === "OK") {
      let poolData = data.message;

      obj = {
        data: createPoolInfo(poolData, withLenders),
        status: "RECEIVED",
      };
    } else {
      obj = {
        data: "NO DATA",
        status: "FAILED",
      };
    }

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const updatePool = async (publicKey: string) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/updatePool",
      method: "post",
      data: {
        publicKey: publicKey,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };
    //console.log("be-calls updatePool = ", data.message.totalLockedAmount);
    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getPoolsByState = async (
  state: number[],
  offset: number,
  limit?: number | null,
  sort?: any,
  withLenders?: boolean
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getPoolsByState",
      method: "post",
      data: {
        state: state,
        offset: offset,
        sort,
        limit: limit,
        withLenders: withLenders,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    let out: IPoolInfo[] = [];

    if (data.status === "OK") {
      for (let i = 0; i < data.message.length; i++) {
        let poolData = data.message[i];

        out.push(createPoolInfo(poolData, withLenders));
      }
    }
    
    obj = {
      data: out,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getPoolsForBorrower = async (
  borrowerPublicKey: string,
  offset: number,
  limit?: number | null,
  withLenders?: boolean
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getPoolsForBorrower",
      method: "post",
      data: {
        borrowerPublicKey: borrowerPublicKey,
        offset: offset,
        limit: limit,
        withLenders: withLenders,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    let out: IPoolInfo[] = [];

    if (data.status === "OK") {
      for (let i = 0; i < data.message.length; i++) {
        let poolData = data.message[i];

        out.push(createPoolInfo(poolData, withLenders));
      }
    }

    obj = {
      data: out,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getPoolsFromOffset = async (
  offset: number,
  limit?: number | null,
  withLenders?: boolean
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getPoolsFromOffset",
      method: "post",
      data: {
        offset: offset,
        limit: limit,
        withLenders: withLenders,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    let out: IPoolInfo[] = [];

    if (data.status === "OK") {
      for (let i = 0; i < data.message.length; i++) {
        let poolData = data.message[i];

        out.push(createPoolInfo(poolData, withLenders));
      }
    }

    obj = {
      data: out,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getPoolsWithNFTCollateral = async (
  offset: number,
  limit?: number | null,
  withLenders?: boolean,
  state?: number[],
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getPoolsWithNFTCollateral",
      method: "post",
      data: {
        offset: offset,
        limit: limit,
        withLenders: withLenders,
        state: state,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    let out: IPoolInfo[] = [];

    if (data.status === "OK") {
      for (let i = 0; i < data.message.length; i++) {
        let poolData = data.message[i];

        out.push(createPoolInfo(poolData, withLenders));
      }
    }
    
    obj = {
      data: out,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getPoolsNFTByState = async (
  offset: number,
  limit?: number | null,
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getPoolsByState",
      method: "post",
      data: {
        offset: offset,
        limit: limit,
        state: 5,
        withLenders: true
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    let out: any = [];

    if (data.status === "OK") {
      out = data.message;
    }
    
    obj = {
      data: out,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};


export const searchPoolsByState = async (
  state: number[],
  address: string,
  sort?: any,
  withLenders?: boolean
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/searchPoolsByState",
      method: "post",
      data: {
        state: state,
        address,
        sort,
        withLenders: withLenders,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    let out: IPoolInfo[] = [];

    if (data.status === "OK") {
      for (let i = 0; i < data.message.length; i++) {
        let poolData = data.message[i];

        out.push(createPoolInfo(poolData, withLenders));
      }
    }
    
    obj = {
      data: out,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const setCancelledState = async (publicKey: string, cancelledState: number) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/setCancelledState",
      method: "post",
      data: {
        publicKey: publicKey,
        cancelledState: cancelledState,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getLender = async (owner: string, pool: string) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getLender",
      method: "post",
      data: {
        owner: owner,
        pool: pool,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    let lender: any;

    if (data.status === "OK") {
      lender = {
        account: {
          owner: new PublicKey(data.message.owner),
          pool: new PublicKey(data.message.pool),
          lockedBalance: new BN(data.message.lockedBalance),
          totalCommitment: new BN(data.totalCommitment),
          lastClaimedTime: data.message.lastClaimedTime,
          claimedInterest: new BN(data.message.claimedInterest),
          claimedBonus: new BN(data.message.claimedBonus),
          claimedCollateral: new BN(data.message.claimedCollateral),
        },
        publicKey: new PublicKey(data.message.publicKey),
      } as ILender;
    } else {
      lender = undefined;
    }

    obj = {
      data: lender,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const updateLender = async (publicKey: string) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/updateLender",
      method: "post",
      data: {
        publicKey: publicKey,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getLendersForPools = async (offset: number, limit: number) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getLendersForPools",
      method: "post",
      data: {
        offset: offset,
        limit: limit,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const updateGlobalState = async () => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/updateGlobalState",
      method: "get",
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getGlobalStateFromDB = async () => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getGlobalState",
      method: "get",
    });

    let out: any;

    if (data.status === "OK") {
      out = {
        governor: new PublicKey(data.message.governor),
        admins: data.message.admins.map((admin: any) => {
          return new PublicKey(admin);
        }),
        approvalLimit: data.message.approvalLimit,

        feeVault: new PublicKey(data.message.feeVault),
        feeMint: new PublicKey(data.message.feeMint),

        feeVaultTreasury: new PublicKey(data.message.feeVaultTreasury),
        treasuryMint: new PublicKey(data.message.treasuryMint),

        requestFeeApproved: new BN(data.message.requestFeeApproved),
        requestFeeUnapproved: new BN(data.message.requestFeeUnapproved),
        requestFeeApprovedConverted:  new BN(data.message.requestFeeApproved).div(new BN(decimalSB)),
        requestFeeUnapprovedConverted: new BN(data.message.requestFeeUnapproved).div(new BN(decimalSB)),
        withdrawFeeApproved: new BN(data.message.withdrawFeeApproved),
        withdrawFeeUnapproved: new BN(data.message.withdrawFeeUnapproved),
        approversCount: new BN(data.message.approvedList.length),
        approvedList: data.message.approvedList.map((approver: any) => {
          return new PublicKey(approver);
        }),

        limitPoolCntPerBorrower: data.message.limitPoolCntPerBorrower,
        limitAdminCount: data.message.limitAdminCount,
        limitApprovedBorrowerCount: data.message.limitApprovedBorrowerCount,

        requestExpireDuration: data.message.requestExpireDuration,

        commitFee: new BN(data.message.commitFee),
        claimFee: new BN(data.message.claimFee),

        gasFee: new BN(data.message.gasFee),
        gasFeeConverted: new BN(data.message.gasFee).div(new BN(decimalSB)),

        extraPeriod: data.message.extraPeriod,
        feeBurnedRate: data.message.feeBurnedRate,

        daoAdmin: data.message.daoAdmin,
        daoUsdcVault: data.message.daoUsdcVault,
      };
    }

    obj = {
      data: out,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getMessage = async (solanaAddress: string) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/get-message",
      method: "post",
      data: { solanaAddress },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getVerifiedData = async (solanaAddress: string) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/get-self-verification",
      method: "post",
      data: { solanaAddress },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const login = async (solanaAddress: string, signature: string) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/login",
      method: "post",
      data: { solanaAddress, signature },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const update = async (
  solanaAddress: string,
  signature: string,
  twitterUrl: string,
  facebookUrl: string,
  discordUrl: string,
  telegramUrl: string,
  name: string,
  website: string,
  email: string,
  about: string,
  ethereumAddress: string,
  addresses: any,
  teleGroup: string,
  discordServer: string
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/update-self-verification",
      method: "post",
      data: {
        solanaAddress,
        signature,
        twitterUrl,
        facebookUrl,
        discordUrl,
        telegramUrl,
        name,
        website,
        email,
        about,
        ethereumAddress,
        addresses,
        teleGroup,
        discordServer
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const saveEthereumAddress = async (
  solanaAddress: string,
  solanaSignature: string,
  ethereumAddress: string,
  ethereumSignature: string
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/save-eth",
      method: "post",
      data: {
        solanaAddress,
        ethereumAddress,
        ethereumSignature,
        solanaSignature,
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const createMarket = async (
  poolAddress: string,
  marketAddress: string,
  dexProgramAddress: string,
  baseTokenMint: string,
  quoteTokenMint: string
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/createMarket",
      method: "post",
      data: {
        poolAddress: poolAddress,
        marketAddress: marketAddress,
        dexProgramAddress: dexProgramAddress,
        baseTokenMint: baseTokenMint,
        quoteTokenMint: quoteTokenMint,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const verifySocialInfo = async (
  dataAuth: any, 
  type: number,
  solanaAddress?: string
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/verify-social-info",
      method: "post",
      data: {
        data: dataAuth,
        type, 
        solanaAddress
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const sendMailVerify = async (
  email: string,
  solanaAddress?: string
) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/send-mail-verify",
      method: "post",
      data: {
        email, 
        solanaAddress
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getMarket = async (poolPublicKey: string) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/getAllMarkets",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    for (let i = 0; i < data.length; i++) {
      if (data[i].poolAddress && data[i].poolAddress === poolPublicKey) {
        obj = {
          data: data[i].address,
          status: "RECEIVED",
        };
        return obj;
      }
    }

    obj = {
      data: "No market for pool",
      status: "FAILED",
    };
    //console.log("be-calls updatePool = ", data.message.totalLockedAmount);
    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const authTwitter = async () => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/twitter",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    obj = {
      data: "No market for pool",
      status: "RECEIVED",
    };
    //console.log("be-calls updatePool = ", data.message.totalLockedAmount);
    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getCreditScore = async () => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: "https://platform.credora.io",
      url: "/api/v2/risk",
      method: "post",
      data: [],
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "clientId": CREDORA_CLIENTID,
        "clientSecret": CREODORA_CLIENTSECRET
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    return obj;
  }
};

export const getAuctionItem = async (poolPublicKey: string) => {
  let obj;

  try {
    const { data } = await axios({
      baseURL: BASE_URL,
      url: "/auction/get-auction-nft",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    throw obj
  }
};

const getKeyMoralis = () => {
  return MORALIS_KEY[Math.floor(Math.random()*MORALIS_KEY.length)];
}

export const getNftSolana = async (address: string) => {
  let obj;

  try {
    const { data } = await axios({
      url: `https://solana-gateway.moralis.io/nft/devnet/${address}/metadata`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        'X-API-Key': getKeyMoralis()
      },
    });

    obj = {
      data: data,
      status: "RECEIVED",
    };

    return obj;
  } catch (error) {
    obj = {
      data: error,
      status: "ERROR",
    };

    throw obj
  }
};


//---------------------------------------------------------
/*
const testcalls = async () => {
    let obj;

    console.log("---------------------------------------------------------");
    console.log("updatePool");
    obj = await updatePool("9tts6HaTuu72X8UXwk4eS2fEmrf8f4DcreGaiRUU9Nof");
    if ((obj.status === "RECEIVED") && (obj.data.status === "OK")) {
        console.log("Updated");
    }

    console.log("---------------------------------------------------------");
    console.log("getPoolsByState");
    obj = await getPoolsByState([3, 6], 0, 5);
    if ((obj.status === "RECEIVED") && (obj.data.status === "OK")) {
        console.log(obj.data.message);
    }


    console.log("---------------------------------------------------------");
    console.log("getPoolsFromOffset");
    obj = await getPoolsFromOffset(0, 5);
    if ((obj.status === "RECEIVED") && (obj.data.status === "OK")) {
        console.log(obj.data.message);
    }

    console.log("---------------------------------------------------------");
    console.log("getPoolsInfoForDex");
    obj = await getPoolsInfoForDex(0, 2);
    if ((obj.status === "RECEIVED") && (obj.data.status === "OK")) {
        console.log(obj.data.message);
    }

    console.log("---------------------------------------------------------");
    console.log("updateLender");
    obj = await updateLender("9JiEEaA5RPv941BR5Myb6VtPxVY5YMSWLNFESijRsn54");
    if ((obj.status === "RECEIVED") && (obj.data.status === "OK")) {
        console.log("Updated");
    }

    console.log("---------------------------------------------------------");
    console.log("getLendersForPools");
    obj = await getLendersForPools(0, 5);
    if ((obj.status === "RECEIVED") && (obj.data.status === "OK")) {
        console.log(obj.data.message);
    }

    console.log("---------------------------------------------------------");
    console.log("updateGlobalState");
    obj = await updateGlobalState();
    if ((obj.status === "RECEIVED") && (obj.data.status === "OK")) {
        console.log(obj.data.message);
    }

    console.log("---------------------------------------------------------");
    console.log("getGlobalState");
    obj = await getGlobalState();
    if ((obj.status === "RECEIVED") && (obj.data.status === "OK")) {
        console.log(obj.data.message);
    }
}

testcalls();
*/

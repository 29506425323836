import { Wrapper,ButtonWithEffect } from "./style";
import {useState} from "react"
import { useNavigate } from "react-router-dom";


const LendEmptyPage = ({text,url}:{text:any,url:any}) => {
 
    const navigate = useNavigate();
  return (
    <Wrapper>
       <div className="main">
         <div className="content-section">
            <text className="header">{text?text:'Lend'} on </text>
            <text className="sub-header">RaiseR</text>
            <ButtonWithEffect   onClick={()=>navigate(url)}>
                <span>{text}</span>
            </ButtonWithEffect>
         </div>
       </div>
    </Wrapper>
  );
};

export default LendEmptyPage;

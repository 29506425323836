import {
  Container,
  Wrapper,
  TopSearchBar,
  ContentWrapper,
  Main,
} from "./style";
import AuctionHistory from "../../components/Auction";
import { useEffect, useState } from "react";
import {
  getPoolsByState,
  getPoolsWithNFTCollateral,
} from "../../be-calls/be-calls";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getAuctionPoolInfo } from "../../utils/instructions";
import SpinnerImg from "../../assets/images/spin.svg"

const AUCTION_TAB_TYPE = {
  liveAution: "liveAuctions",
  myBid: "myBids",
  auctionHistory: "auctionHistory",
};

const AuctionHistoryUI = () => {
  const [listItem, setListItem] = useState({
    liveAuctions: [],
    myBids: [],
    auctionHistory: [],
  });
  const [currentListGov, setCurrentListGov] = useState([]);
  const { connection } = useConnection();
  const wallet = useWallet();
  const [refreshData, setRefreshData] = useState(false);

  const [activeTabCont, setActiveTabCont] = useState(
    AUCTION_TAB_TYPE.liveAution
  );

  const onChangeTab = (value) => {
    console.log(value);
    setActiveTabCont(value);
  };

  const getPoolInfo = (listPoolInfo, listItem, isLive) => {
    const newList = listItem.map((el, index) => {
      if (listPoolInfo[index]?.state.toNumber() >= 0) {
        if (listPoolInfo[index]?.isExecuted) {
          el.status = "Started";
        } else {
          el.status = "Ready";
        }
        if (
          listPoolInfo[index]?.timeEnd?.toNumber() < Date.now() / 1000 &&
          new Date(listPoolInfo[index]?.timeEnd?.toNumber()*1000).getFullYear() > 1970
        ) {
          el.status = "Ended";
          if (isLive) return;
        }
        el.auctionPoolInfo = listPoolInfo[index];
        return el;
      }
    });
    return newList;
  };

  useEffect(() => {
    const getAuction = async () => {
      try {
        if (wallet.publicKey) {
              const newListItem = {}
              const res = await getPoolsWithNFTCollateral(0, null, true, [6, 5]);
              const resState5 = res.data.filter(el => el.account.state == 5)
              const resState6 = res.data.filter(el => el.account.state == 6)
              console.log(resState5, resState6);
              const listPoolInfo = await getAuctionPoolInfo(connection, wallet, resState5);
              const listPoolInfoHistory = await getAuctionPoolInfo(connection, wallet, resState6);
              const newList = getPoolInfo(listPoolInfo, resState5, true);
              const myNewList = getPoolInfo(listPoolInfo, resState5);
              newListItem.liveAuctions = newList

              const newListHistory = getPoolInfo(listPoolInfoHistory, resState6);
              newListItem.auctionHistory = newListHistory
              const myBid = myNewList.concat(newListHistory).filter(
                (el) =>
                  el?.auctionPoolInfo?.currentMaxBidUser?.toBase58().toLowerCase() ==
                  wallet.publicKey.toBase58().toLowerCase()
              );
              newListItem.myBids = myBid
              setListItem(newListItem);
              return;
          }
        } catch (er) {}
    };
    getAuction();
  }, [activeTabCont ,connection, wallet, refreshData]);

  return (
    <>
      <Main>
        <Container>
          <Wrapper>
            <div className="text-sm font-medium text-center menu-color mb-4 dark:text-gray-400 dark:border-gray-700 mt-10">
              <ul className="flex flex-wrap -mb-px z-30">
                <li className="cursor-pointer mr-3 ">
                  <text
                    onClick={() => onChangeTab(AUCTION_TAB_TYPE.liveAution)}
                    className={
                      `inline-block rounded-t-lg border-b-2 border-transparent` +
                      (activeTabCont == AUCTION_TAB_TYPE.liveAution
                        ? "active active-item border-blue-600 dark:text-blue-500 dark:border-blue-500"
                        : "")
                    }
                  >
                    Live Auctions
                  </text>
                </li>
                <li className="cursor-pointer mr-3">
                  <text
                    onClick={() => onChangeTab(AUCTION_TAB_TYPE.myBid)}
                    className={
                      `inline-block rounded-t-lg border-b-2 border-transparent` +
                      (activeTabCont == AUCTION_TAB_TYPE.myBid
                        ? "active active-item border-blue-600 dark:text-blue-500 dark:border-blue-500"
                        : "")
                    }
                    aria-current="page"
                  >
                    MY BIDS
                  </text>
                </li>
                <li className="cursor-pointer">
                  <text
                    onClick={() => onChangeTab(AUCTION_TAB_TYPE.auctionHistory)}
                    className={
                      `inline-block rounded-t-lg border-b-2 border-transparent ` +
                      (activeTabCont == AUCTION_TAB_TYPE.auctionHistory
                        ? "active active-item border-blue-600 dark:text-blue-500 dark:border-blue-500"
                        : "")
                    }
                  >
                    auction history
                  </text>
                </li>
              </ul>
            </div>
              {Object.keys(listItem).map((key) => {
                return <ContentWrapper style={{display: key == activeTabCont ? "flex" : "none" }}>
                {listItem[key].map(value => {
                  if (value)
                  return (
                    <AuctionHistory
                      item={value}
                      refreshData={refreshData}
                      setRefreshData={setRefreshData}
                      isHistory={
                        activeTabCont == AUCTION_TAB_TYPE.auctionHistory
                      }
                    />
                  )})
                    }
                  </ContentWrapper>
              })}
          </Wrapper>
        </Container>
      </Main>
    </>
  );
};

export default AuctionHistoryUI;

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  width: 100%;
  .first-section {
    display: flex;
    justify-content: space-between;
  }
`;

export const SecondSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #3d4f61;
  margin-top: 2rem;
  padding-top: 2rem;
  .menu-option {
    display: flex;
    gap: 1.5rem;
  }
  .input-box-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
  }
  .liability-section {
    display: flex;
    width: 340px;
    justify-content: space-between;
    margin-top: 0.5rem;
    .liability-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #687d95;
    }
  }
`;

export const DisableButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 14px;
  gap: 10px;
  height: 33px;
  cursor: pointer;
  /* primary/semi green-blue */
  background: gray;
  border-radius: 6px;
  span {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #12181f;
  }
`;
export const HeaderText = styled.text`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #687d95;
`;
export const MenuWrapper = styled.text.attrs(
  (props: { active: Boolean }) => props
)`
  border-bottom: ${(props) =>
    props.active === true ? "2px solid #18B2DE" : ""};
  padding-bottom: 6px;
`;
export const MenuText = styled.text.attrs(
  (props: { active: Boolean }) => props
)`
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  color: ${(props) => (props.active === true ? "#FFFFFF" : "#3D4F61")};
`;
export const HiglightedLine = styled.hr`
  background: #18b2de;
  height: 2px;
  width: inherit;
  box-shadow: 0px 1px 6px 2px rgba(1, 252, 252, 0.23);
  border-radius: 15px;
`;
export const GradientText = styled.text`
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 65px;
`;

export const Input = styled.input.attrs(
  (props: { padding: any; width: String }) => props
)`
  width: ${({ width }) => width || "340px"};
  height: 40px;
  background: #1a232b;
  border-radius: 6px;
  border: 1px solid transparent;
  outline: none;
  transition: border 0.3s ease-in;
  padding-left: 12px;
  padding-right: ${(props) => (props.padding ? props.padding : "0px")};
  font-size: 12px;
  color: #ffffff;
  font-family: "Space Grotesk";
  font-weight: 500;
  &:focus {
    border: 1px solid rgba(1, 192, 252, 0.8);
    /* box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5); */
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #ffffff;
    }
    :-ms-input-placeholder {
      color: #ffffff;
    }
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
  :-ms-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
`;

export const TextArea = styled.textarea.attrs(
  (props: { padding: any; width: any }) => props
)`
  width: ${({ width }) => width || "340px"};
  height: 60px;
  background: #1a232b;
  border-radius: 6px;
  padding-top: 3px;
  border: 1px solid transparent;
  outline: none;
  transition: border 0.3s ease-in;
  padding-left: 12px;
  padding-right: ${(props) => (props.padding ? props.padding : "0px")};
  font-size: 12px;
  color: #ffffff;
  font-family: "Space Grotesk";
  font-weight: 500;
  &:focus {
    border: 1px solid rgba(1, 192, 252, 0.8);
    /* box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5); */
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #ffffff;
    }
    :-ms-input-placeholder {
      color: #ffffff;
    }
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
  :-ms-input-placeholder {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #3d4f61;
  }
`;

export const ButtonWithEffect = styled.button`
  position: relative;
  margin-top: 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
  background: transparent;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 6px;
  width: 120px;
  padding: 13px 7px;
  z-index: 1;
  transition: width 0.3s linear;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 100%;
    padding: 13px 7px;
    text-align: center;
    display: flex;
    justify-content: center;
    background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
      border-box;
    border: inherit;
    border-radius: inherit;
    mask: linear-gradient(rgba(1, 192, 252, 0.7), rgba(1, 192, 252, 0.7))
        padding-box,
      linear-gradient(white, white);
    -webkit-mask-composite: xor;
  }
  &:hover {
    position: relative;
    border: 3px solid transparent;
    border-radius: 6px;
    width: 120px;
    padding: 13px 710x;
    box-shadow: white;
    filter: drop-shadow(0px 3px 9px rgba(255, 255, 255, 0.46));
    &::before {
      content: "";
      position: absolute;
      text-align: center;
      top: 0;
      left: 0;
      width: 120px;
      height: 100%;
      padding: 13px 7px;
      background: linear-gradient(269.54deg, #01c0fc 0.15%, #0085ff 96.26%)
        border-box;
      border: inherit;
      border-radius: inherit;
      mask: linear-gradient(white, white) padding-box,
        linear-gradient(white, white);
      -webkit-mask-composite: xor;
    }
  }
  .btn-text {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #ffffff;
  }
`;
export const ButtonWithoutEffect = styled.button`
  position: relative;
  margin-top: 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
  background: transparent;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 6px;
  width: 120px;
  padding: 13px 7px;
  z-index: 1;
  transition: width 0.3s linear;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    background: linear-gradient(269.54deg, gray 0.15%, gray 96.26%) border-box;
    border: inherit;
    border-radius: inherit;
    mask: linear-gradient(rgba(1, 192, 252, 0.7), rgba(1, 192, 252, 0.7))
        padding-box,
      linear-gradient(white, white);
    -webkit-mask-composite: xor;
  }
  .btn-text {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
    color: #ffffff;
  }
`;

export const InputTag = styled.div`
  border-left: 1px solid #ccc;
  position: absolute;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  color: #ffffff;
  top: 12px;
  right: 9px;
  padding-left: 16px;
  padding-right: 8px;
`;

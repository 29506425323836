import { /* Dialog, */ Transition, Popover } from "@headlessui/react";
import { Fragment } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {getTokenNameFromAddress, shortAddress, truncateStr} from "../../utils/utils";
import toast from "react-hot-toast";
import { H4 } from "./styles";
import { BsInfoCircle } from "react-icons/bs";
import {MdOutlineDesktopWindows} from "react-icons/md"

export function CreditInfoModal({ pool }) {

  const CopyAction = () => {
    toast.success("Copied Successfully!");
  };
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="flex">
            <H4 className="tracking-wider cursor-pointer hover:text-[#01FCFC] hover:border-b hover:border-[#01C0FC] max-h-[15px]">
            CREDORA VERIFIED
            </H4>
            <BsInfoCircle className="ml-1 mt-0.5 cursor-pointer text-xs text-[#01C0FC]" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              style={{ left: "270px", bottom: "15px"  }}
              className="absolute z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 transform overflow-hidden rounded-2xl bg-[#28333F] p-6 text-left align-middle shadow-xl transition-all"
            >
                <div className="flex flex-col gap-1">
              
            
                    {pool?.credit?.cpUsername ? (
                      <div className="text-[#687D95] text-xs font-medium">
                        Username: {pool?.credit?.cpUsername}
                      </div>
                    ) : (
                      ""
                    )}
                     {pool?.credit?.creditScore ? (
                      <div className="text-[#687D95] text-xs font-medium">
                        Credit Score: {pool?.credit?.creditScore}
                      </div>
                    ) : (
                      ""
                    )}
                     {pool?.credit?.creditScoreRating ? (
                      <div className="text-[#687D95] text-xs font-medium">
                        Credit Score Rating: {pool?.credit?.creditScoreRating}
                      </div>
                    ) : (
                      ""
                    )}
                     {pool?.credit?.borrowCapacity ? (
                      <div className="text-[#687D95] text-xs font-medium">
                        Borrow Capacity: {pool?.credit?.borrowCapacity}
                      </div>
                    ) : (
                      ""
                    )}

                </div> 
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

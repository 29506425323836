import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import {
  getGlobalStateFromDB,
  getPoolsByState,
  searchPoolsByState,
} from "../../../be-calls/be-calls";
import { FilterSearch, StatusPool } from "../../../components";
import { AWAITING_FOR_SUBSCRIPTION } from "../../../utils/constants";
import { SB_MINT_ADDRESS } from "../../../utils/ids";
import {
  IGlobalState,
  IPoolInfo,
  TokenAccount,
} from "../../../utils/interfaces";
import { useWalletAccounts } from "../../../utils/walletManager";
import {
  Container,
  Wrapper,
  TopSearchBar,
  ContentWrapper,
  Main,
} from "./styles";
import { ButtonWithoutEffect } from "../ActiveSubscriptionPool/styles";
import { getCreditScoreByPool } from "../../../utils/utils";
import LoadingBar from 'react-top-loading-bar'

const PreSubscriptionPools = () => {
  const wallet = useWallet();
  const [poolWithOwnersList, setPoolWithOwnersList] = useState<IPoolInfo[]>([]);
  const [adminInfo, setAdminInfo] = useState<IGlobalState | undefined>(
    undefined
  );
  const [feeAccount, setFeeAccount] = useState<TokenAccount | undefined>(
    undefined
  );
    const [progress, setProgress] = useState(0)
  const [dataLoaded, setDataLoaded] = useState<any>(false);
  const { userAccounts, tokenMap } = useWalletAccounts();
  const [showCount, setShowCount] = useState(5);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(progress + 10);
    }, 1000);
    if(dataLoaded) {
      setProgress(100);
      clearTimeout(timeout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [dataLoaded]);


  useEffect(() => {
    // if (wallet.publicKey) {
    //   checkAdmin();
    // }
    checkAdmin();
    // }, [wallet.connected, wallet.publicKey]);
  }, []);

  useEffect(() => {
    // if (wallet.publicKey && adminInfo) {
    //   getPoolsByState([AWAITING_FOR_SUBSCRIPTION], 0, showCount).then(
    //     (info: any) => {
    //       if (info.status === "RECEIVED") {
    //         let poolWithOwners: IPoolInfo[] = info.data;
    //         setPoolWithOwnersList(poolWithOwners);
    //       }
    //     }
    //   );
    // }
    getPoolsByState([AWAITING_FOR_SUBSCRIPTION], 0, showCount).then(
      async (info: any) => {
        if (info.status === "RECEIVED") {
          let poolWithOwners: IPoolInfo[] = info.data;
          const resArr = await getCreditScoreByPool(poolWithOwners)
          setPoolWithOwnersList(resArr);
          setDataLoaded(true)
        }
      }
    );
    // }, [wallet.connected, adminInfo, wallet.publicKey, showCount]);
  }, [adminInfo, showCount]);

  useEffect(() => {
    const found = userAccounts.find(
      (acc) => acc.info.mint.toBase58() === SB_MINT_ADDRESS
    );
    setFeeAccount(found);
  }, [userAccounts]);

  const checkAdmin = async () => {
    let info: any = await getGlobalStateFromDB();

    if (info.status === "RECEIVED") {
      let adminInfo: IGlobalState = info.data;
      setAdminInfo(adminInfo);
    }
  };

  const showMore = () => {
    if (poolDfWithOwnersList[0]?.account?.count || 0 > showCount) setShowCount(showCount + 5);
  };

  const [checkVerifiedPool, setCheckVerifiedPool] = useState(false);
  const [poolDfWithOwnersList, setPoolDfWithOwnersList] = useState<IPoolInfo[]>(
    []
  );
  const [filterState, setFilterState] = useState({});
  const [sortState, setSortState] = useState([]);

  const onChangeFilter = (filter: any) => {
    let newListP = poolDfWithOwnersList;
    if (filter?.selfVerified) {
      setCheckVerifiedPool(true);
    } else {
      setCheckVerifiedPool(false);
    }
    if (filter?.credoraVerified) {
      newListP = newListP.filter((el) => !!el?.credit);
    }
    if (filter?.collateralosedPool) {
      newListP = poolDfWithOwnersList.filter(
        (el) => el?.account.collateralAmount.toNumber() > 0
      );
    }
    setPoolWithOwnersList(newListP);
  };

  useEffect(() => {
    onChangeFilter(filterState);
    console.log(poolDfWithOwnersList);
  }, [filterState, poolDfWithOwnersList]);

  useEffect(() => {
    onChangeSort(sortState);
  }, [sortState]);

  const onChangeSort = (sort: any) => {
    console.log(sort);
    getPoolsByState(
      [AWAITING_FOR_SUBSCRIPTION],
      0,
      showCount,
      sort
    ).then(async (info: any) => {
      if (info.status === "RECEIVED") {
        let poolWithOwners: IPoolInfo[] = info.data;
        const resArr = await getCreditScoreByPool(poolWithOwners);
        setPoolWithOwnersList(resArr);
        setPoolDfWithOwnersList(resArr);
        setDataLoaded(true);
      }
    });
  };

  const onSearchPool = async (address: string) => {
    if (address) {
      const info: any = await searchPoolsByState(
        [AWAITING_FOR_SUBSCRIPTION],
        address,
        sortState
      );
      if (info.status === "RECEIVED") {
      setPoolWithOwnersList(info.data || []);
      setPoolDfWithOwnersList(info.data||[]);
      }
    } else {
      onChangeSort(sortState);
    }
  };


  return (
    <Main>
       <LoadingBar color="#01C0FC" progress={progress} onLoaderFinished={() => setProgress(0)} />
      <Container>
        <Wrapper>
        <TopSearchBar>
            <FilterSearch
              onSearchPool={onSearchPool}
              filterState={filterState}
              setFilterState={setFilterState}
              sortState={sortState}
              setSortState={setSortState}
            />
          </TopSearchBar>
          <ContentWrapper className="mt-10">
            {/*{adminInfo && feeAccount ? (*/}
            {dataLoaded && adminInfo ? (
              poolWithOwnersList
                .filter((_poolInfo, i) => i < showCount)
                .map((pool, index) => {
                  return (
                    <StatusPool
                      style={{ display: "grid" }}
                      key={index}
                      poolInfo={pool}
                      feeVault={adminInfo.feeVaultTreasury}
                      feeMint={adminInfo.feeMint}
                      sbFeeVault={adminInfo.feeVault}
                      userSbVault={feeAccount?.pubkey!}
                      extraPeriod={adminInfo?.extraPeriod}
                      wallet={wallet}
                      userAccounts={userAccounts}
                      tokenMap={tokenMap}
                      checkVerifiedPool={checkVerifiedPool}
                    />
                  );
                })
            ) : (
              <></>
            )}
            {dataLoaded && adminInfo && poolWithOwnersList && poolWithOwnersList.length ? (
              <div className="flex justify-center">
                <ButtonWithoutEffect onClick={showMore}>
                  <span>Load More</span>
                </ButtonWithoutEffect>
              </div>
            ) : (
              <div className="flex justify-center">
                {dataLoaded && adminInfo && poolWithOwnersList && (
                  <text className="text-white">0 Pool Found</text>
                )}
              </div>
            )}
          </ContentWrapper>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default PreSubscriptionPools;
